import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { lineBreak } from '../../ResearchReport/TradeArea';
import {
    labelStyle,
    table10Column,
    tableCellStyle,
} from './TradeAreaStyle';

//国勢調査 ヘッダー
const NationalTaxHeaderColumns = [
    //コロンで改行
    {colName: 'taxAuditAdministrativeName', label:'行政名称'},
    {colName: 'taxAuditHouseholdCount', label:'世帯数:(千世帯)'},
    {colName: 'taxAuditPopulation', label:'人口:(千人)'}, 
    {colName: 'taxAuditAveragePopulation', label:'平均人員:(人)'},
    {colName: 'taxAuditSingleHouseholdRatio', label:'単身比:(%)'},
    {colName: 'taxAuditAgeGroup20Ratio', label:'20代比:(%)'},
    {colName: 'taxAuditAgeGroup65MoreRatio', label:'65上比:(%)'},
    {colName: 'taxAuditOffice', label:'事業所'},
    {colName: 'taxAuditEmployees', label:'従業員:(人)'},
    {colName: 'taxAuditAverageEmployees', label:'平均人員:(人)'},
];


export interface TradeAreaProps {
    props:{
    }
};

export const TradeAreaNationalTaxAudit:React.FC<TradeAreaProps> = ({props}) => {

    const objTaxAudit = useAppSelector((state) => state.tradeArea.taxAuditObj);

    return (
        <Box>

            <Typography component="h2">住宅世帯調査</Typography>
            
            <Typography component="h2" mt={2}>①周辺の国税調査（H27）データ</Typography>
            
            <TableContainer>
                <Table sx={table10Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {NationalTaxHeaderColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* データ */}
                        <TableRow>
                            <TableCell sx={labelStyle}>{objTaxAudit.taxAuditAdministrativeName}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objTaxAudit.taxAuditHouseholdCount)}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objTaxAudit.taxAuditPopulation)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objTaxAudit.taxAuditAveragePopulation*100)/100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objTaxAudit.taxAuditSingleHouseholdRatio*10)/10).toFixed(1)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objTaxAudit.taxAuditAgeGroup20Ratio*10)/10).toFixed(1)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objTaxAudit.taxAuditAgeGroup65MoreRatio*10)/10).toFixed(1)}</TableCell>
                            <TableCell sx={tableCellStyle}>{objTaxAudit.taxAuditOffice}</TableCell>
                            <TableCell sx={tableCellStyle}>{objTaxAudit.taxAuditEmployees}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objTaxAudit.taxAuditAverageEmployees*100)/100).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}


export default TradeAreaNationalTaxAudit;

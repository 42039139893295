import { createSlice } from '@reduxjs/toolkit';
import { initAddHeaderObj } from '../data/type';

//Sliceの作成
export const appHeaderSlice = createSlice({
    name: 'appHeader',
    //初期State(Typeからインポート)
    initialState: initAddHeaderObj,

    //toolKitを利用した値変更処理
    reducers: {
        setObjAppHeader: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setObjAppHeader
} = appHeaderSlice.actions;
export default appHeaderSlice.reducer;
// 競合店舗のコード管理
interface cvsBrandCodeMasterType {
    [key: number]: cvsBrandCodeType; // <-この行を追加!
  }
  
  interface cvsBrandCodeType {
    blandName:      string;
    blandJapaneseName:     string;
    iconPathBase:     string;
    blandCode:     string;
  }
  
  const cvsBrandCodeMaster = {
    2: {
      blandName: "familymart",
      blandJapaneseName: "ファミリーマート",
      iconPathBase: "/image/icons/conflict_familymart",
      blandCode: "3",
    },
    3: {
      blandName: "lawson",
      blandJapaneseName: "ローソン",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    4: {
      blandName: "lawson100",
      blandJapaneseName: "ローソンストア100",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    5: {
      blandName: "familymartExclamation",
      blandJapaneseName: "ファミマ！！",
      iconPathBase: "/image/icons/conflict_familymart",
      blandCode: "3",
    },
    6: {
      blandName: "CircleK",
      blandJapaneseName: "サークルK",
      iconPathBase: "/image/icons/conflict",
      blandCode: "4",
    },
    7: {
      blandName: "sunkus",
      blandJapaneseName: "サンクス",
      iconPathBase: "/image/icons/conflict",
      blandCode: "4",
    },
    9: {
      blandName: "ministop",
      blandJapaneseName: "ミニストップ",
      iconPathBase: "/image/icons/conflict_ministop",
      blandCode: "5",
    },
    10: {
      blandName: "saveOn",
      blandJapaneseName: "セーブオン",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
    11: {
      blandName: "seicomart",
      blandJapaneseName: "セイコーマート",
      iconPathBase: "/image/icons/conflict_seicomart",
      blandCode: "6",
    },
    13: {
      blandName: "poplar",
      blandJapaneseName: "ポプラ",
      iconPathBase: "/image/icons/conflict_poplar",
      blandCode: "8",
    },
    14: {
      blandName: "c-store",
      blandJapaneseName: "コミュニティ・ストア",
      iconPathBase: "/image/icons/conflict_c-store",
      blandCode: "9",
    },
    15: {
      blandName: "dailyYamazaki",
      blandJapaneseName: "デイリーヤマザキ",
      iconPathBase: "/image/icons/conflict_dailyYamazaki",
      blandCode: "9",
    },
    16: {
      blandName: "threeF",
      blandJapaneseName: "スリーエフ",
      iconPathBase: "/image/icons/conflict",
      blandCode: "7",
    },
    18: {
      blandName: "piago",
      blandJapaneseName: "ｍｉｎｉピアゴ",
      iconPathBase: "/image/icons/conflict_piago",
      blandCode: "9",
    },
    20: {
      blandName: "poplar-cvs",
      blandJapaneseName: "生活彩家",
      iconPathBase: "/image/icons/conflict_poplarcvs",
      blandCode: "9",
    },
    120: {
      blandName: "naturallawson",
      blandJapaneseName: "ナチュラルローソン",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    1002: {
      blandName: "familymart",
      blandJapaneseName: "ファミリーマート",
      iconPathBase: "/image/icons/conflict_familymart",
      blandCode: "3",
    },
    1003: {
      blandName: "lawson",
      blandJapaneseName: "ローソン",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    1004: {
      blandName: "lawson100",
      blandJapaneseName: "ローソンストア100",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    1005: {
      blandName: "familymartExclamation",
      blandJapaneseName: "ファミマ！！",
      iconPathBase: "/image/icons/conflict_familymart",
      blandCode: "3",
    },
    1006: {
      blandName: "CircleK",
      blandJapaneseName: "サークルK",
      iconPathBase: "/image/icons/conflict",
      blandCode: "4",
    },
    1007: {
      blandName: "sunkus",
      blandJapaneseName: "サンクス",
      iconPathBase: "/image/icons/conflict",
      blandCode: "4",
    },
    1008: {
      blandName: "am-pm",
      blandJapaneseName: "am / pm",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
    1009: {
      blandName: "ministop",
      blandJapaneseName: "ミニストップ",
      iconPathBase: "/image/icons/conflict_ministop",
      blandCode: "5",
    },
    1010: {
      blandName: "saveOn",
      blandJapaneseName: "セーブオン",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
    1011: {
      blandName: "seicomart",
      blandJapaneseName: "セイコーマート",
      iconPathBase: "/image/icons/conflict_seicomart",
      blandCode: "6",
    },
    1012: {
      blandName: "cocostore",
      blandJapaneseName: "ココストア",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
    1013: {
      blandName: "poplar",
      blandJapaneseName: "ポプラ",
      iconPathBase: "/image/icons/conflict_poplar",
      blandCode: "8",
    },
    1014: {
      blandName: "c-store",
      blandJapaneseName: "コミュニティ・ストア",
      iconPathBase: "/image/icons/conflict_c-store",
      blandCode: "9",
    },
    1015: {
      blandName: "dailyYamazaki",
      blandJapaneseName: "デイリーヤマザキ",
      iconPathBase: "/image/icons/conflict_dailyYamazaki",
      blandCode: "9",
    },
    1016: {
      blandName: "threeF",
      blandJapaneseName: "スリーエフ",
      iconPathBase: "/image/icons/conflict",
      blandCode: "7",
    },
    1017: {
      blandName: "everyone",
      blandJapaneseName: "エブリワン",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
    1018: {
      blandName: "piago",
      blandJapaneseName: "ｍｉｎｉピアゴ",
      iconPathBase: "/image/icons/conflict_piago",
      blandCode: "9",
    },
    1020: {
      blandName: "poplar-cvs",
      blandJapaneseName: "生活彩家",
      iconPathBase: "/image/icons/conflict_poplarcvs",
      blandCode: "9",
    },
    1120: {
      blandName: "naturallawson",
      blandJapaneseName: "ナチュラルローソン",
      iconPathBase: "/image/icons/conflict_lawson",
      blandCode: "2",
    },
    0: {
      blandName: "",
      blandJapaneseName: "",
      iconPathBase: "/image/icons/conflict",
      blandCode: "9",
    },
  } as cvsBrandCodeMasterType;
  
  // 競合店舗のコードの値を取得する
  export const getCvsBrandCodeValue = (blandId: number) => {
    return blandId in cvsBrandCodeMaster
      ? cvsBrandCodeMaster[blandId]
      : cvsBrandCodeMaster[0];
  };

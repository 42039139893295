import * as React from 'react';

import { useUserContext } from '../../../user-provider';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  TextField,
  FormLabel,
} from '@mui/material';
import {
  dateDisplayByType,
  timeDisplay,
  carValue,
  carAvg,
  borderStyle,
  borderStyleColor,
  headerStyle,
  setParam,
} from "./TrafficVolumeFunction";
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import ConfirmDialog from '../../components/ConfirmDialog';
import Comment from '../../components/Comment';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LOCAL_CONSTANT } from "../../Const";
import DateModal from "./DateModal";
import CountDate from "./CountDate";
import { groupSearchFunc } from '../../components/PermissionFunc';
import dayjs from 'dayjs';
import { setUnitEdit } from '../../../features/longPageSlice';
import { setPermissionFunc } from '../../components/PermissionFunc';


const avgList = () => {
  return [
    {
      key: 'front',
      w: '22%',
      data: [
        { key: 'front_traffic_jam_time', w: 25, h: 50 },
        { key: 'car_front_total', w: 25, h: 50 },
        { key: 'car_front_light', w: 25, h: 50 },
        { key: 'car_front_large', w: 25, h: 50 },
      ],
    },
    {
      key: 'side_a',
      w: '22%',
      data: [
        { key: 'side_a_traffic_jam_time', w: 25, h: 50 },
        { key: 'car_side_a_total', w: 25, h: 50 },
        { key: 'car_side_a_light', w: 25, h: 50 },
        { key: 'car_side_a_large', w: 25, h: 50 },
      ],
    },
    {
      key: 'side_b',
      w: '20%',
      data: [
        { key: 'car_side_b_total', w: 34, h: 50 },
        { key: 'car_side_b_light', w: 33, h: 50 },
        { key: 'car_side_b_large', w: 33, h: 50 },
      ],
    },
    {
      key: 'back',
      w: '20%',
      data: [
        { key: 'car_back_total', w: 34, h: 50 },
        { key: 'car_back_light', w: 33, h: 50 },
        { key: 'car_back_large', w: 33, h: 50 },
      ],
    },
  ]
}

export interface trafficVolumeCarProps {
  props: {
    keyName: string;
    trafficType: string;
    trafficData: any;
    setReload: any;
    nightRatio: number;
    holidaysRatio: number;
    nightRatioValue: number;
    holidaysRatioValue: number;
    userType: string;
  }
}

const TrafficVolumeCar: React.FC<trafficVolumeCarProps> = ({ props }) => {
  const utilityCtx = useUtilityContext();
  const dispatch = useAppDispatch();

  const [actionFlag, setActionFlag] = React.useState<boolean>(false)
  const [trafficData, setTrafficData] = React.useState([])
  const [originData, setOriginData] = React.useState([])
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
  const [commentOpen, setCommentOpen] = React.useState<boolean>(false)
  const [edit, setEdit] = React.useState<boolean>(false)
  const [openDate, setOpenDate] = React.useState<boolean>(false);
  const [targetDate, setTargetDate] = React.useState<any>();
  const [openCountDate, setOpenCountDate] = React.useState<boolean>(false);
  const [targetCountDate, setTargetCountDate] = React.useState<any>();
  const [text, setText] = React.useState<string>("")

  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);

  const [userData, setUserData] = React.useState<any>({});
  const user = useUserContext();
  // 追加ボタン
  const [commentDisabled, setCommentDisabled] = React.useState<boolean>(true)
  const [editDisabled, setEditDisabled] = React.useState<boolean>(true)
  const [deleteDisabled, setDeleteDisabled] = React.useState<boolean>(true)

  //ユーザー取得タイミングによる画面制御
  React.useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserData(JSON.parse(user.userData));
    }
  }, [user]);

  React.useEffect(() => {
    if (userData && objLocationBase.plan_area_id !== '') {
      // 不備コメント
      if (userData.department_code_OA === '992002' &&
        userData.job_category_code_OA === '550' &&
        groupSearchFunc(objLocationBase.task_id) === 'B'
      ) {
        setCommentDisabled(false)
      }
      // 編集ボタン
      if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
        setEditDisabled(false)
      }
      // 削除ボタン
      if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
        setDeleteDisabled(false)
      }
      // モバ空表示
      switch (props.trafficType) {
        case '3':
          setTableDisplay('none')
          if (userData.user_type === 'z003' || userData.department_code_OA === '992002') {
            setTableDisplay('display')
          }
          break
        case '5':
          setTableDisplay('none')
          if (userData.user_type === 'z003' || userData.department_code_OA === '992002') {
            setTableDisplay('display')
          }
          break
        default:
          break
      }
    }
  }, [userData, objLocationBase])

  // テーブル表示非表示
  const [tableDisplay, setTableDisplay] = React.useState<string>("none")
  React.useEffect(() => {
    if (objLocationTraffic5mcut && objLocationTraffic5mcut.plan_area_id !== '') {
      switch (props.trafficType) {
        case '0':
        case '1':
          setTableDisplay('display')
          break
        case '2':
          setTableDisplay('none')
          if (['1', '3'].includes(objLocationTraffic5mcut.car_another_survey)) {
            setTableDisplay('display')
          }
          break
        case '4':
          setTableDisplay('none')
          if (['2', '3'].includes(objLocationTraffic5mcut.car_another_survey)) {
            setTableDisplay('display')
          }
          break
        default:
          break
      }
    }
  }, [props.trafficType, objLocationTraffic5mcut])

  React.useEffect(() => {
    const copyData = JSON.parse(JSON.stringify(props.trafficData))
    setTrafficData(copyData)
  }, [props.trafficData])

  const clickCommentOpen = () => {
    setText(LOCAL_CONSTANT.LABEL.CAR_TRAFFIC + " " + props.keyName)
    setCommentOpen(true)
  }

  const handleSaveComment = () => {
    setCommentOpen(false)
  }

  const deleteCancel = () => {
    setConfirmDialogOpen(false)
  }
  const onClickDelete = () => {
    setConfirmDialogOpen(true)
  }

  const setBody = () => {
    return props.keyName + "削除しますか？"
  }

  const deleteResult = () => {
    const count_type = trafficData.filter((row: any) => row.count_type === "3");
    const params = {
      mode: "regist",
      endPoint: "/location/Traffic/v1/delete",
      query: {
        planAreaId: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        trafficType: props.trafficType,
        count_type: count_type.length.toString()
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        props.setReload(true)
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const checkDisabled = () => {
    let disabled = true
    if (trafficData.length > 0) {
      disabled = false
    }

    return disabled
  }

  const changeHandlerTotalCheck = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: any, labelName: string, traffic_type: string, index: number) => {
    if (labelName === "front_traffic_jam_time" || labelName === "side_a_traffic_jam_time") {
      if (e.target.value.includes(".")) {
        const val = e.target.value.split(".")
        if (val[1].length > 2) {
          // 小数点2桁まで
          return
        }
      } else {
        if (Number(e.target.value) > 15) {
          // 15分まで
          return
        }
      }
    }
    let inputTotal = 0
    let hasTotal = 0
    let inputStandard = 0
    let inputLight = 0
    let inputLarge = 0
    let standerdLabel = '';
    switch (labelName) {
      case 'car_front_total':
        if(document.getElementById('car_front_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_front_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        if(document.getElementById('car_front_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_front_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_front_standard';
        break;
      case 'car_front_light':
        if(document.getElementById('car_front_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_front_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(row.car_front_total);
        inputLight = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_front_standard';
        break;
      case 'car_front_large':
        if(document.getElementById('car_front_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_front_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        hasTotal = Number(row.car_front_total);
        inputLarge = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_front_standard';
        break;
      case 'car_side_a_total':
        if(document.getElementById('car_side_a_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_a_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        if(document.getElementById('car_side_a_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_a_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_a_standard';
        break;
      case 'car_side_a_light':
        if(document.getElementById('car_side_a_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_a_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(row.car_side_a_total);
        inputLight = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_a_standard';
        break;
      case 'car_side_a_large':
        if(document.getElementById('car_side_a_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_a_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        hasTotal = Number(row.car_side_a_total);
        inputLarge = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_a_standard';
        break;
      case 'car_side_b_total':
        if(document.getElementById('car_side_b_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_b_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        if(document.getElementById('car_side_b_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_b_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_b_standard';
        break;
      case 'car_side_b_light':
        if(document.getElementById('car_side_b_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_b_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(row.car_side_b_total);
        inputLight = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_b_standard';
        break;
      case 'car_side_b_large':
        if(document.getElementById('car_side_b_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_side_b_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        hasTotal = Number(row.car_side_b_total);
        inputLarge = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_side_b_standard';
        break;
      case 'car_back_total':
        if(document.getElementById('car_back_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_back_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        if(document.getElementById('car_back_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_back_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_back_standard';
        break;
      case 'car_back_light':
        if(document.getElementById('car_back_large_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_back_large_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLarge = Number(inputElem.value);
        }
        hasTotal = Number(row.car_back_total);
        inputLight = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_back_standard';
        break;
      case 'car_back_large':
        if(document.getElementById('car_back_light_'+traffic_type+'_'+index)){
          const inputElem = document.getElementById('car_back_light_'+traffic_type+'_'+index) as HTMLInputElement;
          inputLight = Number(inputElem.value);
        }
        hasTotal = Number(row.car_back_total);
        inputLarge = Number(e.target.value);
        inputStandard = hasTotal - inputLight - inputLarge;
        if(inputStandard < 0){ inputStandard = 0; }
        inputTotal = inputStandard + inputLight + inputLarge;
        standerdLabel = 'car_back_standard';
        break;
      default:
        row[labelName] = e.target.value
        break;
    }
    if (inputTotal > hasTotal) {
      let message: any = LOCAL_CONSTANT.CONFIRM_MESSAGE.OVER_USER_INPUT_CAR
      message = message.replace('__TOTAL__', hasTotal.toString())
      message = message.replace('__STANDARD__', inputStandard.toString())
      message = message.replace('__LIGHT__', inputLight.toString())
      message = message.replace('__LARGE__', inputLarge.toString())
      if (utilityCtx.showSnackbar) {
        utilityCtx.showSnackbar("warning", message.slice(0, -1).split("\n").map((line: any, key: any) => <span key={key}>{line}<br /></span>));
      }
    } else {
      row[labelName] = e.target.value;
      row[standerdLabel] = inputStandard;
      setActionFlag(!actionFlag);
    }
  }

  const editStart = () => {
    // 更新フラグ
    dispatch(setUnitEdit(true));
    setEdit(true)
    const copyData = JSON.parse(JSON.stringify(trafficData))
    setOriginData(copyData)
  }

  const editCancel = () => {
    dispatch(setUnitEdit(false));
    setEdit(false)
    setTrafficData(originData)
    setOriginData([])
    setActionFlag(!actionFlag)
  }

  const editRegist = () => {
    const params = setParam(objLongPageParam.planAreaId, trafficData, LOCAL_CONSTANT.VALUE.ADD_CAR)
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        dispatch(setUnitEdit(false));
        setEdit(false)
        setActionFlag(!actionFlag)
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        props.setReload(true)
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const textColor = () => {
    return {
      '& .MuiInputBase-input': {
        textAlign: 'center',
        color: 'blue',
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
      },
      '& fieldset': {
        border: 'none',
      },
    }
  }

  const openDateModal = (count_date: any) => {
    setTargetDate(count_date)
    setOpenDate(true)
  }

  const openCountDateModal = (count_date: any) => {
    setTargetCountDate(count_date)
    setOpenCountDate(true)
  }

  const setDate = (data: any, newData: any) => {
    data.count_date = dayjs(newData).format('YYYY-MM-DD HH:mm:ss').toString() + ".001" // マイクロ秒の追加
  }

  const setCountDate = (data: any, count_hour: string, count_minute: string, count_time: string) => {
    data.count_date = data.count_date.substring(0, 10)
      + ' ' + count_hour.padStart(2, "0")
      + ':' + count_minute.padStart(2, "0")
      + ':' + data.count_date.substring(17, 23)
    data.count_time = count_time
  }

  const checkMenuDisplay = () => {
    let display = 'flex'
    if (([
      LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal,
      LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal
    ].includes(props.trafficType))) {
      display = 'none'
    }

    return display
  }

  const ratio = (key: string) => {
    let ratioValue = 0
    if (key === 'night') {
      ratioValue = Math.round(props.nightRatioValue * 1000) / 10
    } else if (key === 'holidays') {
      ratioValue = Math.round(props.holidaysRatioValue * 1000) / 10
    }

    return ratioValue.toString() + '%'
  }

  return (
    <>
      {
        props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.name &&
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '36%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
              <FormLabel sx={{ fontSize: '1.4rem' }}>昼間に対する</FormLabel>
              <FormLabel sx={{ fontSize: '1.4rem', color: 'red' }}>夜間</FormLabel>
              <FormLabel sx={{ fontSize: '1.4rem' }}>人流比率</FormLabel>
            </Box>
            <Box sx={{ width: '10%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
              <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}>{ratio('night')}</FormLabel>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '36%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
              <FormLabel sx={{ fontSize: '1.4rem' }}>平日に対する</FormLabel>
              <FormLabel sx={{ fontSize: '1.4rem', color: 'red' }}>休日</FormLabel>
              <FormLabel sx={{ fontSize: '1.4rem' }}>人流比率</FormLabel>
            </Box>
            <Box sx={{ width: '10%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
              <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}>{ratio('holidays')}</FormLabel>
            </Box>
          </Box>
        </Box>
      }
      {
        <TableContainer sx={{ width: '100%', marginBottom: '30px', border: 'solid 1px rgba(224, 224, 224, 1)', display: tableDisplay }} >
          <Box sx={{ width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ margin: '20px 10px' }}>{props.keyName}</Typography>
            <Box sx={{ display: checkMenuDisplay() }}>
              <Button
                disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficCarCommentButton')}
                onClick={clickCommentOpen}>
                <ChatBubbleIcon fontSize="large" />
              </Button>
              <Button
                sx={{ color: 'black', display: edit ? 'none' : 'block' }}
                disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficCarEditButton')}
                onClick={() => editStart()}
              ><EditIcon fontSize="large" /></Button>
              <Button variant="outlined" color="error" size="small"
                sx={{ margin: '10px', display: edit ? 'block' : 'none' }}
                onClick={editCancel}
              >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
              <Button variant="contained" color="success" size="small"
                sx={{ margin: '10px', display: edit ? 'block' : 'none' }}
                onClick={editRegist}
              >{LOCAL_CONSTANT.LABEL.UPDATE}</Button>
              <Button
                sx={{ color: 'black' }}
                disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficCarDeleteButton')}
                onClick={onClickDelete}
              ><DeleteIcon fontSize="large" /></Button>
            </Box>
          </Box>
          <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
            {/* アスタリスクを非表示 */}
            {(props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.name || props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.name) ? (
              <TableHead>
                <TableRow sx={{ height: '100px', '* p': { fontSize: '0.75rem' } }}>
                  <TableCell sx={headerStyle(16, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(50, 50)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE}</Typography>
                      <Typography sx={borderStyle(50, 50)}>{LOCAL_CONSTANT.LABEL.COUNT_TIME}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(22, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.FRONT}</Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.TRAFFIC_JAM_TIME}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(22, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.SIDE_A}</Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.TRAFFIC_JAM_TIME}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(20, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.SIDE_B}</Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(34, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(20, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.BACK}</Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(34, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              //アスタリスクを表示
              <TableHead>
                <TableRow sx={{ height: '100px', '* p': { fontSize: '0.75rem' } }}>
                  <TableCell sx={headerStyle(16, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(50, 50)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE}<span style={{ color: 'red' }}>＊</span></Typography>
                      <Typography sx={borderStyle(50, 50)}>{LOCAL_CONSTANT.LABEL.COUNT_TIME}<span style={{ color: 'red' }}>＊</span></Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(22, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.FRONT}<span style={{ color: 'red' }}>＊</span></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.TRAFFIC_JAM_TIME}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(22, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.SIDE_A}<span style={{ color: 'red' }}>＊＊</span></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.TRAFFIC_JAM_TIME}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(25, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(20, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.SIDE_B}<span style={{ color: 'red' }}>＊＊</span></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(34, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={headerStyle(20, 100)} align="center">
                    <Typography sx={borderStyle(100, 50)}>{LOCAL_CONSTANT.LABEL.BACK}<span style={{ color: 'red' }}>＊＊</span></Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={borderStyle(34, 50)}>{LOCAL_CONSTANT.LABEL.CAR_TOTAL}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LIGHT}</Typography>
                      <Typography sx={borderStyle(33, 50)}>{LOCAL_CONSTANT.LABEL.IN_LARGE}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            )
            }
            <TableBody sx={{ '* p': { fontSize: '0.75rem' }, '* input': { fontSize: '0.75rem' } }}>
              {trafficData.map((row: any, dataIndex: number) => (
                <TableRow key={dataIndex} sx={{ width: '100%' }}>
                  <TableCell sx={{ width: '16%', padding: '0' }} align="center">
                    <Box sx={{ width: '100%', display: 'flex' }}>
                      {
                        (
                          (edit === false) ||
                          (edit === true && row.counter_uuid !== '')
                        ) &&
                        <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, props.trafficType, "yearday")}</Typography>
                      }
                      {
                        edit === true &&
                        row.counter_uuid === '' &&
                        <Box onClick={() => { openDateModal(row) }}
                          sx={borderStyle(50, 50)}
                        >
                          <Typography sx={{ color: 'blue' }}>{dateDisplayByType(row, props.trafficType, "yearday")}</Typography>
                        </Box>
                      }
                      {
                        (
                          (edit === false) ||
                          (edit === true && row.counter_uuid !== '')
                        ) &&
                        <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, props.trafficType)}</Typography>
                      }
                      {
                        edit === true &&
                        row.counter_uuid === '' &&
                        <Box onClick={() => { openCountDateModal(row) }}
                          sx={borderStyle(50, 50)}
                        >
                          <Typography sx={{ color: 'blue' }}>{timeDisplay(row, props.trafficType)}</Typography>
                        </Box>
                      }
                    </Box>
                  </TableCell>
                  {
                    avgList().map((list: any) => (
                      <TableCell sx={{ width: list.w, padding: '0' }} align="center" key={list.key}>
                        <Box sx={{ display: 'flex' }}>
                          {list.data.map((rowData: any) => (
                            <Box sx={borderStyle(rowData.w, rowData.h)} key={rowData.key}>
                              <TextField sx={textColor()} disabled={!edit} id={rowData.key+"_"+row.traffic_type+"_"+dataIndex}
                                value={carValue(row[rowData.key], rowData.key, props.trafficType, props.nightRatio, props.holidaysRatio)}
                                onChange={(e) => changeHandlerTotalCheck(e, row, rowData.key, row.traffic_type, dataIndex)}>
                              </TextField>
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))}
              <TableRow sx={{ width: '100%' }}>
                <TableCell sx={{ width: '16%', padding: '0' }} align="center">
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={borderStyle(50, 50)}></Typography>
                    <Typography sx={borderStyleColor(50, 50)}>{LOCAL_CONSTANT.LABEL.AVG}</Typography>
                  </Box>
                </TableCell>
                {
                  avgList().map((list: any) => (
                    <TableCell sx={{ width: list.w, padding: '0' }} align="center" key={list.key}>
                      <Box sx={{ display: 'flex' }}>
                        {list.data.map((rowData: any) => (
                          <Box key={rowData.key}
                            sx={borderStyle(rowData.w, rowData.h)}
                          >
                            <Typography
                            >{carAvg(trafficData, rowData.key, props.trafficType, props.nightRatio, props.holidaysRatio)}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
      <DateModal props={{
        open: openDate,
        handleModal: setOpenDate,
        data: targetDate,
        trafficType: props.trafficType,
        setDate: setDate,
      }} />
      <CountDate props={{
        open: openCountDate,
        handleModal: setOpenCountDate,
        data: targetCountDate,
        trafficType: props.trafficType,
        setCountDate: setCountDate,
      }} />
      <Comment props={{
        open: commentOpen,
        params: {
          table_name: "location_traffic",
          column_name: text,
          comment: "",
        },
        handleModal: setCommentOpen,
        handleSaveComment: handleSaveComment
      }}
      />
      <ConfirmDialog props={{
        open: confirmDialogOpen,
        handleModal: setConfirmDialogOpen,
        onCancel: deleteCancel,
        onExecution: deleteResult,
        mode: "deleteConfirm",
        body: setBody(),
        confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
        confirmOk: LOCAL_CONSTANT.LABEL.DELETE
      }}
      />
    </>
  );

}


export default TrafficVolumeCar;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUtilityContext } from '../../../utility-provider';
import { setSaveStatus } from '../../../features/longPageSlice';
import {
  setObjDrawing,
  setDrawingLongTextShow,
  updateAddCell,
  updateDeleteCell,
  updateSubTotalCell,
  updateFormlaCell,
  updateTotalCell,
} from '../../../features/drawingSlice';
import { apiRequest } from '../../../api/ApiCall';
import { useUserContext } from '../../../user-provider';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import { LOCAL_CONSTANT } from "../../Const";
import {
  DrawingTypeList,
  initDrawingObj,
  DrawingBooleanTypeList,
  initDrawingReadOnlyObj,
  DrawingStringTypeList,
  initDrawingCommentTextFieldObj,
  initDrawingShowEditIconObj,
  initDrawingShowIconsObj
} from '../../../data/type';
import { mojiLimit } from '../../components/MojiLimit';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import { setPermissionFunc } from '../../components/PermissionFunc';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import LongTextFiledWithIcons from '../../components/LongTextFiledWithIcons';
import {
  chatBubbleCircleSx,
  chatBubbleIconSx,
  editIconCircleSx,
  iconHover
} from '../../components/ChatBubbleAndEditIconStyle';

const tableStyle = (columnCount: number) => {
  return {
    width: `calc(${columnCount} * 200px)`,
  }
};

const tableHeaderStyle = {
  width: '200px',
  height: '50px',
  color: 'black',
  border: '1px solid black',
  textAlign: 'center',
  backgroundColor: '#D9D9D9',
};

const HeaderStyle = {
  width: '200px',
  height: '50px',
  border: '1px solid black',
};

const tableCellStyle = {
  width: '200px',
  height: '50px',
  border: '1px solid black',
  padding: '0px'
};

const TextFieldSx = {
  '& .MuiOutlinedInput-input': {
    textAlign: 'center', //入力した値を中央寄せ
    paddingTop: '17px',
    paddingBottom: '17px'
  },
  backgroundColor: 'rgba(0, 0, 0, 0.06)'
};

//ユーザー権限種別が活性の場合にテキストフィールドの色を変える
const tableActiveInactiveTextSx = (isUserType: boolean) => {
  return {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center', //入力した値を中央寄せ
    },
    color: isUserType ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
    backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
  }
};

export interface ObstaclesEtcProps {
  props: {
  };
};


export const ObstaclesEtc: React.FC<ObstaclesEtcProps> = ({ props }) => {

  const objDrawing = useAppSelector((state) => state.drawing.obj);
  //図面ページの項目値をReduxへ登録する前に保存
  const [drawing, setDrawing] = useState<DrawingTypeList>(initDrawingObj);
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  const utilityCtx = useUtilityContext();
  //寄付きページからの値を取得
  const objDonation = useAppSelector((state) => state.donation.obj);
  //手書き配置図 詳細 
  const listFormulaSubTotal = useAppSelector((state) => state.drawing.obj.handDrawnLayout); //計算式 小計
  const listFormulaTotal = useAppSelector((state) => state.drawing.obj.handDrawnLayoutTotal); //合計
  //手書き配置図 詳細をReduxへ登録する前に保存
  const [handDrawnLayout, setHandDrawnLayout] = useState<any[]>([]);

  //ロングテキストを表示非表示のステータスを取得
  const longTextShow = useAppSelector((state) => state.drawing.showLongText);
  //パラメーターオブジェクト
  const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
  //タスクIDを取得するため
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //不備コメントモーダル項目名
  const [columnNameStr, setColumnNameStr] = useState<string>("");
  //不備コメントモーダルラベル名
  const [labelNameStr, setLabelNameStr] = useState<string>("");
  //不備コメントモーダル開閉フラグ
  const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);
  //不備コメントモーダル内、TextFieldのコメント
  const [drawingCommentTextFieldObj, setDrawingCommentTextFieldObj] = useState<DrawingStringTypeList>(initDrawingCommentTextFieldObj);
  //アイコン全体の表示非表示のステータス
  const [drawingShowIconsObj, setDrawingShowIconsObj] = useState<DrawingBooleanTypeList>(initDrawingShowIconsObj);
  //項目値を編集可能にするステータスの型定義
  const [drawingReadOnlyObj, setDrawingReadOnlyObj] = useState<DrawingBooleanTypeList>(initDrawingReadOnlyObj);

  const dispatch = useAppDispatch();

  const [userType, setUserType] = useState('');
  const user = useUserContext();

  //モーダル開閉フラグ(削除確認)
  const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
  const [rowId, setRowId] = useState<number>(0);

  const handleDeleteConfModalOpen = (id: number) => {
    setDeleteConfModalOpen(true);
    setRowId(id);
  }

  //Redux チェックボックス
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
    // その他 来店・進入の障害物にチェックを入れた時　下にロングテキストを表示
    if (labelName === "isOther") {
      dispatch(setDrawingLongTextShow(e.target.checked));
    }
    dispatch(setObjDrawing({ ...objDrawing, [labelName]: e.target.checked }));
    // 更新フラグ
    if (!objLongPageSaveStatus.Drawing) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Drawing']: true }));
  };

  //Redux 計算式の行項目を追加
  const handleAddCellChange = () => {
    const updateCellData = [...listFormulaSubTotal];
    dispatch(
      updateAddCell(
        {
          ...updateCellData,
          handDrawnLayoutFormula: '',
          handDrawnLayoutSubTotal: '',
        }
      ));
  };

  //Redux 計算式の行項目を削除
  const handleDeleteCellChange = () => {
    const updateCellData = [...listFormulaSubTotal];
    dispatch(updateDeleteCell({ ...updateCellData, id: rowId }));
    //項目削除したときの合計の計算
    dispatch(updateTotalCell(listFormulaTotal));
    // 更新フラグ
    if (!objLongPageSaveStatus.Drawing) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Drawing']: true }));
  };

  //計算式 インプット
  const handleFormlaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updateCellData = [...handDrawnLayout];
    updateCellData[index] = { ...updateCellData[index], handDrawnLayoutFormula: e.target.value };
    setHandDrawnLayout(updateCellData);
    // 更新フラグ
    if (!objLongPageSaveStatus.Drawing) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Drawing']: true }));
  };

  //計算式 Redux 
  const handleFormlaBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updateCellData = [...handDrawnLayout];
    dispatch(updateFormlaCell({ ...updateCellData[index], id: Number(index), handDrawnLayoutFormula: e.target.value }));
  };

  //小計入力 インプット
  const handleSubTotalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updateCellData = [...handDrawnLayout];
    const subTotal = e.target.value;
    if (/^\d*$/.test(subTotal)) { //整数のみ可能
      updateCellData[index] = { ...updateCellData[index], handDrawnLayoutSubTotal: Number(subTotal) };
      setHandDrawnLayout(updateCellData);
    }
    // 更新フラグ
    if (!objLongPageSaveStatus.Drawing) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Drawing']: true }));
  };

  //Redux 小計入力後の更新および合計
  const handleSubTotalBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updateCellData = [...handDrawnLayout];
    dispatch(updateSubTotalCell({ ...updateCellData[index], id: Number(index), handDrawnLayoutSubTotal: Number(e.target.value) }));
    dispatch(updateTotalCell(updateCellData));
  };

  //Redux インプット
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    if (labelName === 'otherLongText') {
      const targetValue = e.target.value;
      setDrawing({ ...drawing, [labelName]: mojiLimit(targetValue, 60) });
    } else {
      dispatch(setObjDrawing({ ...objDrawing, [labelName]: e.target.value }));
    }
    // 更新フラグ
    if (!objLongPageSaveStatus.Drawing) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Drawing']: true }));
  };

  //入力不可のインプットをクリックしたときアイコンを表示
  const handleInputClick = (labelName: string) => {
    setDrawingShowIconsObj({ ...drawingShowIconsObj, [labelName]: true });
  };

  // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    //Reduxへ保存
    dispatch(setObjDrawing({ ...objDrawing, [labelName]: e.target.value }));

    // インプット編集不可
    setDrawingReadOnlyObj({ ...drawingReadOnlyObj, [labelName]: true });

    //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
    if (!(e.relatedTarget)) {
      setDrawingShowIconsObj({ ...drawingShowIconsObj, [labelName]: false });
    }

    //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
    if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
      setDrawingShowIconsObj({ ...drawingShowIconsObj, [labelName]: false });
    }

    //テキストエリアからフォーカスが移動する先がテキストエリアタグの場合アイコンを閉じる
    if (e.relatedTarget && e.relatedTarget.tagName === "TEXTAREA") {
      setDrawingShowIconsObj({ ...drawingShowIconsObj, [labelName]: false });
    }
  };

  //その他 来店・進入の障害物のロングテキスト入力制御 
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.value.split('\n').length >= 1) { //2行以上入力させない
      e.preventDefault();
    }
  };

  // 吹き出しをクリックしたとき、モーダル画面を開く
  const handleChatBubbleClick = (columnName: string, labelName: string) => {
    setColumnNameStr(columnName);
    setLabelNameStr(labelName);
    setDeficiencyComentModalFlag(true);
    setDrawingCommentTextFieldObj({ ...drawingCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
  };

  // 鉛筆をクリックしたとき、Input編集可
  const handleEditIconClick = (labelName: string) => {
    if (setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, labelName) === false) {
      //falseにすると、Input編集可
      setDrawingReadOnlyObj({ ...drawingReadOnlyObj, [labelName]: false });
    }
  };

  // モーダル内でのコメント取得
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    setDrawingCommentTextFieldObj({
      ...drawingCommentTextFieldObj,
      [labelName]: e.target.value,
    });
  };

  // モーダル内の保存ボタンをクリックしたときコメントを保存
  const handleSaveComment = (columnName: string, labelName: string) => {
    //不備コメントを登録
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/regist",
      query: {
        plan_area_id: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        status_seq: objLocationBase.status_seq,
        table_name: "location_drawing",
        column_name: columnName,
        comment: drawingCommentTextFieldObj[labelName],
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
      }
    })();
  };

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  useEffect(() => {
    //ReduxをuseStateに保存
    setDrawing(objDrawing);
  }, [objDrawing]);

  useEffect(() => {
    //ReduxをuseStateに保存
    setHandDrawnLayout(listFormulaSubTotal); //手書き配置図 詳細 計算式 小計
  }, [listFormulaSubTotal]);

  return (
    <Box>
      <Typography mt={2}>手書き配置図　詳細</Typography>

      <Box sx={{ display: 'flex' }}>
        <Typography mt={2}>人車来店時の障害物・支障物</Typography>
        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
        {userType === 'z001' &&
          <Grid sx={{ display: 'flex' }}>
            <IconButton
              disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'ObstaclesAndHindrancesComingToStore')}
              onClick={() => { handleChatBubbleClick('ObstaclesAndHindrancesComingToStore', 'ObstaclesAndHindrancesComingToStore') }}
              sx={iconHover}
            >
              <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'ObstaclesAndHindrancesComingToStore'))}>
                <ChatBubbleIcon sx={chatBubbleIconSx} />
              </Box>
            </IconButton>
            <IconButton
              onClick={() => { handleEditIconClick('ObstaclesAndHindrancesComingToStore') }}
              sx={iconHover}
            >
              <Box sx={editIconCircleSx}>
                <EditIcon />
              </Box>
            </IconButton>
          </Grid>
        }
      </Box>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isGreenGem')}
                checked={objDrawing.isGreenGem}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isGreenGem') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="縁石"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isGrave')}
                checked={objDrawing.isGrave}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isGrave') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="墓・墓標・祠"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isMedianStrip')}
                checked={objDrawing.isMedianStrip}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isMedianStrip') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="中央分離帯"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isBusStopBlackout')}
                checked={objDrawing.isBusStopBlackout}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isBusStopBlackout') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="バス停・電停"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isInGreenGem')}
                checked={objDrawing.isInGreenGem}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isInGreenGem') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="内縁石"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOtherTenantParking')}
                checked={objDrawing.isOtherTenantParking}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOtherTenantParking') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="他のテナント駐車場"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isExpresswayEtcPole')}
                checked={objDrawing.isExpresswayEtcPole}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isExpresswayEtcPole') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}

              ></Checkbox>
            }
            label="高速道路等の支柱"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isBusLaneEtc')}
                checked={objDrawing.isBusLaneEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isBusLaneEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}

              ></Checkbox>
            }
            label="バスレーン・自転車道路"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isElectricityPole')}
                checked={objDrawing.isElectricityPole}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isElectricityPole') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="電柱"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isSidewalk')}
                checked={objDrawing.isSidewalk}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isSidewalk') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="歩道"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOneWayStreet')}
                checked={objDrawing.isOneWayStreet}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOneWayStreet') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="一方通行"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isTramTrack')}
                checked={objDrawing.isTramTrack}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isTramTrack') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="路面電車軌道"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isElectricityPoleBranchLine')}
                checked={objDrawing.isElectricityPoleBranchLine}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isElectricityPoleBranchLine') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="電柱の支線"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isCrosswalk')}
                checked={objDrawing.isCrosswalk}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isCrosswalk') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="横断歩道"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isNoEntryRoad')}
                checked={objDrawing.isNoEntryRoad}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isNoEntryRoad') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="車両進入禁止道路"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isParkingMeter')}
                checked={objDrawing.isParkingMeter}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isParkingMeter') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="パーキングメーター"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isHighVoltageTowers')}
                checked={objDrawing.isHighVoltageTowers}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isHighVoltageTowers') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="高圧線鉄塔"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isGuardrail')}
                checked={objDrawing.isGuardrail}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isGuardrail') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="ガードレール"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isWaterwayEtc')}
                checked={objDrawing.isWaterwayEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isWaterwayEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="水路・側溝・河川内縁石"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOthersPlace')}
                checked={objDrawing.isOthersPlace}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOthersPlace') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="他人所有地・官地"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isHydrantEtc')}
                checked={objDrawing.isHydrantEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isHydrantEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="消火栓・消化灯"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isStreetTreesEtc')}
                checked={objDrawing.isStreetTreesEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isStreetTreesEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="街路樹・植木"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isRailroadCrossing')}
                checked={objDrawing.isRailroadCrossing}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isRailroadCrossing') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="鉄道踏切"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOldRoad')}
                checked={objDrawing.isOldRoad}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOldRoad') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}

              ></Checkbox>
            }
            label="取り残された旧道"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isBillboardTowerEtc')}
                checked={objDrawing.isBillboardTowerEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isBillboardTowerEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="広告塔・看板"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isStreetLightsEtc')}
                checked={objDrawing.isStreetLightsEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isStreetLightsEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="街灯・カーブミラー"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isBridge')}
                checked={objDrawing.isBridge}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isBridge') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="橋"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isUnpavedRoad')}
                checked={objDrawing.isUnpavedRoad}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isUnpavedRoad') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="未舗装道路"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isGarbageDump')}
                checked={objDrawing.isGarbageDump}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isGarbageDump') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="ゴミ捨て場"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isRoadSigns')}
                checked={objDrawing.isRoadSigns}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isRoadSigns') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="道路標識"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOverpass')}
                checked={objDrawing.isOverpass}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOverpass') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="陸橋"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isUnmaintainedEtc')}
                checked={objDrawing.isUnmaintainedEtc}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isUnmaintainedEtc') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="未整備・工事中道路"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid item xs={12} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleCheckBoxChange(e, 'isOther')}
                checked={objDrawing.isOther}
                disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'isOther') : drawingReadOnlyObj.ObstaclesAndHindrancesComingToStore}
              ></Checkbox>
            }
            label="その他 来店・進入の障害物"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          {/* その他 来店・進入の障害物にチェックを入れた時　下にロングテキストを表示  1行60文字*/}
          {longTextShow && (
            <Box sx={{ width: '1000px' }}> {/* テキストフィールドの幅 */}
              <LongTextFiledWithIcons props={{
                userType: userType, //ユーザー
                disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'otherLongText'), //ユーザー権限
                pageName: LOCAL_CONSTANT.NAVI.DRAWING,
                columnName: 'other_long_text',
                labelName: 'otherLongText',
                rows: 1, //ロングテキストの行数 全角180文字
                width: '900px',//テキストフィールドの幅
                InputValueObj: drawing,
                ReadOnlyObj: drawingReadOnlyObj,
                setReadOnlyObj: setDrawingReadOnlyObj,
                ShowEditIconObj: initDrawingShowEditIconObj,
                ShowIconsObj: drawingShowIconsObj,
                handleChatBubbleClick: handleChatBubbleClick,
                handleInputBlur: handleInputBlur,
                handleInputChange: handleInputChange,
                handleInputClick: handleInputClick,
                handleKeyDown: handleKeyDown,
              }} />
            </Box>
          )}
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex' }}>
        <Typography mt={2}>駐車場面積算出根拠</Typography>
        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
        {userType === 'z001' &&
          <Grid sx={{ display: 'flex' }}>
            <IconButton
              disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'HandDrawnLayout')}
              onClick={() => { handleChatBubbleClick('HandDrawnLayout', 'HandDrawnLayout') }}
            >
              <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'HandDrawnLayout'))}>
                <ChatBubbleIcon sx={chatBubbleIconSx} />
              </Box>
            </IconButton>
            <IconButton
              onClick={() => { handleEditIconClick('HandDrawnLayout') }}
              sx={iconHover}
            >
              <Box sx={editIconCircleSx}>
                <EditIcon />
              </Box>
            </IconButton>
          </Grid>
        }
      </Box>

      <TableContainer>
        <Table sx={tableStyle(3)}>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeaderStyle}>計算式</TableCell>
              <TableCell sx={tableHeaderStyle}>小計</TableCell>
              <TableCell sx={{ width: '200px', border: 'none' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handDrawnLayout.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={tableCellStyle}>
                  <TextField
                    fullWidth
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') : drawingReadOnlyObj.HandDrawnLayout}
                    value={row.handDrawnLayoutFormula}
                    onChange={(e) => handleFormlaChange(e, index)}
                    onBlur={(e) => handleFormlaBlur(e, index)}
                    sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') : drawingReadOnlyObj.HandDrawnLayout)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end"></InputAdornment>
                    }}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TextField
                    fullWidth
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') : drawingReadOnlyObj.HandDrawnLayout}
                    value={row.handDrawnLayoutSubTotal}
                    onChange={(e) => handleSubTotalChange(e, index)}
                    onBlur={(e) => handleSubTotalBlur(e, index)}
                    sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') : drawingReadOnlyObj.HandDrawnLayout)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.SQUARE_METER}</InputAdornment>
                    }}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell sx={{ width: '200px', border: 'none', paddingTop: '0px', paddingBottom: '0px' }}>
                  {/* RFCの場合はアイコンを表示 リサーチの場合はアイコンを非表示 */}
                  {index === 0 ? (!setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') && (<IconButton onClick={handleAddCellChange} color="primary"><AddBoxIcon /></IconButton>))         //「プラス」のアイコンを表示
                    : (!setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'hand_drawn_layout') && (<IconButton onClick={() => handleDeleteConfModalOpen(row.id)} color="primary"><DeleteIcon /></IconButton>))//「ゴミ箱」のアイコンを表示
                  }
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell sx={tableHeaderStyle}>合計</TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={listFormulaTotal}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.SQUARE_METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={{ width: '200px', border: 'none' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableHeaderStyle}></TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={(Math.round(Number(listFormulaTotal) * 0.3025 * 1000) / 1000).toFixed(2)}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">坪</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={{ width: '200px', border: 'none' }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography mt={2}>進入口・奥行</Typography>

      <TableContainer>
        <Table sx={tableStyle(6)}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={HeaderStyle}></TableCell>
              <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.FRONT}</TableCell>
              <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.SIDE_A}</TableCell>
              <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.SIDE_B}</TableCell>
              <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.BACK}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2} sx={tableHeaderStyle}>第一</TableCell>
              <TableCell sx={tableHeaderStyle}>進入口</TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1EntranceFrontage}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1EntranceSideWayA}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1EntranceSideWayB}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1EntranceBackstreet}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableHeaderStyle}>奥行</TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1DepthFrontage}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1DepthSideWayA}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1DepthSideWayB}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no1DepthBackstreet}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2} sx={tableHeaderStyle}>第二</TableCell>
              <TableCell sx={tableHeaderStyle}>進入口</TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2EntranceFrontage}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2EntranceSideWayA}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2EntranceSideWayB}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2EntranceBackstreet}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableHeaderStyle}>奥行</TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2DepthFrontage}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2DepthSideWayA}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2DepthSideWayB}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell sx={tableCellStyle}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={objDonation.no2DepthBackstreet}
                  sx={TextFieldSx}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.METER}</InputAdornment>
                  }}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* 削除確認モーダル */}
      <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteCellChange} />

      {/*不備コメント モーダル画面 */}
      <DeficiencyCommentRegistModal
        props={{
          modalOpen: deficiencyComentModalFlag,
          setModalOpen: setDeficiencyComentModalFlag,
          CommentTextFieldObj: drawingCommentTextFieldObj,
          columnName: columnNameStr,
          labelName: labelNameStr,
          handleCommentChange: handleCommentChange,
          handleSaveComment: handleSaveComment,
        }} />

    </Box>

  )

};

export default ObstaclesEtc;

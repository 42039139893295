import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { tokenLogin, getApplicationInfoDocument, msalLogin } from './utility/firebase';
import { useUserContext } from './user-provider';
import { getLongPageData } from './data/DataManage';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setObjPortal } from './features/portalSlice';

type Props = {
  children: React.ReactNode;
  url: string;
}

const Login = ({ children, url }: Props) => {
  const [open, setOpen] = React.useState(true);
  const dispatch = useAppDispatch();
  const objPortal = useAppSelector((state) => state.portal);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const user = useUserContext();

  const getMasterData = async () => {
    const param: any = []
    // コードマスター取得
    param.push({
      mode: 'getCodeMaster',
    })
    // 担当者情報取得
    param.push({
      mode: 'getStaffMaster',
    })
    // 所属情報取得
    param.push({
      mode: 'getDepartmentMaster',
    })
    // 候補地情報取得
    param.push({
      mode: 'getPlanAreaList',
    })
    // テーブル名マスター取得
    param.push({
      mode: 'getTableNameMaster',
    })
    // 既存店一覧取得
    param.push({
      mode: 'getExistingSejStoreAll',
    })

    const result: any = await getLongPageData(param);
    // コードマスター取得
    let obj = result.getCodeMaster.reduce((acc: any, cur: any) => {
      if (!acc[cur.item_name_value]) {
        acc[cur.item_name_value] = []
      }
      acc[cur.item_name_value].push(cur)
      return acc
    }, {})
    localStorage.setItem('codeMaster', JSON.stringify(obj));
    // 担当者情報取得
    localStorage.setItem('staffMaster', JSON.stringify(result.getStaffMaster));
    // 所属情報取得
    localStorage.setItem('departmentMaster', JSON.stringify(result.getDepartmentMaster));
    // 候補地情報取得
    localStorage.setItem('planAreaList', JSON.stringify(result.getPlanAreaList));
    // テーブル名マスター取得
    localStorage.setItem('tableNameMaster', JSON.stringify(result.getTableNameMaster));
    // 既存店一覧
    localStorage.setItem('existingSejStoreAll', JSON.stringify(result.getExistingSejStoreAll));

    //ローカルストレージ更新フラグをセット
    dispatch(
      setObjPortal({
        ...objPortal,
        ["localStorageFlag"]: true
      })
    )
  }

  React.useLayoutEffect(() => {
    const sync = async () => {
      if (process.env.REACT_APP_MODE === "local") {
        await tokenLogin(url)
          .then((val) => {
            console.log(val)
            if (val === 'unprocessed') {
              // 未処理の場合はバックドロップ消さない
              return;
            }
            if (user.setUserData) {
              user.setUserData(val as string);
            }
            // マスター系データ取得
            getMasterData()
            // トークン取得
            getApplicationInfoDocument();
            // 認証に成功したらバックドロップ消す
            handleClose();
          }).catch((e) => {
            // リクルートビューに遷移？
            console.log(e)
          })
      } else {
        await msalLogin(url)
          .then((val) => {
            console.log(val)
            if (val === 'unprocessed') {
              // 未処理の場合はバックドロップ消さない
              return;
            }
            if (user.setUserData) {
              user.setUserData(val as string);
            }
            // マスター系データ取得
            getMasterData()
            // トークン取得
            getApplicationInfoDocument();
            // 認証に成功したらバックドロップ消す
            handleClose();
          }).catch((e) => {
            // リクルートビューに遷移？
            console.log(e)
          })
      }

    }
    sync();
  }, [])

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={open}
          invisible={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {children}
    </>
  )
}

export default Login
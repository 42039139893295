// OFCダッシュボードの画面に応じたAPIのパラメータを返す
export const getApiParamArray = (params: any) => {
    return {
        query:
            params.query + ' { ' + params.params.map((row: any) => { return setQuery(row) }) + ' } '
    }
}

export const getApiParam = (params: any) => {
    return setQuery(params)
}

export const setQuery = (params: any) => {
    switch (params.mode) {
        case "regist":
            return JSON.stringify(params.query);
        case "existingSejStoreSearchByDistance":
            return 'existingSejStoreSearchByDistance(longitude:' + params.longitude +
                ', latitude:' + params.latitude +
                ', distance:' + params.distance +
                ') {' +
                'store_code ' +
                'ctycode ' +
                'tenpo_name ' +
                'tenpo_address ' +
                'prop_site_no ' +
                'step ' +
                'centerlat ' +
                'centerlon ' +
                'geohash ' +
                'openingdate ' +
                'closingdate ' +
                'doname ' +
                'dmname ' +
                'ofcname ' +
                'zoname ' +
                'contract_type ' +
                'parkingarea ' +
                'sales_forecast ' +
                'salesfloor ' +
                'liquorlicense ' +
                'tobaccolicense ' +
                'plan_area_feature ' +
                'building_feature ' +
                'entrance_feature ' +
                'existing_best_location_flag ' +
                'store_direction ' +
                'sales_type ' +
                'existing_multi_flag ' +
                ' }'
        case "planAreaSearchById":
            return 'planAreaSearchById(id: "' + params.id + '"' +
                ', manageCitiesFlg: ' + params.manageCitiesFlg +
                ', manageCities: "' + params.manageCities + '"' +
                ', manageStep:  "' + params.manageStep + '"' +
                ', managePlansOutsideCities:  "' + params.managePlansOutsideCities + '"' +
                ') { ' +
                ' id ' +
                ' uuid ' +
                ' centerlat ' +
                ' centerlon  ' +
                ' entrance_feature ' +
                ' plan_area_feature ' +
                ' building_feature ' +
                ' citycode ' +
                ' display_flag ' +
                ' deletable_flag ' +
                ' request_flag ' +
                ' edit_flag ' +
                ' corporate_flag ' +
                ' plan_area_name ' +
                ' step ' +
                ' plan_area_address ' +
                ' plan_area_zipcode  ' +
                ' plan_area_townname ' +
                ' plan_area_townname_serial_number ' +
                ' zone_id ' +
                ' plan_area_area_size ' +
                ' building_area_size ' +
                ' plan_area_current_state ' +
                ' plan_area_owner_ids ' +
                ' plan_area_amount_sales ' +
                ' latlon_jpn ' +
                ' do_code ' +
                ' dm_confirm ' +
                ' rm_confirm ' +
                ' existing_best_location_flag ' +
                ' existing_multi_flag ' +
                ' evaluation_score ' +
                ' visit_activity ' +
                ' current_situation1 ' +
                ' current_situation2 ' +
                ' minor_classification1 ' +
                ' minor_classification2 ' +
                ' tenant_name1 ' +
                ' tenant_name2 ' +
                ' branch_name1 ' +
                ' branch_name2 ' +
                ' remarks ' +
                ' pending_flag ' +
                ' create_date ' +
                ' update_date ' +
                ' store_direction ' +
                ' giving_up_reason ' +
                ' }'
        case "getImpactCounter":
            return 'getImpactCounter(' +
                ' longitude: ' + params.longitude +
                ', latitude: ' + params.latitude +
                ', uuid: "' + params.uuid + '"' +
                ', distance:  ' + params.distance +
                ') { ' +
                ' uuid ' +
                ' seq ' +
                ' centerlat ' +
                ' centerlon ' +
                ' shape ' +
                ' create_date ' +
                ' update_date ' +
                ' delete_flag ' +
                ' name ' +
                ' count_date ' +
                ' count_time ' +
                ' counter_type ' +
                ' intersection_type ' +
                ' top_car_left ' +
                ' top_car_bottom ' +
                ' top_car_right ' +
                ' top_people_left ' +
                ' top_people_bottom ' +
                ' top_people_right ' +
                ' left_car_top ' +
                ' left_car_right ' +
                ' left_car_bottom ' +
                ' left_people_top ' +
                ' left_people_right ' +
                ' left_people_bottom ' +
                ' bottom_car_left ' +
                ' bottom_car_top ' +
                ' bottom_car_right ' +
                ' bottom_people_left ' +
                ' bottom_people_top ' +
                ' bottom_people_right ' +
                ' right_car_top ' +
                ' right_car_left ' +
                ' right_car_bottom ' +
                ' right_people_top ' +
                ' right_people_left ' +
                ' right_people_bottom ' +
                ' }'
        case "getConflictStores":
            return 'getConflictStores(' +
                '   longitude: ' + params.longitude +
                ' , latitude: ' + params.latitude +
                ' , distance: ' + params.distance +
                ' , type: "' + params.type + '"' +
                ' , hasClosed: "' + params.hasClosed + '"' +
                ') { ' +
                'id ' +
                'uuid ' +
                'centerlat ' +
                'centerlon ' +
                'bland_id ' +
                'bland_name ' +
                'tenpo_name ' +
                'comment ' +
                'open_date ' +
                'close_date ' +
                'type ' +
                'handle_category_tobacco ' +
                'handle_category_liquor ' +
                '}'
        case "getLocationConflict":
            return 'getLocationConflict(' +
                ' plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'conflict_type ' +
                'conflict_sequence ' +
                'create_date ' +
                'update_date ' +
                'type ' +
                'delete_flag ' +
                'conflict_id ' +
                'road_shape ' +
                'road_distance ' +
                'direct_distance ' +
                'location_conflict_type ' +
                'include_commercial_area ' +
                'bland_name ' +
                'tenpo_name ' +
                'prefectures ' +
                'tenpo_address ' +
                'conflict_business_type ' +
                'business_hours ' +
                'closing_time ' +
                'handle_category_liquor ' +
                'handle_category_tobacco ' +
                'location ' +
                'sales_floor_area_size ' +
                'gondola_scale ' +
                'handle_category_parking ' +
                'store_frontage ' +
                'parking_area_size ' +
                'number_of_parking ' +
                'front_parking_tangent ' +
                'front_first_entrance ' +
                'front_first_depth ' +
                'front_second_entrance ' +
                'front_second_depth ' +
                'side_road_type ' +
                'side_parking_tangent ' +
                'side_first_entrance ' +
                'side_first_depth ' +
                'side_second_entrance ' +
                'side_second_depth ' +
                'estimated_daily_sales ' +
                'plan_area_shared_road ' +
                'plan_area_direction ' +
                'conflict_shared_road ' +
                'conflict_median_strip ' +
                'count_left_right_turns ' +
                'count_straight ' +
                'market_cut ' +
                'bland_code ' +
                '}'
        case "getLocationCover":
            return 'getLocationCover(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'create_date ' +
                'reception_no ' +
                'store_no ' +
                'survey_name ' +
                'address ' +
                'address_detail ' +
                'contract_due_date ' +
                'open_due_date ' +
                'prefectures_code ' +
                'new_area_no ' +
                'branch_no ' +
                'existing_store_design ' +
                's_and_b_case ' +
                'conflict_ruins_case ' +
                'target_contract_not_opened ' +
                'survey_creator ' +
                'confirmer ' +
                '}'
        case "getLocationStoreOpeningConditions":
            return 'getLocationStoreOpeningConditions(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'setting_reason ' +
                'candidate_location_ruins ' +
                'candidate_location_ruins_long_text ' +
                'is_store_name_select_reason ' +
                'removal_Reason ' +
                'open24_hours ' +
                'not_open24_hours ' +
                'alcohol_license ' +
                'no_alcohol_license ' +
                'tobacco_licence ' +
                'no_tobacco_licence ' +
                'area_type ' +
                'nearest_already_store_distance ' +
                'environment_type ' +
                'acquisition_requirement ' +
                'tobacco_distance_standard ' +
                'distance_standard_alreadystore_count ' +
                'previous_job ' +
                'conflict_cvs ' +
                'cvs_last_nippan ' +
                'relocation_distance ' +
                'ownership_type ' +
                'store_type ' +
                'store_frontage ' +
                'store_area ' +
                'salses_area ' +
                'open_case ' +
                'nakajima_chilled ' +
                'island_chilled ' +
                'gondola_units ' +
                'gondola_height ' +
                'ice_case_large ' +
                'ice_case_medium ' +
                'ice_case_small ' +
                'compact_stocker ' +
                'low_temp_reach_in ' +
                'walk_in ' +
                'conter_width ' +
                'existing_store_name ' +
                'store_no ' +
                'average_nippan ' +
                'achievement_acquisition_start ' +
                'achievement_acquisition_end ' +
                'reception_no ' +
                's_store_open_date ' +
                's_store_close_due_date ' +
                'closing_days ' +
                'in_sales_construction_days ' +
                'car_common_ratio ' +
                'person_common_ratio ' +
                's_store_line_distance ' +
                's_store_way_distance ' +
                's_store_car_customer_ratio ' +
                'last_fixed_month ' +
                'last_one_year_nippan ' +
                'year_on_year ' +
                'year_month ' +
                'change_type ' +
                'content ' +
                'last_one_year_nippan_0 ' +
                'last_one_year_nippan_1 ' +
                'last_one_year_nippan_2 ' +
                'last_one_year_nippan_3 ' +
                'last_one_year_nippan_4 ' +
                'last_one_year_nippan_5 ' +
                'last_one_year_nippan_6 ' +
                'last_one_year_nippan_7 ' +
                'last_one_year_nippan_8 ' +
                'last_one_year_nippan_9 ' +
                'last_one_year_nippan_10 ' +
                'last_one_year_nippan_11 ' +
                'year_on_year_0 ' +
                'year_on_year_1 ' +
                'year_on_year_2 ' +
                'year_on_year_3 ' +
                'year_on_year_4 ' +
                'year_on_year_5 ' +
                'year_on_year_6 ' +
                'year_on_year_7 ' +
                'year_on_year_8 ' +
                'year_on_year_9 ' +
                'year_on_year_10 ' +
                'year_on_year_11 ' +
                'store_move_type ' +
                'store_move_distance ' +
                'store_type_multiple_store ' +
                '}'
        case "getNationalTaxAudit":
            return 'getNationalTaxAudit(' +
                '   city_code: "' + params.city_code + '"' +
                ') { ' +
                'prefecture_code ' +
                'prefecture ' +
                'city_type ' +
                'gun_goverment_code ' +
                'gun_goverment_name ' +
                'city_name ' +
                'household_count2020 ' +
                'total_population2020 ' +
                'household_count2020_single ' +
                'total_population2020_19to29 ' +
                'total_population2020_65more ' +
                'all_industry_office_count ' +
                'all_industry_employee_count ' +
                '}'
        case "getCar5MinMp":
            return 'getCar5MinMp(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , excel_sequence: ' + params.excel_sequence +
                ') { ' +
                'residences_count ' +
                'proration_single_household_ratio ' +
                'proration_household_count ' +
                'proration_population_total_count ' +
                'proration_age_group_20population ' +
                'proration_age_group_30population ' +
                'proration_age_group_50more_population ' +
                'proration_age_group_65more_population ' +
                'office_cont ' +
                'proration_employees_count ' +
                'proration_office_count ' +
                'proration_retail_count ' +
                'proration_retail_employees_count ' +
                'income_up_to_300 ' +
                'income_up_to_500 ' +
                'income_up_to_700 ' +
                'income_up_to_1000 ' +
                'income_over1000 ' +
                '}'
        case "getLocationTradeAreaMpData":
            return 'getLocationTradeAreaMpData(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ' , excel_sequence: ' + params.excel_sequence +
                ') { ' +
                'proration_retail_count ' +
                'proration_retail_employees_count ' +
                'income_up_to_300 ' +
                'income_up_to_500 ' +
                'income_up_to_700 ' +
                'income_up_to_1000 ' +
                'income_over1000 ' +
                'oaza_name ' +
                'section_name ' +
                'single_household_ratio ' +
                'popilation_total ' +
                'household_count ' +
                'age_group_20ratio ' +
                'age_group_30ratio ' +
                'age_group_50moreratio ' +
                'age_group_65moreratio ' +
                'mp_data_office_count ' +
                'employees_count ' +
                'residences_count ' +
                'mp_data2_office_count ' +
                'trade_area_seq ' +
                'k_chocode ' +
                'reality_survey_household_count ' +
                'reality_survey_office ' +
                'reality_survey_employed_workers ' +
                '}'
        case "getGradientWalk5MinMpData":
            return 'getGradientWalk5MinMpData(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , excel_sequence: ' + params.excel_sequence +
                ') { ' +
                'proration_retail_count ' +
                'proration_retail_employees_count ' +
                'income_up_to_300 ' +
                'income_up_to_500 ' +
                'income_up_to_700 ' +
                'income_up_to_1000 ' +
                'income_over1000 ' +
                'oaza_name ' +
                'section_name ' +
                'single_household_ratio ' +
                'popilation_total ' +
                'household_count ' +
                'age_group_20ratio ' +
                'age_group_30ratio ' +
                'age_group_50moreratio ' +
                'age_group_65moreratio ' +
                'mp_data_office_count ' +
                'employees_count ' +
                'residences_count ' +
                'mp_data2_office_count ' +
                'k_chocode ' +
                '}'
        case "getLocationDonation":
            return 'getLocationDonation(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'driving_lane_positive_frontage ' +
                'driving_lane_positive_sideway_a ' +
                'driving_lane_positive_sideway_b ' +
                'driving_lane_positive_backstreet ' +
                'driving_lane_opposite_frontage ' +
                'driving_lane_opposite_sideway_a ' +
                'driving_lane_opposite_sideway_b ' +
                'driving_lane_opposite_backstreet ' +
                'median_strip_select ' +
                'median_strip_select2 ' +
                'median_strip_select3 ' +
                'median_strip_select4 ' +
                'median_strip_height ' +
                'median_strip_height2 ' +
                'median_strip_height3 ' +
                'median_strip_height4 ' +
                'sidewalk_select ' +
                'sidewalk_select2 ' +
                'sidewalk_select3 ' +
                'sidewalk_select4 ' +
                'sidewalk_height ' +
                'sidewalk_height2 ' +
                'sidewalk_height3 ' +
                'sidewalk_height4 ' +
                'road_width_door ' +
                'road_width_door2 ' +
                'road_width_door3 ' +
                'road_width_door4 ' +
                'road_width_forward ' +
                'road_width_forward2 ' +
                'road_width_forward3 ' +
                'road_width_forward4 ' +
                'road_width_front_location ' +
                'road_width_front_location2 ' +
                'road_width_front_location3 ' +
                'road_width_front_location4 ' +
                'slope_climb ' +
                'slope_climb2 ' +
                'slope_climb3 ' +
                'slope_climb4 ' +
                'slope_descend ' +
                'slope_descend2 ' +
                'slope_descend3 ' +
                'slope_descend4 ' +
                'traffic_signal_etc_forward_select ' +
                'traffic_signal_etc_forward_select2 ' +
                'traffic_signal_etc_forward_select3 ' +
                'traffic_signal_etc_forward_select4 ' +
                'traffic_signal_etc_forward_distance ' +
                'traffic_signal_etc_forward_distance2 ' +
                'traffic_signal_etc_forward_distance3 ' +
                'traffic_signal_etc_forward_distance4 ' +
                'traffic_signal_etc_front_location_select ' +
                'traffic_signal_etc_front_location_select2 ' +
                'traffic_signal_etc_front_location_select3 ' +
                'traffic_signal_etc_front_location_select4 ' +
                'traffic_signal_etc_front_location_distance ' +
                'traffic_signal_etc_front_location_distance2 ' +
                'traffic_signal_etc_front_location_distance3 ' +
                'traffic_signal_etc_front_location_distance4 ' +
                'visibility_factor_building ' +
                'visibility_factor_building2 ' +
                'visibility_factor_building3 ' +
                'visibility_factor_building4 ' +
                'visibility_factor_sign_post ' +
                'visibility_factor_sign_post2 ' +
                'visibility_factor_sign_post3 ' +
                'visibility_factor_sign_post4 ' +
                'parking_area ' +
                'parkingtsubo_area ' +
                'parking_capacity ' +
                'parking_tangent_line_frontage ' +
                'parking_tangent_line_sideway_a ' +
                'parking_tangent_line_sideway_b ' +
                'parking_tangent_line_backstreet ' +
                'no1_entrance_frontage ' +
                'no1_entrance_sideway_a ' +
                'no1_entrance_sideway_b ' +
                'no1_entrance_backstreet ' +
                'no1_depth_frontage ' +
                'no1_depth_sideway_a ' +
                'no1_depth_sideway_b ' +
                'no1_depth_backstreet ' +
                'no2_entrance_frontage ' +
                'no2_entrance_sideway_a ' +
                'no2_entrance_sideway_b ' +
                'no2_entrance_backstreet ' +
                'no2_depth_frontage ' +
                'no2_depth_sideway_a ' +
                'no2_depth_sideway_b ' +
                'no2_depth_backstreet ' +
                'complete_schedule_year ' +
                'complete_schedule_month ' +
                '}'
        case "getTrafficVolume":
            return 'getTrafficVolume( ' +
                'plan_area_id: "' + params.plan_area_id + '"' +
                ', count_type: "' + params.count_type + '"' +
                ') { ' +
                ' uuid ' +
                ' create_date ' +
                ' update_date ' +
                ' delete_flag ' +
                ' plan_area_id ' +
                ' count_date ' +
                ' count_time ' +
                ' front_traffic_jam_time ' +
                ' side_a_traffic_jam_time ' +
                ' count_type ' +
                ' car_front_standard ' +
                ' car_front_light ' +
                ' car_front_large ' +
                ' car_side_a_standard ' +
                ' car_side_a_light ' +
                ' car_side_a_large ' +
                ' car_side_b_standard ' +
                ' car_side_b_light ' +
                ' car_side_b_large ' +
                ' car_back_standard ' +
                ' car_back_light ' +
                ' car_back_large ' +
                ' people_5m_in_male_65over ' +
                ' people_5m_in_female_65over ' +
                ' people_5m_in_male_other ' +
                ' people_5m_in_female_other ' +
                ' people_5m_in_student ' +
                ' people_5m_in_bicycle ' +
                ' people_5m_out_male_65over ' +
                ' people_5m_out_female_65over ' +
                ' people_5m_out_male_other ' +
                ' people_5m_out_female_other ' +
                ' people_5m_out_student ' +
                ' people_5m_out_bicycle ' +
                '}'
        case "getLocationTraffic":
            return 'getLocationTraffic( ' +
                'plan_area_id: "' + params.plan_area_id + '"' +
                ', branch: "' + params.branch + '"' +
                ') { ' +
                ' plan_area_id ' +
                ' branch ' +
                ' traffic_type ' +
                ' traffic_type_seq ' +
                ' create_date ' +
                ' update_date ' +
                ' delete_flag ' +
                ' counter_uuid ' +
                ' count_date ' +
                ' count_time ' +
                ' front_traffic_jam_time ' +
                ' side_a_traffic_jam_time ' +
                ' count_type ' +
                ' car_front_standard ' +
                ' car_front_light ' +
                ' car_front_large ' +
                ' car_side_a_standard ' +
                ' car_side_a_light ' +
                ' car_side_a_large ' +
                ' car_side_b_standard ' +
                ' car_side_b_light ' +
                ' car_side_b_large ' +
                ' car_back_standard ' +
                ' car_back_light ' +
                ' car_back_large ' +
                ' people_5m_in_male_65over ' +
                ' people_5m_in_female_65over ' +
                ' people_5m_in_male_other ' +
                ' people_5m_in_female_other ' +
                ' people_5m_in_student ' +
                ' people_5m_in_bicycle ' +
                ' people_5m_out_male_65over ' +
                ' people_5m_out_female_65over ' +
                ' people_5m_out_male_other ' +
                ' people_5m_out_female_other ' +
                ' people_5m_out_student ' +
                ' people_5m_out_bicycle ' +
                '}'
        case "getLocationImpact":
            return 'getLocationImpact(' +
                ' plan_area_id: "' + params.plan_area_id + '"' +
                ' branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'store_code ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'store_position ' +
                'common_road ' +
                'plan_median_strip ' +
                'store_median_strip ' +
                'straight_count ' +
                'turn_count ' +
                'railroad_crossing ' +
                'river_width ' +
                'crossing_road_count ' +
                'conflict_sej ' +
                'conflict_main_cvs ' +
                'conflict_other_cvs ' +
                'near_store_code ' +
                'average_common_ratio ' +
                'road_distance ' +
                'salesfloor ' +
                'parkingarea ' +
                'car_front_store_count ' +
                'car_total_share_ratio ' +
                'people_front_store_count ' +
                'people_total_share_ratio ' +
                'tenpo_name ' +
                'tobaccolicense ' +
                'store_centerlat ' +
                'store_centerlon ' +
                'location_impact_counter ' +
                'location_impact_common_number ' +
                'plan_area_centerlat ' +
                'plan_area_centerlon ' +
                '}'
        case "getCodeMaster":
            return 'getCodeMaster' +
                ' { ' +
                'uuid ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'common_item_flag ' +
                'item_name_display ' +
                'item_name_value ' +
                'code_display ' +
                'code_value ' +
                'sort_column ' +
                '}'
        case "getLocationBase":
            return 'getLocationBase(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'location_type ' +
                'location_excel_flag ' +
                'location_excel_sequence ' +
                'child_location ' +
                'parent_location ' +
                'temp_sales_forecast_path ' +
                'temp_sales_forecast_upload_user ' +
                'temp_sales_forecast_upload_mail ' +
                'sales_forecast_path ' +
                'sales_forecast_upload_user ' +
                'sales_forecast_upload_mail ' +
                'shp_flag ' +
                'task_id ' +
                'status_id ' +
                'status_seq ' +
                'add_survey_cover_excel_path ' +
                'add_survey_cover_excel_user ' +
                'add_survey_cover_pdf_path ' +
                'add_survey_cover_pdf_user ' +
                'sb_document_excel_path ' +
                'sb_document_pdf_path ' +
                'other_document_excel_path ' +
                'other_document_pdf_path ' +
                'rr_document_pdf_path ' +
                'map_excel_path ' +
                'map_excel_user ' +
                'map_pdf_path ' +
                'map_pdf_user ' +
                'impact_judge_pdf_path ' +
                'impact_judge_pdf_user ' +
                'daily_sales_year_1 ' +
                'daily_sales_year_2 ' +
                'daily_sales_year_3 ' +
                'daily_sales_year_4 ' +
                'daily_sales_year_5 ' +
                'impact_store_name_1 ' +
                'impact_store_1 ' +
                'impact_store_name_2 ' +
                'impact_store_2 ' +
                'impact_store_name_3 ' +
                'impact_store_3 ' +
                'impact_store_name_4 ' +
                'impact_store_4 ' +
                'add_survey_doc_pdf_path ' +
                'add_survey_reference_value_1 ' +
                'add_survey_reference_value_2 ' +
                'add_survey_reference_value_3 ' +
                'add_survey_reference_value_4 ' +
                'add_survey_reference_value_5 ' +
                '}'
        case "getExistingSejConflictStores":
            return 'getExistingSejConflictStores(' +
                ' longitude: ' + params.longitude +
                ', latitude: ' + params.latitude +
                ', distance: ' + params.distance +
                ' , propSiteNo: "' + params.propSiteNo + '"' +
                ' , excelSequence: ' + params.excelSequence +
                ' , type: "' + params.type + '"' +
                ' , hasClosed: "' + params.hasClosed + '"' +
                ') { ' +
                'id ' +
                'uuid ' +
                'store_code ' +
                'centerlat ' +
                'centerlon ' +
                'type ' +
                'bland_id ' +
                'handle_category_tobacco ' +
                'open_date ' +
                'location_excel_03_data ' +
                '}'
        case "getReceptionBook":
            const statusId: string[] = params.status_id.map((value: any) => `"${value}"`);
            const picType: string[] = params.pic_type.map((value: any) => `"${value}"`);
            const pic_staff_number: string[] = params.pic_staff_number.map((value: any) => `"${value}"`);
            const taskId: string[] = params.task_id.map((value: any) => `"${value}"`);
            const departmentCode: string[] = params.department_code.map((value: any) => `"${value}"`);
            const district_code: string[] = params.district_code.map((value: any) => `"${value}"`);
            const plan_area_rfc: string[] = params.plan_area_rfc.map((value: any) => `"${value}"`);
            return 'getReceptionBook(' +
                '   status_task_update_date: "' + params.status_task_update_date + '"' +
                ' , status_id: [' + statusId + ']' +
                ' , pic_type: [' + picType + ']' +
                ' , pic_staff_number: [' + pic_staff_number + ']' +
                ' , task_id: [' + taskId + ']' +
                ' , plan_area_id: "' + params.plan_area_id + '"' +
                ' , department_code: [' + departmentCode + ']' +
                ' , district_code: [' + district_code + ']' +
                ' , plan_area_name: "' + params.plan_area_name + '"' +
                ' , plan_area_rfc: [' + plan_area_rfc + ']' +
                ' , login_user_id: "' + params.login_user_id + '"' +
                ' , userType: "' + params.userType + '"' +
                ') { ' +
                'id ' +
                'plan_area_id ' +
                'branch ' +
                'status_seq ' +
                'create_date ' +
                'reception_no ' +
                'type ' +
                'status_id ' +
                'task_id ' +
                'task_name ' +
                'pic_type ' +
                'pic_name ' +
                'pic_staff_number ' +
                'pic_mail_address ' +
                'pic_department_code ' +
                'pic_department_name ' +
                'pic_job_category_code ' +
                'pic_job_category_name ' +
                'assembly ' +
                'pattern ' +
                'pattern_details ' +
                'plan_area_name ' +
                'status_task_update_date ' +
                'elapsedTime ' +
                'elapsedFlag ' +
                'plan_area_staff_number ' +
                'plan_area_staff_name ' +
                'plan_area_district_code ' +
                'plan_area_district_name ' +
                'plan_area_department_code ' +
                'plan_area_department_name ' +
                'daily_sales_year_1 ' +
                'daily_sales_year_2 ' +
                'daily_sales_year_3 ' +
                'daily_sales_year_4 ' +
                'daily_sales_year_5 ' +
                '}'
        case "getPlanAreaList":
            return 'getPlanAreaList' +
                ' { ' +
                'id ' +
                'citycode ' +
                'plan_area_name ' +
                'do_code ' +
                '}'
        case "regist":
            return JSON.stringify(params.query);
        case "getN15AddMarket":
            return 'getN15AddMarket(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ') { ' +
                'prop_site_no ' +
                'store_code ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'household_count_sej_road_3km ' +
                'employees_count_sej_road_3km ' +
                'household_count_cvs_road_3km ' +
                'employees_count_cvs_road_3km ' +
                'household_count_cvssm_road_3km ' +
                'employees_count_cvssm_road_3km ' +
                'household_count_road_0to1km ' +
                'employees_count_road_0to1km ' +
                'household_count_road_1to2km ' +
                'employees_count_road_1to2km ' +
                'household_count_road_2to3km ' +
                'employees_count_road_2to3km ' +
                'household_count_walk_market_5min_cut ' +
                'employees_count_walk_market_5min_cut ' +
                'household_count_walk_market_0to1min ' +
                'employees_count_walk_market_0to1min ' +
                'household_count_walk_market_1to2min ' +
                'employees_count_walk_market_1to2min ' +
                'household_count_walk_market_2to3min ' +
                'employees_count_walk_market_2to3min ' +
                'household_count_walk_market_3to4min ' +
                'employees_count_walk_market_3to4min ' +
                'household_count_walk_market_4to5min ' +
                'employees_count_walk_market_4to5min ' +
                'household_count_station_back_market_0to5min ' +
                'employees_count_station_back_market_0to5min ' +
                'household_count_station_back_market_5to10min ' +
                'employees_count_station_back_market_5to10min ' +
                'household_count_station_back_market_10to15min ' +
                'employees_count_station_back_market_10to15min ' +
                'passengers_count_nearest_station ' +
                'distance_nearest_station ' +
                'population_ordinary_daytime_residence ' +
                'population_ordinary_daytime_work ' +
                'population_ordinary_daytime_others ' +
                'population_ordinary_night_residence ' +
                'population_ordinary_night_work ' +
                'population_ordinary_night_others ' +
                'population_ordinary_residence ' +
                'population_ordinary_work ' +
                'population_ordinary_others ' +
                'population_holiday_residence ' +
                'population_holiday_work ' +
                'population_holiday_others ' +
                '}'
        case 'getLocationTraffic5mcut':
            return 'getLocationTraffic5mcut(' +
                ' plan_area_id: "' + params.plan_area_id + '"' +
                ' branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'excel_path ' +
                'layout_img_path ' +
                'photo_img_path ' +
                'check_planting ' +
                'check_visibility ' +
                'check_stairs ' +
                'check_count ' +
                'car_another_survey ' +
                'people_another_survey ' +
                'cut_survey ' +
                'others_facility_1 ' +
                'others_name_1 ' +
                'others_distance_1 ' +
                'others_value_1 ' +
                'others_facility_2 ' +
                'others_name_2 ' +
                'others_distance_2 ' +
                'others_value_2 ' +
                'others_facility_3 ' +
                'others_name_3 ' +
                'others_distance_3 ' +
                'others_value_3 ' +
                'others_facility_4 ' +
                'others_name_4 ' +
                'others_distance_4 ' +
                'others_value_4 ' +
                'approval_confirm_flag ' +
                'approval_confirm_user ' +
                'approval_confirm_department ' +
                'approval_confirm_date ' +
                'approval_confirm_stamp ' +
                'approval_research_mgr_flag ' +
                'approval_research_mgr_user ' +
                'approval_research_mgr_department ' +
                'approval_research_mgr_date ' +
                'approval_research_mgr_stamp ' +
                'approval_research_gm_flag ' +
                'approval_research_gm_user ' +
                'approval_research_gm_department ' +
                'approval_research_gm_date ' +
                'approval_research_gm_stamp ' +
                'approval_mgr_flag ' +
                'approval_mgr_user ' +
                'approval_mgr_department ' +
                'approval_mgr_date ' +
                'approval_mgr_stamp ' +
                'approval_gm_flag ' +
                'approval_gm_user ' +
                'approval_gm_department ' +
                'approval_gm_date ' +
                'approval_gm_stamp ' +
                '}'
        case 'getLocationWalk5minCircle':
            return 'getLocationWalk5minCircle(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ') { ' +
                'plan_area_id ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'shape_geometry ' +
                'shape_feature ' +
                'ring_id ' +
                'ring_size ' +
                'ring_no ' +
                'ring_pntid ' +
                'ring_pntnm ' +
                'store_code ' +
                'step ' +
                'store_open_date ' +
                'store_close_date ' +
                'prefecture ' +
                'address ' +
                'alcohollicense ' +
                'tobaccolicence ' +
                'latitude ' +
                'longtitude ' +
                'location_survey_date ' +
                'request_candidate_name ' +
                'survey_detail ' +
                'specialtype ' +
                'requester_id ' +
                'requester_name ' +
                'match_x ' +
                'match_y ' +
                'shape_leng ' +
                'shape_area ' +
                '}'
        case "getChangeStatus":
            return 'getChangeStatus(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                '   branch: "' + params.branch + '"' +
                ') { ' +
                'before_task_data { ' +
                'before_task_id ' +
                'before_task_name ' +
                'before_status_id ' +
                'before_status_name ' +
                'before_pic_type ' +
                'before_unique_process_type } ' +
                'after_task_data { ' +
                'after_task_id ' +
                'after_task_name ' +
                'after_status_id ' +
                'after_status_name ' +
                'after_pic_type ' +
                'after_unique_process_type } ' +
                '}'
        case "getDeficiencyComment":
            return 'getDeficiencyComment(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'create_date ' +
                'status_seq ' +
                'table_name ' +
                'column_name ' +
                'deficiency_status ' +
                'comment ' +
                '}'
        case 'getLocationPictuer':
            return 'getLocationPictuer(' +
                ' plan_area_id: "' + params.plan_area_id + '"' +
                ' branch: "' + params.branch + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'create_date ' +
                'update_date ' +
                'delete_flag ' +
                'pictuer_1_path ' +
                'pictuer_2_path ' +
                'pictuer_3_path ' +
                'pictuer_4_path ' +
                'pictuer_5_path ' +
                'pictuer_6_path ' +
                'pictuer_7_path ' +
                'pictuer_8_path ' +
                'viewing_distance ' +
                '}'
        case "getCircularHistory":
            return 'getCircularHistory(' +
                '   plan_area_id: "' + params.plan_area_id + '"' +
                ' , plan_area_name: "' + params.plan_area_name + '"' +
                ' , fromDate: "' + params.fromDate + '"' +
                ' , toDate: "' + params.toDate + '"' +
                ' , comment_type: "' + params.comment_type + '"' +
                ' , commentPoA: "' + params.commentPoA + '"' +
                ') { ' +
                'create_date ' +
                'pic_department_name ' +
                'pic_staff_number ' +
                'plan_area_id ' +
                'plan_area_name ' +
                'reception_no ' +
                'branch ' +
                'status_name ' +
                'pic_type_name ' +
                'pic_name ' +
                'task_name ' +
                'comment_type ' +
                'commentText ' +
                'plan_area_staff_number ' +
                'plan_area_staff_name ' +
                'plan_area_district_code ' +
                'plan_area_district_name ' +
                'plan_area_department_code ' +
                'plan_area_department_name ' +
            '}'
    case 'getLocationConflictMarketCut':
      return 'getLocationConflictMarketCut('+
        ' plan_area_id: "' + params.plan_area_id + '"' +
        ' branch: "' + params.branch + '"' +
        ') { ' +
        'plan_area_id ' +
        'branch ' +
        'create_date ' +
        'update_date ' +
        'delete_flag ' +
        'households ' +
        'employees ' +
        'excel_path ' +
        'market_cut_img_path ' +
        'market_cut_basis_img_path ' +
        'approval_confirm_flag ' +
        'approval_confirm_user ' +
        'approval_confirm_department ' +
        'approval_confirm_date ' +
        'approval_confirm_stamp ' +
        'approval_research_mgr_flag ' +
        'approval_research_mgr_user ' +
        'approval_research_mgr_department ' +
        'approval_research_mgr_date ' +
        'approval_research_mgr_stamp ' +
        'approval_research_gm_flag ' +
        'approval_research_gm_user ' +
        'approval_research_gm_department ' +
        'approval_research_gm_date ' +
        'approval_research_gm_stamp ' +
        'approval_mgr_flag ' +
        'approval_mgr_user ' +
        'approval_mgr_department ' +
        'approval_mgr_date ' +
        'approval_mgr_stamp ' +
        'approval_gm_flag ' +
        'approval_gm_user ' +
        'approval_gm_department ' +
        'approval_gm_date ' +
        'approval_gm_stamp ' +
        '}'
    case "getLocationDrawing":
      return 'getLocationDrawing(' +
        '   plan_area_id: "' + params.plan_area_id + '"' +
        ' , branch: "' + params.branch + '"' +
        ') { ' +
        'upload_file_paths ' +
        'is_green_gem ' +
        'is_grave ' +
        'is_median_strip ' +
        'is_bus_stop_blackout ' +
        'is_in_green_gem ' +
        'is_other_tenant_parking ' +
        'is_expressway_etc_pole ' +
        'is_bus_lane_etc ' +
        'is_electricity_pole ' +
        'is_sidewalk ' +
        'is_one_way_street ' +
        'is_tram_track ' +
        'is_electricity_pole_branch_line ' +
        'is_crosswalk ' +
        'is_no_entry_road ' +
        'is_parking_meter ' +
        'is_high_voltage_towers ' +
        'is_guardrail ' +
        'is_waterway_etc ' +
        'is_others_place ' +
        'is_hydrant_etc ' +
        'is_street_trees_etc ' +
        'is_railroad_crossing ' +
        'is_old_road ' +
        'is_billboard_tower_etc ' +
        'is_street_lights_etc ' +
        'is_bridge ' +
        'is_unpaved_road ' +
        'is_garbage_dump ' +
        'is_road_signs ' +
        'is_overpass ' +
        'is_unmaintained_etc ' +
        'is_other ' +
        'other_long_text ' +
        'hand_drawn_layout ' +
        'hand_drawn_layout_total ' +
        'hand_drawn_layout_total_tsubo ' +
        '}'
    case "getStaffMaster":
      return 'getStaffMaster' +
        ' { ' +
        'staff_number ' +
        'staff_name ' +
        'department_code_oa ' +
        'department_name_oa ' +
        'top_department_code_oa ' +
        'top_department_name_oa ' +
        'hq_department_code_oa ' +
        'hq_department_name_oa ' +
        'job_category_code_oa ' +
        'job_category_name_oa ' +
        '}'
    case "getDepartmentMaster":
      return 'getDepartmentMaster' +
        ' { ' +
        'department_code_oa ' +
        'department_name_oa ' +
        'district_code_oa ' +
        'district_name_oa ' +
        '}'
    case "getTrafficOtherStation":
      return 'getTrafficOtherStation(' +
        '   plan_area_id: "' + params.plan_area_id + '"' +
        ' , excel_sequence: ' + params.excel_sequence +
        ') { ' +
        'station_entrance_name ' +
        'road_distance ' +
        '}'
    case "getTrafficOtherSchool":
      return 'getTrafficOtherSchool(' +
        '   plan_area_id: "' + params.plan_area_id + '"' +
        ' , excel_sequence: ' + params.excel_sequence +
        ') { ' +
        'display_name ' +
        'trade_area_value ' +
        '}'
    case "getTableNameMaster":
      return 'getTableNameMaster' +
        ' { ' +
          'table_physics_name ' +
          'column_physics_name ' +
          'create_date ' +
          'update_date ' +
          'delete_flag ' +
          'table_logic_name ' +
          'column_logic_name ' +
          'sort_column ' +
        '}'
    case "getExistingSejStoreAll":
      return 'getExistingSejStoreAll' +
        ' { ' +
          'store_code ' +
          'tenpo_name ' +
        '}'
    case "getExistingSejStoreUsePlanAreaId":
      return 'getExistingSejStoreUsePlanAreaId(' +
        '   plan_area_id: "' + params.plan_area_id + '"' +
        ' , branch: "' + params.branch + '"' +
        ' , child_location: "' + params.child_location + '"' +
        ') { ' +
          'use_plan_area_id ' +
        '}'
    case "getExistingSejStore":
      return 'getExistingSejStore(' +
        '   storeCode: "' + params.store_code + '"' +
        ') { ' +
          'store_code ' +
          'ctycode ' +
          'tenpo_name ' +
          'tenpo_address ' +
          'prop_site_no ' +
          'step ' +
          'centerlat ' +
          'centerlon ' +
          'geohash ' +
          'openingdate ' +
          'closingdate ' +
          'doname ' +
          'dmname ' +
          'ofcname ' +
          'zoname ' +
          'contract_type ' +
          'parkingarea ' +
          'sales_forecast ' +
          'salesfloor ' +
          'liquorlicense ' +
          'tobaccolicense ' +
          'plan_area_feature ' +
          'building_feature ' +
          'entrance_feature ' +
          'update_date ' +
          'existing_best_location_flag ' +
          'existing_multi_flag ' +
          'sales_type ' +
          'store_direction ' +
          'sales ' +
          'docode ' +
        '}'
    case "getReceptionBookByIdAndBranch":
      return 'getReceptionBookByIdAndBranch(' +
        '   plan_area_id: "' + params.plan_area_id + '"' +
        '   branch: "' + params.branch + '"' +
        ') { ' +
          'id ' +
          'plan_area_id ' +
          'branch ' +
          'status_seq ' +
          'create_date ' +
          'reception_no ' +
          'type ' +
          'status_id ' +
          'task_id ' +
          'task_name ' +
          'pic_type ' +
          'pic_name ' +
          'pic_staff_number ' +
          'pic_mail_address ' +
          'pic_department_code ' +
          'pic_department_name ' +
          'pic_job_category_code ' +
          'pic_job_category_name ' +
          'assembly ' +
          'pattern ' +
          'pattern_details ' +
          'plan_area_name ' +
          'status_task_update_date ' +
          'elapsedTime ' +
          'elapsedFlag ' +
          'plan_area_staff_number ' +
          'plan_area_staff_name ' +
          'plan_area_district_code ' +
          'plan_area_district_name ' +
          'plan_area_department_code ' +
          'plan_area_department_name ' +
          '}'
    case "getLocationLinkByUsePlanAreaId":
      return 'getLocationLinkByUsePlanAreaId(' +
        '   usePlanAreaId: "' + params.use_plan_area_id + '"' +
        ') { ' +
          'store_code ' +
          'link_no ' +
          'link_plan_area_id ' +
          'use_plan_area_id ' +
          'store_plan_area_id ' +
          'create_date ' +
          'update_date ' +
          '}'
    case "getTaskManage":
      return 'getTaskManage(' +
        '   task_id: "' + params.task_id + '"' +
        ') { ' +
          'task_id ' +
          'before_task_id ' +
          'after_task_id ' +
          'status_id ' +
          'pic_type ' +
          'task_name_id ' +
          'unique_process_type ' +
          'department_job_check_type ' +
          '}'
    default:
      return "";
  }
}



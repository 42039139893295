import * as React from 'react';

import TradeAreaCar5MinMp from '../subPage/TradeArea/TradeAreaCar5MinMp';
import TradeAreaMpData from '../subPage/TradeArea/TradeAreaMpData';
import TradeAreaNationalTaxAudit from '../subPage/TradeArea/TradeAreaNationalTaxAudit';

export interface resultDialogProps {
    props:{
    }
};

//「:」で文字列を改行する関数
export const lineBreak = (text: string) => {
    const newTextList = text.split(':');
    return newTextList.map((text, index)=> (<span key={index}>{text}<br /></span>));
};

const TradeArea:React.FC<resultDialogProps> = ({props}) => {


    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <TradeAreaNationalTaxAudit props={{}}/>
            <TradeAreaCar5MinMp props={{}}/>
            <TradeAreaMpData props={{}}/>
        </div>
    );
        
}


export default TradeArea;

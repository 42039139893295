import React,{ useEffect } from 'react';

import {
    Container,
    InputLabel,
    Box,
    Button,
    Grid,
    Modal,
} from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DrawIcon from '@mui/icons-material/Draw';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import UndoIcon from '@mui/icons-material/Undo';
import RefreshIcon from '@mui/icons-material/Refresh';
import { storage } from '../../../utility/firebase';
import { uploadString, uploadBytes, ref } from 'firebase/storage';
import { useUtilityContext } from '../../../utility-provider';
import ConfirmDialog from '../../components/ConfirmDialog';
import { LOCAL_CONSTANT } from '../../Const'
import { useAppSelector } from '../../../app/hooks';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "96%",
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const TOUCHRESET = 0
const TOUCHSTART = 1
const TOUCHEND = 2

interface PhotoEditProps {
    props:{
        open:boolean,
        handleModal:React.Dispatch<React.SetStateAction<boolean>>,
        editImgSrc: string,
        editImgMeta: any,
        editFlg:boolean,
        setEditFlg: React.Dispatch<React.SetStateAction<boolean>>,
        rowData: any,
        openId: number,
        changeEditPhoto: any,
    }
};

const PhotoEdit:React.FC<PhotoEditProps> = ({props}) => {
    //各種設定値定数
    const defaultLineWidth: number = 2; // デフォルトの線の太さ
    const thickLineWidth: number = 6; // BOLDモード時の線の太さ
    const defaultEraserWidth: number = 12; // デフォルトの線の太さ
    const thickEraserWidth: number = 48; // BOLDモード時の線の太さ
    const defaultLineColor: string = "black"; // デフォルトの線の色
    const defaultTransParency: number = 1; // デフォルトの線の透過度
    const halfTransParency: number = 0.3; // ぼかし時の線の透過度
    const cropHandleLength: number = 12; // トリミング用ドラッグポイント辺長
    const cropHandleRangeMagnification: number = 2; // トリミング用ドラッグポイント範囲倍率
    const lineColorKinds: any = {
        // 線の色設定
        black: "black",
        red: "red",
        orange: "orange",
        blue: "blue",
        green: "green",
    };
    interface Point {
        x: number;
        y: number;
    };
    interface CropRect {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    const styledList={
        listStyle:"none",
        padding:0
    };
    const selectedList = {
        width: "4px",
        backgroundColor: "#3ccc0094",
    };
    const nonSelectedList = {};

    // モーダルopen
    const [open, setOpen] = React.useState(false);

    //画像用
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    //線引き用
    const lineCanvasRef = React.useRef<HTMLCanvasElement>(null);

    //トリミング用
    const cropCanvasRef = React.useRef<HTMLCanvasElement>(null); 

    //直線ポイント描画用
    const straightLinePointCanvasRef = React.useRef<HTMLCanvasElement>(null); 

    //マージ用
    const mergeCanvasRef = React.useRef<HTMLCanvasElement>(null);

    //トリミング用
    const trimingCanvasRef = React.useRef<HTMLCanvasElement>(null);

    //画像用canvasのcontext
    const [canvasContext, setCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); 

    //ライン用canvasのcontext
    const [lineCanvasContext, setLineCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null);

    //切取り用canvasのcontext
    const [cropCanvasContext, setCropCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); 

    //直線ポイント描画用canvasのcontext
    const [straightLinePointCanvasContext, setStraightLinePointCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); 


    //マージ用canvasのcontext
    const [mergeCanvasContext, setMergeCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null);

    //トリミング用canvasのcontext
    const [trimingCanvasContext, setTrimingCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null);

    //線を引くモード
    const [createMode, setCreateMode] = React.useState<boolean>(false);

    //線を引くモード
    const [lineMode, setLineMode] = React.useState<boolean>(false);

    //線を消すモード
    const [eraserMode, setEraserMode] = React.useState<boolean>(false);

    //切り取りモード
    const [cropMode, setCropMode] = React.useState<boolean>(false);

    //切り取りモード
    const [textMode, setTextMode] = React.useState<boolean>(false);

    const [textValue, setTextValue] = React.useState("");

    //切り取り準備完了有無(切り取り範囲を指定し終わったらtrueとなる)
    const [readyCrop, setReadyCrop] = React.useState<boolean>(false); 

    //透過度設定値(線を引くときの線の透過度を管理)
    const [transParency, setTransParency] = React.useState<boolean>(false); 
    
    //初期画像
    const [initImage, setInitImage] = React.useState<ImageData | null>(null);

    //切取り範囲変更点対象
    const [dragHandle, setDragHandle] = React.useState<number | null>(null); 

    //マウスのドラッグ判定用
    const [drawing, setDrawing] = React.useState(false); 

    //クリック、ドラッグ時のマウスポイント。線を引くときに使用
    const [lastPoint, setLastPoint] = React.useState<Point>({ x: 0, y: 0 }); 

    const [startToutchPoint, setStartToutchPoint] = React.useState<Point>({ x: 0, y: 0 });
    const [touchStart, setTouchStart] = React.useState(0)
    const [touchHandle, setTouchHandle] = React.useState(false);

    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

    //切取り範囲
    const [cropRect, setCropRect] = React.useState<CropRect>({
        x: 0,
        y: 0,
        width: 200,
        height: 200,
    });

    //線の色
    const [lineColor, setLineColor] = React.useState<string>(lineColorKinds.black);

    //線の太さ
    const [lineWidth, setLineWidth] = React.useState<boolean>(false);
    const [mergeDraw, setMergeDraw] = React.useState<boolean>(false);
    const [lineMergeDraw, setLineMergeDraw] = React.useState<boolean>(false);
    const [editImgPath, setEditImgPath] = React.useState(props.editImgSrc)
    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const utilityCtx = useUtilityContext();

    React.useEffect(() =>{
        if(props.open){
            if (props.rowData.id === props.openId) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        }
    },[props.open])


    //キャンセルボタン押下時のイベント
    const closeEditForm = () => {
        props.setEditFlg(false);
        setOpen(false)
        props.handleModal(false)
        handleLineMode(false);
    }

    // canvasの統合
    const imgMerge = async (mergeCanvasContext: any, cropRect: CropRect) => {
        if (mergeCanvasContext) {
            await mergeCanvasContext.drawImage(createImage(canvasRef.current), 0, 0)
            await mergeCanvasContext.drawImage(createImage(lineCanvasRef.current), 0, 0)
            await mergeCanvasContext.drawImage(createImage(straightLinePointCanvasRef.current), 0, 0)
            setMergeDraw(!mergeDraw)
            // トリミング
            trimming(cropRect)
        }
    }

    const trimming = async (cropRect: CropRect) => {
        if (trimingCanvasContext) {
            const image: HTMLImageElement = await trimmingImage(mergeCanvasRef.current)
            const canvas: any = trimingCanvasRef.current;
            if (!canvas) return;
            canvas.width = cropRect.width
            canvas.height = cropRect.height
            trimingCanvasContext.drawImage(
                image,
                cropRect.x, cropRect.y,
                cropRect.width, cropRect.height,
                0, 0,
                cropRect.width, cropRect.height
            );
        }
    }

    const confirmUpload = () => {
        imgMerge(mergeCanvasContext, cropRect)
        setConfirmDialogOpen(true)
    }

    const cancelUpload = () => {
        setConfirmDialogOpen(false)
    }

    //登録ボタン押下時のイベント
    const uploadResult = async () => {
        handleLineMode(false);
        setConfirmDialogOpen(false)
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }

        imgMerge(mergeCanvasContext, cropRect)
        .then(() => {
            getImagefromCanvas(trimingCanvasRef.current)
            .then((image) => {
                uploadImage(image)
            })
        }).catch((e) => {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }).finally(() => {
            setMergeDraw(false)
            resetCrop()
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        });
    }

    /**
     * Canvasをblobとして取得
     *
     * @param {string} id  対象canvasのid
     * @return {object}
     */
    function getImagefromCanvas(canvas: any){
        return new Promise((resolve) => {
            canvas.toBlob( function ( blob: any ) {
                resolve(blob)
            });
        });
    }

    const createImage = (canvas: any) => {
        var image= new Image
         image.src = canvas.toDataURL()
        return image
    }

    const trimmingImage = async (canvas: any) => {
        const image: HTMLImageElement = await new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', reject)
            image.src = canvas.toDataURL()
          })
        return image
    }

    // 画像アップロード関数
    const uploadImage = async (image: any) => {
        const date = new Date();
        const dateString = date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0") + date.getDate().toString().padStart(2, "0") + '_' +
            date.getHours().toString().padStart(2, "0") + date.getMinutes().toString().padStart(2, "0") + date.getSeconds().toString().padStart(2, "0");
            
        // metaデータのファイル名を分割
        const fileData = props.editImgMeta.contentType.split(/[/\n]/);
        const path = '/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+'/photo/EDITIMG_'+props.openId+'_'+dateString+'.'+fileData[1]
        const storageRef = ref(storage, path);

        setEditImgPath(path)
        const metadata = {
            contentType: props.editImgMeta.contentType,
            customMetadata: {
                GPSDateTimeStamp: props.editImgMeta.customMetadata === undefined
                ? undefined
                : props.editImgMeta.customMetadata.GPSDateTimeStamp
            }
        };
        await uploadBytes(storageRef, image, metadata)
            .then((snapshot) => {
                console.log("アップロードに成功しました");
                setOpen(false)
                props.handleModal(false)
                props.changeEditPhoto(props.openId, path)
            })
            .catch((error) => {
                console.log("アップロードに失敗しました");
            });
    }

    //画像変更時。canvasへの画像表示、各種設定初期化。
    useEffect(() => {
        refreshCanvas();
    }, [props.editImgSrc]);

    //編集画面開閉時のファイルパス設定
    useEffect(() => {
        if (props.editFlg) {
            //setFilePath(baseFilePath);
            resetCrop();
            resetLine();
            resetCreate();
            setCreateMode(true);
            refreshCanvas();
        if (!lineCanvasContext) return;
            lineCanvasContext.setLineDash([]);
        } else {
            setCropRect({ x: 0, y: 0, width: 0, height: 0 });
        }
    }, [props.editFlg]);

    //線の透過度設定
    useEffect(() => {
        if (!lineCanvasContext || !straightLinePointCanvasContext) return;
        if (transParency) {
            lineCanvasContext.globalAlpha = halfTransParency;
            straightLinePointCanvasContext.globalAlpha = halfTransParency;
        } else {
            lineCanvasContext.globalAlpha = defaultTransParency;
            straightLinePointCanvasContext.globalAlpha = defaultTransParency;
        }
    }, [transParency]);

    //消しゴムモード設定
    useEffect(() => {
        if (!lineCanvasContext || !straightLinePointCanvasContext) return;
        lineCanvasContext.globalCompositeOperation = eraserMode
        ? "destination-out"
        : "source-over";
    }, [eraserMode]);

    //線のカラー変更
    useEffect(() => {
        if (!lineCanvasContext || !straightLinePointCanvasContext) return;
        lineCanvasContext.strokeStyle = lineColor;
        straightLinePointCanvasContext.strokeStyle = lineColor;
    }, [lineColor]);

    //線の太さ変更
    useEffect(() => {
        changeLineWidth()
    }, [lineWidth]);

    // モード切替で線の太さ変更
    useEffect(() => {
        changeLineWidth()
    }, [createMode, lineMode, eraserMode]);

    const changeLineWidth = () => {
        if (!lineCanvasContext || !straightLinePointCanvasContext) return;
        let width = defaultLineWidth
        if (eraserMode) {
            width = defaultEraserWidth;
            if (lineWidth) {
                width = thickEraserWidth;
            }
        } else {
            if (lineWidth) {
                width = thickLineWidth;
            }
        }
        lineCanvasContext.lineWidth = width;
        straightLinePointCanvasContext.lineWidth = width;
    }

    //トリミング範囲変更時
    useEffect(() => {
        if (!cropCanvasContext) return;
        drawOverlay(cropCanvasContext, cropRect);
        if (cropMode) {
            drawCropRect(cropCanvasContext, cropRect);
        }
    }, [cropRect]);

    //トリミングモードOFF時トリミング範囲表示初期化
    useEffect(() => {
        if (!cropCanvasContext) return;
        if (cropMode) {
            drawOverlay(cropCanvasContext, cropRect);
            drawCropRect(cropCanvasContext, cropRect);
        } else {
            cropCanvasContext.clearRect(
            0,
            0,
            cropCanvasContext.canvas.width,
            cropCanvasContext.canvas.height
            );
            drawOverlay(cropCanvasContext, cropRect);
        }
    }, [cropMode]);

    //線描画関連(透過度、太さ)リセット
    const resetLine = (): void => {
        setLineWidth(false);
        setTransParency(false);
        setLineColor(defaultLineColor);
    };

    //create系(トリミング、透過度、消しゴム)リセット
    const resetCreate = (): void => {
        setCreateMode(false);
        setCropMode(false);
        setEraserMode(false);
        // setTransParency(false);
        setLineMode(false);
        setTextMode(false);
        setTextValue("")
    };

    //線の透過度選択時発火
    const handleCreateMode = (isMode: boolean) => {
        if (isMode) {
          resetCreate();
        }
        setCreateMode(isMode);
    };

    //線の透過度選択時発火
    const handleLineMode = (isMode: boolean) => {
        if (isMode) {
          resetCreate();
        }
        setLineMode(isMode);
    };

    //消しゴムモードボタン押下時発火
    const handleEraserMode = (isMode: boolean) => {
        if (isMode) {
          resetCreate();
        }
        setEraserMode(isMode);
    };

    //トリミングモードボタン押下時発火
    const handleCropMode = (isMode: boolean) => {
        if (isMode) {
          resetCreate();
        }
        setCropMode(isMode);
    };

    //トリミングモードボタン押下時発火
    const handleTextMode = (isMode: boolean) => {
        if (isMode) {
          resetCreate();
        }
        setTextMode(isMode);
    };

    // canvas更新
    const refreshCanvas = (): void => {
        const img = new Image();
        img.src = props.editImgSrc;
        img.crossOrigin = "Anonymous";
        //画像最大サイズ
        const maxWidth = 700;
        const maxHeight = 580;
        img.onload = () => {

            //元画像サイズ設定
            let newWidth = img.width;
            let newHeight = img.height;

            //最大サイズを超える場合リサイズ
            if(newWidth > maxWidth) {
                const scaleFactor = maxWidth / newWidth;
                newWidth *= scaleFactor;
                newHeight *= scaleFactor;
            }
            if(newHeight > maxHeight) {
                const scaleFactor = maxHeight / newHeight;
                newWidth *= scaleFactor;
                newHeight *= scaleFactor;
            }

            // imageCanvas(画像表示キャンバス)
            const canvas: any = canvasRef.current;
            if (!canvas) return;
            canvas.width = newWidth;
            canvas.height = newHeight;
            const context: CanvasRenderingContext2D = canvas.getContext("2d");
            context.drawImage(img, 0, 0, newWidth, newHeight);

            // lineCanvas(線描画キャンバス)
            const lineCanvas: HTMLCanvasElement | null = lineCanvasRef.current;
            if (!lineCanvas) return;

            setLineCanvasContext(getContext(lineCanvas, 0, 0, newWidth, newHeight, null));

            // cropCanvas(トリミング画像表示キャンバス)
            const cropCanvas: HTMLCanvasElement | null = cropCanvasRef.current;
            if (!cropCanvas) return;
            setCropCanvasContext(getContext(cropCanvas, 0, 0, newWidth, newHeight, null));

            // straightLinePointCanvas(直線描画表示キャンバス)
            const sLinePointCanvas: HTMLCanvasElement | null = straightLinePointCanvasRef.current;
            if (!sLinePointCanvas) return;
            setStraightLinePointCanvasContext(getContext(sLinePointCanvas, 0, 0, newWidth, newHeight, null));

            // mergeCanvas(画像表示キャンバス)
            const mergeCanvas: HTMLCanvasElement | null = mergeCanvasRef.current;
            if (!mergeCanvas) return;
            setMergeCanvasContext(getContext(mergeCanvas, 0, 0, newWidth, newHeight, img));

            // trimingCanvas(トリミング画像キャンバス)
            const trimingCanvas: HTMLCanvasElement | null = trimingCanvasRef.current;
            if (!trimingCanvas) return;
            setTrimingCanvasContext(getContext(trimingCanvas, 0, 0, newWidth, newHeight, img));

            if (!props.editImgSrc) return;
            setDrawing(false);
            setCanvasContext(context);
            setInitImage(context.getImageData(0, 0, newWidth, newHeight));
            setCropRect({ x: 0, y: 0, width: newWidth, height: newHeight});
        };
    };

    const getContext = (canvas: any, x: number, y : number, widht: number, height: number, img: any) => {
        canvas.width = widht;
        canvas.height = height;
        const context: CanvasRenderingContext2D = canvas.getContext("2d");
        if (img) {
            context.drawImage(img, x, y, widht, height);
        }

        return context
    }

    //トリミング(crop)関連リセット
    const resetCrop = (): void => {
        setCropMode(false);
        setReadyCrop(false);
    };

    //トリミング範囲描画
    const drawCropRect = (
        ctx: CanvasRenderingContext2D,
        rect: CropRect
        ): void => {
            const storeStrokeStyle = ctx.strokeStyle;
            const storeLineWidth = ctx.lineWidth;
            const storeFillStyle = ctx.fillStyle;

            // 対象範囲囲み線
            ctx.strokeStyle = "deepskyblue";
            ctx.lineWidth = 1;
            const { x, y, width, height } = rect;
            ctx.strokeRect(x, y, width, height);

            // 対象範囲移動点_塗りつぶし
            const half = cropHandleLength / 2;
            ctx.fillStyle = "white";
            ctx.lineWidth = 2;
            // 左上
            ctx.fillRect(x, y, cropHandleLength, cropHandleLength);
            // 右上
            ctx.fillRect(
                x + width - cropHandleLength,
                y,
                cropHandleLength,
                cropHandleLength
            );
            // 右下
            ctx.fillRect(
                x + width - cropHandleLength,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 左下
            ctx.fillRect(
                x,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 上
            ctx.fillRect(x + width / 2 - half, y, cropHandleLength, cropHandleLength);
            // 右
            ctx.fillRect(
                x + width - cropHandleLength,
                y + height / 2 - half,
                cropHandleLength,
                cropHandleLength
            );
            // 下
            ctx.fillRect(
                x + width / 2 - half,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 左
            ctx.fillRect(x, y + height / 2 - half, cropHandleLength, cropHandleLength);

            // 対象範囲移動点_囲み
            ctx.strokeStyle = "deepskyblue";
            ctx.lineWidth = 1;
            // 左上
            ctx.strokeRect(x, y, cropHandleLength, cropHandleLength);
            // 右上
            ctx.strokeRect(
                x + width - cropHandleLength,
                y,
                cropHandleLength,
                cropHandleLength
            );
            // 右下
            ctx.strokeRect(
                x + width - cropHandleLength,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 左下
            ctx.strokeRect(
                x,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 上
            ctx.strokeRect(x + width / 2 - half, y, cropHandleLength, cropHandleLength);
            // 右
            ctx.strokeRect(
                x + width - cropHandleLength,
                y + height / 2 - half,
                cropHandleLength,
                cropHandleLength
            );
            // 下
            ctx.strokeRect(
                x + width / 2 - half,
                y + height - cropHandleLength,
                cropHandleLength,
                cropHandleLength
            );
            // 左
            ctx.strokeRect(
            x,
            y + height / 2 - half,
            cropHandleLength,
            cropHandleLength
            );

        // 設定を戻す
        ctx.strokeStyle = storeStrokeStyle;
        ctx.lineWidth = storeLineWidth;
        ctx.fillStyle = storeFillStyle;
    };

    //トリミング範囲外部分のキャンバススタイル設定
    const drawOverlay = (ctx: CanvasRenderingContext2D, rect: CropRect): void => {
        const { x, y, width, height } = rect;
        ctx.clearRect(x, y, width, height);
        const storeFillStyle = ctx.fillStyle;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, ctx.canvas.width, y);
        ctx.fillRect(0, y, x, height);
        ctx.fillRect(x + width, y, ctx.canvas.width - (x + width), height);
        ctx.fillRect(
            0,
            y + height,
            ctx.canvas.width,
            ctx.canvas.height - (y + height)
            );
        ctx.fillStyle = storeFillStyle;
    };

    const setText = (text: string) => {
        handleTextMode(true)
        setTextValue(text)
    }

    //ドラッグの始め
    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const { offsetX, offsetY } = e.nativeEvent;
        if (textMode) return;
        if (lineMode) {
            if (touchStart === TOUCHRESET) {
                setStartToutchPoint({ x : offsetX, y : offsetY})
                handleTouchStartMode(TOUCHSTART)
            } else if (touchStart === TOUCHSTART) {
                handleTouchStartMode(TOUCHEND)
            }
            setDrawing(true);
        } else {
            startOperation(offsetX, offsetY);
        }
    };

    //ドラッグの終わり
    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (textMode) {
            drawText(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
        } else if (lineMode) {
            lineOperation(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
        } else {
            endOperation();
        }
    };

    const drawText = (offsetX: number, offsetY: number) => {
        // 座標ずらし
        let x = offsetX - 15
        let y = offsetY + 25
        if (textValue === 'SP') {
            x = offsetX - 25
            y = offsetY + 25
        }

        if (lineCanvasContext) {
            lineCanvasContext.globalAlpha = defaultTransParency;
            lineCanvasContext.font = '50px Roboto medium';
            lineCanvasContext.fillStyle = 'red';
            lineCanvasContext.fillText(textValue, x, y)

            let lineX = offsetX - 30
            let lineXX = 60
            let lineY = offsetY - 30
            let lineYY = 72
            if (textValue === 'SP') {
                lineX = offsetX - 34
                lineXX = 80
                lineY = offsetY - 30
                lineYY = 70
            }
            // 枠
            lineCanvasContext.rect( lineX, lineY, lineXX, lineYY);
            lineCanvasContext.strokeStyle = 'red';
            lineCanvasContext.lineWidth = 2;
            lineCanvasContext.stroke();

            lineCanvasContext.strokeStyle = lineColor;
            lineCanvasContext.fillStyle = lineColor;
            if (transParency) {
                lineCanvasContext.globalAlpha = halfTransParency;
            } else {
                lineCanvasContext.globalAlpha = defaultTransParency;
            }
            if (lineWidth) {
                lineCanvasContext.lineWidth = thickLineWidth;
            } else {
                lineCanvasContext.lineWidth = defaultLineWidth;
            }
        }
        imgMerge(mergeCanvasContext, cropRect)
    }

    //ドラッグ中。線を引く、クラップ範囲の指定等を行う。
    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        // 座標位置取得が必要なモードになっていないとき
        if (!drawing || (!createMode && !eraserMode && !cropMode && lineMode)) {
            return;
        }
        if (!lineMode && startToutchPoint.x !== 0 && startToutchPoint.y !== 0) {
            setStartToutchPoint({ x: 0, y: 0})
            handleTouchStartMode(TOUCHRESET)
            if (straightLinePointCanvasContext) {
                // ポイント削除
                straightLinePointCanvasContext.clearRect(
                    0,
                    0,
                    straightLinePointCanvasContext.canvas.clientWidth,
                    straightLinePointCanvasContext.canvas.clientHeight
                );
            }
            setDrawing(false);
        } else {
            moveOperation(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        }
    };

    const handleTouchStartMode = (touch: number) => {
        setTouchStart(touch)
    };

    //操作開始時(マウスクリック、タッチ)
    const startOperation = (offsetX: number, offsetY: number): void => {
        const { x, y, width, height } = cropRect;
        const operationLength = cropHandleLength * cropHandleRangeMagnification;

        if (cropMode) {
            if (
                offsetX >= x - cropHandleLength &&
                offsetX <= x + operationLength &&
                offsetY >= y - cropHandleLength &&
                offsetY <= y + operationLength
            ) {
                // 左上のドラッグ
                setDragHandle(0);
            } else if (
                offsetX >= x + width - operationLength &&
                offsetX <= x + width + cropHandleLength &&
                offsetY >= y - cropHandleLength &&
                offsetY <= y + operationLength
            ) {
                // 右上のドラッグ
                setDragHandle(1);
            } else if (
                offsetX >= x + width - operationLength &&
                offsetX <= x + width + cropHandleLength &&
                offsetY >= y + height - operationLength &&
                offsetY <= y + height + cropHandleLength
            ) {
                // 右下のドラッグ
                setDragHandle(2);
            } else if (
                offsetX >= x - cropHandleLength &&
                offsetX <= x + operationLength &&
                offsetY >= y + height - operationLength &&
                offsetY <= y + height + cropHandleLength
            ) {
                // 左下のドラッグ
                setDragHandle(3);
            } else if (
                offsetX >= x + width / 2 - cropHandleLength &&
                offsetX <= x + width / 2 + cropHandleLength &&
                offsetY >= y - cropHandleLength &&
                offsetY <= y + operationLength
            ) {
                // 上のドラッグ
                setDragHandle(4);
            } else if (
                offsetX >= x + width - operationLength &&
                offsetX <= x + width + cropHandleLength &&
                offsetY >= y + height / 2 - cropHandleLength &&
                offsetY <= y + height / 2 + cropHandleLength
            ) {
                // 右のドラッグ
                setDragHandle(5);
            } else if (
                offsetX >= x + width / 2 - cropHandleLength &&
                offsetX <= x + width / 2 + cropHandleLength &&
                offsetY >= y + height - operationLength &&
                offsetY <= y + height + cropHandleLength
            ) {
                // 下のドラッグ
                setDragHandle(6);
            } else if (
                offsetX >= x - cropHandleLength &&
                offsetX <= x + operationLength &&
                offsetY >= y + height / 2 - cropHandleLength &&
                offsetY <= y + height / 2 + cropHandleLength
            ) {
                // 左のドラッグ
                setDragHandle(7);
            }
        }
        setLastPoint({ x: offsetX, y: offsetY });
        setDrawing(true);
    };

    //操作終了時（マウスクリック、タッチ）
    const endOperation = (): void => {
        imgMerge(mergeCanvasContext, cropRect)
        setDrawing(false);
            if (cropMode) {
                setReadyCrop(true);
                setDragHandle(null);
            }
    };

    //操作時(マウスクリック、タッチ)
    const moveOperation = (offsetX: number, offsetY: number): void => {
        if (cropMode) {
            moveCropOperation(offsetX, offsetY);
        } else {
        if (!canvasContext || !lineCanvasContext) return;
            const canvas: HTMLCanvasElement = canvasContext.canvas;
            // 座標補正
            if (offsetX < 0) {
                offsetX = 0;
            } else if (canvas.width < offsetX) {
                offsetX = canvas.width;
            }
            if (offsetY < 0) {
                offsetY = 0;
            } else if (canvas.height < offsetY) {
                offsetY = canvas.height;
            }
            const currentPoint = {
                x: offsetX,
                y: offsetY,
            };

            lineCanvasContext.beginPath();
            lineCanvasContext.moveTo(lastPoint.x, lastPoint.y);
            lineCanvasContext.lineTo(currentPoint.x, currentPoint.y);
            lineCanvasContext.stroke();

            setLastPoint(currentPoint);
        }
    };

    // 直線描画
    const lineOperation = (offsetX: number, offsetY: number): void => {
        if (touchStart === TOUCHSTART) {
            if (!straightLinePointCanvasContext) return;
            // ポイント描画
            straightLinePointCanvasContext.beginPath();
            straightLinePointCanvasContext.fillStyle = lineColor;
            let lineWidthValue = defaultLineWidth
            if (lineWidth) {
                lineWidthValue = thickLineWidth;
            }
            straightLinePointCanvasContext.fillRect(
                startToutchPoint.x - (lineWidthValue / 2),
                startToutchPoint.y - (lineWidthValue / 2),
                lineWidthValue,
                lineWidthValue
            );
        } else if (touchStart === TOUCHEND) {
            if (!lineCanvasContext) return;
            lineCanvasContext.beginPath();
            lineCanvasContext.moveTo(startToutchPoint.x, startToutchPoint.y);
            lineCanvasContext.lineTo(offsetX, offsetY);
            lineCanvasContext.stroke();
            
            imgMerge(mergeCanvasContext, cropRect)
            setDrawing(false);
            // 始点クリック位置の初期化
            setStartToutchPoint({ x: 0, y: 0})
            handleTouchStartMode(TOUCHRESET)
            if (straightLinePointCanvasContext) {
                // ポイント削除
                straightLinePointCanvasContext.clearRect(
                    0,
                    0,
                    straightLinePointCanvasContext.canvas.clientWidth,
                    straightLinePointCanvasContext.canvas.clientHeight
                );
            }
        }
    };

    //トリミング操作時（マウスクリック、タッチ）
    const moveCropOperation = (offsetX: number, offsetY: number): void => {
        let currentPoint = {
            x: offsetX,
            y: offsetY,
        };

        if (!cropCanvasContext || dragHandle === null) return;
        const xDiff = currentPoint.x - lastPoint.x;
        const yDiff = currentPoint.y - lastPoint.y;
        let updateCropRect = { ...cropRect };

        switch (dragHandle) {
        case 0: // 左上
            updateCropRect.x += xDiff;
            updateCropRect.y += yDiff;
            updateCropRect.width -= xDiff;
            updateCropRect.height -= yDiff;
            break;
        case 1: // 右上
            updateCropRect.y += yDiff;
            updateCropRect.width += xDiff;
            updateCropRect.height -= yDiff;
            break;
        case 2: // 右下
            updateCropRect.width += xDiff;
            updateCropRect.height += yDiff;
            break;
        case 3: // 左下
            updateCropRect.x += xDiff;
            updateCropRect.width -= xDiff;
            updateCropRect.height += yDiff;
            break;
        case 4: // 上
            updateCropRect.y += yDiff;
            updateCropRect.height -= yDiff;
            break;
        case 5: // 右
            updateCropRect.width += xDiff;
            break;
        case 6: // 下
            updateCropRect.height += yDiff;
            break;
        case 7: // 左
            updateCropRect.x += xDiff;
            updateCropRect.width -= xDiff;
        break;
        }

        //範囲外かを判定
        const range = {
            x: 0,
            y: 0,
            width: cropCanvasContext.canvas.width,
            height: cropCanvasContext.canvas.height,
        };
        const within = isWithin(updateCropRect, range);

        //異常値回避、範囲外に出た場合の対応
        if (updateCropRect.x < 0) {
            updateCropRect.x = 0;
        } else if (updateCropRect.x > cropCanvasContext.canvas.width) {
            updateCropRect.x = cropCanvasContext.canvas.width;
        } else if (updateCropRect.y < 0) {
            updateCropRect.y = 0;
        } else if (updateCropRect.y > cropCanvasContext.canvas.height) {
            updateCropRect.y = cropCanvasContext.canvas.height;
        } else if (updateCropRect.width < 0) {
            updateCropRect.width = 0;
        } else if (updateCropRect.width > cropCanvasContext.canvas.width) {
            updateCropRect.width = cropCanvasContext.canvas.width;
        } else if (updateCropRect.height < 0) {
            updateCropRect.height = 0;
        } else if (updateCropRect.height > cropCanvasContext.canvas.height) {
            updateCropRect.height = cropCanvasContext.canvas.height;
        }

        setCropRect(updateCropRect);
        setLastPoint(currentPoint);

        //範囲外に出た場合は終了
        if (!within) {
            setDrawing(false);
            setReadyCrop(true);
            setDragHandle(null);
        }
    };

    //対象が範囲内か判定
    const isWithin = (target: CropRect, range: CropRect): boolean => {
        let ret = true;
            if (
                target.x < range.x ||
                target.x > range.width ||
                target.y < range.y ||
                target.y > range.height ||
                target.width < 0 ||
                target.width > range.width ||
                target.height < 0 ||
                target.height > range.height
            ) {
                ret = false;
            }
        return ret;
    };

    //元の画像状態に戻す
    const replyAll = () => {
        if (!canvasContext || !initImage || !lineCanvasContext || !straightLinePointCanvasContext || !mergeCanvasContext) return;
            canvasContext.putImageData(initImage, 0, 0);
            lineCanvasContext.clearRect(
                0,
                0,
                lineCanvasContext.canvas.clientWidth,
                lineCanvasContext.canvas.clientHeight
            );
            lineCanvasContext.beginPath()
            straightLinePointCanvasContext.clearRect(
                0,
                0,
                straightLinePointCanvasContext.canvas.clientWidth,
                straightLinePointCanvasContext.canvas.clientHeight
            );
            straightLinePointCanvasContext.beginPath()
            mergeCanvasContext.clearRect(
                0,
                0,
                mergeCanvasContext.canvas.clientWidth,
                mergeCanvasContext.canvas.clientHeight
            );
            mergeCanvasContext.beginPath()
            handleTouchStartMode(TOUCHRESET)
        if (!cropCanvasContext) return;
        const { width, height } = cropCanvasContext.canvas;
        setCropRect({ x: 0, y: 0, width: width, height: height });
    };

    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
            <Box>
                <InputLabel>{props.rowData.id + '：' + props.rowData.title + ' '}写真編集</InputLabel>
            </Box>
            <Container>
                <Grid container spacing={2} sx={{justifyContent:"center",alignItem:"center"}}>
                    {/* 編集ボタン */}
                    <Grid item xs={1}>
                        <div>
                            <ul style={styledList}>
                                <li style={cropMode ? selectedList : nonSelectedList}>
                                    <Button onClick={() => {
                                        handleCropMode(true)
                                    }}><CropIcon/>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <Box sx={{ display: 'flex'}}>
                            <Box>
                                <ul style={styledList}>
                                    <li style={createMode && !lineMode ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    handleCreateMode(true);
                                                    handleLineMode(false);
                                                }}><BorderColorIcon/>
                                        </Button>
                                    </li>
                                    <li style={lineMode ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    handleCreateMode(false);
                                                    handleLineMode(true);
                                                }}><BlurOffIcon/>
                                        </Button>
                                    </li>
                                    <li style={eraserMode ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    handleEraserMode(true);
                                                    handleLineMode(false);
                                                    setTransParency(false);
                                                }}><AutoFixHighIcon/>
                                        </Button>
                                    </li>
                                </ul>
                                <ul style={styledList}>
                                    <li style={transParency ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setTransParency(!transParency);
                                                }}><BorderColorOutlinedIcon/>
                                        </Button>
                                    </li>
                                    <li style={lineWidth ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineWidth(!lineWidth);
                                                }}><FormatBoldIcon/>
                                        </Button>
                                    </li>
                                </ul>
                                <ul style={styledList}>
                                    <li style={textValue === '7' ? selectedList : nonSelectedList}>
                                        <Button
                                            sx={{color: '#FF0000', fontWeight: 'bold', fontSize: '1.2em'}}
                                            onClick={() => {
                                                    setText('7');
                                                }}>7
                                        </Button>
                                    </li>
                                    <li style={textValue === 'SP' ? selectedList : nonSelectedList}>
                                        <Button
                                            sx={{color: '#FF0000', fontWeight: 'bold', fontSize: '1.2em'}}
                                            onClick={() => {
                                                    setText('SP');
                                                }}>SP
                                        </Button>
                                    </li>
                                </ul>
                            </Box>
                            <Box>
                                <ul style={styledList}>
                                    <li style={lineColor === "black" ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineColor(lineColorKinds.black);
                                                }}><ColorLensIcon style={{color:"black"}}/>
                                        </Button>
                                    </li>
                                    <li style={lineColor === "red" ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineColor(lineColorKinds.red);
                                                }}><ColorLensIcon style={{color:"red"}}/>
                                        </Button>
                                    </li>
                                    <li style={lineColor === "orange" ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineColor(lineColorKinds.orange);
                                                }}><ColorLensIcon style={{color:"orange"}}/>
                                        </Button>
                                    </li>
                                    <li style={lineColor === "blue" ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineColor(lineColorKinds.blue);
                                                }}><ColorLensIcon style={{color:"blue"}}/>
                                        </Button>
                                    </li>
                                    <li style={lineColor === "green" ? selectedList : nonSelectedList}>
                                        <Button onClick={() => {
                                                    setLineColor(lineColorKinds.green);
                                                }}><ColorLensIcon style={{color:"green"}}/>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button onClick={() => {
                                                    replyAll();
                                                }}><RefreshIcon/>
                                        </Button>
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
                    {/* キャンバス */}
                    <Grid item xs={11}>
                        <Box sx={{
                            width: '90%',
                            height: '80vh'
                        }}>
                            <Box 
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    width: '900px',
                                }}
                            >
                                <canvas
                                    ref={canvasRef}
                                    style={{ position: "absolute", zIndex: 1, border: '1px solid black'}}
                                />
                                <canvas
                                    ref={lineCanvasRef}
                                    style={{ position: "absolute", zIndex: 2}}
                                />
                                <canvas
                                    ref={cropCanvasRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    style={{ position: "absolute", zIndex: 3, userSelect: "none", WebkitTouchCallout: "none", WebkitUserSelect: "none" }}
                                />
                                <canvas
                                    ref={straightLinePointCanvasRef}
                                    style={{ position: "absolute", zIndex: 2}}
                                />
                                <canvas
                                    ref={mergeCanvasRef}
                                    style={{ position: "absolute", zIndex: -1 }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{justifyContent:"center",alignItem:"center"}}>
                    {/* ボタン */}
                    <Grid container sx={{alignItems:'center', justifyContent:'center'}}>
                        <Grid item xs={6}>
                            <Box sx={{display: "flex",justifyContent: "center", alignItems: "center",}}>
                                <Button variant="outlined" color="error" onClick={()=>{closeEditForm()}}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{display: "flex",justifyContent: "center", alignItems: "center",}}>
                                <Button variant="contained" color="success" onClick={()=>{confirmUpload()}}>{LOCAL_CONSTANT.LABEL.SAVE}</Button>
                            </Box>
                        </Grid>
                        <ConfirmDialog props={{
                            open:confirmDialogOpen,
                            handleModal:setConfirmDialogOpen,
                            onCancel:cancelUpload,
                            onExecution:uploadResult,
                            mode:"uploadConfirm",
                            body:LOCAL_CONSTANT.CONFIRM_MESSAGE.CHANGE_SAVE,
                            confirmCancel:LOCAL_CONSTANT.LABEL.CANCEL,
                            confirmOk:LOCAL_CONSTANT.LABEL.SAVE}}
                        />                        
                    </Grid>
                    <canvas
                        ref={trimingCanvasRef}
                        style={{ position: "absolute", zIndex: -1, display: 'none' }}
                    />
                </Grid>
            </Container>
        </Box>
        </Modal>
    );
};

export default PhotoEdit;
import { createSlice } from '@reduxjs/toolkit';
import { initPictureObj } from '../data/type';

//Sliceの作成
export const pictureObjSlice = createSlice({
    name: 'picture',
    //初期State(Typeからインポート)
    initialState: initPictureObj,

    //toolKitを利用した値変更処理
    reducers: {
        setObjPicture: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setObjPicture
} = pictureObjSlice.actions;
export default pictureObjSlice.reducer;


import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Skeleton,
    styled,
} from "@mui/material";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../src/app/hooks';
import { useUserContext } from './user-provider';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.h5,
    padding: theme.spacing(1),
    textAlign: 'center',
    boxShadow: "none",
    border: "none",
}));

const headerStyle = {
    bgcolor: "white",
    position: "fixed",
    zIndex: 1250,
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
};

let userChangeMenu = {
    visibility: "hidden"
}

type Props = {
    toggleDrawer: () => void;
    userdata: {}
}

const AppHeader = ({ toggleDrawer, userdata }: Props) => {
    const location = useLocation();
    const user = useUserContext();
    const navigate = useNavigate();
    //ヘッダーオブジェクト
    const objAppHeader = useAppSelector((state) => state.appHeader);
    //ヘッダータイトル
    const [appTitle, setAppTitle] = useState("");
    //候補地情報
    const [planInfo, setPlanInfo] = useState("");
    //メニュースタイル
    const [iconVisible, setIconVisible] = useState("");
    //ローディングフラグ
    const [loadingFlg, setLoadingFlg] = useState(false);
    //候補地情報表示フラグ
    const [planInfoViewFlg, setPlanInfoViewFlg] = useState(false);
    //ユーザー切り替えメニュー表示フラグ(true = 表示　false = 非表示)
    const [userChangeFlag, setUserChangeFlag] = useState(false);

    //アプリのヘッダーオブジェクトの更新
    useEffect(() => {
        const planAreaId = objAppHeader.planAreaId; //候補地ID
        const planAreaName = objAppHeader.planAreaName; //候補地名
        const strPlanInfo = `　${planAreaName}\n（候補地No.${planAreaId}）`;
        setPlanInfo(strPlanInfo);
        setLoadingFlg(false);
    }, [objAppHeader]);


    //ヘッダータイトル切り分け
    useEffect(() => {
        //タイトル
        let strTitle = "";
        //スタイル
        let strVisible = "";
        //ローディング中
        setLoadingFlg(true);

        if (location.pathname === "/" ||
            location.pathname === "/PortalReport" ||
            location.pathname === "/Portal/CircularHistory"
        ) {
            strTitle = "立地調査書デジタル（ポータル）";
            strVisible = "hidden";
            setAppTitle(strTitle);
            setIconVisible(strVisible);
            setPlanInfoViewFlg(false);
        } else {
            strTitle = "立地調査書デジタル（調査書）";
            strVisible = "visible";
            setAppTitle(strTitle);
            setIconVisible(strVisible);
            setPlanInfoViewFlg(false);
            if (location.pathname === "/LongPage") {
                setPlanInfoViewFlg(true);
            }
        }
    }, [location.pathname]);

    //アプリ実行環境判定
    useEffect(() => {
        //ローカルまはたdev環境の場合はユーザー切り替えメニュー表示
        if (process.env.REACT_APP_MODE === "local" || process.env.REACT_APP_MODE === "dev") {
            setUserChangeFlag(true);
        }
    }, [])

    //Homeボタン押下イベント
    const goTop = () => {
        const element = document.getElementById('naviDrawer');

        //サイドメニュー非表示
        if (element) {
            element.style.display = "none";
        }
        navigate("/");
    };

    //ユーザーデータ（RFC）
    const rfcData = {
        "user_type": "z003",
        "department_code": "950404",
        "department_code_OA": "950404",
        "job_category_code": "403",
        "job_category_code_OA": "360",
        "top_department_code_OA": "950400",
        "manage_steps": ['0', '1', '2', '3', '4', '5', '6', '8', '44', '99', '7'],
        "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
        "substitute_staff_number": "k000196",
        "update_date": { "_seconds": 1631668481, "_nanoseconds": 652000000 },
        "mode": "Administrator",
        "a_test_flag": true,
        "manage_plans_outside_cities": ["9f884cb2f8"],
        "corporate_create_flag": "1",
        "corporate_flag": "ALL",
        "sales_display_flag": "1",
        "daily_regist_plan_flag": "0",
        "daily_regist_existing_flag": "0",
        "daily_regist_owner_flag": "0",
        "administrator_flag": "1",
        "zone_strategy_flag": "1",
        "manage_cities": ["13101", "13103", "13105", "ALL"],
        "expansion_polygon_flag": "1",
        "user_name": "RFCユーザー",
        "email": "",
        "staff_number": "s990099",
    };
    //ユーザーデータ（リサーチ）
    const rrData = {
        "user_type": "z001",
        "department_code": "992002",
        "department_code_OA": "992002",
        "job_category_code": "401",
        "job_category_code_OA": "230",
        "top_department_code_OA": "992000",
        "manage_steps": ['0', '1', '2', '3', '4', '5', '6', '8', '44', '99', '7'],
        "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
        "substitute_staff_number": "k000196",
        "update_date": { "_seconds": 1631668481, "_nanoseconds": 652000000 },
        "mode": "Administrator",
        "a_test_flag": true,
        "manage_plans_outside_cities": ["9f884cb2f8"],
        "corporate_create_flag": "1",
        "corporate_flag": "ALL",
        "sales_display_flag": "1",
        "daily_regist_plan_flag": "0",
        "daily_regist_existing_flag": "0",
        "daily_regist_owner_flag": "0",
        "administrator_flag": "1",
        "zone_strategy_flag": "1",
        "manage_cities": ["13101", "13103", "13105", "ALL"],
        "expansion_polygon_flag": "1",
        "user_name": "リサーチユーザー",
        "email": "",
        "staff_number": "s382444",
    };
    //ユーザーデータ（RM）
    const rmData = {
        "user_type": "z002",
        "department_code": "950652",
        "department_code_OA": "950652",
        "job_category_code": "401",
        "job_category_code_OA": "230",
        "top_department_code_OA": "950650",
        "manage_steps": ['0', '1', '2', '3', '4', '5', '6', '8', '44', '99', '7'],
        "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
        "substitute_staff_number": "k000196",
        "update_date": { "_seconds": 1631668481, "_nanoseconds": 652000000 },
        "mode": "Administrator",
        "a_test_flag": true,
        "manage_plans_outside_cities": ["9f884cb2f8"],
        "corporate_create_flag": "1",
        "corporate_flag": "ALL",
        "sales_display_flag": "1",
        "daily_regist_plan_flag": "0",
        "daily_regist_existing_flag": "0",
        "daily_regist_owner_flag": "0",
        "administrator_flag": "1",
        "zone_strategy_flag": "1",
        "manage_cities": ["13101", "13103", "13105", "ALL"],
        "expansion_polygon_flag": "1",
        "user_name": "RMユーザー",
        "email": "",
        "staff_number": "",
    };
    //ユーザーデータ（GM）
    const gmData = {
        "user_type": "z005",
        "department_code": "950650",
        "department_code_OA": "950650",
        "job_category_code": "401",
        "job_category_code_OA": "101",
        "top_department_code_OA": "950000",
        "manage_steps": ['0', '1', '2', '3', '4', '5', '6', '8', '44', '99', '7'],
        "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
        "substitute_staff_number": "k000196",
        "update_date": { "_seconds": 1631668481, "_nanoseconds": 652000000 },
        "mode": "Administrator",
        "a_test_flag": true,
        "manage_plans_outside_cities": ["9f884cb2f8"],
        "corporate_create_flag": "1",
        "corporate_flag": "ALL",
        "sales_display_flag": "1",
        "daily_regist_plan_flag": "0",
        "daily_regist_existing_flag": "0",
        "daily_regist_owner_flag": "0",
        "administrator_flag": "1",
        "zone_strategy_flag": "1",
        "manage_cities": ["13101", "13103", "13105", "ALL"],
        "expansion_polygon_flag": "1",
        "user_name": "GMユーザー",
        "email": "",
        "staff_number": "",
    };
    //ユーザーデータ（その他）
    const etcData = {
        "user_type": "z004",
        "department_code": "950801",
        "department_code_OA": "950801",
        "job_category_code": "401",
        "job_category_code_OA": "550",
        "top_department_code_OA": "950000",
        "manage_steps": ['0', '1', '2', '3', '4', '5', '6', '8', '44', '99', '7'],
        "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
        "substitute_staff_number": "k000196",
        "update_date": { "_seconds": 1631668481, "_nanoseconds": 652000000 },
        "mode": "Administrator",
        "a_test_flag": true,
        "manage_plans_outside_cities": ["9f884cb2f8"],
        "corporate_create_flag": "1",
        "corporate_flag": "ALL",
        "sales_display_flag": "1",
        "daily_regist_plan_flag": "0",
        "daily_regist_existing_flag": "0",
        "daily_regist_owner_flag": "0",
        "administrator_flag": "1",
        "zone_strategy_flag": "1",
        "manage_cities": ["13101", "13103", "13105", "ALL"],
        "expansion_polygon_flag": "1",
        "user_name": "その他ユーザー",
        "email": "",
        "staff_number": "",
    };
    //ユーザー権限変更時発火イベント
    const changeUserSelect = (e: SelectChangeEvent) => {
        const val = e.target.value;

        if (user.setUserData) {
            if (val === "1") {
                user.setUserData(JSON.stringify(rfcData));
            } else if (val === "2") {
                user.setUserData(JSON.stringify(rrData));
            } else if (val === "3") {
                user.setUserData(JSON.stringify(rmData));
            } else if (val === "4") {
                user.setUserData(JSON.stringify(gmData));
            } else if (val === "5") {
                user.setUserData(JSON.stringify(etcData));
            }
        }
    };
    //ユーザー情報確認
    const userDataCheck = () => {
        console.log(userdata);
    };

    //文字列分割関数
    const breakedText = (text: string) => {
        return text.split("\n").map((line, key) => <span key={key}>{line}<br /></span>);
    };

    return (
        <AppBar component="header" position="sticky" sx={headerStyle}>
            <Grid
                columns={24}
                container
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: 'row' }}
            >
                <Grid container alignItems="center">
                    {/* 【立地調査リリース後対応】5m外、商圏カット承認用ページ */}
                    {/* {
                        location.pathname === "/approval-5mcut" &&
                        <Grid item xs={0.5} sx={{ textAlign: "center" }}></Grid>
                    } */}
                    {
                        location.pathname !== "/approval-5mcut" &&
                        <Grid item xs={0.5} sx={{ textAlign: "center" }}>
                            <IconButton sx={{ visibility: iconVisible }} onClick={toggleDrawer}>
                                <MenuIcon sx={{ padding: "3px" }} />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item xs={3.5}>
                        <Item sx={{ float: "left" }}>{appTitle}</Item>
                    </Grid>
                    <Grid item xs={5}>
                        {planInfoViewFlg &&
                            <Item sx={{ float: "left", fontSize: "15px" }}>{loadingFlg ? <Skeleton width={400} /> : breakedText(planInfo)}</Item>
                        }
                    </Grid>
                    {userChangeFlag ?
                        <Grid xs={2}>
                            <Button onClick={userDataCheck} sx={{ fontSize: "12px" }}>ユーザー確認</Button>
                            <Select
                                label="User"
                                onChange={changeUserSelect}
                                sx={{ height: "60%" }}
                            >
                                <MenuItem value={"1"}>RFC</MenuItem>
                                <MenuItem value={"2"}>リサーチ</MenuItem>
                                <MenuItem value={"3"}>RM</MenuItem>
                                <MenuItem value={"4"}>GM</MenuItem>
                                <MenuItem value={"5"}>その他</MenuItem>
                            </Select>
                        </Grid>
                        :
                        <Grid xs={2} sx={{ visibility: "hidden" }}>
                        </Grid>
                    }
                    <Grid item xs={0.5} sx={{ textAlign: "center" }}>
                        <Button onClick={goTop}>
                            <HomeIcon sx={{ padding: "3px" }} />
                        </Button>
                    </Grid>
                    <Grid item xs={0.5} sx={{ textAlign: "center" }}>
                        <Button>
                            <ListAltIcon sx={{ padding: "3px" }} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    )
}

export default AppHeader;

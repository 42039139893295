import dayjs from 'dayjs';
export const coverData = (res:any) => {
    const DataObj = {
        createDate:res[0].create_date,                                          //作成日 graphsqlの呼び出しのときのみ
        receptionNo:res[0].reception_no,                                        //受付NO
        storeNo:res[0].store_no,                                                //店NO
        surveyName:res[0].survey_name,                                          //名称
        address:res[0].address,                                                 //所在地
        // addressDetail:res[0].address_detail,                                 //所在地詳細 検討中
        contractDueDate:dayjs(res[0].contract_due_date).format('YYYY-MM-DD') === 'Invalid Date' ? '' : dayjs(res[0].contract_due_date).format('YYYY-MM-DD'),   //契約予定日
        openDueDate:dayjs(res[0].open_due_date).format('YYYY-MM-DD') === 'Invalid Date' ? '' : dayjs(res[0].open_due_date).format('YYYY-MM-DD'),               //開店予定日 
        prefecturesCode:res[0].prefectures_code,                                //市区町村コード
        newAreaNo:res[0].new_area_no,                                           //新エリアNO
        branchNo:res[0].branch_no,                                              //枝NO
        existingStoreDesign:res[0].existing_store_design,                       //既存店図面の面積・進入口が現状と差異ないことを確認後、チェック記入
        sAndBCase:res[0].s_and_b_case,                                          //S&Bの場合 車・人共通比根拠シート
        conflictRuinsCase:res[0].conflict_ruins_case,                           //競合跡地の場合 競合日販推定表
        targetContractNotOpened:res[0].target_contract_not_opened,              //調査対象範囲内に契約済未開店のセブン-イレブン
        surveyCreator:res[0].survey_creator,                                    //調査書作成者
        confirmer:res[0].confirmer                                              //確認者
    };
    return DataObj;
};

//表紙ページAPI登録
export const upsertCoverParam = (objCover: any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Cover/v1/regist",
        query: {
            plan_area_id:plan_area_id,
            branch:branch,
            reception_no:objCover.receptionNo,
            store_no:objCover.storeNo,
            survey_name:objCover.surveyName,
            address:objCover.address,
            contract_due_date:objCover.contractDueDate  ==='Invalid Date' ? '' : objCover.contractDueDate,
            open_due_date:objCover.openDueDate === 'Invalid Date' ? '' : objCover.openDueDate,
            prefectures_code:objCover.prefecturesCode,
            new_area_no:objCover.newAreaNo,
            branch_no:objCover.branchNo,
            existing_store_design:objCover.existingStoreDesign,
            s_and_b_case:objCover.sAndBCase,
            conflict_ruins_case:objCover.conflictRuinsCase,
            target_contract_not_opened:objCover.targetContractNotOpened,
            survey_creator:objCover.surveyCreator,
            confirmer:objCover.confirmer
        }
    }
    return params
}
import { useNavigate } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  styled
} from '@mui/material';

const RRconfirmModalBackdrop = styled(Backdrop, {
  name: 'MuiModal',
  slot: 'Backdrop',
  overridesResolver: (props, styles) => {
  return styles.backdrop;
  },
  })({
  zIndex: -1,
  backgroundColor: 'rgba(0, 0, 0, 0.9)'
  })
  
export interface RRconfirmModalProps {
  props: {
    RRconfModalOpen: boolean,
    setRRconfModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
};

const RRconfirmModal: React.FC<RRconfirmModalProps> = ({ props }) => {
  const navigate = useNavigate();

  const handleOpenPortal = () => {
    const element = document.getElementById('naviDrawer');
    //ポータル画面に戻るため、サイドメニューを非表示
    if (element) element.style.display='none';
    //ポータル画面に戻る
    navigate("/");
    //リサーチ確認中のモーダルを閉じる
    props.setRRconfModalOpen(false);
  };

  return (
    <>
      <Dialog
        open={props.RRconfModalOpen}
        slots={{backdrop:RRconfirmModalBackdrop}}
      >
        <DialogContent>
          <DialogContentText>リサーチ確認中の為、閲覧不可です。</DialogContentText>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 10px'}}>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleOpenPortal}
            >立地調査ポータルに戻る</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default RRconfirmModal;
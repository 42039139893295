import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LOCAL_CONSTANT } from "../Const";
import { getLocationBase, getTaskManage, getLongPageData } from '../../data/DataManage';
import { apiRequest } from "../../api/ApiCall";
import { useUserContext } from '../../user-provider';
import RequestCommentMailModal from '../components/RequestCommentMailModal';
import { useAppSelector } from '../../app/hooks';

import {
  Box,
  Button,
  Autocomplete,
  Grid,
  TextField,
  styled,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  CircularProgress,
} from '@mui/material';

export interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
};
//テーブル表示用タイプリスト
export interface dataTypeList {
  id: number,
  orderType: string,
  status: string,
  charge: string,
  task: string,
  statusId: string,
  taskId: string,
  unique_process_type: string
};
//コードマスタ取得用プルダウンタイプリスト
export interface pullDownTypeList {
  uuid: string,
  create_date: string,
  update_date: string,
  delete_flag: string,
  common_item_flag: string,
  item_name_display: string,
  item_name_value: string,
  code_display: string,
  code_value: string,
  sort_column: string
};
//メール作成用タイプリスト
export interface mailModalObjTypeList {
  statusId: string,
  taskId: string,
  charge: string,
  picNameValue: string,
  assemblySelectValue: string,
  patternValue: string
  patternDetailsValue: string,
  commentValue: string,
  commentTypeValue: string,
  receptionNo: string,
  subjectTitle: string,
  planAreaName: string,
  /* picRfc: string,
  districtCode: string */
}

//担当者情報プルダウンリスト
let picNameList: any[] = [];

//スタイル
const GridContainer = styled(Grid)({
  marginBottom: "30px"
});
const RequestButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "#ffffff",
  float: "right",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const TableHeadCell = styled(TableCell)({
  backgroundColor: "#00CC5B",
  color: "#ffffff",
  textAlign: "center",
  border: "1px solid rgba(224, 224, 224, 1)"
});
const DataTableCell = styled(TableCell)({
  border: "1px solid rgba(224, 224, 224, 1)"
});
const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
});
const styles = {
  select:{
    ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary":{
      width: "100%"
    }
  }
}

const RequestComment: React.FC<resultDialogProps> = ({ props }) => {

  const user = useUserContext();
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  const objStorePlanAreaId = useAppSelector((state) => state.longPage.storePlanAreaId);
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //[組立]項目値
  const [assemblySelectValue, setAssemblySelectValue] = useState<string | null>("");
  //[パターン]項目値
  const [patternValue, setPatternValue] = useState<string | null>("");
  //[パターン詳細]項目値
  const [patternDetailsValue, setPatternDetailsValue] = useState<string | null>("");
  //[担当者]項目値（担当者ID）
  const [picNumberValue, setPicNumberValue] = useState<string>("");
  //[担当者]項目値（担当者名）
  const [picNameValue, setPicNameValue] = useState<string>("");
  //[コメント種別]項目値
  const [commentTypeValue, setCommentTypeValue] = useState("0");
  //[コメント]項目値
  const [commentValue, setCommentValue] = useState("");
  //「組立」項目プルダウンリスト
  const [assemblyList, setAssemblyList] = useState<pullDownTypeList[]>();
  //「パターン」項目プルダウンリスト
  const [patternList, setPatternList] = useState<pullDownTypeList[]>();
  //「パターン詳細」項目プルダウンリスト
  const [patternDetailsList, setPatternDetailsList ] = useState<pullDownTypeList[]>();
  //必須項目フラグ
  const [requiredFlag, setRequiredFlag] = useState(false);
  //テーブルデータ内ラジオボタンの選択値
  const [selectedValue, setSelectedValue] = useState(0);
  //テーブルデータ表示用オブジェクト
  const [rows, setRows] = useState<dataTypeList[]>([]);
  //モーダル開閉フラグ(依頼コメント画面)
  const [modalOpen, setModalOpen] = useState(false);
  //モーダルページ切替用ステート(依頼コメント画面)
  const [pageing, setPageing] = useState<number>(0);
  //モーダル開閉フラグ(メール作成画面)
  const [mailModalOpen, setMailModalOpen] = useState(false);
  //受付No(メール作成用)
  const [receptionNo, setReceptionNo] = useState<string>("");
  //件名(メール作成用)
  const [subjectTitle, setSubjectTitle] = useState<string>("");
  //候補地名(メール作成用)
  const [planAreaName, setPlanAreaName] = useState<string>("");
  //RFC名(メール作成用)
  const [picRfcId, setPicRfcId] = useState<string>("");
  //地区ID(メール作成用)
  const [districtCode, setDistrictCode] = useState<string>("");
  //メール作成モーダル受渡用オブジェクト
  const [mailModalObj, setMailModalObj] = useState<mailModalObjTypeList[]>();
  //エラーコメント
  const [errorComment, setErrorComment] = useState<string>("");
  //タスクID
  const [taskId, setTaskId] = useState("");
  //タスク所属タイプ
  const [departmentJobCheckType, setDepartmentJobCheckType] = useState<string>("");
  //依頼ボタン活性化フラグ
  const [requestButtonFlag, setRequestButtonFlag] = useState<boolean>(false);
  //候補地情報
  const [planAreaInfoData, setPlanAreaInfoData] = useState<any>();
  //担当コードマスタ
  const [picTypeCodeMaster, setPicTypeCodeMaster] = useState<any>();

  //テーブル表示のラジオボタン変更時のイベント
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rowId = Number(e.target.value);
    const selectRowData = rows.find(dataTypeList => dataTypeList.id === rowId);
    //特殊処理2のタスクを選択した場合　担当者項目必須
    if(selectRowData?.unique_process_type === "2"){
      setRequiredFlag(true);
    } else {
      setRequiredFlag(false);
    }
    setSelectedValue(rowId);
  };

  //モーダルクローズ
  const handleModalClose = () => {
    if (pageing === 1) { return }
    setModalOpen(false);
    setTimeout(() => {
      setPageing(0);
    }, 500);
  };

  //担当者情報プルダウンリスト項目値セット
  const setPicInfo = (val: any) => {
    if(!val){
      setPicNumberValue("");
      setPicNameValue("");
    } else {
      setPicNumberValue(val.value);
      setPicNameValue(val.label);
    }
  }

  //プルダウンリスト項目値セット
  const setPulldownList = () => {
    const masterObj = localStorage.getItem("codeMaster");
    if (masterObj !== null) {
      const jsonMasterObj = JSON.parse(masterObj);
      setAssemblyList(jsonMasterObj.assembly);
      setPatternList(jsonMasterObj.pattern);
      setPatternDetailsList(jsonMasterObj.pattern_details);
      setPicTypeCodeMaster(jsonMasterObj.pic_type);
    }
    const staffMasterObj = localStorage.getItem("staffMaster");
    if (staffMasterObj !== null) {
      const jsonStaffMasterObj = JSON.parse(staffMasterObj);
      jsonStaffMasterObj.map((e: any) => {
        if (!picNameList.some(t => t.value === e.staff_number)) {
          picNameList.push({
            label: e.staff_name,
            value: e.staff_number
          })
        }
      })
    }
  };

  //API呼び出し
  const callApi = async (target: string) => {
    if (target === "getLocationBase") {
      return await getLocationBase({
        mode: "getLocationBase",
        plan_area_id: planAreaId,
        branch: branch,
      })
    }
    if (target === 'getTaskManage') {
      return await getTaskManage({
        mode: "getTaskManage",
        task_id: taskId
      })
    }
  };

  //依頼ボタン押下時API呼び出し
  const callApiRequestButton = async (target: string) => {
    const param: any = [];
    if (target === 'getRequestButton') {
      param.push({
        mode: 'getReceptionBookByIdAndBranch',
        plan_area_id: planAreaId,
        branch: branch
      })
      param.push({
        mode: 'getChangeStatus',
        plan_area_id: planAreaId,
        branch: branch,
      })
      return await getLongPageData(param);
    }
  };

  //候補地情報取得
  const getPlanAreaInfo = () => {
    let searchPlanAreaId = planAreaId;
    if(planAreaId !== null){
      if (planAreaId.startsWith("X") || planAreaId.startsWith("Y")) {
        searchPlanAreaId = objStorePlanAreaId
      }
    }
    const params = {
      mode:"regist",
      endPoint: "/sfdc/planArea/v1/info",
      query: {
          plan_area_id: searchPlanAreaId
      }
    };
    (async () => {
      try {
        const apiPlanAreaInfo = await apiRequest(params);
        if (apiPlanAreaInfo.data.dataList.length > 0) {
          const planAreaInfoJSON = JSON.parse(apiPlanAreaInfo.data.dataList[0]);
          setPlanAreaInfoData(planAreaInfoJSON);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  //特殊処理6 メール自動送信
  const mailAutoSend = () => {
    setPageing(1);
    //登録データセット
    const selectData = settingData(selectedValue);
    //テーブルセレクトが無い場合は処理中断
    if(!selectData){
      setErrorComment("送信に失敗しました");
      setPageing(3);
      return 
    }
    const userData = JSON.parse(user.userData || 'null');
    const params = {
      mode: "regist",
      endPoint: "/location/Task/v1/requestComment-send-Mail",
      query: {
        mail: {
          detail:{
            tiele: "立地調査依頼キャンセル完了",
            body: "立地調査依頼キャンセルが完了しました。",
            staffNoList:{
              user: userData?.substitute_staff_number,
              RFC: picRfcId,
              RM: "",
              GM: "",
              Etc: ""
            }
          },
          mailType: 4
        },
        requestComment: {
          plan_area_id: planAreaId,
          branch: branch,
          status_id: selectData.statusId,
          task_id: selectData.taskId,
          pic_type: selectData.charge,
          pic_name: picNameValue,
          pic_staff_number: picNumberValue,
          assembly: assemblySelectValue,
          pattern: patternValue,
          pattern_details: patternDetailsValue,
          comment: commentValue,
          comment_type: commentTypeValue
        }
      }
    };
    (async () => {
      try {
        await apiRequest(params);
        setPageing(2);
      } catch (e) {
        console.log(e);
        setErrorComment("送信に失敗しました");
        setPageing(3);
      }
    })();
  }

  //初期データ取得
  useEffect(() => {
    //プルダウンリストセット
    setPulldownList();
    //候補地情報取得
    getPlanAreaInfo();
    try {
      callApiRequestButton('getRequestButton').then((res: any) => {
        //受付簿情報取得
        setReceptionNo(res.getReceptionBookByIdAndBranch[0].reception_no);
        setSubjectTitle(`【立地調査】${res.getReceptionBookByIdAndBranch[0].plan_area_name} 修正依頼`);
        setPlanAreaName(res.getReceptionBookByIdAndBranch[0].plan_area_name);
        setPicRfcId(res.getReceptionBookByIdAndBranch[0].plan_area_staff_number);
        setDistrictCode(res.getReceptionBookByIdAndBranch[0].plan_area_district_code);
        setTaskId(res.getReceptionBookByIdAndBranch[0].task_id);
        //テーブル表示用データ取得
        let rowsList: dataTypeList[] = [];
        let index = 0;
        res.getChangeStatus.after_task_data.map((data: any) => {
          if (!data.after_status_id || !data.after_task_id) { return }
          //表示するタスクが特殊処理1の場合表示しない
          if(data.after_unique_process_type !== "1") {
            const dataObj = { id: index, orderType: "引き渡し", status: data.after_status_name, charge: data.after_pic_type, task: data.after_task_name, statusId: data.after_status_id, taskId: data.after_task_id, unique_process_type: data.after_unique_process_type };
            rowsList.push(dataObj);
            index++;
          //現状タスクがD003かつ変更後タスクがD001の場合は表示する
          } else if (res.getReceptionBookByIdAndBranch[0].task_id === "D003" && data.after_task_id === "D001") {
            const dataD001obj = { id: index, orderType: "引き渡し", status: data.after_status_name, charge: data.after_pic_type, task: data.after_task_name, statusId: data.after_status_id, taskId: data.after_task_id, unique_process_type: data.after_unique_process_type };
            rowsList.push(dataD001obj);
            index++;
          }
        })
        res.getChangeStatus.before_task_data.map((data: any) => {
          if (!data.before_status_id || !data.before_task_id) { return }
          const dataObj = { id: index, orderType: "戻し", status: data.before_status_name, charge: data.before_pic_type, task: data.before_task_name, statusId: data.before_status_id, taskId: data.before_task_id, unique_process_type: data.before_unique_process_type };
          rowsList.push(dataObj);
          index++;
        })
        setRows(rowsList);
      })
    } catch (e) {
      console.log(e);
    }
  }, []);

  //登録データセット
  const settingData = (selectNum: number) => {
    const selectRowData = rows.find(dataTypeList => dataTypeList.id === selectNum);
    let paramsList:any = [];
    paramsList.push({
      statusId: selectRowData?.statusId,
      taskId: selectRowData?.taskId,
      charge: selectRowData?.charge,
      picNameValue: picNameValue,
      assemblySelectValue: assemblySelectValue,
      patternValue: patternValue,
      patternDetailsValue: patternDetailsValue,
      commentValue: commentValue,
      commentTypeValue: commentTypeValue,
      receptionNo: receptionNo,
      subjectTitle: subjectTitle,
      planAreaName: planAreaName,
    });
    setMailModalObj(paramsList);
    return selectRowData;
  };

  //担当IDから担当へ変換
  const setPicTypeCodedDisplay = (pic_type: string) => {
    let pic_type_code_display = pic_type;
    picTypeCodeMaster.map((row: any) => {
      if (row.code_value === pic_type) {
        pic_type_code_display = row.code_display;
      }
    })
    return pic_type_code_display;
  };

  //登録処理
  const onRegist = () => {
    //予測集計表等パス
    let sales_forecast_path = '';
    //仮予測集計表等パス
    let temp_sales_forecast_path = '';
    try {
      callApi('getLocationBase').then((res: any) => {
        sales_forecast_path = res[0].sales_forecast_path;
        temp_sales_forecast_path = res[0].temp_sales_forecast_path;
      }).then(() => {
        //登録データセット
        const selectData = settingData(selectedValue);
        //テーブルセレクトが無い場合は処理中断
        if (!selectData) {
          setErrorComment("送信に失敗しました");
          setPageing(3); 
          return
        //選択したタスクが特殊処理2の場合「担当者」項目必須
        } else if (selectData.unique_process_type === "2" && !picNumberValue) {
          setErrorComment("担当者は必須項目です。担当者を選択して下さい。");
          setPageing(3); 
          return
        //選択したタスクが特殊処理3の場合メール作成モーダルオープン
        } else if (selectData.unique_process_type === "3") {
          handleModalClose();
          setMailModalOpen(true);
          return
        //選択したタスクが特殊処理4の場合 予測集計表がアップロードされているか判定
        } else if (selectData.unique_process_type === "4" && !sales_forecast_path) {
          setErrorComment("予測集計表がアップロードされていません");
          setPageing(3);
          return
        //選択したタスク特殊処理5の場合 仮予測集計表がアップロードされているか判定
        } else if (selectData.unique_process_type === "5" && !temp_sales_forecast_path) {
          setErrorComment("仮予測集計表がアップロードされていません");
          setPageing(3);
          return
        //選択したタスクが特殊処理6の場合メール自動送信
        } else if (selectData.unique_process_type === "6") {
          setPageing(4);
          return
        //依頼コメント登録
        } else {
          //ローディング
          setPageing(1);
          const params = {
            mode: "regist",
            endPoint: "/location/Reception/v1/requestComment",
            query: {
              plan_area_id: planAreaId,
              branch: branch,
              status_id: selectData.statusId,
              task_id: selectData.taskId,
              pic_type: selectData.charge,
              pic_name: picNameValue,
              pic_staff_number: picNumberValue,
              assembly: assemblySelectValue,
              pattern: patternValue,
              pattern_details: patternDetailsValue,
              comment: commentValue,
              comment_type: commentTypeValue
            }
          };
          (async () => {
            try {
              await apiRequest(params);
              setPageing(2);
            } catch (e) {
              console.log(e);
              setErrorComment("送信に失敗しました");
              setPageing(3);
            }
          })();
        }
      }).catch((e) => {
        console.log(e);
        return
      })
    } catch (e) {
      console.log(e);
      return
    }
  };

  //タスク情報取得
  useEffect(()=>{
    try {
      callApi('getTaskManage').then((res: any) => {
        if(res[0].department_job_check_type !== null){
          setDepartmentJobCheckType(res[0].department_job_check_type);
        }
      }).catch((e) => {
        console.log(e);
      })
    } catch (e) {
      console.log(e);
    }
  },[taskId]);

  //依頼ボタン活性、非活性フラグ更新
  useEffect(()=>{
    const userData = JSON.parse(user.userData || 'null');
    if(departmentJobCheckType === "1"){
      if(userData.department_code_OA !== "992002" || userData.job_category_code_OA !== "230"){
        setRequestButtonFlag(true);
      }
    }else if(departmentJobCheckType === "2"){
      if(userData.department_code_OA !== "992002" || (userData.job_category_code_OA  !== "101" && userData.job_category_code_OA  !== "102")){
        setRequestButtonFlag(true);
      }
    }else if(departmentJobCheckType === "3"){
      if(planAreaInfoData.district_code_oa !== userData.department_code_OA  || userData.job_category_code_OA !== "230"){
        setRequestButtonFlag(true);
      }
    }else if(departmentJobCheckType === "4"){
      if(planAreaInfoData.top_department_code_oa !==  userData.top_department_code_OA || (userData.job_category_code_OA !== "101" && userData.job_category_code_OA !== "102")){
        setRequestButtonFlag(true);
      }
    }else{
      setRequestButtonFlag(false);
    }
  },[departmentJobCheckType]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item sx={{ width: props.width, height: props.height, overflow: "scroll", padding: "10px" }}>
          
          {/* 入力フォーム部分（上段） */}
          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography>{LOCAL_CONSTANT.LABEL.ASSEMBLY}</Typography>
                </Grid>
                <Grid item xs={6} sx={styles.select}>
                  <Select
                    onChange={(e)=>{setAssemblySelectValue(e.target.value)}}
                    value={assemblySelectValue}
                  >
                    {assemblyList !== undefined &&
                    assemblyList.map((val, i) => (
                      <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography>{LOCAL_CONSTANT.LABEL.PATTERN}</Typography>
                </Grid>
                <Grid item xs={6} sx={styles.select}>
                  <Select
                    onChange={(e)=>{setPatternValue(e.target.value)}}
                    value={patternValue} 
                  >
                    {patternList !== undefined &&
                    patternList.map((val, i) => (
                      <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography>{LOCAL_CONSTANT.LABEL.PATTERN_DETAILS}</Typography>
                </Grid>
                <Grid item xs={6} sx={styles.select}>
                  <Select
                    onChange={(e)=>{setPatternDetailsValue(e.target.value)}}
                    value={patternDetailsValue}
                  >
                    {patternDetailsList !== undefined &&
                    patternDetailsList.map((val, i) => (
                      <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          {/* テーブル部分 */}
          <GridContainer container spacing={1} maxWidth="lg">
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell sx={{ width: "2rem" }}></TableHeadCell>
                    <TableHeadCell>{LOCAL_CONSTANT.LABEL.REQUEST_TYPE}</TableHeadCell>
                    <TableHeadCell>{LOCAL_CONSTANT.LABEL.STATUS}</TableHeadCell>
                    <TableHeadCell>{LOCAL_CONSTANT.LABEL.IN_CHARGE}</TableHeadCell>
                    <TableHeadCell>{LOCAL_CONSTANT.LABEL.TASK}</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((item: any, i: any) => (
                    <TableRow key={i}>
                      <DataTableCell><Radio name="datatable-radio" value={i} onChange={(e) => { handleRadioChange(e) }} checked={selectedValue === i} /></DataTableCell>
                      <DataTableCell>{item.orderType}</DataTableCell>
                      <DataTableCell>{item.status}</DataTableCell>
                      <DataTableCell>{setPicTypeCodedDisplay(item.charge)}</DataTableCell>
                      <DataTableCell>{item.task}</DataTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </GridContainer>

          {/* 入力フォーム部分（下段） */}
          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sx={{ display: "flex" }}>
                  <Typography>{LOCAL_CONSTANT.LABEL.MANAGER}</Typography>
                  {requiredFlag &&
                    <Typography sx={{ color: "red" }}>＊</Typography>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    value={picNameList.find(e => e.value === picNumberValue) ?? null}
                    options={picNameList}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, v) => option.value === v.value}
                    onChange={(e, val) => { setPicInfo(val) }}
                    renderInput={(params) => (<TextField {...params} variant="outlined"></TextField>)}
                  ></Autocomplete>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography>{LOCAL_CONSTANT.LABEL.COMMENT_TYPE}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    row
                    name="comment-type-group"
                    value={commentTypeValue}
                    onChange={(e) => { setCommentTypeValue(e.target.value) }}>
                    <FormControlLabel value="0" control={<Radio />} label={LOCAL_CONSTANT.LABEL.GREEN_PAPER} />
                    <FormControlLabel value="1" control={<Radio />} label={LOCAL_CONSTANT.LABEL.BLANK_PAPER} />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2} maxWidth="lg">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography>{LOCAL_CONSTANT.LABEL.COMMENT}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    multiline
                    rows={3}
                    value={commentValue}
                    onChange={(e) => { setCommentValue(e.target.value) }}
                    sx={{ width: "30rem" }}>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>

          {/* ボタン部分 */}
          <Grid container spacing={1} maxWidth="lg">
            <Grid item xs={12}>
              <RequestButton 
                disabled={requestButtonFlag}
                onClick={() => { setModalOpen(true) }}>
                {LOCAL_CONSTANT.LABEL.REQUEST}
              </RequestButton>
            </Grid>
          </Grid>

          {/* 登録モーダル */}
          <Dialog open={modalOpen} onClose={handleModalClose}>
            {pageing === 0 &&
              <Box>
                <DialogTitle>依頼を送信しますか？</DialogTitle>
                <Box sx={{ margin: "30px" }}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                      <CancelButton onClick={handleModalClose}>キャンセル</CancelButton>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorButton onClick={onRegist}>送信</ColorButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            }

            {/* ローディング */}
            {pageing === 1 &&
              <Box>
                <DialogTitle>依頼を送信中</DialogTitle>
                <Box sx={{ margin: "30px" }}>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress color="inherit" />
                  </Grid>
                </Box>
              </Box>
            }

            {/* 完了 */}
            {pageing === 2 &&
              <Box>
                <DialogTitle>依頼を送信しました</DialogTitle>
                <Box sx={{ margin: "30px" }}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorButton onClick={handleModalClose}>閉じる</ColorButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            }

            {/* エラーメッセージ */}
            {pageing === 3 &&
              <Box>
                <DialogTitle>{errorComment}</DialogTitle>
                <Box sx={{ margin: "30px" }}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                      <CancelButton onClick={handleModalClose}>閉じる</CancelButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            }

            {/* 特殊処理6 */}
            {pageing === 4 &&
              <Box>
                <DialogTitle>候補店アプリに送信されているデータを必ず削除してください</DialogTitle>
                <Box sx={{ margin: "30px" }}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                      <CancelButton onClick={handleModalClose}>キャンセル</CancelButton>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorButton onClick={mailAutoSend}>送信</ColorButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            }
          </Dialog>

          {/* モーダル部分(メール作成画面) */}
          <RequestCommentMailModal props={{ modalOpen: mailModalOpen, setModalOpen: setMailModalOpen, user: user.userData, planAreaId: planAreaId, branch: branch, obj: mailModalObj, planAreaRfc: picRfcId, planAreaDistrictCode: districtCode}}/>

        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestComment;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LocationSurveyDigital from './LocationSurveyDigital';
import { UtilityProvider } from './utility-provider';
import { UserProvider } from './user-provider';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import Login from './Login';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={ store }>
    <UserProvider>
    <Login url={window.location.href}>
      <UtilityProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <LocationSurveyDigital />
        </LocalizationProvider>
      </UtilityProvider>
    </Login>
    </UserProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

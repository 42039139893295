import { createSlice } from '@reduxjs/toolkit';
import { initPortalObj } from '../data/type';

//Sliceの作成
export const portalSlice = createSlice({
    name: 'portal',
    //初期State(Typeからインポート)
    initialState: initPortalObj,

    //toolKitを利用した値変更処理
    reducers: {
        setObjPortal: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setObjPortal
} = portalSlice.actions;
export default portalSlice.reducer;
import * as React from 'react';

import { useUserContext } from '../../user-provider';
import { Link, useSearchParams } from "react-router-dom";
import { useUtilityContext } from '../../utility-provider';
import { LOCAL_CONSTANT } from '../Const';

import { getAccessToken } from '../Common';
import { getLongPageData } from '../../data/DataManage';
import {
    mpData,
    calculateColumnTotal,
} from '../subPage/TradeArea/TradeAreaFunction';
import Approval5mcutModal from '../subPage/Approval5mcut/Approval5mcutModal';
import {
    Box,
    Typography,
    Checkbox,
    Button,
} from '@mui/material';



const DEFAULT_IMAGE_01 = '/image/TrafficVolume/image001.png'
const DEFAULT_IMAGE_02 = '/image/TrafficVolume/image002.png'
const listHeaderStyle = {
    width: '150px',
    height: '50px',
    padding: '12px 0',
    border: '1px solid black',
    backgroundColor: '#D9D9D9',
}

interface resultProps {
    props: {
    }
}
const Approval5mcut: React.FC<resultProps> = ({ props }) => {
    const user = useUserContext();

    const utilityCtx = useUtilityContext();
    const [windowSize, setWindowSize] = React.useState({ "width": window.innerWidth, "height": window.innerHeight });

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const planAreaId = searchParams.get("planAreaId");
    const branch = searchParams.get("branch");
    const [locationTraffic5mcut, setLocationTraffic5mcut] = React.useState<any>([])
    const [locationConflictMarketCut, setLocationConflictMarketCut] = React.useState<any>([])
    const [image001, setImage001] = React.useState<string>(DEFAULT_IMAGE_01)
    const [image002, setImage002] = React.useState<string>(DEFAULT_IMAGE_02)
    const [marketCutImage001, setMarketCutImage001] = React.useState<string>("./image/conflict/market_cut_01.png")
    const [marketCutImage002, setMarketCutImage002] = React.useState<string>("./image/conflict/market_cut_02.png")
    const [approval5mcutModalOpen, setApproval5mcutModalOpen] = React.useState<boolean>(false);
    const [userData, setUserData] = React.useState<any>({});
    const [approvalConfirmStamp, setApprovalConfirmStamp] = React.useState<string>("")
    const [approvalResearchMgrStamp, setApprovalResearchMgrStamp] = React.useState<string>("")
    const [approvalResearchGmStamp, setApprovalResearchGmStamp] = React.useState<string>("")
    const [approvalMgrStamp, setApprovalMgrStamp] = React.useState<string>("")
    const [approvalGmStamp, setApprovalGmStamp] = React.useState<string>("")
    const [marketCutApprovalConfirmStamp, setMarketCutApprovalConfirmStamp] = React.useState<string>("")
    const [marketCutApprovalResearchMgrStamp, setMarketCutApprovalResearchMgrStamp] = React.useState<string>("")
    const [marketCutApprovalResearchGmStamp, setMarketCutApprovalResearchGmStamp] = React.useState<string>("")
    const [marketCutApprovalMgrStamp, setMarketCutApprovalMgrStamp] = React.useState<string>("")
    const [marketCutApprovalGmStamp, setMarketCutApprovalGmStamp] = React.useState<string>("")

    const [checkList, setCheckList] = React.useState([
        { 'name': 0, 'key': 'check_planting', 'checked': false, label: '店舗入口ドア予定位置前に植栽等障害物がない' },
        { 'name': 1, 'key': 'check_visibility', 'checked': false, label: '5ｍ外算入範囲から新店頭看板・入口ドアが視認可能​' },
        { 'name': 2, 'key': 'check_stairs', 'checked': false, label: '店舗入口ドア予定位置まで階段等段差がない' },
        { 'name': 3, 'key': 'check_count', 'checked': false, label: '道路の反対側をカウントする場合、車交通量が少なく店舗への往来に支障がない事' },
    ])

    const setChecked = (target: number) => {
        return checkList[Number(target)]['checked']
    }

    const [marketCut, setMarketCut] = React.useState({
        'households': 0,
        'employees': 0,
        'delete_households': 0,
        'delete_employees': 0,
        'substance_households': 0,
        'substance_employees': 0,
    })

    React.useEffect(() => {
        loadApi()
        function handleResize() {
            // ヘッダーの分を引く
            const tempHeight = window.innerHeight - 48;
            // ハンバーガーメニューの分を引く
            const tempWidth = window.innerWidth - 56;
            setWindowSize({ "width": tempWidth, "height": tempHeight });
        };

        // 初回ロード時のサイズに基づいてスケーリング
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserData(JSON.parse(user.userData))
        }
    }, [user]);

    const loadApi = async () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        callApi('getLocationBase', [])
            .then((res: any) => {
                if (res.getLocationBase.length > 0) {
                    callApi('getData', res)
                        .then((dataRes: any) => {
                            console.log(dataRes)
                            // 画像
                            const bucket_path = process.env.REACT_APP_GOOGLE_STORAGE
                                + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
                                + '/' + planAreaId
                                + '/' + branch
                            const access_token = getAccessToken()
                            setLocationTraffic5mcut(dataRes.getLocationTraffic5mcut[0])
                            setLocationConflictMarketCut(dataRes.getLocationConflictMarketCut[0])
                            if (dataRes.getLocationTraffic5mcut[0].layout_img_path !== '') {
                                setImage001(bucket_path + dataRes.getLocationTraffic5mcut[0].layout_img_path + access_token)
                            } else {
                                setImage001(DEFAULT_IMAGE_01)
                            }
                            if (dataRes.getLocationTraffic5mcut[0].photo_img_path !== '') {
                                setImage002(bucket_path + dataRes.getLocationTraffic5mcut[0].photo_img_path + access_token)
                            } else {
                                setImage002(DEFAULT_IMAGE_02)
                            }
                            // 5m外印影画像
                            if (dataRes.getLocationTraffic5mcut[0].approval_research_mgr_stamp !== '') {
                                setApprovalResearchMgrStamp(bucket_path + dataRes.getLocationTraffic5mcut[0].approval_research_mgr_stamp + access_token)
                            }
                            if (dataRes.getLocationTraffic5mcut[0].approval_research_gm_stamp !== '') {
                                setApprovalResearchGmStamp(bucket_path + dataRes.getLocationTraffic5mcut[0].approval_research_gm_stamp + access_token)
                            }
                            if (dataRes.getLocationTraffic5mcut[0].approval_mgr_stamp !== '') {
                                setApprovalMgrStamp(bucket_path + dataRes.getLocationTraffic5mcut[0].approval_mgr_stamp + access_token)
                            }
                            if (dataRes.getLocationTraffic5mcut[0].approval_gm_stamp !== '') {
                                setApprovalGmStamp(bucket_path + dataRes.getLocationTraffic5mcut[0].approval_gm_stamp + access_token)
                            }
                            // checkList
                            if (dataRes.getLocationTraffic5mcut[0].check_planting === "1") {
                                checkList[0]['checked'] = true
                            }
                            if (dataRes.getLocationTraffic5mcut[0].check_visibility === "1") {
                                checkList[1]['checked'] = true
                            }
                            if (dataRes.getLocationTraffic5mcut[0].check_stairs === "1") {
                                checkList[2]['checked'] = true
                            }
                            if (dataRes.getLocationTraffic5mcut[0].check_count === "1") {
                                checkList[3]['checked'] = true
                            }
                            var listMpData: any = {}
                            if (res.getLocationBase.location_excel_sequence !== '0') {
                                // location_excel_04_mp_dataとlocation_excel_05_mp_data2とlocation_trade_areaにデータが存在する場合
                                if (dataRes.getLocationTradeAreaMpData.length > 0) {
                                    listMpData = mpData(dataRes.getLocationTradeAreaMpData)
                                    // location_excel_04_mp_dataとlocation_excel_05_mp_data2にデータが存在する場合
                                } else if (dataRes.getGradientWalk5MinMpData.length > 0) {
                                    listMpData = mpData(dataRes.getGradientWalk5MinMpData)
                                }
                                const realitySurveyHouseholdCount = Math.round(calculateColumnTotal('realitySurveyHouseholdCount', listMpData))
                                const realitySurveyEmployedWorkers = Math.round(calculateColumnTotal('realitySurveyEmployedWorkers', listMpData))
                                // 徒歩5分商圏
                                marketCut.households = realitySurveyHouseholdCount
                                marketCut.employees = realitySurveyEmployedWorkers
                                // 削除商圏
                                marketCut.delete_households = dataRes.getLocationConflictMarketCut[0]['households']
                                marketCut.delete_employees = dataRes.getLocationConflictMarketCut[0]['employees']
                                // 実質商圏
                                if (marketCut.households - marketCut.delete_households > 0) {
                                    marketCut.substance_households = marketCut.households - marketCut.delete_households
                                }
                                if (marketCut.employees - marketCut.delete_employees > 0) {
                                    marketCut.substance_employees = marketCut.employees - marketCut.delete_employees
                                }
                            }
                            // 商圏カット根拠
                            if (dataRes.getLocationConflictMarketCut[0].market_cut_img_path !== '') {
                                setMarketCutImage001(bucket_path + dataRes.getLocationConflictMarketCut[0].market_cut_img_path + access_token)
                            }
                            if (dataRes.getLocationConflictMarketCut[0].market_cut_basis_img_path !== '') {
                                setMarketCutImage002(bucket_path + dataRes.getLocationConflictMarketCut[0].market_cut_basis_img_path + access_token)
                            }
                            // 印影画像
                            if (dataRes.getLocationConflictMarketCut[0].approval_research_mgr_stamp !== '') {
                                setMarketCutApprovalResearchMgrStamp(bucket_path + dataRes.getLocationConflictMarketCut[0].approval_research_mgr_stamp + access_token)
                            }
                            if (dataRes.getLocationConflictMarketCut[0].approval_research_gm_stamp !== '') {
                                setMarketCutApprovalResearchGmStamp(bucket_path + dataRes.getLocationConflictMarketCut[0].approval_research_gm_stamp + access_token)
                            }
                            if (dataRes.getLocationConflictMarketCut[0].approval_mgr_stamp !== '') {
                                setMarketCutApprovalMgrStamp(bucket_path + dataRes.getLocationConflictMarketCut[0].approval_mgr_stamp + access_token)
                            }
                            if (dataRes.getLocationConflictMarketCut[0].approval_gm_stamp !== '') {
                                setMarketCutApprovalGmStamp(bucket_path + dataRes.getLocationConflictMarketCut[0].approval_gm_stamp + access_token)
                            }
                        }).catch((e) => {
                            console.log(e);
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                        })
                }
            }).catch((e) => {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).finally(() => {
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
    }
    const callApi = async (target: string, params: any) => {
        const param: any = []

        if (target === 'getLocationBase') {
            param.push({
                mode: 'getLocationBase',
                plan_area_id: planAreaId,
                branch: branch,
            })
        } else {
            // 5m外
            param.push({
                mode: 'getLocationTraffic5mcut',
                plan_area_id: planAreaId,
                branch: branch,
            })
            // 商圏用
            param.push({
                mode: 'getLocationTradeAreaMpData',
                plan_area_id: planAreaId,
                branch: branch,
                excel_sequence: params.getLocationBase[0].location_excel_sequence,
            })
            param.push({
                mode: 'getGradientWalk5MinMpData',
                plan_area_id: planAreaId,
                excel_sequence: params.getLocationBase[0].location_excel_sequence,
            })
            // 商圏カット
            param.push({
                mode: 'getLocationConflictMarketCut',
                plan_area_id: planAreaId,
                branch: branch,
            })
        }

        return await getLongPageData(param);
    }

    const openApproval5mcutModal = () => {
        console.log('openApproval5mcutModal')
        setApproval5mcutModalOpen(true)
    }

    const closeApproval5mcutModal = () => {
        setApproval5mcutModalOpen(false)
    }

    const gotoLocation = () => {
        window.location.href = "/LongPage?planAreaId=" + planAreaId + "&branch=" + branch
    }


    return (
        <Box sx={{ width: windowSize.width + 56, height: windowSize.height + 56, overflowY: 'scroll' }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ padding: '70px 0 20px 40px' }}>
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{ padding: '10px 20px' }}
                            onClick={gotoLocation}
                        >調査書</Button>
                    </Box>
                    <Box sx={{ display: locationTraffic5mcut.excel_path !== "" ? 'block' : 'none' }}>
                        <Box><h3>5m外算入根拠</h3></Box>
                        <Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ Mgr</Box>
                                <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ GM</Box>
                                <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート Mgr</Box>
                                <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート GM</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalResearchMgrStamp}></img></Box>
                                <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalResearchGmStamp}></img></Box>
                                <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalMgrStamp}></img></Box>
                                <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={approvalGmStamp}></img></Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ margin: '10px' }}>
                                <Typography>配置図または航空写真<br />（5m内外の計測範囲を記載）</Typography>
                                <Box sx={{ border: '1px solid black' }}>
                                    <img src={image001} style={{ width: '560px', maxWidth: '560px' }} />
                                </Box>
                            </Box>
                            <Box sx={{ margin: '10px' }}>
                                <Typography>写真（障害物有無がわかる）<br />（5m内外の計測範囲を記載）</Typography>
                                <Box sx={{ border: '1px solid black' }}>
                                    <img src={image002} style={{ width: '560px', maxWidth: '560px' }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box><Typography>5m外歩行者TR算入確認</Typography></Box>
                        {checkList.map((row: any) => (
                            <Typography key={row.key}>
                                <Checkbox
                                    checked={setChecked(row['name'])}
                                    name={row['name']}
                                    disabled={true}
                                ></Checkbox>{row.label}</Typography>
                        ))}
                    </Box>

                    <Box sx={{ display: locationConflictMarketCut.excel_path !== "" ? 'block' : 'none' }}>
                        <Typography sx={{ padding: '20px 0' }}>実質商圏（商圏カット）</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>注意</Typography>
                            <Typography>間違いなく来店が見込めないという根拠に基づき、カットを判断して下さい。</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>定義</Typography>
                            <Box>
                                <Typography>・自店の来店メインターゲットは歩行者である</Typography>
                                <Typography>・徒歩５分商圏内で線路・河川・総４車線以上の大規模道路・駅・大型施設等のバリア要素により、バリアの反対側から徒歩での来店が見込めない範囲</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>ルール</Typography>
                            <Typography>削除となる基礎競合（ＣＶＳ・ＳＭ等全ての競合店）はバリア要素の反対側にある全てが対象（但し導線競合は対象外）</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ padding: '20px 0' }}>実質世帯数、従業員数</Typography>
                            <Box sx={{ width: '500px', textAlign: 'center' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100px', padding: '12px 0', border: '1px solid black', backgroundColor: '#D9D9D9', color: '#D9D9D9' }}>tab</Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: '#D9D9D9' }}>世帯数</Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: '#D9D9D9' }}>従業員数</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100px', padding: '12px 0', border: '1px solid black' }}>徒歩5分商圏</Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.households}
                                    </Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.employees}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100px', padding: '12px 0', border: '1px solid black' }}>削除商圏</Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.delete_households}
                                    </Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.delete_employees}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100px', padding: '12px 0', border: '1px solid black' }}>実質商圏</Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.substance_households}
                                    </Typography>
                                    <Typography sx={{ width: '200px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {marketCut.substance_employees}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{ padding: '20px 0' }}>{LOCAL_CONSTANT.LABEL.ESSENTIALLY_COMPETING_STORE}</Typography>
                            <Box sx={{ width: '1050px', textAlign: 'center' }}>
                                <Box sx={{ width: '100%', textAlign: 'center' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.CHAIN_NAME}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.STORE_NAME}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.LIQUOR + '・' + LOCAL_CONSTANT.LABEL.TOBACCO}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.BUSINESS_HOURS}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE + '（' + LOCAL_CONSTANT.LABEL.METER + '）'}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA + '（' + LOCAL_CONSTANT.LABEL.SQUARE_METER + '）'}</Typography>
                                        <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.PARKING_AREA + '（' + LOCAL_CONSTANT.LABEL.SQUARE_METER + '）'}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{ padding: '20px 0' }}>{LOCAL_CONSTANT.LABEL.BASIS_FOR_TRADE_AREA_CUT}</Typography>
                            <Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ Mgr</Box>
                                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リサーチ GM</Box>
                                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート Mgr</Box>
                                    <Box sx={{ width: '120px', border: '1px solid #000', textAlign: 'center'}}>リクルート GM</Box>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={marketCutApprovalResearchMgrStamp}></img></Box>
                                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={marketCutApprovalResearchGmStamp}></img></Box>
                                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={marketCutApprovalMgrStamp}></img></Box>
                                    <Box sx={{ width: '120px', height: '92px', border: '1px solid #000', textAlign: 'center'}}><img src={marketCutApprovalGmStamp}></img></Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '560px', minWidth: '560px', maxWidth: '560px', height: '360px', margin: "20px" }}>
                                    <img style={{ width: "100%" }} src={marketCutImage001}></img>
                                </Box>
                                <Box sx={{ width: '560px', minWidth: '560px', maxWidth: '560px', height: '360px', margin: "20px" }}>
                                    <img style={{ width: "100%" }} src={marketCutImage002}></img>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px',
                }}>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{ padding: '10px 20px' }}
                        onClick={openApproval5mcutModal}
                    >承認/差戻</Button>
                </Box>
                <Approval5mcutModal props={{
                    open: approval5mcutModalOpen,
                    handleModal: closeApproval5mcutModal,
                    planAreaId: planAreaId,
                    branch: branch,
                    locationTraffic5mcut: locationTraffic5mcut,
                    locationConflictMarketCut: locationConflictMarketCut,
                    userData: userData,
                }}
                />
            </Box>
        </Box>
    )
}



export default Approval5mcut;

import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import {
    InputLabel,
    Box,
    Modal,
    ImageList,
    ImageListItem,
    Pagination,
} from '@mui/material';

import { getListPaginateFroFirebase, getFileData, getFileMetadata } from '../../../utility/storage';
import { useUtilityContext } from '../../../utility-provider';
import { LOCAL_CONSTANT } from '../../Const';

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export interface PhotoSelectModalProps {
    props:{
      open:boolean,
      handleModal:React.Dispatch<React.SetStateAction<boolean>>,
      keyId:number,
      rowData:any,
      changePhoto:any,
    }
}

const PhotoSelectModal:React.FC<PhotoSelectModalProps> = ({props}) => {
    const [open, setOpen] = React.useState(false);
    const [endLoad, setEndLoad] = React.useState(false);
    const [photoDataList, setPhotoDataList] = React.useState([]);
    const utilityCtx = useUtilityContext();
    
    const [pageToken, setPageToken] = React.useState<{ page: number; token: string;}[]>([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const objLongPageParam = useAppSelector((state) => state.longPage.param);

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
    };

    React.useEffect(() =>{
        if(props.open){
            if (props.rowData.id === props.keyId) {
                setOpen(props.open);
                setEndLoad(false)
                getPhotoList(currentPage)
            } else {
                setOpen(false);
            }
        }
    },[props.open])

    const handleClick = (item: any) => {
        setOpen(false)
        props.handleModal(false)
        props.changePhoto(props.keyId, item)
    }

    // firebaseから任意の写真一覧を取得
    const getPhotoList = async (page: number) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        let token = ""
        if (page !== 1) {
            pageToken.map((row) => {
                if (row.page === page) {
                    token =  row.token
                }
            })
        }
        const path = '/' + objLongPageParam.planAreaId+'/'+'photo'
        await getListPaginateFroFirebase(path, page, token, pageToken)
        .then((data: any) => {
            (async ()=>{
                const result = await Promise.all(data?.item?.map( async(row: any) => {
                        await getFileData(row.fullPath)
                        .then((imageData: any) => {
                            row.imgPath = imageData
                        })
                        await getFileMetadata(row.fullPath)
                        .then((metaData: any) => {
                            row.metaData = metaData
                        })
                        setPhotoDataList(data.item)
                    })
                );
                const tmpToken:any = []
                tmpToken.push({page: 1, token: ''})
                if (data.nextPageToken && pageToken.length === 0) {
                    data.nextPageToken.map((row: any) => {
                        tmpToken.push(row)
                    })
                }
                if (page > 1) {
                    setPageToken(pageToken)
                } else {
                    if (pageToken.length > 0) {
                        setPageToken(pageToken)
                    } else {
                        setPageToken(tmpToken)
                    }
                }
            }
            )();
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
                setEndLoad(true)
            }
        })
    }

    const handleChange = (event: any, value: number) => {
        setCurrentPage(value)
        getPhotoList(value)
    };

    const getDate = (item: any) => {
        if (
            item.customMetadata !== undefined &&
            item.customMetadata.GPSDateTimeStamp !== '' &&
            item.customMetadata.GPSDateTimeStamp !== null
        ) {
            return item.customMetadata.GPSDateTimeStamp
        }
        return 'YYYY/MM/DD HH:MM:SS'
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{margin: '20px 0 0 20px', height: '5vh'}}>
                        <InputLabel>{props.keyId}：{props.rowData.title}</InputLabel>
                        <InputLabel>{LOCAL_CONSTANT.CONFIRM_MESSAGE.PLEASE_SELECT_A_PHOTO}</InputLabel>
                    </Box>
                    <Box sx={{margin: '0 20px 0 20px', height: '64vh'}}>
                        <ImageList sx={{ width: '100%', height: '100%' }} cols={3} rowHeight={340}>
                        {
                        endLoad &&
                        photoDataList.sort().map((item: any) => (
                            <ImageListItem key={item.generation} sx={{margin: '10px', border: '1px solid black'}}>
                                <Box sx={{position: 'relative', height: '320px'}}>
                                    <img
                                        src={item.imgPath}
                                        style={{
                                            width: '80%',
                                            maxHeight: '80%',
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                            bottom: '0',
                                            left: '0',
                                            margin: 'auto',
                                        }}
                                        loading="lazy"
                                        onClick={ () => handleClick(item)}
                                    />
                                </Box>
                                <InputLabel>{LOCAL_CONSTANT.LABEL.SHOOTING_DATE_AND_TIME + LOCAL_CONSTANT.LABEL.FULL_WIDTH_SPACE}+{getDate(item)}</InputLabel>
                            </ImageListItem>
                        ))}
                        </ImageList>
                    </Box>
                    <Box sx={{margin: '0 0 0 20px', display: 'flex', height: '5vh', position: 'absolute', bottom: 0}}>
                        <Pagination
                            count={pageToken.length}
                            color="primary"
                            page={currentPage}
                            onChange={handleChange} 
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default PhotoSelectModal;

import {
  Box,
  Button,
  DialogTitle,
  Grid,
  styled
} from '@mui/material';
import { LOCAL_CONSTANT } from '../Const';

//スタイル
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
});

export interface LocationExcelAndN15AddMarktCheckProps {
  props:{
    locationExcelFlag: string,
    n15AddMarketFlag: string,
    handleModalClose: () => void
  }
};

export const LocationExcelAndN15AddMarktCheck:React.FC<LocationExcelAndN15AddMarktCheckProps> = ({props}) => {

  return (
    <Box>
      {props.locationExcelFlag === "0" && props.n15AddMarketFlag === "" &&
        <DialogTitle>{LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_LOCATION_EXCEL_FLAG}</DialogTitle>
      }
      {props.n15AddMarketFlag === "0" && props.locationExcelFlag === "" &&
        <DialogTitle>{LOCAL_CONSTANT.ERROR_MESSAGE.ERROR_EMPTY_N15ADDMARKET}</DialogTitle>
      }
      {props.n15AddMarketFlag === "0" && props.locationExcelFlag === "0" &&
        <>
          <DialogTitle sx={{paddingBottom: "0px"}}>{LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_LOCATION_EXCEL_FLAG}</DialogTitle>
          <DialogTitle sx={{paddingTop: "0px"}}>{LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_EMPTY_N15ADDMARKET}</DialogTitle>
        </>
      }
      <Box sx={{ margin: "30px" }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CancelButton onClick={props.handleModalClose}>閉じる</CancelButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LocationExcelAndN15AddMarktCheck;
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  styled
} from '@mui/material';
import { useState, useEffect } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

export interface InputProps {
  props: {
    modalOpen: boolean,
		setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    CommentTextFieldObj: any,
    columnName: string,
    labelName: string,
    handleCommentChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => void,
    handleSaveComment: (columnName: string ,labelName: string) => void,
  }
}

//スタイル
//不備コメントモーダル画面を移動
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"             //ドラック可能
      cancel={'[class*="MuiDialogContent-root"]'}  //ドラックを無効
    >
      <Paper {...props} />
    </Draggable>
  );
}
//不備コメントモーダルの背景を透明
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
  }
}));
//登録ボタン
const RegistButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
//キャンセルボタン
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border: "1px solid #f00",
});


export const DeficiencyCommentRegModal: React.FC<InputProps> = ({ props }) => {

  //項目名用オブジェクト
  const [tableNameMaster, setTableNameMaster] = useState<any>([]);

  //項目名オブジェクトセット
  useEffect(() => {
    const tableNameMasterObj = localStorage.getItem("tableNameMaster");
    if (tableNameMasterObj) {
      setTableNameMaster(JSON.parse(tableNameMasterObj));
    }
  }, []);

  //項目名セット
  const setColumnName = (column_physics_name: string) => {
    let column_logic_name = column_physics_name
    tableNameMaster.map((row: any) => {
      if (row.column_physics_name === column_physics_name) {
        column_logic_name = row.column_logic_name
      }
    })

    return column_logic_name;
  };

  //モーダルクローズ
  const handleModalClose = () => {
    props.setModalOpen(false);
  };

  return (
    <Box>
      {/*不備コメント モーダル画面 */}
      <Draggable>
        <CustomDialog
          open={props.modalOpen}
          onClose={handleModalClose}
          PaperComponent={PaperComponent} //PaperComponentをモーダル全体をドラック可能
          aria-labelledby="draggable-dialog-title"
          fullWidth
        >
          <DialogTitle
            style={{ cursor: 'move' }} //スタイルを追加し、カーソルを変更しドラック可能
            id="draggable-dialog-title"
          >不備コメント {setColumnName(props.columnName)}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              variant="outlined"
              value={props.CommentTextFieldObj[props.labelName]}
              multiline
              onChange={(e) => props.handleCommentChange(e, props.labelName)}
            />
            <DialogActions sx={{ justifyContent: 'space-around' }}>
              <CancelButton onClick={handleModalClose}>キャンセル</CancelButton>
              <RegistButton onClick={() => props.handleSaveComment(props.columnName, props.labelName)}>登録</RegistButton>
            </DialogActions>
          </DialogContent>
        </CustomDialog>
      </Draggable>
    </Box>
  );
}

export default DeficiencyCommentRegModal;
import React, { createContext, useContext, useState } from 'react';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MuiSnackbar from "@mui/material/Snackbar";
import { styled } from '@mui/material/styles';

const Snackbar = styled(MuiSnackbar)(({ theme }) => ({
  "& .MuiSnackbar-root": {
    top: theme.spacing(15),
  },
}));

type SnackbarSeverity = "error" | "warning" | "info" | "success";

type IUtilityContext = {
  //snackbarを表示するときに呼び出す関数
  showSnackbar: ((type: SnackbarSeverity, message: string) => void) | undefined;
  showSpinner: (() => void) | undefined;
  hideSpinner: (() => void) | undefined;
}

const defaultContext:IUtilityContext ={
  showSnackbar: () => {},
  showSpinner: () => {},
  hideSpinner: () => {}
}

const UtilityContext = createContext<IUtilityContext>(defaultContext);

export function useUtilityContext() {
  return useContext(UtilityContext);
}

interface Props  {
  children?: React.ReactNode;
}

export const UtilityProvider: React.FC<Props> = ({children}) => {
    //Snackbarに与えるパラメータをstateで管理
    const [open, setOpen] = useState<boolean>(false);
    const [severity, setSeverity] = useState<SnackbarSeverity>("info");
    const [message, setMessage] = useState<string>("");

    //showSnackbarの実体。各stateをセットし、snackbarを表示する
    const showSnackbar= (type: SnackbarSeverity, message: string): void => {
        setOpen(true);
        setSeverity(type);
        setMessage(message);
    };

    //snackbarのxボタンが押された時のコールバック関数
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    // 
    const [spinnerOpen, setSpinnerOpen] = useState<boolean>(false);
    //showSnackbarの実体。各stateをセットし、snackbarを表示する
    const showSpinner= (): void => {
      setSpinnerOpen(true);
    };
    const hideSpinner= (): void => {
      setSpinnerOpen(false);
    };

    return (
        <>
            {/* 子コンポーネントの描画 */}
            <UtilityContext.Provider value={{showSnackbar: showSnackbar,showSpinner: showSpinner,hideSpinner: hideSpinner}}>{children}</UtilityContext.Provider>
            {/* Snackbarの描画 */}
            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical:'bottom',horizontal:'center'}}> */}
            <Snackbar
             open={open}
             onClose={handleClose}
             autoHideDuration={3000}
             anchorOrigin={{vertical:'top',horizontal:'center'}}
             sx={{
              marginTop: 5
              }}
             >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Backdrop
              sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
              open={spinnerOpen}
              invisible={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
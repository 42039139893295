import React, { createContext, useState, useContext, useCallback } from 'react';

type IUserContext = {
  userData: string;
  setUserData: ((data: string) => void) | undefined;
}

const defaultContext:IUserContext ={
  userData: '',
  setUserData: () => {}
}

export const UserContext = createContext<IUserContext>(defaultContext);

export function useUserContext() {
  return useContext(UserContext);
}

const useUserData = (): IUserContext => {
  // state名はThemeContext typeのプロパティに合わせる。
  const [userData, setUserDataState] = useState<string>('');

  // 関数名はThemeContext typeのプロパティに合わせる。
  const setUserData = useCallback((current: string): void => {
    setUserDataState(current);
  }, []);
  
  return {
    userData,
    setUserData
  };
}

interface Props  {
  children?: React.ReactNode;
}

export const UserProvider: React.FC<Props> = ({children}) => {
    const userCtx = useUserData();

    return (
        <>
            {/* 子コンポーネントの描画 */}
            <UserContext.Provider value={userCtx}>{children}</UserContext.Provider>
        </>
    );
};
import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    Modal,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LOCAL_CONSTANT } from "../../Const";
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

const dateStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    height: "30%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    justifyContent: 'center'
};

export interface countDateProps {
    props:{
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        data: any;
        trafficType: any;
        setDate: any;
    }
}
const DateModal:React.FC<countDateProps> = ({props}) => {
    const [open, setOpen] = React.useState(false);
    const [newDate, setNewDate] = React.useState<any>("")


    const today = dayjs();

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
    };

    const registDate = () => {
        props.setDate(props.data, newDate)
        setOpen(false)
        props.handleModal(false)
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open)

            if (props.data) {
                setNewDate(props.data.count_date)
            }
        }
    }, [props.open])

    return (
        <>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="set-count-date"
              aria-describedby="modal-modal-description"
          >
            <Box sx={dateStyle}>
                <Box sx={{ width: '90%' }}>
                    <Box sx={{ display: 'flex', padding: '40px 0' }}>
                        <Typography sx={{ width: '30%', padding: '14px' }}>{LOCAL_CONSTANT.LABEL.COUNT_DATE}</Typography>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}
                        >
                            <DatePicker
                                openTo="day"
                                format="YYYY-MM-DD"
                                maxDate={today}
                                label={LOCAL_CONSTANT.LABEL.COUNT_DATE}
                                value={dayjs(newDate)}
                                onChange={(newDate) => setNewDate(newDate)}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ width: '90%', bottom: 0, position: 'absolute'}}>
                        <Box sx={{ width: '100%', padding: '10px 0', display: 'flex', justifyContent: 'space-around' }}>
                            <Button 
                                variant="outlined" color="error" size="small"
                                onClick={handleClose}
                            >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                            <Button
                                variant="contained" color="success" size="small"
                                onClick={registDate}
                            >{LOCAL_CONSTANT.LABEL.REGIST_LABEL}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
          </Modal>
        </>
    );
}

export default DateModal;
import * as React from 'react';

import {
    TextField,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    InputLabel,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useUtilityContext } from '../../utility-provider';
import { apiRequest } from '../../api/ApiCall';

export interface commentDialogProps {
    props: {
        open: boolean,
        params: any,
        handleModal:React.Dispatch<React.SetStateAction<boolean>>,
        handleSaveComment: () => void
    }
}

export const Comment:React.FC<commentDialogProps> = ({props}) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [disabled, setDisabled] = React.useState<boolean>(true)
    const [comment, setComment] = React.useState("")
    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //ステータス管理sequence
    const statusSeq = useAppSelector((state) => state.longPage.locationBase.status_seq);
    const utilityCtx = useUtilityContext();

    //項目名用オブジェクト
  const [tableNameMaster, setTableNameMaster] = React.useState<any>([]);

  //項目名オブジェクトセット
  React.useEffect(() => {
    const tableNameMasterObj = localStorage.getItem("tableNameMaster");
    if (tableNameMasterObj) {
      setTableNameMaster(JSON.parse(tableNameMasterObj));
    }
  }, []);

  //項目名セット
  const setColumnName = (column_physics_name: string) => {
    let column_logic_name = column_physics_name
    tableNameMaster.map((row: any) => {
      if (row.column_physics_name === column_physics_name) {
        column_logic_name = row.column_logic_name
      }
    })

    return column_logic_name;
  };

    React.useEffect(() => {
        setOpen(props.open)
        setComment("")  //コメント初期化
    }, [props.open])

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(e.target.value)
        if (e.target.value) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    const registComment = () => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: statusSeq,
                table_name: props.params.table_name,
                column_name: props.params.column_name,
                comment: comment,
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setComment("")
                //モーダルを閉じる
                props.handleSaveComment()
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setComment("")
                //モーダルを閉じる
                props.handleSaveComment()
            }
        })();
    }

    const closeComment = () => {
        //モーダルを閉じる
        props.handleSaveComment()
    }

    return (
        <>
            <Dialog open={open} onClose={() => props.handleModal(false)} fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputLabel sx={{ padding: '10px' }}>不備コメント</InputLabel>
                        {setColumnName(props.params.column_name)}
                    </Box>
                </DialogTitle>
                <DialogContent> 
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={comment}
                        multiline
                        onChange={(e) => handleCommentChange(e)}
                    />
                </DialogContent>
                <Box sx={{ display: 'flex', justifyContent: "space-around", margin: '10px' }}>
                    <DialogActions>
                        <Button
                            variant="outlined" color="error" size="small"
                            onClick={() => closeComment()}>
                            キャンセル
                        </Button>
                    </DialogActions>
                    <DialogActions>
                        <Button
                            variant="contained" color="success" size="small"
                            disabled={disabled}
                            onClick={() => registComment()}>
                            登録
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default Comment;

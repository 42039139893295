// 吹き出しスタイル調整
export const chatBubbleCircleSx = (flag: boolean) => {
  return {
    backgroundColor: flag ? '#e0e0e0': '#1565c0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px'
  }
};

// 吹き出しアイコンのスタイル調整
export const chatBubbleIconSx = {
  color: 'white'
};

//鉛筆スタイル調整
export const editIconCircleSx = {
  backgroundColor: '#e0e0e0',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
};

//アイコンホバー時のスタイル調整
export const iconHover = {
  padding: '8px 0px 8px 4px',//アイコンのpaddingを調整
  '&:hover': {
    backgroundColor: 'transparent',
  }
};
import { createSlice } from '@reduxjs/toolkit';
import { initDonationObj } from '../data/type';


export const donationSlice = createSlice({
    name: 'donation',
    initialState: {
        obj:initDonationObj,
    },
    reducers: {
        setObjDonation: (state, action) => {
            state.obj = action.payload;
        }
     }
});

export const {
    setObjDonation,
} = donationSlice.actions;
export default donationSlice.reducer;
import * as turf from '@turf/turf';

const { point } = require('@turf/helpers')
const distance = require('@turf/distance').default

interface latlngLiteral {
    lat: number,
    lng: number
}

/**
 * ２地点間の距離をメートルで返します。1ｍ以下切り捨て
 */
export const point2distance = (from: google.maps.LatLng, to: google.maps.LatLng) => {
    let turfPointFrom = turf.point([from.lng(), from.lat()]);
    let turfPointTo = turf.point([to.lng(), to.lat()]);
    return Math.floor(turf.distance(turfPointFrom, turfPointTo) * 1000);
};

/**
 * ２地点間の距離をメートルで返します。1ｍ以下を切り捨てない
 */
export const point2distance2 = (from: google.maps.LatLng, to: google.maps.LatLng) => {
    let turfPointFrom = turf.point([from.lng(), from.lat()]);
    let turfPointTo = turf.point([to.lng(), to.lat()]);
    return turf.distance(turfPointFrom, turfPointTo);
};

/**
 * ポリゴン内に指定したポイントが入っているか確認する
 * ポリゴンもポイントもgooglemapsオブジェクト
 * @param {*} polygon
 * @param {*} point
 */
export const booleanPointInPolygon = (polygon: any, point: any) => {
    return turf.booleanPointInPolygon(
      googleMapsPoint2GeoJsonPoint(point),
      googleMapsPolygon2GeoJsonPolygon(polygon)
    );
};

/**
 * GoogleMapsのポイントをturfでGeoJSONのポイントに変換します。
 */
export const googleMapsPoint2GeoJsonPoint = (point: any) => {
    // turf用ポイントを返す
    return turf.point([point.getPosition().lng(), point.getPosition().lat()]);
};

/**
 * GoogleMapsのポリゴンをturfでGeoJSONのポリゴンに変換します。
 */
export const googleMapsPolygon2GeoJsonPolygon = (polygon: any) => {
    const polygonPath = polygon.getPath();
    let turfPolygonPath = [];
    // turfのポリゴンは最初と最後に同じパスを設定する必要がある為、最初のパスを保存
    const firstPath = [
      polygonPath.getAt(0)["lng"](),
      polygonPath.getAt(0)["lat"](),
    ];
    polygon.getPath().forEach((value: any, ii: any) => {
      let path = [polygonPath.getAt(ii)["lng"](), polygonPath.getAt(ii)["lat"]()];
      // turf用のポリゴンパスを作成
      turfPolygonPath.push(path);
    });
    turfPolygonPath.push(firstPath);
    // turf用ポリゴンを返す
    return turf.polygon([turfPolygonPath]);
  };
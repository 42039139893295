import { createSlice } from "@reduxjs/toolkit";

export const otherDocumentSlice = createSlice({
    name: 'otherDocument',
    initialState: {
        pdfPath:''
    },
    reducers:{
        setOtherDocumentPdfPreview:(state, action) => {
            state.pdfPath = action.payload;
        }
    }
});

export const {
    setOtherDocumentPdfPreview
} = otherDocumentSlice.actions;
export default otherDocumentSlice.reducer;
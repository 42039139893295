import * as React from 'react';

import AddImpact from './AddImpact';
import ImpactTable from './ImpactTable';
import { LOCAL_CONSTANT } from '../../Const';

import {
    Select,
    MenuItem,
    SelectChangeEvent,
    TextField,
    InputLabel,
    Button,
    Grid,
    Divider,
    Box,
    styled
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { apiRequest } from "../../../api/ApiCall";
import { useUtilityContext } from '../../../utility-provider';
import ConfirmDialog from '../../components/ConfirmDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setPermissionFunc } from '../../components/PermissionFunc';
import { setObjEditImpactData } from '../../../features/impactSlice';
import {
    setSaveStatus,
} from '../../../features/longPageSlice';

export interface SubData1Props {
    props: {
        plan_area_id: string;
        locationImpact: any | [];
        impactData: any;
        handleSetReadApi: any;
        expanded: boolean;
        latlng: any;
        userType: string | null;
    }
}

const ImpactLabel = () => {
    return [
        [
            { "size": LOCAL_CONSTANT.NUMBER.SIX, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": LOCAL_CONSTANT.LABEL.ROUTE_CHANGE, "keyName": "update-route", "inputType": "update-route" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": LOCAL_CONSTANT.LABEL.CHANGE, "keyName": "update", "inputType": "update" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": LOCAL_CONSTANT.LABEL.DELETE, "keyName": "delete", "inputType": "delete" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": LOCAL_CONSTANT.LABEL.STORE_NO, "keyName": "store_code", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": LOCAL_CONSTANT.LABEL.STORE_NO, "keyName": "store_code", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": LOCAL_CONSTANT.LABEL.STORE_NAME, "keyName": "tenpo_name", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": LOCAL_CONSTANT.LABEL.STORE_NAME, "keyName": "tenpo_name", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "道なり距離", "keyName": "road_distance", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "道なり距離", "keyName": "road_distance", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "売場面積", "keyName": "salesfloor", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "売場面積", "keyName": "salesfloor", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "駐車場面積", "keyName": "parkingarea", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "駐車場面積", "keyName": "parkingarea", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "たばこ取扱", "keyName": "tobaccolicense", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "たばこ取扱", "keyName": "tobaccolicense", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "既存店位置", "keyName": "store_position", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "既存店位置", "keyName": "store_position", "inputType": "select", "selecter": SELECTER2 },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "車", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "店前総数", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "店前総数", "keyName": "car_front_store_count", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "累積共通比", "keyName": "competitiveTarget", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "累積共通比", "keyName": "car_total_share_ratio", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "人", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "店前総数", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "店前総数", "keyName": "people_front_store_count", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "累積共通比", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "累積共通比", "keyName": "people_total_share_ratio", "inputType": "disabledInput" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "候補店共通道路", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "候補店共通道路", "keyName": "common_road", "inputType": "select", "selecter": SELECTER3 },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "候補店\n中央分離帯高さ", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "候補店\n中央分離帯高さ", "keyName": "plan_median_strip", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "既存店\n中央分離帯高さ", "keyName": "label", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "既存店\n中央分離帯高さ", "keyName": "store_median_strip", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "経路", "keyName": "store_median_strip", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "直進回数", "keyName": "straight_count", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "直進回数", "keyName": "straight_count", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "右左折回数", "keyName": "turn_count", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "右左折回数", "keyName": "turn_count", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "newLine": "label", "inputType": "newLine" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "線路横断", "keyName": "railroad_crossing", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "線路横断", "keyName": "railroad_crossing", "inputType": "select", "selecter": SELECTER },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "河川幅", "keyName": "river_width", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "河川幅", "keyName": "river_width", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "横断車線数合計", "keyName": "crossing_road_count", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "横断車線数合計", "keyName": "crossing_road_count", "inputType": "input" },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "Divider": "label", "inputType": "Divider" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "経路競合", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "セブンイレブン", "keyName": "conflict_sej", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "セブンイレブン", "keyName": "conflict_sej", "inputType": "select", "selecter": SELECTER },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "LW/FM\nセイコーマート", "keyName": "conflict_main_cvs", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "LW/FM\nセイコーマート", "keyName": "conflict_main_cvs", "inputType": "select", "selecter": SELECTER },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "その他CVS", "keyName": "conflict_other_cvs", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "その他CVS", "keyName": "conflict_other_cvs", "inputType": "select", "selecter": SELECTER },
            { "size": LOCAL_CONSTANT.NUMBER.TWENTEEN, "labelName": "", "newLine": "label", "inputType": "newLine" },
        ],
        [
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "", "keyName": "label", "inputType": "header" },
            { "size": LOCAL_CONSTANT.NUMBER.ONE, "labelName": "周辺既存店店番", "keyName": "average_common_ratio", "inputType": "label" },
            { "size": LOCAL_CONSTANT.NUMBER.TWO, "labelName": "周辺既存店店番", "keyName": "average_common_ratio", "inputType": "input" },
        ],
    ]
}

const SELECTER = () => {
    return [
        { 'selectKey': '' },
        { 'selectKey': 'あり' },
        { 'selectKey': 'なし' },
    ]
}

const SELECTER2 = () => {
    return [
        { 'selectKey': '' },
        { 'selectKey': 'A' },
        { 'selectKey': 'B' },
        { 'selectKey': '反A' },
        { 'selectKey': '反B' },
    ]
}

const SELECTER3 = () => {
    return [
        { 'selectKey': '' },
        { 'selectKey': LOCAL_CONSTANT.LABEL.FRONT },
        { 'selectKey': LOCAL_CONSTANT.LABEL.SIDE_A },
        { 'selectKey': LOCAL_CONSTANT.LABEL.SIDE_B },
        { 'selectKey': LOCAL_CONSTANT.LABEL.BACK },
    ]
}

//アスタリスク
const RedAsterisk = styled('span')({
    color: 'red',
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
});

export const LocationImpactDetail: React.FC<SubData1Props> = ({ props }) => {
    //オブジェクトの宣言
    const [open, setOpen] = React.useState<boolean>(false);
    const [targetImpact, setTargetImpact] = React.useState<any>()
    const utilityCtx = useUtilityContext();
    const [impactData, setImpactData] = React.useState<any>(undefined)
    const [actionFlag, setActionFlag] = React.useState<boolean>(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    //調査種別プルダウンの値を取得
    const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);
    //タスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    // 編集影響度
    const objEditImpactData = useAppSelector((state) => state.impact.editImpactData);
    const dispatch = useAppDispatch();
    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);

    React.useEffect(() => {
        if (props.impactData) {
            const copyData = JSON.parse(JSON.stringify(props.impactData))
            setImpactData(copyData)
        }
    }, [props.impactData])

    //インプットデータ取得
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        // 入力した値をStoreへ登録する
        if (impactData !== undefined) {
            impactData[labelName] = e.target.value
            editImpactData(impactData, labelName, e.target.value)
            setActionFlag(!actionFlag)
        }
    };

    //プルダウンデータ取得
    const handleSelectChange = (e: SelectChangeEvent<string>, labelName: string) => {
        // 入力した値をStoreへ登録する
        if (impactData !== undefined) {
            impactData[labelName] = e.target.value
            editImpactData(impactData, labelName, e.target.value)
            setActionFlag(!actionFlag)
        }
    };

    // 更新データ
    const editImpactData = (impactData: any, labelName: string, value: any) => {
        const temp: any = JSON.parse(JSON.stringify(objEditImpactData));
        if (impactData !== undefined) {
            if (temp.length === 0) {
                temp.push(JSON.parse(JSON.stringify(impactData)))
            } else {
                let hasStore = false
                temp.map((row: any) => {
                    if (impactData.store_code === row.store_code) {
                        row[labelName] = value
                        hasStore = true
                    }
                })
                if (!hasStore) {
                    temp.push(JSON.parse(JSON.stringify(impactData)))
                }
            }
        }
        dispatch(setObjEditImpactData(temp));
        //更新フラグ
        if (!objLongPageSaveStatus.Impact) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Impact']: true }));
    }

    // ルート変更
    const onClickHandle = (e: any) => {
        setTargetImpact(props.impactData)
        setOpen(true)
    }

    const confirmDelete = () => {
        setConfirmDialogOpen(true)
    }

    const cancelDelete = () => {
        setConfirmDialogOpen(false)
    }

    // 削除処理
    const onClickdelete = () => {
        interface Obj {
            [prop: string]: any
        }
        const requestParams: Obj = {}
        requestParams.plan_area_id = props.impactData.plan_area_id
        requestParams.branch = props.impactData.branch
        requestParams.store_code = props.impactData.store_code
        const params = {
            mode: "regist",
            endPoint: "/location/Impact/v1/delete",
            query: requestParams,
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setConfirmDialogOpen(false)
                setActionFlag(false)
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                props.handleSetReadApi(true)
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }


    const setBody = () => {
        let sentence = ""
        if (impactData !== undefined) {
            sentence = impactData['tenpo_name'] + "（" + impactData['store_code'] + "）を削除しますか？"
        }
        return sentence
    }

    const inputValue = (data: any, key: string) => {
        let value = data[key]
        if (key === 'car_total_share_ratio' || key === 'people_total_share_ratio') {
            if (Number(value) > 0) {
                value = (Math.round(data[key] * 10000) / 100).toString()
            }

            value = Number(value).toFixed(2).toString() + LOCAL_CONSTANT.LABEL.PERCENT
        }
        return value
    }

    return (
        <>
            {
                // props.expanded === true &&
                <Box sx={{ margin: '10px' }}>
                    <Typography>調査内容</Typography>
                    {
                        impactData !== undefined &&
                        ImpactLabel().map((row, index) => (
                            <Grid container key={index}>
                                {row.map((val: any, keyIndex) => (
                                    <Grid item key={keyIndex} xs={val.size}>
                                        {
                                            val.inputType === 'update-route' &&
                                            <Button
                                                sx={{ margin: '20px 0' }}
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={(e) => onClickHandle(e)}
                                                disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.labelName)}
                                            >{val.labelName}</Button>
                                        }
                                        {
                                            val.inputType === 'delete' &&
                                            <Button
                                                sx={{ margin: '20px 0' }}
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={(e) => confirmDelete()}
                                                disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.labelName)}
                                            >{val.labelName}</Button>
                                        }
                                        {
                                            val.inputType === 'header' && index === 0 &&
                                            <InputLabel sx={{ fontWeight: 'bold' }}>{val.labelName}</InputLabel>
                                        }
                                        {/* 調査種別プルダウンが純増の場合に、項目にアスタリスクを表示 */}
                                        {
                                            val.inputType === 'header' && index !== 0 && selectedSurveyType === '01' && (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <InputLabel sx={{ fontWeight: 'bold' }}>{val.labelName}</InputLabel>
                                                    <RedAsterisk>＊＊</RedAsterisk>
                                                </Box>
                                            )
                                        }
                                        {
                                            val.inputType === 'header' && index !== 0 && selectedSurveyType !== '01' &&
                                            <InputLabel sx={{ fontWeight: 'bold' }}>{val.labelName}</InputLabel>
                                        }
                                        {
                                            val.inputType === 'label' &&
                                            <InputLabel sx={{ fontSize: '0.8em', padding: '20px 0', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>{val.labelName}</InputLabel>
                                        }
                                        {
                                            //テキストフィールド 常に非活性
                                            val.inputType === 'disabledInput' &&
                                            <TextField sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} disabled={true} value={inputValue(impactData, val.keyName)}></TextField>
                                        }
                                        {
                                            //テキストフィールド 活性に切り替え
                                            val.inputType === 'input' &&
                                            setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.keyName) === false &&
                                            <TextField sx={{ backgroundColor: '#FFFCD6' }} onChange={(e) => handleChange(e, val.keyName)} value={impactData[val.keyName]}></TextField>
                                        }
                                        {
                                            //テキストフィールド 非活性に切り替え
                                            val.inputType === 'input' &&
                                            setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.keyName) === true &&
                                            <TextField sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} disabled={true} onChange={(e) => handleChange(e, val.keyName)} value={impactData[val.keyName]}></TextField>
                                        }
                                        {
                                            //プルダウン活性に切り替え
                                            val.inputType === 'select' &&
                                            setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.keyName) === false &&
                                            <Select
                                                sx={{ width: '40%', backgroundColor: '#FFFCD6' }}
                                                onChange={(e) => handleSelectChange(e, val.keyName)}
                                                value={impactData[val.keyName]}
                                            >
                                                {
                                                    val.selecter().map((row: any) => (
                                                        <MenuItem key={props.impactData.store_code + '_' + row.selectKey} value={row.selectKey}>{row.selectKey}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            //プルダウン非活性に切り替え
                                            val.inputType === 'select' &&
                                            setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.IMPACT, val.keyName) === true &&
                                            <Select
                                                sx={{ width: '40%', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                                onChange={(e) => handleSelectChange(e, val.keyName)}
                                                value={impactData[val.keyName]}
                                                disabled={true}
                                            >
                                                {
                                                    val.selecter().map((row: any) => (
                                                        <MenuItem key={props.impactData.store_code + '_' + row.selectKey} value={row.selectKey}>{row.selectKey}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            val.inputType === 'Divider' &&
                                            <Divider sx={{ my: 1 }} />
                                        }
                                        {
                                            val.inputType === 'newLine' &&
                                            <br />
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                        ))
                    }
                    <ImpactTable props={{
                        impactData: impactData,
                    }} />
                    <AddImpact props={{
                        open: open,
                        handleModal: setOpen,
                        mode: 'edit',
                        latlng: props.latlng,
                        locationImpactList: props.locationImpact,
                        targetImpact: targetImpact,
                        handleSetReadApi: props.handleSetReadApi
                    }} />
                    <ConfirmDialog props={{
                        open: confirmDialogOpen,
                        handleModal: setConfirmDialogOpen,
                        onCancel: cancelDelete,
                        onExecution: onClickdelete,
                        mode: "deleteConfirm",
                        body: setBody(),
                        confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                        confirmOk: LOCAL_CONSTANT.LABEL.DELETE
                    }}
                    />
                </Box>
            }
        </>
    )
}

export default LocationImpactDetail;

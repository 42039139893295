import * as React from 'react';

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    TableSortLabel,
} from '@mui/material';
import { LOCAL_CONSTANT } from '../../Const';

interface Data {
    id: number;
    outputRequestDate: string;
    department: string;
    district: string;
    candidateLocationNumber: string;
    candidatePlaceName: string;
    rfc: string;
    assembly: string;
    pattern: string;
    patternDetails: string;
    branch: string;
    status: string;
    task: string;
    manager: string;
    surveyReportConfirmation: string;
    predictionFirst: string;
    predictionSecond: string;
    predictionThird: string;
    predictionFourth: string;
    predictionFifth: string;
    logConfirmation: string;
}
  
function createData(
  id: number,
  outputRequestDate: string,
  department: string,
  district: string,
  candidateLocationNumber: string,
  candidatePlaceName: string,
  rfc: string,
  assembly: string,
  pattern: string,
  patternDetails: string,
  branch: string,
  status: string,
  task: string,
  manager: string,
  surveyReportConfirmation: string,
  predictionFirst: string,
  predictionSecond: string,
  predictionThird: string,
  predictionFourth: string,
  predictionFifth: string,
  logConfirmation: string
): Data {
  return {
    id,
    outputRequestDate,
    department,
    district,
    candidateLocationNumber,
    candidatePlaceName,
    rfc,
    assembly,
    pattern,
    patternDetails,
    branch,
    status,
    task,
    manager,
    surveyReportConfirmation,
    predictionFirst,
    predictionSecond,
    predictionThird,
    predictionFourth,
    predictionFifth,
    logConfirmation
  };
}

const DataList = [
        createData(1, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301491", "〇〇2301491丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(2, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301492", "〇〇2301492丁目", "", "セットパック", "2（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(3, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301493", "〇〇2301493丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(4, "2023/10/01 10:00", "第4リクルート部", "大阪", "A2301494", "〇〇2301494丁目", "", "セットパック", "3（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(5, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301495", "〇〇2301495丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(6, "2023/10/01 10:00", "第1リクルート部", "神奈川", "A2301496", "〇〇2301496丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(7, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301497", "〇〇2301497丁目", "", "セットパック", "4（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(8, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301498", "〇〇2301498丁目", "", "セットパック", "4（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(9, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301499", "〇〇2301499丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(10, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301500", "〇〇2301500丁目", "", "セットパック", "5（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(11, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301501", "〇〇2301501丁目", "", "セットパック", "5（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(12, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301502", "〇〇2301502丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(13, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301503", "〇〇2301503丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(14, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301504", "〇〇2301504丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(15, "2023/10/01 10:00", "第5リクルート部", "大阪", "A2301505", "〇〇2301505丁目", "", "セットパック", "6（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(16, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301506", "〇〇2301506丁目", "", "セットパック", "6（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(17, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301507", "〇〇2301507丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(18, "2023/10/01 10:00", "第3リクルート部", "神奈川", "A2301508", "〇〇2301508丁目", "", "セットパック", "7（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(19, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301509", "〇〇2301509丁目", "", "セットパック", "7（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(20, "2023/10/01 10:00", "第5リクルート部", "秋田", "A2301510", "〇〇2301510丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(21, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301511", "〇〇2301511丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(22, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301512", "〇〇2301512丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(23, "2023/10/01 10:00", "第3リクルート部", "大阪", "A2301513", "〇〇2301513丁目", "", "セットパック", "8（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(24, "2023/10/01 10:00", "第4リクルート部", "秋田", "A2301514", "〇〇2301514丁目", "", "セットパック", "8（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(25, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301515", "〇〇2301515丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(26, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301516", "〇〇2301516丁目", "", "セットパック", "8（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(27, "2023/10/01 10:00", "第2リクルート部", "神奈川", "A2301517", "〇〇2301517丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(28, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301518", "〇〇2301518丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(29, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301519", "〇〇2301519丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(30, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301520", "〇〇2301520丁目", "", "セットパック", "9（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(31, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301521", "〇〇2301521丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(32, "2023/10/01 10:00", "第2リクルート部", "大阪", "A2301522", "〇〇2301522丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(33, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301523", "〇〇2301523丁目", "", "セットパック", "9（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(34, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301524", "〇〇2301524丁目", "", "セットパック", "10（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(35, "2023/10/01 10:00", "第5リクルート部", "神奈川", "A2301525", "〇〇2301525丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(36, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301526", "〇〇2301526丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(37, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301527", "〇〇2301527丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(38, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301528", "〇〇2301528丁目", "", "セットパック", "10（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(39, "2023/10/01 10:00", "第4リクルート部", "大阪", "A2301529", "〇〇2301529丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(40, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301530", "〇〇2301530丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(41, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301531", "〇〇2301531丁目", "", "セットパック", "1（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(42, "2023/10/01 10:00", "第2リクルート部", "秋田", "A2301532", "〇〇2301532丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(43, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301533", "〇〇2301533丁目", "", "セットパック", "11（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(44, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301534", "〇〇2301534丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(45, "2023/10/01 10:00", "第5リクルート部", "東京", "A2301535", "〇〇2301535丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(46, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301536", "〇〇2301536丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(47, "2023/10/01 10:00", "第2リクルート部", "秋田", "A2301537", "〇〇2301537丁目", "", "セットパック", "12（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(48, "2023/10/01 10:00", "第3リクルート部", "東京", "A2301538", "〇〇2301538丁目", "", "セットパック", "13（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(49, "2023/10/01 10:00", "第4リクルート部", "東京", "A2301539", "〇〇2301539丁目", "", "セットパック", "1（通常）", "-", "4", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(50, "2023/10/01 10:00", "第5リクルート部", "神奈川", "A2301540", "〇〇2301540丁目", "", "セットパック", "1（通常）", "-", "3", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(51, "2023/10/01 10:00", "第1リクルート部", "東京", "A2301541", "〇〇2301541丁目", "", "セットパック", "1（通常）", "-", "2", "-", "-", "-", "", "", "", "", "", "", ""),
        createData(52, "2023/10/01 10:00", "第2リクルート部", "東京", "A2301542", "〇〇2301542丁目", "", "セットパック", "20（通常）", "-", "1", "-", "-", "-", "", "", "", "", "", "", ""),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
type Order = "asc" | "desc";

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const ReportListData = () => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderKey, setOrderKey] = React.useState<keyof Data>();

    const createSortHandler = (property: keyof Data) => (
      event: React.MouseEvent<unknown>
    ) => {
      setOrderKey(property)
      setOrder(order === "asc" ? "desc" : "asc");
    };

    // クリックイベント
    const checkSurveyReportConfirmation = () => {
        console.log('クリックイベント 検索')
    }

    // クリックイベント
    const checkLogConfirmation = () => {
        console.log('クリックイベント 検索')
    }
    return (
        <>
            <TableContainer sx={{ height: "40vh", width: "96%", marginTop: '60px' }}>
                <Table
                    stickyHeader
                    style={{
                    tableLayout: "fixed",
                    }}
                >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("id")}>{LOCAL_CONSTANT.LABEL.ID}</TableSortLabel>
                        </TableCell>
                        <TableCell>{LOCAL_CONSTANT.LABEL.OUTPUT_REQUEST_DATE}</TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("department")}>{LOCAL_CONSTANT.LABEL.DEPARTMENT}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("district")}>{LOCAL_CONSTANT.LABEL.DISTRICT}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("candidateLocationNumber")}>{LOCAL_CONSTANT.LABEL.PLAN_AREA_NO_2}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("candidatePlaceName")}>{LOCAL_CONSTANT.LABEL.PLAN_AREA_NAME}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("rfc")}>{LOCAL_CONSTANT.LABEL.RFC}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("assembly")}>{LOCAL_CONSTANT.LABEL.ASSEMBLY}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("pattern")}>{LOCAL_CONSTANT.LABEL.PATTERN}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("patternDetails")}>{LOCAL_CONSTANT.LABEL.PATTERN_DETAILS}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("branch")}>{LOCAL_CONSTANT.LABEL.BRANCH}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("status")}>{LOCAL_CONSTANT.LABEL.STATUS}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("task")}>{LOCAL_CONSTANT.LABEL.TASK}</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("manager")}>{LOCAL_CONSTANT.LABEL.MANAGER}</TableSortLabel>
                        </TableCell>
                        <TableCell>{LOCAL_CONSTANT.LABEL.SURVEY_REPORT_CONFIRMATION}</TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("predictionFirst")}>{LOCAL_CONSTANT.LABEL.PREDICTION}（初）</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("predictionSecond")}>{LOCAL_CONSTANT.LABEL.PREDICTION}（2）</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("predictionThird")}>{LOCAL_CONSTANT.LABEL.PREDICTION}（3）</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("predictionFourth")}>{LOCAL_CONSTANT.LABEL.PREDICTION}（4）</TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active direction={order} onClick={createSortHandler("predictionFifth")}>{LOCAL_CONSTANT.LABEL.PREDICTION}（5）</TableSortLabel>
                        </TableCell>
                        <TableCell>{LOCAL_CONSTANT.LABEL.LOG_CONFIRM}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {DataList.sort(getComparator(order, orderKey === undefined ? 'id' : orderKey)).map((row: any, index: any) => {
                            return (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.outputRequestDate}</TableCell>
                                <TableCell>{row.department}</TableCell>
                                <TableCell>{row.district}</TableCell>
                                <TableCell>{row.candidateLocationNumber}</TableCell>
                                <TableCell>{row.candidatePlaceName}</TableCell>
                                <TableCell>{row.rfc}</TableCell>
                                <TableCell>{row.assembly}</TableCell>
                                <TableCell>{row.pattern}</TableCell>
                                <TableCell>{row.patternDetails}</TableCell>
                                <TableCell>{row.branch}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{row.task}</TableCell>
                                <TableCell>{row.manager}</TableCell>
                                <TableCell><Button color="success" variant="contained" onClick={(event) => checkSurveyReportConfirmation()}>{LOCAL_CONSTANT.LABEL.CONFIRM}</Button></TableCell>
                                <TableCell>{row.predictionFirst}</TableCell>
                                <TableCell>{row.predictionSecond}</TableCell>
                                <TableCell>{row.predictionThird}</TableCell>
                                <TableCell>{row.predictionFourth}</TableCell>
                                <TableCell>{row.predictionFifth}</TableCell>
                                <TableCell><Button color="success" variant="contained" onClick={(event) => checkLogConfirmation()}>{LOCAL_CONSTANT.LABEL.CONFIRM}</Button></TableCell>
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ReportListData;

import { createSlice } from '@reduxjs/toolkit';


export const rrInternalSlice = createSlice({
    name: 'rrInternalUse',
    initialState: {
        pdfPath:''
    },
    reducers:{
        setRRinternalUsePdf:(state, action) => {
            state.pdfPath = action.payload;
        }
    }
});

export const {
    setRRinternalUsePdf
} = rrInternalSlice.actions;
export default rrInternalSlice.reducer;
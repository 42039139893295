import {
  Button,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiRequest } from '../../../api/ApiCall';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LOCAL_CONSTANT } from '../../Const';
import {
  StoreOpeningConditionsTypeList,
  StoreOpeningConditionsBooleanTypeList,
  StoreOpeningConditionsStringTypeList,
  initStoreOpeningConditionsCommentTextFieldObj,
  initStoreOpeningConditionsReadOnlyObj,
  initStoreOpeningConditionsShowEditIconObj,
  initStoreOpeningConditionsShowIconObj,
} from '../../../data/type';
import { setSaveStatus } from '../../../features/longPageSlice';
import {
  setObjStoreOpeningConditions,
  setClosingDays,
  setMonthSelect,
  setMonthHeder,
  setNippanAverage,
  setOnYearAverage,
  setAchievementAcquisition
} from '../../../features/storeOpeningConditionsSlice';
import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import LongTextFiledWithIcons from '../../components/LongTextFiledWithIcons';
import { mojiLimit } from '../../components/MojiLimit';
import {
  activeInactiveTextSx,
  inactiveTextSx,
} from '../../components/TextFieldStyle';
import { lineBreak } from '../../ResearchReport/TradeArea';
import {
  parseDateString,
  getTwelveMonths,
  closingDaysFunc,
  avgCulcNippanFunc,
  avgCulcPrevRatioFunc
} from '../../subPage/StoreOpeningConditions/StoreOpeningConditionsFunction';
import { setPermissionFunc } from '../../components/PermissionFunc';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import InputWithIcons from '../../components/InputWithIcons';
import {
  chatBubbleCircleSx,
  chatBubbleIconSx,
  editIconCircleSx,
  iconHover
} from '../../components/ChatBubbleAndEditIconStyle';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';

//項目値の横幅調整
const itemValueSx = {
  width: '250px'
};

//プルダウンのスタイル
const selectStyle = (isUserType: boolean) => {
  return {
    textAlign: 'center',
    backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
  }
}

//変化の種類のプルダウン値
const changeTypeItems: { [key: string]: string } = {
  changeTypeItem1: "固定マーケットの変化",
  changeTypeItem2: "流動マーケットの変化",
  changeTypeItem3: "競合店の出店、閉店",
  changeTypeItem4: "その他",
};

const table14Column = {
  width: '1250px',
  marginTop: '15px',
  border: '1px solid black',
};

const labelStyle = {
  width: '89px',
  height: '50px',
  color: "black",
  textAlign: 'center',
  border: '1px solid black',
  backgroundColor: "#D9D9D9",
};

const tableCellStyle = {
  width: '89px',
  height: '50px',
  border: '1px solid black',
  padding: '0px 0px',
  textAlign: 'center',
};

// TextFieldのスタイル調整
const TextFieldSx = {
  '& .MuiOutlinedInput-input.Mui-disabled': {
    textAlign: 'center', //入力した値を中央寄せ
  }
};

//ユーザー権限種別が活性の場合にテキストフィールドの色を変える
const tableActiveInactiveTextSx = (isUserType: boolean) => {
  return {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center', //入力した値を中央寄せ
      color: isUserType ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
    },
    backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
  }
};

interface SstoreInfoProps {
  props: {
    storeOpeningConditions: StoreOpeningConditionsTypeList,
    setStoreOpeningConditions: React.Dispatch<React.SetStateAction<StoreOpeningConditionsTypeList>>
  }
};

//アスタリスク
const RedAsterisk = styled('span')({
  color: 'red',
  marginLeft: '4px',
  display: 'flex',
  alignItems: 'center',
});

const SstoreInfo: React.FC<SstoreInfoProps> = ({ props }) => {

  //オブジェクト「objStoreOpeningConditions」にStoreに入っている初期値代入
  const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
  //調査種別プルダウンの値を取得
  const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);

  //ロングページオブジェクト 候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //ステータス管理sequenceとタスクIDを取得するため
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //パラメーターオブジェクト
  const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
  //直近確定月 プルダウン
  const listMonthSelect = useAppSelector((state) => state.storeOpeningConditions.monthSelect);
  //直近確定月 6か月経過している場合 プルダウンリストへ追加
  const sixMonthPassed = useAppSelector((state) => state.storeOpeningConditions.sixMonthPassed);
  //直近確定月の表 ヘッダー
  const objMonthHeader = useAppSelector((state) => state.storeOpeningConditions.monthHeader);
  //直近1年日販の平均
  const nippanAverage = useAppSelector((state) => state.storeOpeningConditions.nippanAverage);
  //前年比の平均
  const onYearAverage = useAppSelector((state) => state.storeOpeningConditions.onYearAverage);
  //休業日数の計算のため
  const objCover = useAppSelector((state) => state.cover.obj);

  const dispatch = useAppDispatch();

  //不備コメントモーダル項目名
  const [columnNameStr, setColumnNameStr] = useState<string>("");
  //不備コメントモーダルラベル名
  const [labelNameStr, setLabelNameStr] = useState<string>("");
  //不備コメントモーダル開閉フラグ
  const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);
  //アイコン全体の表示非表示のステータス
  const [storeOpeningConditionsShowIconsObj, setStoreOpeningConditionsShowIconsObj] = useState<StoreOpeningConditionsBooleanTypeList>(initStoreOpeningConditionsShowIconObj);
  //不備コメントモーダ内、TextFieldのコメント
  const [storeOpeningConditionsCommentTextFieldObj, setStoreOpeningConditionsCommentTextFieldObj] = useState<StoreOpeningConditionsStringTypeList>(initStoreOpeningConditionsCommentTextFieldObj);
  //項目値を編集可能にするステータスの型定義
  const [storeOpeningConditionsReadOnlyObj, setStoreOpeningConditionsReadOnlyObj] = useState<StoreOpeningConditionsBooleanTypeList>(initStoreOpeningConditionsReadOnlyObj);

  const [userType, setUserType] = useState('');
  const user = useUserContext();

  const utilityCtx = useUtilityContext();

  //インプット
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    const targetValue = e.target.value; //入力値
    const storeOpeningConditionsValue = props.storeOpeningConditions[labelName];
    const regexRange0To9999 = /^(|0|[1-9][0-9]{0,3})$/;  //入力規則:整数 範囲:0～9999
    const regexRange0TO100Point00 = /^(|0|0\.|0\.00|0\.[0-9][0-9]?|[1-9][0-9]{0,1}(\.[0-9]{0,2})?|100(\.00)?)$/;   //入力規則:小数点数 範囲:0～100.00
    const regexDate = /^([0-9]{4})-(0[1-9]|1[0-2])-([0-2][0-9]|3[01])$/; //入力規則：日付 yyyy-mm-dd 

    switch (labelName) {
      case 'sStoreOpenDate': //S店開店日
        if (regexDate.test(targetValue)) dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: targetValue })); break;
      case 'sStoreCloseDueDate': //S店閉店日
        if (regexDate.test(targetValue)) dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: targetValue })); break;
      case 'carCommonRatio': // 車共通比
        if (regexRange0TO100Point00.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'personCommonRatio': // 人共通比
        if (regexRange0TO100Point00.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'sStoreLineDistance': // S店との直線距離
        if (regexRange0To9999.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'sStoreWayDistance': // S店との道なり距離
        if (regexRange0To9999.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'sStoreCarCustomerRatio': // S店車客構成比
        if (regexRange0TO100Point00.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      default: props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break;
    }
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  // Redux カレンダー
  const handleCalendarChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value }));
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //Redux プルダウン
  const handleSelectChange = (e: SelectChangeEvent<string>, labelName: string) => {
    if (labelName === 'lastfixedMonth') {
      dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, lastfixedMonth: e.target.value }));
      const { year, month } = parseDateString(e.target.value);
      const mothsHeader = getTwelveMonths(year, month);
      dispatch(setMonthHeder(mothsHeader));
    } else {
      dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value }));
    }
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //直近1年日販テーブルセルを更新
  const handleLastOneYearNippanChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    const targetValue = e.target.value;
    if (/^$|^0$|^[1-9]\d*$/.test(targetValue)) { //空文字列、0、または正の整数のみ可能
      props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue });
    }
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //直近1年日販の各月をReduxへ保存
  const handleLastOneYearNippanBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    dispatch(setObjStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: e.target.value }));
  };

  //前年比のテーブルセルを更新
  const handleYearOnYearChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    const targetValue = e.target.value;
    if (/^$|^(0|[1-9]\d*)(\.\d{0,1})?$/.test(targetValue)) { //空文字列、数値および小数点以下1桁まで入力可能
      props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue });
    }
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  }

  //前年比の各月をReduxへ保存 
  const handleYearOnYearBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    dispatch(setObjStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: e.target.value }));
  };

  //入力不可のインプットをクリックしたときアイコンを表示
  const handleInputClick = (labelName: string) => {
    setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: true });
  };

  //吹き出しをクリックしたとき、モーダル画面を開く
  const handleChatBubbleClick = (columnName: string, labelName: string) => {
    setColumnNameStr(columnName);
    setLabelNameStr(labelName);
    setDeficiencyComentModalFlag(true);
    setStoreOpeningConditionsCommentTextFieldObj({ ...storeOpeningConditionsCommentTextFieldObj, [labelName]: "" });  //テキストフィールドのコメントを初期化
  };

  //不備コメントモーダル内でのコメント取得
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    setStoreOpeningConditionsCommentTextFieldObj({
      ...storeOpeningConditionsCommentTextFieldObj,
      [labelName]: e.target.value,
    });
  };

  //不備コメントモーダル内の保存ボタンをクリックしたときコメントを保存
  const handleSaveComment = (columnName: string, labelName: string) => {
    //不備コメントを登録
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/regist",
      query: {
        plan_area_id: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        status_seq: objLocationBase.status_seq,
        table_name: "location_storeopeningconditions",
        column_name: columnName,
        comment: storeOpeningConditionsCommentTextFieldObj[labelName],
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
        //アイコンを非表示
        setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
        //アイコンを非表示
        setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
      }
    })();
  };

  // 鉛筆をクリックしたとき、Input編集可
  const handleEditIconClick = (labelName: string) => {
    if (setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, labelName) === false) {
      //falseにすると、Input編集可
      setStoreOpeningConditionsReadOnlyObj({ ...storeOpeningConditionsReadOnlyObj, [labelName]: false });
    }
  };

  // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    //Reduxへ保存
    dispatch(setObjStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: e.target.value }));

    // インプット編集不可
    setStoreOpeningConditionsReadOnlyObj({ ...storeOpeningConditionsReadOnlyObj, [labelName]: true });

    //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
    if (!(e.relatedTarget)) {
      setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
    }

    //インプットからフォーカスが移動する先がINPUTタグの場合アイコンを閉じる
    if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
      setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
    }
  };

  //平均日販再計算ボタン
  const handleAverageNippanButtonClick = () => {
    //平均日販の共通処理
    const [avgCulcNippan, avgCulcPrevRatio, calcNippanAverage] = averageNippanCommon();
    //RRの場合、平均日販再計算ボタンで平均日販を計算
    if (userType === 'z001') {
      //avgCulcNippan または avgCulcPrevRatioの値がない場合「全月の日販の平均」
      if (avgCulcNippan === '' || avgCulcPrevRatio === '') {
        dispatch(setObjStoreOpeningConditions({
          ...objStoreOpeningConditions,
          averageNippan: Math.round(Number(calcNippanAverage)).toString()
        }));
        //avgCulcNippan * avgCulcPrevRatio / 100
      } else {
        dispatch(setObjStoreOpeningConditions({
          ...objStoreOpeningConditions,
          averageNippan: Math.round(Number(avgCulcNippan) * Number(avgCulcPrevRatio) / 100).toString()
        }));
      }
    }
  };


  //平均日販の共通処理
  const averageNippanCommon = () => {
    //直近1年日販 平均を更新
    const nippan0ToRange11 = [
      objStoreOpeningConditions.lastOneYearNippan11,
      objStoreOpeningConditions.lastOneYearNippan10,
      objStoreOpeningConditions.lastOneYearNippan9,
      objStoreOpeningConditions.lastOneYearNippan8,
      objStoreOpeningConditions.lastOneYearNippan7,
      objStoreOpeningConditions.lastOneYearNippan6,
      objStoreOpeningConditions.lastOneYearNippan5,
      objStoreOpeningConditions.lastOneYearNippan4,
      objStoreOpeningConditions.lastOneYearNippan3,
      objStoreOpeningConditions.lastOneYearNippan2,
      objStoreOpeningConditions.lastOneYearNippan1,
      objStoreOpeningConditions.lastOneYearNippan0
    ];
    //空白でない値をフィルタリング
    const nippan0ToRange11Values = nippan0ToRange11.filter(value => value !== '');
    //空白を除いた値で合計
    const calcNippanTotal = nippan0ToRange11Values.reduce((acc, value) => acc + parseInt(value), 0);
    //空白を除いた値で平均
    const calcNippanAverage = nippan0ToRange11Values.length > 0 ? calcNippanTotal / nippan0ToRange11Values.length : 0;
    dispatch(setNippanAverage(calcNippanAverage));

    //前年比 平均を更新
    const onYear0RangeTo11 = [
      objStoreOpeningConditions.yearOnYear11,
      objStoreOpeningConditions.yearOnYear10,
      objStoreOpeningConditions.yearOnYear9,
      objStoreOpeningConditions.yearOnYear8,
      objStoreOpeningConditions.yearOnYear7,
      objStoreOpeningConditions.yearOnYear6,
      objStoreOpeningConditions.yearOnYear5,
      objStoreOpeningConditions.yearOnYear4,
      objStoreOpeningConditions.yearOnYear3,
      objStoreOpeningConditions.yearOnYear2,
      objStoreOpeningConditions.yearOnYear1,
      objStoreOpeningConditions.yearOnYear0
    ];
    //空白でない値をフィルタリング
    const onYear0RangeTo11Values = onYear0RangeTo11.filter(value => value !== '');
    //空白を除いた値で合計
    const calcOnYearTotal = onYear0RangeTo11Values.reduce((acc, value) => acc + parseFloat(value), 0);
    //空白を除いた値で平均
    const calcOnYearAverage = onYear0RangeTo11Values.length > 0 ? calcOnYearTotal / onYear0RangeTo11Values.length : 0;
    dispatch(setOnYearAverage(calcOnYearAverage));

    const avgCulcNippan = avgCulcNippanFunc(nippan0ToRange11, onYear0RangeTo11);
    const avgCulcPrevRatio = avgCulcPrevRatioFunc(objMonthHeader, onYear0RangeTo11, objStoreOpeningConditions.yearMonth);

    return [avgCulcNippan, avgCulcPrevRatio, calcNippanAverage];
  };

  //S店情報を表示
  const sStoreInfoShow: boolean = (
    selectedSurveyType === "02"  //セットバック（改造後）
    || selectedSurveyType === "03"  //立地評価（タイプ変更後）
    || selectedSurveyType === "04"  //S＆B（B店）
  );

  //ロングテキスト入力制御 
  const handleKeyDown = (e: any, labelName: string) => {
    if (labelName === 'content' && e.key === 'Enter' && e.target.value.split('\n').length >= 1) {
      e.preventDefault();
    }
  };

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  //直近確定月のプルダウン値 現在の日付から6か月分取得
  const getSixMonths = () => {
    const sixMonths: string[] = [];
    for (let i = 0; i < 6; i++) {
      const month = dayjs().subtract(i, 'month').format('YYYY年M月');
      sixMonths.push(month);
    }
    //6か月分取得中にDBへ登録した'YYYY年M月'がない場合追加
    if (!sixMonths.includes(sixMonthPassed)) {
      sixMonths.push(sixMonthPassed);
    }
    return sixMonths;
  };

  useEffect(() => {
    //直近確定月のプルダウン値
    const monsths6 = getSixMonths();
    dispatch(setMonthSelect(monsths6));
    //直近確定月に応じて実績取得期間​を自動入力 2024年8月の場合は2023年9月～2024年8月
    const { year, month } = parseDateString(objStoreOpeningConditions.lastfixedMonth);
    const yearMonth = dayjs(`${year}-${month}`);
    //実績取引期間を更新
    dispatch(setAchievementAcquisition({
      ...objStoreOpeningConditions,
      achievementAcquisitionStart: yearMonth.subtract(1, 'years').add(1, 'months').format('YYYY年M月'),
      achievementAcquisitionEnd: yearMonth.format('YYYY年M月')
    }));
  }, [objStoreOpeningConditions.lastfixedMonth]);

  useEffect(() => {
    //休業日数の計算
    const diffDate = closingDaysFunc(objCover.openDueDate, objStoreOpeningConditions.sStoreCloseDueDate);
    dispatch(setClosingDays({ ...objStoreOpeningConditions, closingDays: String(diffDate) }));
  }, [objCover.openDueDate, objStoreOpeningConditions.sStoreCloseDueDate]);

  useEffect(() => {
    //平均日販の共通処理
    const [avgCulcNippan, avgCulcPrevRatio, calcNippanAverage] = averageNippanCommon();
    //RFCの場合、平均日販を計算
    if (userType === 'z003') {
      //avgCulcNippan または avgCulcPrevRatioの値がない場合「全月の日販の平均」
      if (avgCulcNippan === '' || avgCulcPrevRatio === '') {
        dispatch(setObjStoreOpeningConditions({
          ...objStoreOpeningConditions,
          averageNippan: Math.round(Number(calcNippanAverage)).toString()
        }));
        //avgCulcNippan * avgCulcPrevRatio / 100
      } else {
        dispatch(setObjStoreOpeningConditions({
          ...objStoreOpeningConditions,
          averageNippan: Math.round(Number(avgCulcNippan) * Number(avgCulcPrevRatio) / 100).toString()
        }));
      }
    }
  }, [
    objStoreOpeningConditions.lastOneYearNippan11,
    objStoreOpeningConditions.lastOneYearNippan10,
    objStoreOpeningConditions.lastOneYearNippan9,
    objStoreOpeningConditions.lastOneYearNippan8,
    objStoreOpeningConditions.lastOneYearNippan7,
    objStoreOpeningConditions.lastOneYearNippan6,
    objStoreOpeningConditions.lastOneYearNippan5,
    objStoreOpeningConditions.lastOneYearNippan4,
    objStoreOpeningConditions.lastOneYearNippan3,
    objStoreOpeningConditions.lastOneYearNippan2,
    objStoreOpeningConditions.lastOneYearNippan1,
    objStoreOpeningConditions.lastOneYearNippan0,
    objStoreOpeningConditions.yearOnYear11,
    objStoreOpeningConditions.yearOnYear10,
    objStoreOpeningConditions.yearOnYear9,
    objStoreOpeningConditions.yearOnYear8,
    objStoreOpeningConditions.yearOnYear7,
    objStoreOpeningConditions.yearOnYear6,
    objStoreOpeningConditions.yearOnYear5,
    objStoreOpeningConditions.yearOnYear4,
    objStoreOpeningConditions.yearOnYear3,
    objStoreOpeningConditions.yearOnYear2,
    objStoreOpeningConditions.yearOnYear1,
    objStoreOpeningConditions.yearOnYear0,
    objStoreOpeningConditions.yearMonth
  ]);

  useEffect(() => {
    //ReduxをuseStateに保存
    props.setStoreOpeningConditions(objStoreOpeningConditions);
  }, [objStoreOpeningConditions]);

  return (
    <Box>
      {/* S店情報 */}
      {sStoreInfoShow && (
        <Box>
          <Typography component="h2" mt={2}>S店情報</Typography>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>既存店名​</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={props.storeOpeningConditions.existingStoreName}
                    sx={inactiveTextSx}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      endAdornment: <InputAdornment position="end"></InputAdornment>,
                    }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>店NO</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={props.storeOpeningConditions.storeNo}
                    sx={inactiveTextSx}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      endAdornment: <InputAdornment position="end"></InputAdornment>,
                    }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography width={100}>平均日販​</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'averageNippan'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'average_nippan',
                    labelName: 'averageNippan',
                    startAdornment: '',
                    endAdornment: '千円',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
                <Button
                  sx={{ margin: '20px 0 20px 40px' }}
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'averageNippanButton')}
                  onClick={() => handleAverageNippanButtonClick()}
                >平均日販再計算</Button>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={8}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>実績取得期間​</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={props.storeOpeningConditions.achievementAcquisitionStart}
                    sx={inactiveTextSx}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      endAdornment: <InputAdornment position="end"></InputAdornment>,
                    }}
                    variant="filled"
                  >
                  </TextField>
                </Grid>
                <Grid>～</Grid>
                <Grid>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={props.storeOpeningConditions.achievementAcquisitionEnd}
                    sx={inactiveTextSx}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      endAdornment: <InputAdornment position="end"></InputAdornment>,
                    }}
                    variant="filled"
                  >
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={4}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Typography width={180}>受付NO</Typography>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'receptionNo'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'reception_no',
                    labelName: 'receptionNo',
                    startAdornment: '',
                    endAdornment: '',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>S店開店日</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                  <Grid sx={itemValueSx}>
                    <TextField
                      fullWidth
                      type="date"
                      value={objStoreOpeningConditions.sStoreOpenDate}
                      sx={activeInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreOpenDate') : storeOpeningConditionsReadOnlyObj.sStoreOpenDate)}
                      onChange={(e) => handleInputChange(e, 'sStoreOpenDate')}
                      InputProps={{
                        readOnly: userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreOpenDate') : storeOpeningConditionsReadOnlyObj.sStoreOpenDate,
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                      }}
                      variant="filled"
                    />
                  </Grid>
                  {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                  {userType === 'z001' &&
                    <Grid sx={{ width: '120px' }}>
                      <IconButton
                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreOpenDate')}
                        onClick={() => { handleChatBubbleClick('s_store_open_date', 'sStoreOpenDate') }}
                        sx={iconHover}
                      >
                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreOpenDate'))}>
                          <ChatBubbleIcon sx={chatBubbleIconSx} />
                        </Box>
                      </IconButton>
                      <IconButton
                        onClick={() => { handleEditIconClick('sStoreOpenDate') }}
                        sx={iconHover}
                      >
                        <Box sx={editIconCircleSx}>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>S店閉店予定日</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                  <Grid sx={itemValueSx}>
                    <TextField
                      fullWidth
                      type="date"
                      value={objStoreOpeningConditions.sStoreCloseDueDate}
                      sx={activeInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreCloseDueDate') : storeOpeningConditionsReadOnlyObj.sStoreCloseDueDate)}
                      onChange={(e) => handleInputChange(e, 'sStoreCloseDueDate')}
                      InputProps={{
                        readOnly: userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreCloseDueDate') : storeOpeningConditionsReadOnlyObj.sStoreCloseDueDate,
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                      }}
                      variant="filled"
                    />
                  </Grid>
                  {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                  {userType === 'z001' &&
                    <Grid sx={{ width: '120px' }}>
                      <IconButton
                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreCloseDueDate')}
                        onClick={() => { handleChatBubbleClick('s_store_close_due_date', 'sStoreCloseDueDate') }}
                        sx={iconHover}
                      >
                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreCloseDueDate'))}>
                          <ChatBubbleIcon sx={chatBubbleIconSx} />
                        </Box>
                      </IconButton>
                      <IconButton
                        onClick={() => { handleEditIconClick('sStoreCloseDueDate') }}
                        sx={iconHover}
                      >
                        <Box sx={editIconCircleSx}>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>休業日数</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <TextField
                    fullWidth
                    disabled={true}
                    value={objStoreOpeningConditions.closingDays}
                    sx={inactiveTextSx}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                      endAdornment: <InputAdornment position="end">日</InputAdornment>,
                    }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>営業中工事日数</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'inSalesConstructionDays'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'in_sales_construction_days',
                    labelName: 'inSalesConstructionDays',
                    startAdornment: '',
                    endAdornment: '日',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>車共通比</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'carCommonRatio'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'car_common_ratio',
                    labelName: 'carCommonRatio',
                    startAdornment: '',
                    endAdornment: '%',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>人共通比</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'personCommonRatio'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'person_common_ratio',
                    labelName: 'personCommonRatio',
                    startAdornment: '',
                    endAdornment: '%',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>S店との直線距離</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreLineDistance'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 's_store_line_distance',
                    labelName: 'sStoreLineDistance',
                    startAdornment: '',
                    endAdornment: 'm',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>S店との道なり距離</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreWayDistance'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 's_store_way_distance',
                    labelName: 'sStoreWayDistance',
                    startAdornment: '',
                    endAdornment: 'm',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>S店車客構成比</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreCarCustomerRatio'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 's_store_car_customer_ratio',
                    labelName: 'sStoreCarCustomerRatio',
                    startAdornment: '',
                    endAdornment: '%',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>直近確定月</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                  <Grid sx={itemValueSx}>
                    <FormControl fullWidth>
                      <Select
                        onChange={(e) => handleSelectChange(e, 'lastfixedMonth')}
                        value={objStoreOpeningConditions.lastfixedMonth}
                        sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastfixedMonth') : storeOpeningConditionsReadOnlyObj.lastfixedMonth)}
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastfixedMonth') : storeOpeningConditionsReadOnlyObj.lastfixedMonth}
                      >
                        {listMonthSelect.map((month, index) => (
                          <MenuItem key={index} value={month}>{month}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                  {userType === 'z001' &&
                    <Grid sx={{ width: '120px' }}>
                      <IconButton
                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastfixedMonth')}
                        onClick={() => { handleChatBubbleClick('last_fixed_month', 'lastfixedMonth') }}
                        sx={iconHover}
                      >
                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastfixedMonth'))}>
                          <ChatBubbleIcon sx={chatBubbleIconSx} />
                        </Box>
                      </IconButton>
                      <IconButton
                        onClick={() => { handleEditIconClick('lastfixedMonth') }}
                        sx={iconHover}
                      >
                        <Box sx={editIconCircleSx}>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <TableContainer sx={{ display: 'flex' }}>
            <Table sx={table14Column} >
              <TableHead>
                {/* ヘッダー */}
                <TableRow>
                  <TableCell sx={labelStyle}></TableCell>
                  {objMonthHeader.length > 0 ? (
                    <>
                      {objMonthHeader.map((month: any) => (
                        <TableCell key={month.colName} sx={labelStyle}>{month.label}</TableCell>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                      <TableCell sx={labelStyle}></TableCell>
                    </>
                  )}
                  <TableCell sx={labelStyle}>平均</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={tableCellStyle}>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                    {lineBreak('直近1年日販:（千円）')}
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan11}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan11')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan11')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan10}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan10')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan10')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan9}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan9')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan9')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan8}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan8')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan8')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan7}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan7')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan7')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan6}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan6')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan6')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan5}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan5')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan5')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan4}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan4')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan4')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan3}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan3')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan3')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan2}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan2')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan2')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan1}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan1')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan1')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'lastOneYearNippan') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.lastOneYearNippan0}
                      onChange={(e) => handleLastOneYearNippanChange(e, 'lastOneYearNippan0')}
                      onBlur={(e) => handleLastOneYearNippanBlur(e, 'lastOneYearNippan0')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={true}
                      sx={TextFieldSx}
                      value={Math.round(nippanAverage)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={tableCellStyle}>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
                    {lineBreak('前年比:（%）')}
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear11}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear11')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear11')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear10}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear10')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear10')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear9}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear9')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear9')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear8}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear8')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear8')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear7}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear7')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear7')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear6}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear6')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear6')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear5}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear5')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear5')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear4}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear4')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear4')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear3}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear3')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear3')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear2}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear2')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear2')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear1}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear1')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear1')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      fullWidth
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison}
                      sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearOnYear') : storeOpeningConditionsReadOnlyObj.sStoreDailySalesAndPreYearComparison)}
                      value={props.storeOpeningConditions.yearOnYear0}
                      onChange={(e) => handleYearOnYearChange(e, 'yearOnYear0')}
                      onBlur={(e) => handleYearOnYearBlur(e, 'yearOnYear0')}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TextField
                      disabled
                      fullWidth
                      sx={TextFieldSx}
                      value={(Math.round(onYearAverage * 100) / 100).toFixed(1)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
            {userType === 'z001' &&
              <Grid sx={{ width: '115px' }}>
                <IconButton
                  disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreDailySalesAndPreYearComparison')}
                  onClick={() => { handleChatBubbleClick('s_store_daily_sales_and_pre_year_comparison', 'sStoreDailySalesAndPreYearComparison') }}
                  sx={iconHover}
                >
                  <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'sStoreDailySalesAndPreYearComparison'))}>
                    <ChatBubbleIcon sx={chatBubbleIconSx} />
                  </Box>
                </IconButton>
                <IconButton
                  onClick={() => { handleEditIconClick('sStoreDailySalesAndPreYearComparison') }}
                  sx={iconHover}
                >
                  <Box sx={editIconCircleSx}>
                    <EditIcon />
                  </Box>
                </IconButton>
              </Grid>
            }
          </TableContainer>


          <Typography component="h2" mt={2}>直近１年以内に起きた環境変化及び前月との前年比さが１０%以上ある場合は必ず変化要因を記入して下さい。​</Typography>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>年月​</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                  <Grid sx={itemValueSx}>
                    <FormControl fullWidth>
                      <Select
                        onChange={(e) => handleSelectChange(e, 'yearMonth')}
                        value={objStoreOpeningConditions.yearMonth}
                        sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearMonth') : storeOpeningConditionsReadOnlyObj.yearMonth)}
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearMonth') : storeOpeningConditionsReadOnlyObj.yearMonth}
                      >
                        {Array(12).fill(1).map((val, i) => {
                          return (
                            <MenuItem key={i} value={`${val + i}月`}>{`${val + i} 月`}</MenuItem>
                          )
                        })}
                        {/* プルダウンに空白を追加 */}
                        <MenuItem value="">　</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                  {userType === 'z001' &&
                    <Grid sx={{ width: '120px' }}>
                      <IconButton
                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearMonth')}
                        onClick={() => { handleChatBubbleClick('year_month', 'yearMonth') }}
                        sx={iconHover}
                      >
                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'yearMonth'))}>
                          <ChatBubbleIcon sx={chatBubbleIconSx} />
                        </Box>
                      </IconButton>
                      <IconButton
                        onClick={() => { handleEditIconClick('yearMonth') }}
                        sx={iconHover}
                      >
                        <Box sx={editIconCircleSx}>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>変化の種類​</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                  <Grid sx={itemValueSx}>
                    <FormControl fullWidth>
                      <Select
                        onChange={(e) => handleSelectChange(e, 'changeType')}
                        value={objStoreOpeningConditions.changeType}
                        sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'changeType') : storeOpeningConditionsReadOnlyObj.changeType)}
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'changeType') : storeOpeningConditionsReadOnlyObj.changeType}
                      >
                        {Object.keys(changeTypeItems).map((key) => (
                          <MenuItem key={key} value={changeTypeItems[key]}>{changeTypeItems[key]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                  {userType === 'z001' &&
                    <Grid sx={{ width: '120px' }}>
                      <IconButton
                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'changeType')}
                        onClick={() => { handleChatBubbleClick('change_type', 'changeType') }}
                        sx={iconHover}
                      >
                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'changeType'))}>
                          <ChatBubbleIcon sx={chatBubbleIconSx} />
                        </Box>
                      </IconButton>
                      <IconButton
                        onClick={() => { handleEditIconClick('changeType') }}
                        sx={iconHover}
                      >
                        <Box sx={editIconCircleSx}>
                          <EditIcon />
                        </Box>
                      </IconButton>
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}></Grid>
          </Grid>

          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                    <Typography>内容</Typography>
                    {/* 調査書種別がセットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid>
                  <LongTextFiledWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'content'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'content',
                    labelName: 'content',
                    rows: 1, //ロングテキストの行数
                    width: '500px',//テキストフィールドの幅
                    
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick,
                    handleKeyDown: handleKeyDown
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      {/*不備コメント モーダル画面 */}
      <DeficiencyCommentRegistModal
        props={{
          modalOpen: deficiencyComentModalFlag,
          setModalOpen: setDeficiencyComentModalFlag,
          CommentTextFieldObj: storeOpeningConditionsCommentTextFieldObj,
          columnName: columnNameStr,
          labelName: labelNameStr,
          handleCommentChange: handleCommentChange,
          handleSaveComment: handleSaveComment,
        }} />

    </Box>
  )
}

export default SstoreInfo;

import React from "react";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUtilityContext } from '../../../utility-provider';
import { setApprovalStatus } from "../../../features/approval5mcutSlice";
import { apiRequest, postPython } from "../../../api/ApiCall";
import { useRouteLoaderData } from "react-router-dom";
import MessageDialog from '../../components/MessageDialog';
import { LOCAL_CONSTANT } from "../../Const";

// import { LOCAL_CONSTANT } from "../page/Const";

export interface approval5mcutModalProps {
    props: {
        open: boolean,
        handleModal: any,
        planAreaId: string | null,
        branch: string | null,
        locationTraffic5mcut: any,
        locationConflictMarketCut: any,
        userData: any,
    }
}


const Approval5mcutModal: React.FC<approval5mcutModalProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState(false);
    const [hasDisplay, setHasDisplay] = React.useState('none');

    const objApproval5mcut = useAppSelector((state) => state.approval5mcut.approvalStatus);
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
        }
    }, [props.open])

    const handleClose = () => {
        dispatch(setApprovalStatus({
            ...objApproval5mcut,
            "fiveMeters": "",
            "marketCut": "",
        }));
        // dispatch(setApprovalStatus({ ...objApproval5mcut, ["marketCut"]: "" }));
        setOpen(false)
        props.handleModal()
    }

    // const applovalForm = {

    // }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
        // if (labelName === '5m') {
        //     applovalForm['5m'] = e.target.value
        // }
        // if (labelName === 'marketCut') {
        //     applovalForm['marketCut'] = e.target.value
        // }
        console.log(objApproval5mcut)
        dispatch(setApprovalStatus({ ...objApproval5mcut, [labelName]: e.target.value }));
    }

    const handleDisabled = () => {
        let disabled = false
        if (props.locationTraffic5mcut.excel_path !== '' && objApproval5mcut.fiveMeters === '') {
            disabled = true
        }

        if (props.locationConflictMarketCut.excel_path !== '' && objApproval5mcut.marketCut === '') {
            disabled = true
        }

        return disabled
    }

    const onSubmit = () => {
        console.log(objApproval5mcut)

        if (
            (props.locationTraffic5mcut.excel_path !== '' && objApproval5mcut.fiveMeters === '0') ||
            (props.locationConflictMarketCut.excel_path !== '' && objApproval5mcut.marketCut === '0')
        ) {
            // メール
            console.log('// メール')
        } else {
            console.log('// 送信')
            onRegist()
        }
    }

    const onRegist = () => {
        (async () => {
            try {
                if (utilityCtx.showSpinner) {
                    utilityCtx.showSpinner()
                }
                // メッセージモーダル表示
                setHasButton(false);
                setMessage("承認中です");
                setMessageDialogOpen(true);
                const managerName = props.userData.user_name
                let department = ""
                const staffMasterObj = localStorage.getItem("staffMaster");
                if (staffMasterObj !== null) {
                    const jsonStaffMasterObj = JSON.parse(staffMasterObj);
                    jsonStaffMasterObj.map((row: any) => {
                        if (row.department_code_oa === props.userData.department_code_OA) {
                            department = row.department_name_oa
                        }
                    })
                }

                // {
                //     "staff_number": "s350812",
                //     "staff_name": "荻野 俊一",
                //     "department_code_oa": "992002",
                //     "department_name_oa": "リクルートリサーチ",
                //     "top_department_code_oa": "992000",
                //     "top_department_name_oa": "営業本部",
                //     "hq_department_code_oa": "992000",
                //     "hq_department_name_oa": "営業本部",
                //     "job_category_code_oa": "550",
                //     "job_category_name_oa": "担当"
                // }
                // {
                //     "job_category_code": "143",
                //     "user_type": "z003",
                //     "department_code_OA": "950404",
                //     "daily_regist_existing_flag": "0",
                //     "daily_regist_plan_flag": "0",
                //     "corporate_flag": "ALL",
                //     "firebase_id": "mHQqumoXO3hgRcqDswCigZsUV0H3",
                //     "corporate_create_flag": "1",
                //     "substitute_staff_number": "k000196",
                //     "manage_do": [
                //         {
                //             "lat": 35.681165488505236,
                //             "lng": 139.76738613786307,
                //             "code": "00001",
                //             "name": "東京テスト用"
                //         },
                //         {
                //             "code": "00141",
                //             "lng": 140.10361687622,
                //             "lat": 39.7201218305603,
                //             "name": "秋田南"
                //         },
                //         {
                //             "name": "浦安",
                //             "code": "00236",
                //             "lat": 35.7221840336391,
                //             "lng": 139.93050104152
                //         },
                //         {
                //             "lat": 36.3147796611378,
                //             "code": "00183",
                //             "name": "小山",
                //             "lng": 139.800576839515
                //         },
                //         {
                //             "code": "00244",
                //             "lng": 139.716019483669,
                //             "lat": 35.5614166812925,
                //             "name": "目黒"
                //         },
                //         {
                //             "lng": 139.490880968321,
                //             "lat": 35.3389565202483,
                //             "code": "00280",
                //             "name": "藤沢"
                //         },
                //         {
                //             "name": "テスト用",
                //             "lat": 35.3389565202483,
                //             "code": "00099",
                //             "lng": 139.490880968321
                //         }
                //     ],
                //     "mode": "Administrator",
                //     "user_name": "デモ二郎",
                //     "expansion_polygon_flag": "1",
                //     "top_department_code_OA": "950400",
                //     "update_date": {
                //         "seconds": 1631668481,
                //         "nanoseconds": 652000000
                //     },
                //     "zone_strategy_flag": "1",
                //     "administrator_flag": "1",
                //     "manage_steps": [
                //         "0",
                //         "1",
                //         "2",
                //         "3",
                //         "4",
                //         "5",
                //         "6",
                //         "8",
                //         "44",
                //         "99",
                //         "7"
                //     ],
                //     "department_code": "950404",
                //     "manage_plans_outside_cities": [
                //         "9f884cb2f8"
                //     ],
                //     "staff_number": "a999033",
                //     "daily_regist_owner_flag": "0",
                //     "a_test_flag": true,
                //     "job_category_code_OA": "360",
                //     "sales_display_flag": "1",
                //     "manage_cities": [
                //         "13101",
                //         "13103",
                //         "13105",
                //         "ALL"
                //     ],
                //     "email": "rview-demo-2@rviewdevteam12345.onmicrosoft.com"
                // }
                const paythonParams = {
                    mode: "regist",
                    endPoint: "/make-iner",
                    query: {
                        prop_site_no: props.planAreaId,
                        branch: props.branch,
                        managerName: managerName,
                        department: department,
                    }
                };
                console.log(paythonParams)
                const result = await postPython(paythonParams)
                console.log(result)

                if (result.data.data.inei_file_path) {
                    const params = {
                        mode: "regist",
                        endPoint: "/location/Reception/v1/approval5mcut",
                        query: {
                            plan_area_id: props.planAreaId,
                            branch: props.branch,
                        }
                    };
                }



                // await apiRequest(params);
                //   setPageing(2);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setHasButton(true);
                setMessage("承認しました");
            } catch (e) {
                console.log(e);
                setHasButton(true);
                setMessage("容認に失敗しました");
                //   setErrorComment("送信に失敗しました");
                //   setPageing(3);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const closeMessageDialog = () => {
        handleClose()
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{ display: messageDialogOpen ? 'none' : 'block' }}
            >
                <DialogContent>
                    <Box sx={{ display: props.locationTraffic5mcut.excel_path !== "" ? 'block' : 'none' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>「5m外」を承認しますか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'fiveMeters')}
                            value={objApproval5mcut.fiveMeters}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'承認'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'差戻'}
                            />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ display: props.locationConflictMarketCut.excel_path !== "" ? 'block' : 'none' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>「商圏カット」を承認しますか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'marketCut')}
                            value={objApproval5mcut.marketCut}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'承認'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'差戻'}
                            />
                        </RadioGroup>
                    </Box>


                    <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={handleClose}
                            >キャンセル</Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={handleDisabled()}
                                onClick={onSubmit}
                            >送信</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }} />
        </div>
    );
}

export default Approval5mcutModal;



import {
    zIndexOrder,
} from "../Interface";
import { getCvsBrandCodeValue } from "../../../utility/codeMaster"

export const putMarker = (data: any, target: string) => {
    // marker
    var marker = new google.maps.Marker({
    position:
    {
        lat: data.centerlat,
        lng: data.centerlon,
    },
    });
  
    let iconUrl = ''
    let x = 0
    let y = 0
    let zIndex = 0
    if (target === 'sej') {
      iconUrl = process.env.PUBLIC_URL + '/image/icons/sej_normal.png'
      x = 10
      y = 10
      zIndex = zIndexOrder.existingSejStoreIcon
    } else if (target === 'sejSvg') {
      iconUrl = process.env.PUBLIC_URL + '/image/icons/sej_normal.svg'
      x = 20
      y = 40
      zIndex = zIndexOrder.existingSejSvgStoreIcon
    } else if (target === 'conflict') {
      let date = new Date();
      let nowY4M =
        date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0");
      let nowY2M =
        date.getFullYear().toString().substring(2, 4) +
        (date.getMonth() + 1).toString().padStart(2, "0");
      iconUrl = setInnerConflictIconPath(
        data.bland_id,
        data.handle_category_tobacco,
        data.open_date === nowY4M || data.open_date === nowY2M ? "1" : "0",
      )
      x = 10
      y = 14
      if (iconUrl.indexOf('conflict_normal.svg')) {
        x = 5
        y = 2
      }
      zIndex = zIndexOrder.conflictStoreIcon
      if (data.bland_id.length > 3) {
        zIndex = zIndexOrder.closedConflictStoreIcon
      }
    }
  
    marker.setOptions({
        icon: {
            url: iconUrl,
            anchor: new google.maps.Point(x, y),
        },
        zIndex: zIndex
    })
  
    return marker
  }
  
export const clearMarker = (list: any) => {
  list.forEach((data: any) => {
    data.marker.setMap(null)
  })
}
  
  /**
   * 競合店アイコンの設定
   * @param bland_id 
   * @param isTobacco 
   * @param isNewShop 
   * @returns 
   */
  const setInnerConflictIconPath = (bland_id: string, isTobacco: string, isNewShop: string) => {
    if (isTobacco === "1" && isNewShop === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco_new.svg";
    } else if (isTobacco === "1" && String(bland_id).length === 4) {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed_tobacco.svg";
    } else if (isTobacco === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco.svg";
    } else if (isNewShop === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal_new.svg";
    } else if (String(bland_id).length === 4) {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed.svg";
    } else {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal.svg";
    }
  }
import { createSlice } from '@reduxjs/toolkit';
import { initMapPdfObj } from '../data/type';


export const mapPdfSlice = createSlice({
    name: 'mapPdf',
    initialState: initMapPdfObj,
    reducers:{
        setMapPdf: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setMapPdf
} = mapPdfSlice.actions;
export default mapPdfSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
import { initApproval5mcutObj } from '../data/type';

//Sliceの作成
export const approval5mcutSlice = createSlice({
    name: 'approval5mcut',
    //初期State(Typeからインポート)
    initialState: {
        approvalStatus: initApproval5mcutObj,
    },
    //toolKitを利用した値変更処理
    reducers: {
        setApprovalStatus: (state, action) => {
            state.approvalStatus = action.payload;
        },
    }
});

export const {
    setApprovalStatus,
    // setPlanAreaInfo
} = approval5mcutSlice.actions;
export default approval5mcutSlice.reducer;


import * as React from 'react';
import { Box } from '@mui/material';
import AddCover from '../subPage/AdditionalResearch/AddCover';
import AddDescriptionDocument from '../subPage/AdditionalResearch/AddDescriptionDocument';

interface AdditionalResearchProps {
    props:{
    }
}

const AdditionalResearch:React.FC<AdditionalResearchProps> = ({props}) => {
    return (
        <Box sx={{ width: "100%", margin: "30px" }}>
            <AddCover props={{}}/>
            <AddDescriptionDocument props={{}}/>
        </Box>
    );
}

export default AdditionalResearch;
import * as React from 'react';
import SettingReason from '../subPage/StoreOpeningConditions/SettingReason';
import SpecialNotes from '../subPage/StoreOpeningConditions/SpecialNotes';
import SstoreInfo from '../subPage/StoreOpeningConditions/SstoreInfo';
import {
    initStoreOpeningConditionsObj,
    StoreOpeningConditionsTypeList
} from '../../data/type';


export interface resultDialogProps {
    props:{
    }
}

const StoreOpeningConditions:React.FC<resultDialogProps> = ({props}) => {

    //出店条件ページの項目値をReduxへ登録する前に保存
    const [storeOpeningConditions, setStoreOpeningConditions] = React.useState<StoreOpeningConditionsTypeList>(initStoreOpeningConditionsObj);

    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <SettingReason props={{
                storeOpeningConditions:storeOpeningConditions,
                setStoreOpeningConditions:setStoreOpeningConditions}}/>
            <SpecialNotes props={{
                storeOpeningConditions:storeOpeningConditions,
                setStoreOpeningConditions:setStoreOpeningConditions}}/>
            <SstoreInfo props={{
                storeOpeningConditions:storeOpeningConditions,
                setStoreOpeningConditions:setStoreOpeningConditions}}/>
        </div>
    );
        
}


export default StoreOpeningConditions;

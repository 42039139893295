import dayjs from 'dayjs';

//図面ページAPI取得 計算式と小計のデータ加工
export const handDrawnLayoutDataFunc = (res:any) => {
    const DataList:any[] = [];
    res.map((data:any, index:number) => {
        const DataObj = {
            id: index,
            handDrawnLayoutFormula: data.handDrawnLayoutFormula,
            handDrawnLayoutSubTotal:data.handDrawnLayoutSubTotal
        };
        DataList.push(DataObj);
    });
    if (DataList.length > 0) {
        return DataList;
    } else {
        //hand_drawn_layoutの初期値を返す
        return [{
            id: 0,
            handDrawnLayoutFormula:"",
            handDrawnLayoutSubTotal:0
        }];
    }
};

//図面ページAPI取得
export const drawingData = (res:any) => {
    const DataObj = {
        uploadFilePaths:JSON.parse(res[0].upload_file_paths),
        isGreenGem:res[0].is_green_gem,
        isGrave:res[0].is_grave,
        isMedianStrip:res[0].is_median_strip,
        isBusStopBlackout:res[0].is_bus_stop_blackout,
        isInGreenGem:res[0].is_in_green_gem,
        isOtherTenantParking:res[0].is_other_tenant_parking,
        isExpresswayEtcPole:res[0].is_expressway_etc_pole,
        isBusLaneEtc:res[0].is_bus_lane_etc,
        isElectricityPole:res[0].is_electricity_pole,
        isSidewalk:res[0].is_sidewalk,
        isOneWayStreet:res[0].is_one_way_street,
        isTramTrack:res[0].is_tram_track,
        isElectricityPoleBranchLine:res[0].is_electricity_pole_branch_line,
        isCrosswalk:res[0].is_crosswalk,
        isNoEntryRoad:res[0].is_no_entry_road,
        isParkingMeter:res[0].is_parking_meter,
        isHighVoltageTowers:res[0].is_high_voltage_towers,
        isGuardrail:res[0].is_guardrail,
        isWaterwayEtc:res[0].is_waterway_etc,
        isOthersPlace:res[0].is_others_place,
        isHydrantEtc:res[0].is_hydrant_etc,
        isStreetTreesEtc:res[0].is_street_trees_etc,
        isRailroadCrossing:res[0].is_railroad_crossing,
        isOldRoad:res[0].is_old_road,
        isBillboardTowerEtc:res[0].is_billboard_tower_etc,
        isStreetLightsEtc:res[0].is_street_lights_etc,
        isBridge:res[0].is_bridge,
        isUnpavedRoad:res[0].is_unpaved_road,
        isGarbageDump:res[0].is_garbage_dump,
        isRoadSigns:res[0].is_road_signs,
        isOverpass:res[0].is_overpass,
        isUnmaintainedEtc:res[0].is_unmaintained_etc,
        isOther:res[0].is_other,
        otherLongText:res[0].other_long_text,
        handDrawnLayout:handDrawnLayoutDataFunc(JSON.parse(res[0].hand_drawn_layout)),
        handDrawnLayoutTotal:res[0].hand_drawn_layout_total,
        handDrawnLayoutTotalTsubo:res[0].hand_drawn_layout_total_tsubo
    };
    return DataObj;
};

//図面ページAPI登録 計算式と小計のデータ加工
export const hand_drawn_layout_Func = (res:any[]) => {
    const DataList:any[] = [];
    res.map((data:any) => {
        const DataObj = {
            handDrawnLayoutFormula: data.handDrawnLayoutFormula,
            handDrawnLayoutSubTotal:data.handDrawnLayoutSubTotal
        };
        DataList.push(DataObj);
    });
    return DataList;
};

//図面ページAPI登録
export const upsertDrawingParam = (objDrawing:any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Drawing/v1/regist",
        query: {
            plan_area_id:plan_area_id,
            branch:branch,
            upload_file_paths:JSON.stringify(objDrawing.uploadFilePaths),
            is_green_gem:objDrawing.isGreenGem,
            is_grave:objDrawing.isGrave,
            is_median_strip:objDrawing.isMedianStrip,
            is_bus_stop_blackout:objDrawing.isBusStopBlackout,
            is_in_green_gem:objDrawing.isInGreenGem,
            is_other_tenant_parking:objDrawing.isOtherTenantParking,
            is_expressway_etc_pole:objDrawing.isExpresswayEtcPole,
            is_bus_lane_etc:objDrawing.isBusLaneEtc,
            is_electricity_pole:objDrawing.isElectricityPole,
            is_sidewalk:objDrawing.isSidewalk,
            is_one_way_street:objDrawing.isOneWayStreet,
            is_tram_track:objDrawing.isTramTrack,
            is_electricity_pole_branch_line:objDrawing.isElectricityPoleBranchLine,
            is_crosswalk:objDrawing.isCrosswalk,
            is_no_entry_road:objDrawing.isNoEntryRoad,
            is_parking_meter:objDrawing.isParkingMeter,
            is_high_voltage_towers:objDrawing.isHighVoltageTowers,
            is_guardrail:objDrawing.isGuardrail,
            is_waterway_etc:objDrawing.isWaterwayEtc,
            is_others_place:objDrawing.isOthersPlace,
            is_hydrant_etc:objDrawing.isHydrantEtc,
            is_street_trees_etc:objDrawing.isStreetTreesEtc,
            is_railroad_crossing:objDrawing.isRailroadCrossing,
            is_old_road:objDrawing.isOldRoad,
            is_billboard_tower_etc:objDrawing.isBillboardTowerEtc,
            is_street_lights_etc:objDrawing.isStreetLightsEtc,
            is_bridge:objDrawing.isBridge,
            is_unpaved_road:objDrawing.isUnpavedRoad,
            is_garbage_dump:objDrawing.isGarbageDump,
            is_road_signs:objDrawing.isRoadSigns,
            is_overpass:objDrawing.isOverpass,
            is_unmaintained_etc:objDrawing.isUnmaintainedEtc,
            is_other:objDrawing.isOther,
            other_long_text:objDrawing.otherLongText,
            hand_drawn_layout:JSON.stringify(hand_drawn_layout_Func(objDrawing.handDrawnLayout)),
            hand_drawn_layout_total:objDrawing.handDrawnLayoutTotal,
            hand_drawn_layout_total_tsubo:tsuboFunc(objDrawing.handDrawnLayoutTotal)
        }
    }
    return params
}

//㎡から坪へ変換
export const tsuboFunc = (total:number) => {
    return total === 0 ? 0 : total*0.3025 
};


//図面ページAPI登録 PDFアップロード
export const upsertDrawingPdfParam = (objDrawing:any, plan_area_id:any, branch:any, listUploadFile:any, newUploadFile:any) => {
    const newListUploadFile = [].concat(listUploadFile, newUploadFile);
    const params = {
        mode: "regist",
        endPoint: "/location/Drawing/v1/regist",
        query: drawingPdfParamQuery(objDrawing, plan_area_id, branch, newListUploadFile)
    }
    return params
}

// 図面ページAPI登録 PDF削除
export const upsertDrawingDeletePdfParam = (objDrawing:any, plan_area_id:any, branch:any, newListUploadFile:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Drawing/v1/regist",
        query: drawingPdfParamQuery(objDrawing, plan_area_id, branch, newListUploadFile)
    }
    return params
}

//図面ページAPI登録 PDFアップロードとPDF削除時のquery
export const drawingPdfParamQuery = (objDrawing:any, plan_area_id:any, branch:any, uploadFilePaths:any) => {
    return {   
        plan_area_id:plan_area_id,
        branch:branch,
        upload_file_paths:JSON.stringify(uploadFilePaths),
        is_green_gem:objDrawing.isGreenGem,
        is_grave:objDrawing.isGrave,
        is_median_strip:objDrawing.isMedianStrip,
        is_bus_stop_blackout:objDrawing.isBusStopBlackout,
        is_in_green_gem:objDrawing.isInGreenGem,
        is_other_tenant_parking:objDrawing.isOtherTenantParking,
        is_expressway_etc_pole:objDrawing.isExpresswayEtcPole,
        is_bus_lane_etc:objDrawing.isBusLaneEtc,
        is_electricity_pole:objDrawing.isElectricityPole,
        is_sidewalk:objDrawing.isSidewalk,
        is_one_way_street:objDrawing.isOneWayStreet,
        is_tram_track:objDrawing.isTramTrack,
        is_electricity_pole_branch_line:objDrawing.isElectricityPoleBranchLine,
        is_crosswalk:objDrawing.isCrosswalk,
        is_no_entry_road:objDrawing.isNoEntryRoad,
        is_parking_meter:objDrawing.isParkingMeter,
        is_high_voltage_towers:objDrawing.isHighVoltageTowers,
        is_guardrail:objDrawing.isGuardrail,
        is_waterway_etc:objDrawing.isWaterwayEtc,
        is_others_place:objDrawing.isOthersPlace,
        is_hydrant_etc:objDrawing.isHydrantEtc,
        is_street_trees_etc:objDrawing.isStreetTreesEtc,
        is_railroad_crossing:objDrawing.isRailroadCrossing,
        is_old_road:objDrawing.isOldRoad,
        is_billboard_tower_etc:objDrawing.isBillboardTowerEtc,
        is_street_lights_etc:objDrawing.isStreetLightsEtc,
        is_bridge:objDrawing.isBridge,
        is_unpaved_road:objDrawing.isUnpavedRoad,
        is_garbage_dump:objDrawing.isGarbageDump,
        is_road_signs:objDrawing.isRoadSigns,
        is_overpass:objDrawing.isOverpass,
        is_unmaintained_etc:objDrawing.isUnmaintainedEtc,
        is_other:objDrawing.isOther,
        other_long_text:objDrawing.otherLongText,
        hand_drawn_layout:JSON.stringify(hand_drawn_layout_Func(objDrawing.handDrawnLayout)),
        hand_drawn_layout_total:objDrawing.handDrawnLayoutTotal,
        hand_drawn_layout_total_tsubo:tsuboFunc(objDrawing.handDrawnLayoutTotal)
    }
}
// 商圏画面テーブルのスタイル調整
export const table5Column = {
    width: '480px',
    marginBottom: '20px',
    border: '1px solid black',
};

export const table6Column = {
    width: '576px',
    marginBottom: '20px',
    border: '1px solid black',
};

export const table10Column = {
    width: '960px',
    border: '1px solid black',
};

export const table12Column = {
    width: '1152px',
    marginBottom: '20px',
    border: '1px solid black',
};

export const labelStyle = {
    width: '96px',
    height: '50px',
    color: "#000000",
    textAlign: 'center',
    border: '1px solid black',
    backgroundColor: "#D9D9D9",
};

export const labelHeaderStyle= {
    width: '96px',
    height: '50px',
    color: "#000000",
    textAlign: 'center',
    backgroundColor: "#D9D9D9",
    borderBottom: '1px solid black',
};

export const tableCellStyle = {
    width: '96px',
    height: '50px',
    border: '1px solid black',
    padding: '0px 0px',
    textAlign: 'center',
};
import { createSlice } from '@reduxjs/toolkit';
import { initPdfSummaryObjTypeList } from '../data/type';

//Sliceの作成
export const pdfSummarySlice = createSlice({
    name: 'addPdfSummary',
    //初期State(Typeからインポート)
    initialState: initPdfSummaryObjTypeList,

    //toolKitを利用した値変更処理
    reducers: {
        setPdfSummary: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setPdfSummary
} = pdfSummarySlice.actions;
export default pdfSummarySlice.reducer;
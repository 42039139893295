import * as React from 'react';

import {
    Box,
    Modal,
    Button,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { LOCAL_CONSTANT } from '../../Const';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export interface selectModalProps {
    props:{
      open:boolean;
      handleModal:React.Dispatch<React.SetStateAction<boolean>>;
      handleRegistColor:any;
      handleRegistModal:any;
      recreate:boolean;
    }
}

export const CAR_PEOPLE = '0';
export const CAR = '1';
export const PEOPLE = '2';
  
const SelectModal:React.FC<selectModalProps> = ({props}) => {
  // 初期
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState<string>("");

  React.useEffect(() => {
    if (props.open) {
        setOpen(true)
    }
  }, [props.open])

  // 閉じる
  const handleClose = () => {
    // 閉じる
    setOpen(false)
    setColor("")
    props.handleModal(false)
  };

  const handleRegist = () => {
    setOpen(false)
    setColor("")
    props.handleModal(false)
    props.handleRegistModal(props.recreate)
  }

  const handleSelectChange = (e: SelectChangeEvent<typeof color>) => {
    setColor(e.target.value)
    props.handleRegistColor(e.target.value)
  }


  return (
      <>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <Box sx={style}>
                <Box sx={{ margin: '20px'}}>
                  {LOCAL_CONSTANT.CONFIRM_MESSAGE.SALES_FORECASTS_COLOR}
                  <Box sx={{ margin: '10px 0', textAlign: 'center'}}>
                    <Select
                        onChange={handleSelectChange}
                        value={ color }
                        sx={{ width: '80%' }}
                      ><MenuItem value={"0"}>白</MenuItem>
                      <MenuItem value={"1"}>青</MenuItem>
                      <MenuItem value={"2"}>黄色</MenuItem>
                      <MenuItem value={"3"}>ピンク</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                      <Button
                          sx={{margin: '20px 0'}}
                          variant="outlined" color="error" size="small"
                          onClick={handleClose}
                      >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                      <Button
                          sx={{margin: '20px 0'}}
                          variant="contained" color="success" size="small"
                          onClick={handleRegist}
                      >{LOCAL_CONSTANT.LABEL.CREATE}</Button>
                  </Box>
                </Box>
              </Box>
          </Modal>
      </>
  );
}

export default SelectModal;
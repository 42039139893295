import { createSlice } from '@reduxjs/toolkit';
import { initAddConflictObj } from '../data/type';

//Sliceの作成
export const addConflictSlice = createSlice({
    name: 'addConflict',
    //初期State(Typeからインポート)
    initialState: initAddConflictObj,

    //toolKitを利用した値変更処理
    reducers: {
        setAddConflict: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setAddConflict
} = addConflictSlice.actions;
export default addConflictSlice.reducer;
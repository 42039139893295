import * as React from 'react';

import { putMarker } from "./viewDataFunction";

export const drawExistingSejConflictStore = (
    existingSejConflictStoreList: any,
    map: google.maps.Map,
) => {

    const tmpMarker:any[] = [];
    existingSejConflictStoreList.forEach((data: any) => {
        try{
            // marker
            let marker = null
            if (data.type === '0') {
                marker = putMarker(data, 'sej')
            } else {
                marker = putMarker(data, 'conflict') 
            }
            marker.setMap(map);

            tmpMarker.push({
              key: data.type === '0' ? data.store_code : data.id,
              data: data,
              marker: marker,
            })
        } catch (error) {
            console.log("------------------------")
            console.log("表示エラー")
            console.log(error)
            console.log(data)
            console.log("------------------------")
        }
    })

    return tmpMarker
}

export interface ExistingSejConflictStoreProps {
    props:{
    }
  }

const ExistingSejConflictStore:React.FC<ExistingSejConflictStoreProps> = ({props}) => {

    return (
        <></>
    );
        
}


export default ExistingSejConflictStore;

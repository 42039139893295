import React from "react";

import {
  Box,
  Button
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface confirmDialogProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    onCancel:any,
    onExecution:any,
    mode:string,
    body:string,
    confirmCancel:string,
    confirmOk:string,
    handlerResult?:any,
  }
}

const ConfirmDialog:React.FC<confirmDialogProps> = ({props}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.onCancel()
  };

  const handleOk = () => {
    setOpen(false);
    props.onExecution()
  };

  React.useEffect(() =>{
    if(props.open){
      setOpen(props.open);
    }
  },[props.open])

    return (
      <Box>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box sx={{ padding: '20px' }}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">{props.body}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-around'}}>
              <Button variant="outlined" color="error" onClick={handleClose}>{props.confirmCancel}</Button>
              <Button variant="contained" color="success" onClick={handleOk}>{props.confirmOk}</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    );
  
}

export default ConfirmDialog;



import * as React from 'react';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
} from '@mui/material';
import { LOCAL_CONSTANT } from "../Const";
import {
  tableStyle,
  tableHeaderStyle,
  tableCellStyle,
  tableCellStyleWhiteSpace,
  tableHeaderSmallStyle,
  tableCellSmallStyle,
  tableHeaderDateStyle,
  tableCellDateStyle,
  tableCellWhiteSpace,
  tableHeaderNameStyle,
  tableCellNameStyle,
} from "../components/ChangeHistoryTableStyle";
import { apiRequest } from "../../api/ApiCall";
import { useUtilityContext } from '../../utility-provider';
import { useSearchParams } from "react-router-dom";
import ChangeHistoryTableComment from "../components/ChangeHistoryTableComment";
import dayjs from 'dayjs';
import {
  changeHistoryTableName,
  changeHistoryColumnName,
  changeHistorySelecterDisplay,
} from "../components/ChangeHistoryFunc";

export interface ItemChangeHistoryProps {
  props:{
      width: number,
      height: number
  }
}

const commentHeaderStyle = {
  width: '925px',
  height: '50px',
  color: '#fff',
  border: '1px solid black',
  backgroundColor: '#00CC5B',
}

const commentCellStyle = {
  width: '925px',
  height: '50px',
  border: '1px solid black',
};

const ItemChangeHistory:React.FC<ItemChangeHistoryProps> = ({props}) => {
  const utilityCtx = useUtilityContext();
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  const [changeHistory, setChangeHistory] = React.useState([])
  const [comment, setComment] = React.useState([])
  const [tableNameMaster, setTableNameMaster] = React.useState<any>([])
  const [commentOpen, setCommentOpen] = React.useState<boolean>(false)
  const [codeMaster, setCodeMaster] = React.useState<any>();

  React.useEffect(() => {
    const tableNameMasterObj = localStorage.getItem("tableNameMaster");
    if (tableNameMasterObj) {
      setTableNameMaster(JSON.parse(tableNameMasterObj))
    }

    const res: any = localStorage.getItem("codeMaster")
    if (res) {
        const d = JSON.parse(res)
        setCodeMaster(d)
    }

    callChangeHistory()
  }, [])

  const callChangeHistory = () => {
    const params = {
      mode: "regist",
      endPoint: "/location/system/v1/get-log",
      query: {
        plan_area_id: planAreaId,
        log_type: "rfc"
      }
    };
    const commentParams = {
      mode: "regist",
      endPoint: "/location/system/v1/get-rr-comment",
      query: {
        plan_area_id: planAreaId,
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        const res = await apiRequest(params);
        setChangeHistory(res.data)
        const comment_res = await apiRequest(commentParams);
        setComment(comment_res.data)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };


  const change_details = (log_type: string) => {
    const change_details_list = [
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.ITEM_CHANGE,  // "0"
      "",
      "",
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.CONFLICT_CVS_CHANGE, // "3"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.CONFLICT_SMDS_CHANGE, // "4"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.IMPACT_INTERSECTION_CHANGE, // "5"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.SALES_FORECAST_SUMMARY_TABLE_CHANGE, // "6"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.ADDITIONAL_SURVEY_CHANGE, // "7"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.OTHER_DOCUMENT_CHANGE, // "8"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.RR_INTERIOR_CHANGE, // "9"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.CURRENT_STATUS_PHOTO_CHANGE, // "10"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.TRAFFIC_VOLUME_CHANGE, // "11"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.FIVEM_EXTRA_CALCULATION_BASIS_CHANGE, // "12"
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.TRADE_AREA_CUT_CHANGE, // 13
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.IMPACT_ASSESSMENT_CHANGE, // 14
      LOCAL_CONSTANT.SYSTEM_LOG_TYPE.MAP_CHANGE, // 15
    ]

    return change_details_list[Number(log_type)]
  }


  const handleCommentOpen = () => {
    setCommentOpen(true)
  }

  const handleSaveComment = () => {
      setCommentOpen(false)
      callChangeHistory()
  }

  const handleCanCelComment = () => {
      setCommentOpen(false)
  }

  const displayTime = (date: string) => {
    const time = new Date(date);
    time.setHours(time.getHours() + 9);
    return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item sx={{ width: props.width, height: props.height, overflow: "scroll" }}>
          <Grid item sx={{ marginTop: "10px", marginLeft: "10px"}}>
            <Typography>{LOCAL_CONSTANT.LABEL.RFC_ITEM_CHANGE_HISTORY}</Typography>
          </Grid> 
          <TableContainer sx={{
              height: '40%',
              minHeight: '40%',
              maxHeight: '40%',
              overflowY: 'auto',
          }}>
            <Table stickyHeader sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeaderDateStyle}>{LOCAL_CONSTANT.LABEL.DATE}</TableCell>
                  <TableCell sx={tableHeaderNameStyle}>修正者</TableCell>
                  <TableCell sx={tableHeaderSmallStyle}>{LOCAL_CONSTANT.LABEL.BRANCH}</TableCell>
                  <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.PAGE}</TableCell>
                  <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.ITEM}</TableCell>
                  <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.CHANGE_BEFORE}</TableCell>
                  <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.AFTER_CHANGE}</TableCell>
                  <TableCell sx={tableHeaderStyle}>{LOCAL_CONSTANT.LABEL.CHANGE_DETAILS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  changeHistory.map((row: any, index: number) => (
                    <TableRow key={row.primaryKey + "-time-" + row.publish_time + "-" + index}>
                      <TableCell sx={tableCellDateStyle}>{displayTime(row.publish_time)}</TableCell>
                      <TableCell sx={tableCellNameStyle}>{row.update_name}</TableCell>
                      <TableCell sx={tableCellSmallStyle}>{row.branch}</TableCell>
                      <TableCell sx={tableCellStyle}>{changeHistoryTableName(tableNameMaster, row.target_table, row)}</TableCell>
                      <TableCell sx={tableCellWhiteSpace}>{changeHistoryColumnName(tableNameMaster, row.target_column, row)}</TableCell>
                      <TableCell sx={tableCellStyle}>{changeHistorySelecterDisplay(codeMaster, row.target_column, row.target_before_value)}</TableCell>
                      <TableCell sx={tableCellStyle}>{changeHistorySelecterDisplay(codeMaster, row.target_column, row.target_after_value)}</TableCell>
                      <TableCell sx={tableCellStyle}>{change_details(row.log_type)}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item sx={{ marginTop: "10px", marginLeft: "10px"}}>
            <Typography>{LOCAL_CONSTANT.LABEL.RR_ITEM_CHANGE_HISTORY}
            <Button
              onClick={handleCommentOpen}
              variant="contained" color="success" size="small"
              sx={{ margin: '10px 20px' }}
            >
              {LOCAL_CONSTANT.LABEL.REGIST_COMMENT}
            </Button>
            </Typography>
          </Grid>
          <TableContainer sx={{
              height: '36%',
              minHeight: '36%',
              maxHeight: '36%',
              overflowY: 'auto',
          }}>
            <Table stickyHeader sx={tableStyle}>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeaderDateStyle}>{LOCAL_CONSTANT.LABEL.DATE}</TableCell>
                  <TableCell sx={tableHeaderNameStyle}>修正者</TableCell>
                  <TableCell sx={commentHeaderStyle}>{LOCAL_CONSTANT.LABEL.COMMENT}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  comment.map((row: any) => (
                    <TableRow key={row.publish_time}>
                      <TableCell sx={tableCellDateStyle}>{displayTime(row.publish_time)}</TableCell>
                      <TableCell sx={tableCellNameStyle}>{row.update_name}</TableCell>
                      <TableCell sx={tableCellStyleWhiteSpace}>{row.comment}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ChangeHistoryTableComment props={{
          open: commentOpen,
          params: {
              planAreaId: planAreaId,
              branch: branch,
              comment: "",
          },
          handleModal: setCommentOpen,
          handleSaveComment: handleSaveComment,
          handleCanCelComment: handleCanCelComment
      }} />
    </Box>
  );
};

export default ItemChangeHistory;
import { LOCAL_CONSTANT } from '../../Const'

export const longpage_status_check = (params: any) => {
    let check_message = ""

    if (
        params.objLocationBase.location_excel_flag === "" ||
        params.objLocationBase.location_excel_flag === "0"
    ) {
        // check_message += "・中間生成物が取り込まれていません\n"
        check_message += LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_LOCATION_EXCEL_FLAG
    }
    let over_count_date = false
    params.objTrafficVolume.map((row: any) => {
        var today = new Date();
        var dt = new Date(row.count_date);
        // 60日前
        today.setDate(today.getDate() - 60)
        // 60日前以前の場合メッセージ表示
        if(today > dt){
            over_count_date = true
        }
    })
    if  (over_count_date) {
        check_message += LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_60DAYS_OVER_COUNT_DATE
    }
    if  (params.objN15AddMarket.length === 0) {
        check_message += LOCAL_CONSTANT.ALERT_MESSAGE.ALERT_EMPTY_N15ADDMARKET
    }

    return check_message
}

const traffic_type = [
    "車朝",
    "車昼",
    "車夜",
    "車夜（モバ空）",
    "車休祝日",
    "車休祝日（モバ空）",
    "人朝",
    "人朝特定",
    "人昼",
    "人昼特定",
    "人夜",
    "人夜（モバ空）",
    "人夕方特定",
    "人夕方特定（モバ空）",
    "人休祝日",
    "人休祝日（モバ空））" 
]

export const changeHistoryTableName = (tableNameMaster: any, table_physics_name: string, logData: any) => {
    let table_logic_name = table_physics_name
    tableNameMaster.map((row: any) => {
      if (row.table_physics_name === table_physics_name) {
        table_logic_name = row.table_logic_name
      }
    })

    if (table_physics_name === 'location_conflict') {
        
      const subKey = JSON.parse(JSON.stringify(logData.sub_key).replaceAll('"', '').replaceAll("'", '\"'))
      if (subKey.conflict_type === '0') {
        table_logic_name = table_logic_name + "（CVS）"
      } else if (subKey.conflict_type === '1') {
        table_logic_name = table_logic_name + "（CVS）"
      } else if (subKey.conflict_type === '2') {
        table_logic_name = table_logic_name + "（SMDS）"
      } else if (subKey.conflict_type === '3') {
        table_logic_name = table_logic_name + "（SMDS）"
      }
    }

    return table_logic_name
}

export const changeHistoryColumnName = (tableNameMaster: any, column_physics_name: string, logData: any) => {
    let column_logic_name = column_physics_name
    tableNameMaster.map((row: any) => {
        if (row.column_physics_name === column_physics_name) {
            column_logic_name = row.column_logic_name
        }
    })

    if (logData.target_table === 'location_conflict') {
        const subKey = JSON.parse(JSON.stringify(logData.sub_key).replaceAll('"', '').replaceAll("'", '\"'))
        if (Object.keys(subKey).indexOf('tenpo_name') !== -1) {
            column_logic_name = subKey.tenpo_name + "<br>" + column_logic_name
        }
    }
    if (logData.target_table === 'location_traffic') {
        const subKey = JSON.parse(JSON.stringify(logData.sub_key).replaceAll('"', '').replaceAll("'", '\"'))
        column_logic_name = traffic_type[subKey.traffic_type] + "<br>" + column_logic_name
    }
    // 改行置換
    return column_logic_name.replaceAll("<br>", "\n")
}


export const changeHistorySelecterDisplay = (codeMaster: any, key: string, code_value: string) => {
    let code_display = code_value
    let search_key = key
    // common系の場合検索キーの変更
    if ([
        'handle_category_tobacco',
        'handle_category_liquor'
    ].includes(search_key)) {
        search_key = 'marubatu'
    } else if ([
            'handle_category_parking',
            'conflict_median_strip',
            'cut_survey',
        ].includes(search_key)) {
            search_key = 'common_yesnone_kana'
    } else if ([
            'median_strip_select',
            'median_strip_select2',
            'median_strip_select3',
            'median_strip_select4',
            'sidewalk_select',
            'sidewalk_select2',
            'sidewalk_select3',
            'sidewalk_select4',
            'traffic_signal_etc_forward_select',
            'traffic_signal_etc_forward_select2',
            'traffic_signal_etc_forward_select3',
            'traffic_signal_etc_forward_select4',
            'traffic_signal_etc_front_location_select',
            'traffic_signal_etc_front_location_select2',
            'traffic_signal_etc_front_location_select3',
            'traffic_signal_etc_front_location_select4',
        ].includes(search_key)) {
            search_key = 'common_yesnone'
    } else if ([
            'plan_area_shared_road',
            'conflict_shared_road',
        ].includes(search_key)) {
            search_key = 'common__shared_road'
    } else if ([
            'car_another_survey',
            'people_another_survey',
        ].includes(search_key)) {
            search_key = 'separate_investigation'
    } else if ([
            'others_facility_1',
            'others_facility_2',
            'others_facility_3',
            'others_facility_4',
        ].includes(search_key)) {
            search_key = 'others_facility'
    }
    if (codeMaster) {
        if (codeMaster[search_key] && code_value !== '' && code_value !== undefined) {
            codeMaster[search_key].filter((row: any) => {
                if (row.code_value === code_value.toString()) {
                    code_display = row.code_display
                }
            })
        }
    }

    return code_display.replaceAll("<br>", "\n")
}
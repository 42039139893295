import { createSlice } from '@reduxjs/toolkit';
import { initDeficiencyCommentList } from '../data/type';


export const deficiencyCommentSlice = createSlice({
    name: 'deficiencyComment',
    initialState: initDeficiencyCommentList,
    reducers: {
        setDeficiencyComment: (state, action) => {
            return action.payload;
        },
        updateCell: (state, action) => {
            const objIndex = action.payload.id;
            state[objIndex] = {
                ...state[objIndex],
                    comment:action.payload.comment,                    //コメント
                    deficiencyStatus: action.payload.deficiencyStatus, //不備ステータス
                    editStatus: action.payload.editStatus,             //編集の可否 trueが編集不可 falseが編集可
            };
        },
        updateCellSave: (state, action) => {
            const objIndex = action.payload.id;
            state[objIndex] = {
                ...state[objIndex],
                    comment:action.payload.comment,                    //コメント
                    initComment:action.payload.comment,                //初期コメントを更新
                    deficiencyStatus: action.payload.deficiencyStatus, //不備ステータス
                    editStatus: action.payload.editStatus,             //編集の可否 trueが編集不可 falseが編集可
            };
        }
    }
});

export const {
    setDeficiencyComment,
    updateCell,
    updateCellSave
} = deficiencyCommentSlice.actions;
export default deficiencyCommentSlice.reducer;
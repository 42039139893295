// トークン取得
export const getAccessToken= () => {
    let access_token = "?access_token="
    const objectJSON = sessionStorage.getItem('token');
    if (objectJSON) {
        access_token = "?access_token=" + JSON.parse(objectJSON) + "";
    }

    return access_token
}

// スタッフ名取得
export const staffName = (code: string) => {
    let name = ""
    const res: any = localStorage.getItem("staffMaster")
    if (res) {
        // const d = JSON.parse(res)
        JSON.parse(res)?.map((row: any) => {
            if (row.staff_number === code) {
              name = row.staff_name
            }
        })
    }

    return name
}
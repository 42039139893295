import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useUserContext } from '../../user-provider';
import PdfViewer from '../../utility/PdfViewer';
import { useUtilityContext } from '../../utility-provider';
import { apiRequest } from '../../api/ApiCall';
import {
  setDrawingPdf,
  setUploadPdf
} from '../../features/drawingSlice';
import {
  upsertDrawingPdfParam,
  upsertDrawingDeletePdfParam
} from '../subPage/Drawing/DrawingFunction';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  } from '@mui/material';
import { LOCAL_CONSTANT } from "../Const";
import ObstaclesEtc from '../subPage/Drawing/ObstaclesEtc';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  getFileMetadata,
  uploadFile,
  deleteUploadFile,
  getFileData
} from '../../utility/storage';
import MessageDialog from '../components/MessageDialog';
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import { uploadFileJsonPathFunc } from '../components/LocationBaseFunc';
import { setPermissionFunc } from '../components/PermissionFunc';
import { staffName } from '../Common';
import { DrawingStringTypeList, initDrawingCommentTextFieldObj } from '../../data/type';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DeficiencyCommentRegistModal from '../components/DeficiencyCommentRegistModal';
import {
  chatBubbleCircleSx,
  chatBubbleIconSx,
  iconHover
} from '../components/ChatBubbleAndEditIconStyle';

const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color : 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};

export interface resultDialogProps {
  props:{
  };
};

const Drawing: React.FC<resultDialogProps> = ({ props }) => {

  const objDrawing = useAppSelector((state) => state.drawing.obj);

  //ユーザーIDを取得
  const [userId, setUserId] = useState<string>("");
  const [userType, setUserType] = useState('');
  const user = useUserContext();
  
  const utilityCtx = useUtilityContext();

  const [message, setMessage] = React.useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
  const [hasButton, setHasButton] = React.useState<boolean>(false);

  //図面アップロードファイルリスト
  const listUploadFile = useAppSelector((state) => state.drawing.obj.uploadFilePaths);
  //プレビュー
  const previewPdfPath = useAppSelector((state) => state.drawing.pdfPath);

  //候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //タスクIDを取得するため
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

  const dispatch = useAppDispatch();

  //モーダル開閉フラグ(削除確認)
  const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
  const [path, setPath] = useState<string>("");
  const [date, setDate] = useState<string>("");
  //不備コメントモーダル項目名
  const [columnNameStr, setColumnNameStr] = useState<string>("");
  //不備コメントモーダルラベル名
  const [labelNameStr, setLabelNameStr] = useState<string>("");
  //不備コメントモーダル開閉フラグ
  const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);
  //不備コメントモーダル内、TextFieldのコメント
  const [drawingCommentTextFieldObj, setDrawingCommentTextFieldObj] = useState<DrawingStringTypeList>(initDrawingCommentTextFieldObj);

  const handleDeleteConfModalOpen = (pathParam:string, dateParam:string) => {
    setDeleteConfModalOpen(true);
    setPath(pathParam);
    setDate(dateParam);
  }

  const handlePreviewChange = (pdfPath: string) => {
    //表示中のPDFレコードを押下時非表示
    const path = 'gs://'
      + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
      + '/'
      + objLongPageParam.planAreaId
      + '/'
      + objLongPageParam.branch
      + pdfPath
    if(previewPdfPath === path){
      dispatch(setDrawingPdf(""))
    } else {
      dispatch(setDrawingPdf(path));
    }
  };

	const closeMessageDialog = () => {
		setMessage("");
    setHasButton(false);
    setMessageDialogOpen(false);
	};

  const handleClickFile = () => {
    const fileElem = document.getElementById("drawingFileElem");
    if (fileElem) {
      fileElem.click();
    }
  };

  const handleChangeFile = (newFile: any) => {
    fileUpload(newFile.target.files[0]);
    newFile.target.value = '';
  };

  //ファイル保存
  const fileDownload = (fileName: string) => {
    const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/drawing/' + fileName;
    (async () => {
      try {
        const url = await getFileData(filePath);
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = fileName;
          link.href = window.URL.createObjectURL(blob);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open('GET', url as string);
        xhr.send();
      }catch(e){
        console.log(e);
      }
    })();
  }

  //PDFアップロード
  const fileUpload = (file:any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner()
    }
    (async () => {
      try {
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_PDF)
        setMessageDialogOpen(true)     
        await uploadFile(file, objLongPageParam.planAreaId+'/'+objLongPageParam.branch+'/drawing/'+file.name);
        const uploadResultFile = await getFileMetadata(objLongPageParam.planAreaId+'/'+objLongPageParam.branch+'/drawing/'+file.name);
        const newUploadFile = uploadFileJsonPathFunc(uploadResultFile, userId);
        //DB保存
        updateRegist(upsertDrawingPdfParam(objDrawing, objLongPageParam.planAreaId, objLongPageParam.branch, listUploadFile, newUploadFile));
        //Redux保存
        dispatch(setUploadPdf([...listUploadFile, newUploadFile]));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_PDF)
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch(e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_PDF)
				setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } 
    })();
  };

  //PDF削除
  const handleDeleteChange = () => {
    const newListUploadFile = listUploadFile.filter(row => row.file_path !== path && row.upload_date !== date) //選択したpathとupload_date以外を返す
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_PDF)
        setMessageDialogOpen(true)
        //CloudStorageから削除対象のPDF情報を取得
        const deleteFile = await getFileMetadata(path);
        await deleteUploadFile(deleteFile, path);
        //DB保存
        updateRegist(upsertDrawingDeletePdfParam(objDrawing, objLongPageParam.planAreaId, objLongPageParam.branch, newListUploadFile));
        //Redux保存
        dispatch(setUploadPdf([...newListUploadFile]));
        //プレビューを戻す
        dispatch(setDrawingPdf(""));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_PDF)
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_PDF)
				setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const updateRegist = (params: any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
        }
      }
    })();
  }

  // 吹き出しをクリックしたとき、モーダル画面を開く
  const handleChatBubbleClick = (columnName: string, labelName: string) => {
    setColumnNameStr(columnName);
    setLabelNameStr(labelName);
    setDeficiencyComentModalFlag(true);
    setDrawingCommentTextFieldObj({ ...drawingCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
  };

  // モーダル内でのコメント取得
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    setDrawingCommentTextFieldObj({
      ...drawingCommentTextFieldObj,
      [labelName]: e.target.value,
    });
  };

  // モーダル内の保存ボタンをクリックしたときコメントを保存
  const handleSaveComment = (columnName: string, labelName: string) => {
    //不備コメントを登録
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/regist",
      query: {
        plan_area_id: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        status_seq: objLocationBase.status_seq,
        table_name: "location_drawing",
        column_name: columnName,
        comment: drawingCommentTextFieldObj[labelName],
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
      }
    })();
  };

  //ユーザーIDを取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
        setUserId(JSON.parse(user.userData || 'null').staff_number);
        setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  
  return (
    <Box style={{ width: "100%", margin: "30px" }}>
      <Box>
        <MessageDialog props={{
          open:messageDialogOpen,
          handleModal:closeMessageDialog,
          body:message,
          hasButton:hasButton,
          buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
        }}
        />
        <input
          id="drawingFileElem"
          type="file"
          style={{display:'none'}}
          onChange={handleChangeFile}
          accept=".pdf"
        />
        <Button
          sx={{margin: '20px 0'}}
          variant="contained"
          color="success"
          size="small"
          onClick={handleClickFile}
          disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.DRAWING,'uploadButton')}
        >{LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
        {/* 不備コメント、吹き出しアイコンボタン (リサーチのみ表示)*/}
        {userType === 'z001' &&
          <>
            <IconButton
              disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'drawing_upload')}
              onClick={() => { handleChatBubbleClick('drawing_upload', 'drawingUpload') }}
              sx={iconHover}
            >
              <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.DRAWING, 'drawing_upload'))}>
                <ChatBubbleIcon sx={chatBubbleIconSx} />
              </Box>
            </IconButton>
          </>
        }
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingRight: "40px" }}>
          <TableContainer sx={{ height: "278px", width: "100%",border: '1px solid black' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                  <TableCell sx={headerCell}></TableCell>
                  <TableCell sx={headerCell}></TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {listUploadFile.map((data, index) => (
                <TableRow className="tableRow" key={index} >
                  <TableCell onClick={()=>handlePreviewChange(data.file_path)}>{data.file_name}</TableCell>
                  <TableCell onClick={()=>handlePreviewChange(data.file_path)}>{data.upload_date}</TableCell>
                  <TableCell onClick={()=>handlePreviewChange(data.file_path)}>{staffName(data.upload_staff_number)}</TableCell>
                  <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(data.file_name)}><DownloadIcon /></IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      sx={{color:'black'}}
                      onClick={()=>handleDeleteConfModalOpen('gs://'+process.env.REACT_APP_FIREBASE_STORAGE_BUCKET+'/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+data.file_path, data.upload_date)}
                      disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.DRAWING,'deleteButton')}
                    ><DeleteIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      </Grid>

      {/* プレビュー */}
      {previewPdfPath !== "" && (
        <Box sx={{ paddingTop: "30px" }}>
          <PdfViewer props={{pdfPath: previewPdfPath}}/>
        </Box>
      )}

      {/* 削除確認モーダル */}
      <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />
    
      <ObstaclesEtc props={{}}/>

      {/*不備コメント モーダル画面 */}
      <DeficiencyCommentRegistModal
        props={{
          modalOpen: deficiencyComentModalFlag,
          setModalOpen: setDeficiencyComentModalFlag,
          CommentTextFieldObj: drawingCommentTextFieldObj,
          columnName: columnNameStr,
          labelName: labelNameStr,
          handleCommentChange: handleCommentChange,
          handleSaveComment: handleSaveComment,
        }} />
    
    </Box>
  );
        
}

export default Drawing;

import * as React from 'react';
import { Box } from '@mui/material';
import OtherExcel from '../subPage/OtherDocument/OtherExcel';
import OtherPdf from '../subPage/OtherDocument/OtherPdf';

interface OtherDocumentProps {
    props:{
    }
}

const OtherDocument:React.FC<OtherDocumentProps> = ({props}) => {
    return (
        <Box sx={{ width: "100%", margin: "30px" }}>
            <OtherExcel props={{}}/>
            <OtherPdf props={{}}/>
        </Box>
    );
}

export default OtherDocument;
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setObjImpact, setObjPdfData } from '../../features/impactSlice';

import {
    InputLabel,
    Box,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
	  TableContainer,
} from '@mui/material';
import {
  setLocationBase,
} from '../../features/longPageSlice';
import LocationImpact from '../subPage/Impact/LocationImpact';
import AddImpact from '../subPage/Impact/AddImpact';
import ConfirmImpact from '../subPage/Impact/ConfirmImpact';
import {
    getLocationImpact,
    getLongPageData,
  } from "../../data/DataManage";
import { useUtilityContext } from '../../utility-provider';
import { LOCAL_CONSTANT } from "../Const";
import { apiRequest } from '../../api/ApiCall';
import { tradeAreaDensityFunc } from'../subPage/TradeArea/TradeAreaFunction';
import MessageDialog from '../components/MessageDialog';
import { getFileMetadata, uploadFile, deleteUploadFile, getFileData } from '../../utility/storage';
import { useUserContext } from '../../user-provider';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { locationBaseData } from '../components/LocationBaseFunc';
import PdfViewer from '../../utility/PdfViewer';
import dayjs from 'dayjs';
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import { setPermissionFunc, showHideFunc } from '../components/PermissionFunc';
import { staffName } from '../Common';

export interface resultDialogProps {
    props:{
    }
}

const LATLNG = {
  lat: 35.68689153090263,
  lng: 139.73671068454473,
}
const MAX_ADD_IMPACT = 4


const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color: 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
}


const Impact:React.FC<resultDialogProps> = ({props}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
    const utilityCtx = useUtilityContext();
    const [locationImpactList, setLocationImpactList] = React.useState([]);
    const [readApi, setReadApi] = React.useState<boolean>(false);
    const [addDisabled, setAddDisabled] = React.useState<boolean>(true)

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
	  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const objImpact = useAppSelector((state) => state.impact.impactData);
    const objImpactPdfData = useAppSelector((state) => state.impact.pdfData);
    //④商圏データRedux
    const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);
    const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
    const objDonation = useAppSelector((state) => state.donation.obj);
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    const dispatch = useAppDispatch();
    const csvRef = React.useRef<HTMLAnchorElement>(null)
  	const [pdfList, setPdfList] = React.useState<any[]>([]);
    const user = useUserContext();
    const [userType, setUserType] = React.useState('');

    //モーダル開閉フラグ(削除確認)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

    const handleDeleteConfModalOpen = () => {
      setDeleteConfModalOpen(true);
    }

    const onClickHandle = () => {
        setOpen(true)
    }

    const onClickConfirmOpen = () => {
      setConfirmOpen(true)
    }

    React.useEffect(() => {
      setLocationImpactList(objImpact)
    }, [objImpact])

    const callApiMenu = async () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        // 影響度呼び出し
        await callApi('impact').then((res: any) => {
          dispatch(setObjImpact(res))
        }).catch((e) => {
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }).finally(() => {
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        })
    }

    const callApiLocationBase = async () => {
        await callApi('getLocationBase').then((res: any) => {
          dispatch(setLocationBase(locationBaseData(res.getLocationBase)));
        }).catch((e) => {
          console.log(e)
        })
    }

    const callApi = async (target: string) => {
        if (target === 'impact') {
          // テスト中固定値
          return await getLocationImpact({
            mode: "getLocationImpact",
            plan_area_id: objLongPageParam.planAreaId,
            branch: objLongPageParam.branch,
          })
        } else if (target === 'getLocationBase') {
          return await getLongPageData([{
            mode: "getLocationBase",
            plan_area_id: objLongPageParam.planAreaId,
            branch: objLongPageParam.branch,
          }])
        }
    }

    React.useEffect(() => {
      if (readApi) {
        callApiMenu()
        setReadApi(false)
      }
    }, [readApi])

    const handleSetReadApi = (newValue: boolean) => {
      setReadApi(newValue)
    }

    React.useEffect(() => {
      if (locationImpactList && locationImpactList.length < MAX_ADD_IMPACT) {
        setAddDisabled(setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.IMPACT,'add_button'))
      } else {
        setAddDisabled(true)
      }
    }, [userType, locationImpactList, objLocationBase])

    const Impactcount = () => {
      let disabled = false
      if (!locationImpactList) {
        disabled = true
      }

      return disabled
    }

    const closeMessageDialog = () => {
      setMessage("");
      setHasButton(false);
      setMessageDialogOpen(false);
    };

    //ファイル保存
    const fileDownload = (fileName: string) => {
      const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/pdf/' + fileName;
      (async () => {
        try {
          const url = await getFileData(filePath);
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.download = fileName;
            link.href = window.URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(link);
          };
          xhr.open('GET', url as string);
          xhr.send();
        }catch(e){
          console.log(e);
        }
      })();
    }

    // csvダウンロード
    const outputImpactCsv = () => {
      const params = {
        mode: "regist",
        endPoint: "/location/Impact/v1/csv-download",
        query: {
          plan_area_id: objLongPageParam.planAreaId,
          branch: objLongPageParam.branch,
        }
      };
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      (async () => {
        try {
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV)
          setMessageDialogOpen(true)
          const result = await apiRequest(params);
          create_csv(result.data)
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DOWNLOAD_FOR_DETERMINING_THE_IMPACT_CSV)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }
      })();
    }

    const create_csv = (data: any) => {
      const link = csvRef.current
      if (!link) return
      const filename ="影響度判定用データ.csv"
      const dataArray: any = []
      const result: string|undefined = tradeAreaDensityFunc('afterModified', listMpData)
      dataArray.push([
        objLongPageParam.planAreaId, // 候補地NO/既存店NO
        result, // 商圏密度
        "", // 道なり距離
        objStoreOpeningConditions.salsesArea, // 売場面積
        objDonation.parkingArea, // 駐車場面積
        Number(objStoreOpeningConditions.tobaccoLicence) > 0 ? "1" : "0", // たばこ取り扱い
        "", // 既存店位置
        "", // 車　店前総数
        "", // 車　累積共通比
        "", // 人　店前総数
        "", // 人　累積共通比
        "", // 候補店中央分離帯高さ
        "", // 既存店中央分離帯高さ
        "", // 直進回数
        "", // 右左折回数
        "", // 線路横断
        "", // 河川幅
        "", // 横断車線数合計
        "", // セブンイレブン
        "", // LW/FM/セイコーマート
        "", // その他CVS
        ""  // 周辺既存店店番
      ]);
      data.map((row: any) => {
        dataArray.push([
          row.store_code, // 候補地NO/既存店NO
          "", // 商圏密度
          row.road_distance, // 道なり距離
          row.salesfloor, // 売場面積
          row.parkingarea, // 駐車場面積
          convertSelect(row.tobaccolicense, "有"), // たばこ取り扱い
          row.store_position, // 既存店位置
          row.car_front_store_count, // 車　店前総数
          row.car_total_share_ratio !== 0 ? (Math.round(row.car_total_share_ratio * 10000) / 100).toString() : '', // 車　累積共通比
          row.people_front_store_count, // 人　店前総数
          row.people_total_share_ratio !== 0 ? (Math.round(row.people_total_share_ratio * 10000) / 100).toString() : '', // 人　累積共通比
          row.plan_median_strip, // 候補店中央分離帯高さ
          row.store_median_strip, // 既存店中央分離帯高さ
          row.straight_count, // 直進回数
          row.turn_count, // 右左折回数
          convertSelect(row.railroad_crossing, "あり"), // 線路横断
          row.river_width, // 河川幅
          row.crossing_road_count, // 横断車線数合計
          convertSelect(row.conflict_sej, "あり"), // セブンイレブン
          convertSelect(row.conflict_main_cvs, "あり"), // LW/FM/セイコーマート
          convertSelect(row.conflict_other_cvs, "あり"), // その他CVS
          row.average_common_ratio.replace(",", " ") // 周辺既存店店番
        ])
      })
      const csv = dataArray.map((a: any) => a.join(',')).join('\n')
      //BOMを付与
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.click()
    }

    const convertSelect = (data: string, key: string) => {
      return data === key ? "1" : "0"
    }

    const handleChangeFile = (newFile: any) => {
      fileUpload(newFile.target.files[0], true)
      newFile.target.value = '';
    };

    const handleClickFile = () => {
      const fileElem = document.getElementById("impactPDF");
      if (fileElem) {
			  fileElem.click()
      }
    }

    const fileUpload = (file: any, hasUpload: boolean) => {
      let impact_judge_pdf_path = ""
      let impact_judge_pdf_user = ""
      if (hasUpload) {
        impact_judge_pdf_path = '/pdf/' + file.name
        const jsonUserData = JSON.parse(user.userData);
        impact_judge_pdf_user = jsonUserData.staff_number
      }
      const params = {
        mode: "regist",
        endPoint: "/location/Impact/v1/update-ImpactJudgePdf",
        query: {
          impact_judge_pdf_path: hasUpload ? impact_judge_pdf_path : "",
          impact_judge_pdf_user: hasUpload ? impact_judge_pdf_user : "",
          plan_area_id: objLongPageParam.planAreaId,
          branch: objLongPageParam.branch,
          log_type: '14',
          target_table: '影響度判定',
          target_column: hasUpload ? "ファイルアップロード" : "ファイル削除",
          target_before_value: hasUpload ? "" : objImpactPdfData.fileName,
          target_after_value: hasUpload ? file.name : "",
        }
      };
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      (async () => {
        try {
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_PDF)
          if (objLocationBase.impact_judge_pdf_path) {
            const filePath = objLongPageParam.planAreaId + '/' + objLongPageParam.branch + objLocationBase.impact_judge_pdf_path
            const deleteFile = await getFileMetadata(filePath);
            await deleteUploadFile(deleteFile, filePath)
          }
          if (hasUpload) {
            setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_PDF)
            setMessageDialogOpen(true)
            await uploadFile(file, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + impact_judge_pdf_path)
          } else {
            setMessageDialogOpen(true)
          }
          const result = await apiRequest(params)
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_PDF)
          if (hasUpload) {
            setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_PDF)
          }
          callApiLocationBase()
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_PDF)
          if (hasUpload) {
            setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_PDF)
          }
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }
      })();
    }

    React.useEffect(() => {
      if (objLocationBase) {
        if (objLocationBase.impact_judge_pdf_path) {
          getFile(objLocationBase.impact_judge_pdf_path)
        } else {
          setPdfList([])
        }
      }
    }, [objLocationBase])

    const getFile = (filePath: string) => {
      if (filePath) {
        (async () => {
          await getFileMetadata(objLongPageParam.planAreaId+'/'+objLongPageParam.branch+filePath)
            .then((fileData: any) => {
              let uploader = ""
              if (objLocationBase.impact_judge_pdf_user) {
                const staffMaster = localStorage.getItem("staffMaster");
                if (staffMaster) {
                  const jsonStaffMasterObj = JSON.parse(staffMaster);
                  jsonStaffMasterObj?.map((row: any) => {
                    if (row.staff_number === objLocationBase.impact_judge_pdf_user) {
                      uploader = row.staff_name
                      return
                    }
                  })  
                }
              }
              dispatch(setObjPdfData({
                fileName: fileData.name,
                uploadDate: fileData.updated,
                uploader: uploader,
                pdfPath: fileData.fullPath
              }))
            })
        })();
      }
    }

    React.useEffect(() => {
      if (
        objImpactPdfData.fileName !== "" ||
        objImpactPdfData.uploadDate !== "" ||
        objImpactPdfData.uploader !== "" ||
        objImpactPdfData.pdfPath !== ""
      ) {
        setPdfList([{
          fileName: objImpactPdfData.fileName,
          uploadDate:dayjs(objImpactPdfData.uploadDate).format('YYYY/MM/DD HH:mm:ss'),
          uploader: objImpactPdfData.uploader,
          pdfPath: objImpactPdfData.pdfPath,
        }])
      }
    }, [objImpactPdfData])

    const handleValueChange = (path: string, labelName: string) => {
      if(path === objImpactPdfData.previewPdfPath){
        dispatch(setObjPdfData({...objImpactPdfData,[labelName]: path}));
      } else {
        dispatch(setObjPdfData({...objImpactPdfData,[labelName]: path}));
      }
    }

    const handlePdfDelete = () => {
      dispatch(setObjPdfData({...objImpactPdfData,["previewPdfPath"]: ""}));
      fileUpload("", false)
    }

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
      if (JSON.parse(user.userData || 'null') !== null) {
          setUserType(JSON.parse(user.userData || 'null').user_type);
      }
    }, [user]);
    
    return (
        <div style={{ width: "94%", margin: "30px" }}>
            <MessageDialog props={{
              open:messageDialogOpen,
              handleModal:closeMessageDialog,
              body:message,
              hasButton:hasButton,
              buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
            }}/>
            <Box>
                <InputLabel>影響度調査</InputLabel>
                <Button
                    sx={{margin: '20px 0'}}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={addDisabled}
                    onClick={onClickHandle}
                >{LOCAL_CONSTANT.LABEL.ADD}</Button>
            </Box>
            <Box>
              <Button
                sx={{margin: '20px 0'}}
                variant="contained"
                color="success"
                size="small"
                disabled={Impactcount()}
                onClick={onClickConfirmOpen}
              >{LOCAL_CONSTANT.LABEL.INTERSECTION_CONFIRM}</Button>
            </Box>
            <AddImpact props={{
              open:open,
              handleModal:setOpen,
              mode: 'add',
              latlng: LATLNG,
              locationImpactList: locationImpactList,
              targetImpact: [],
              handleSetReadApi: handleSetReadApi}}
            />
            <ConfirmImpact props={{
              open:confirmOpen,
              handleModal:setConfirmOpen,
              mode: 'add',
              plan_area_id: objLongPageParam.planAreaId,
              latlng: LATLNG,
              locationImpactList: locationImpactList,
              targetImpact: [],
              handleSetReadApi: handleSetReadApi}}
            />
            {
              locationImpactList.map((impact: any, index: number) => (
                <LocationImpact props={{
                  plan_area_id: objLongPageParam.planAreaId,
                  indexKey: index,
                  locationImpact: locationImpactList,
                  impact: impact,
                  latlng: LATLNG,
                  handleSetReadApi: handleSetReadApi,
                  userType: userType,
                }} key={index} />
              ))
            }
            <Box sx={{ margin: '20px 0'}}>
              {
                showHideFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.IMPACT,'impact_output_data_button') &&
                <>
                  <Button
                    variant="contained" color="success" size="small"
                    onClick={outputImpactCsv}
                    disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.IMPACT,'impact_output_data_button')}
                  >影響度判定用データ出力</Button>
                  <a ref={csvRef} className='hidden'></a>
                </>
              }
            </Box>
            <input
              id="impactPDF"
              type="file"
              style={{ display: 'none' }}
              onChange={handleChangeFile}
              accept=".pdf"
            />
            <Box sx={{ margin: '20px 0'}}>
              {
                //アップロードボタン
                showHideFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.IMPACT,'upload_button') &&
                <>
                  <Button
                    variant="contained" color="success" size="small"
                    onClick={(e) => { handleClickFile() }}
                    disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.IMPACT,'upload_button')}
                  >{LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
                </>
              }
            </Box>
            <TableContainer sx={{ width: "70%", border: '1px solid black' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                    <TableCell sx={headerCell}></TableCell>
                    <TableCell sx={headerCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
  								{pdfList.map((val, index) => (
                    <TableRow className="tableRow" key={index}>
                      <TableCell onClick={() => handleValueChange(val.pdfPath, "previewPdfPath")}>{val.fileName}</TableCell>
                      <TableCell onClick={() => handleValueChange(val.pdfPath, "previewPdfPath")}>{val.uploadDate}</TableCell>
                      <TableCell onClick={() => handleValueChange(val.pdfPath, "previewPdfPath")}>{staffName(val.uploader)}</TableCell>
                      <TableCell>
                        <Button sx={{ color: 'black' }} onClick={() => fileDownload(val.fileName)}><DownloadIcon /></Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{color: 'black'}}
                          onClick={(e) => { handleDeleteConfModalOpen() }}
                        ><DeleteIcon fontSize="small"/></Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            {/* プレビュー */}
            {objImpactPdfData.previewPdfPath  !== "" &&
            <Box sx={{ paddingTop: "60px" }}>
              <PdfViewer props={{ pdfPath: objImpactPdfData.previewPdfPath }} />
            </Box>
            }

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handlePdfDelete} />
  
        </div>
    );
        
}


export default Impact;

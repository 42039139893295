import { useState, useEffect} from 'react';
import { createPortal } from 'react-dom';
import {
  Box,
  Button,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  styled,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
/* import { apiRequest } from "../../api/ApiCall"; */

/* interface ModalProps {
  props: {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    deleteMethod: React.MouseEventHandler<HTMLButtonElement>
  }
}; */

//スタイル
const CancelButton = styled(Button)({
  backgroundColor: "#ffffff00",
  color: "#f00",
  border:"1px solid #f00",
  float: "left",
  margin: "10px 20px",
});
const OKButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  float: "right",
  margin: "10px 20px",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});

export function DeleteConfirmationModal({modalOpen, setModalOpen, deleteMethod}:
                                        {modalOpen: any, setModalOpen: any, deleteMethod: any}){

  const modalRoot = document.getElementById('root') as HTMLElement | DocumentFragment;

  //モーダルクローズ
  const handleModalClose = () => {
    setModalOpen(false);
  };

  //削除処理実行
  const handleDeleteExecute = () => {
    setModalOpen(false);
    deleteMethod();
  }

  return createPortal(
    <Box>
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle sx={{ textAlign: "center" }}>削除してよろしいですか？</DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CancelButton onClick={()=>{handleModalClose()}}>いいえ</CancelButton>
          </Grid>
          <Grid item xs={6}>
            <OKButton onClick={()=>{handleDeleteExecute()}}>はい</OKButton>
          </Grid>
        </Grid>
      </Dialog>
    </Box>,
    modalRoot
  );
};


/* const DeleteConfirmationModal: React.FC<ModalProps> = ({ props }) => {

  //モーダルクローズ
  const handleModalClose = () => {
    props.setModalOpen(false);
  };

  return(
    <Box>
      <Dialog open={props.modalOpen} onClose={handleModalClose}>
        <DialogTitle sx={{ textAlign: "center" }}>削除してよろしいですか？</DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CancelButton onClick={()=>{handleModalClose()}}>いいえ</CancelButton>
          </Grid>
          <Grid item xs={6}>
            <OKButton onClick={()=>{}}>はい</OKButton>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  )
};

export default DeleteConfirmationModal; */
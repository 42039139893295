import {
  Box,
  IconButton,
  TextField
} from '@mui/material';
import { useRef } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import {
  CoverBooleanTypeList,
  StoreOpeningConditionsBooleanTypeList,
  StoreOpeningConditionsTypeList,
  DrawingBooleanTypeList,
  DrawingTypeList,
} from '../../data/type';
import { setPermissionFunc } from '../components/PermissionFunc';
import { useAppSelector } from '../../app/hooks';
import {
  chatBubbleCircleSx,
  chatBubbleIconSx,
  editIconCircleSx,
  iconHover
} from './ChatBubbleAndEditIconStyle';

export interface TextFieldProps {
  props: {
    userType: string,
    disabled: boolean,
    pageName: string,
    columnName: string,
    labelName: string,
    rows: number,
    width: string,
    InputValueObj: StoreOpeningConditionsTypeList
    | DrawingTypeList
    | any,
    ReadOnlyObj: StoreOpeningConditionsBooleanTypeList
    | DrawingBooleanTypeList
    | any,
    setReadOnlyObj: React.Dispatch<React.SetStateAction<CoverBooleanTypeList>>
    | React.Dispatch<React.SetStateAction<StoreOpeningConditionsBooleanTypeList>>
    | React.Dispatch<React.SetStateAction<DrawingBooleanTypeList>>,
    ShowEditIconObj: CoverBooleanTypeList
    | StoreOpeningConditionsBooleanTypeList
    | DrawingBooleanTypeList,
    ShowIconsObj: StoreOpeningConditionsBooleanTypeList
    | DrawingBooleanTypeList
    | any,
    handleChatBubbleClick: (columnName: string, labelName: string) => void,
    handleInputBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => void,
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => void,
    handleInputClick: (labelName: string) => void,
    handleKeyDown: (e: any, labelName: string) => void,
  }
}

export const LongTextFiledWithIcons: React.FC<TextFieldProps> = ({ props }) => {

  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

  // 鉛筆をクリックしたときのフォーカス制御
  const inputRef = useRef<HTMLInputElement | null>(null);

  // 鉛筆をクリックしたとき、Input編集可
  const handleEditIconClick = (labelName: string) => {
    if (setPermissionFunc(props.userType, objLocationBase.task_id, props.pageName, labelName) === false) {
      //falseにすると、Input編集可
      props.setReadOnlyObj({ ...props.ReadOnlyObj, [labelName]: false });
    }
    //インプットにフォーカスする
    if (inputRef.current) {
      inputRef.current.focus();
    };
  };

  //ユーザー種別（'z002'、'z004'、'z005'）が選択されている場合のTextFieldのスタイル調整
  const TextFieldSx = {
    '& .MuiFilledInput-root': {
      color: 'rgba(0, 0, 0, 0.38)',
      paddingTop: '8px',
      height: props.rows === 1 ? '58px' : '', //ロングテキストが1行の場合は高さを58px 空白の場合は行数分の高さになる
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)'
    },
    '& .MuiFilledInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  };

  //ユーザー種別（'z003'）が選択されている場合のTextFieldのスタイル調整
  const SelectZ003TextFieldSx = {
    '& .MuiFilledInput-root': {
      color: props.disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
      paddingTop: '8px',
      height: props.rows === 1 ? '58px' : '', //ロングテキストが1行の場合は高さを58px 空白の場合は行数分の高さになる
      backgroundColor: props.disabled ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6' //trueのとき背景がグレー系、色を変える
    },
    cursor: props.ReadOnlyObj[props.labelName] ? 'not-allowed' : 'text', //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
  };

  //ユーザー種別（'z001'）が選択されている場合のTextFieldのスタイル調整
  const SelectZ001TextFieldSx = {
    '& .MuiFilledInput-root': {
      color: props.ReadOnlyObj[props.labelName] ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
      paddingTop: '8px',
      height: props.rows === 1 ? '58px' : '', //ロングテキストが1行の場合は高さを58px 空白の場合は行数分の高さになる
      backgroundColor: props.ReadOnlyObj[props.labelName] ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6' //trueのとき背景がグレー系、色を変える
    },
    cursor: props.ReadOnlyObj[props.labelName] ? 'not-allowed' : 'text', //鉛筆アイコンをクリック時にTextFieldにカーソルを表示
  };

  return (
    <Box>
      {/* ユーザー種別がRFCとリサーチ以外の場合*/}
      {(props.userType !== 'z003' && props.userType !== 'z001') && (
        <Box sx={{ width: props.width }}>
          <TextField
            multiline
            fullWidth
            disabled={true}
            rows={props.rows}
            value={props.InputValueObj[props.labelName]}
            sx={TextFieldSx}
            variant="filled"
          />
        </Box>
      )}
      {/* ユーザー種別がRFCの場合 userTypeがz003*/}
      {props.userType === 'z003' && (
        <Box sx={{ width: props.width }}>
          <TextField
            multiline
            fullWidth
            rows={props.rows}
            value={props.InputValueObj[props.labelName]}
            onChange={(e) => props.handleInputChange(e, props.labelName)}
            onBlur={(e) => props.handleInputBlur(e, props.labelName)}
            onKeyDown={(e) => props.handleKeyDown(e, props.labelName)}
            sx={SelectZ003TextFieldSx}
            InputProps={{
              readOnly: props.disabled
            }}
            variant="filled"
          />
        </Box>
      )}
      {/* ユーザー種別がリサーチの場合 userTypeがz001 */}
      {props.userType === 'z001' && (
        <Box display="flex" alignItems="center" sx={{ width: `calc(${props.width} + 85px)` }}>
          <Box sx={{ width: props.width }}>
            <TextField
              multiline
              fullWidth
              rows={props.rows}
              value={props.InputValueObj[props.labelName]}
              onClick={() => props.handleInputClick(props.labelName)}
              onChange={(e) => props.handleInputChange(e, props.labelName)}
              onBlur={(e) => props.handleInputBlur(e, props.labelName)}
              onKeyDown={(e) => props.handleKeyDown(e, props.labelName)}
              inputRef={inputRef}
              sx={SelectZ001TextFieldSx}
              InputProps={{
                readOnly: props.ReadOnlyObj[props.labelName]
              }}
              variant="filled"
            />
          </Box>
          {/* アイコン全体の表示非表示 */}
          {props.ShowIconsObj[props.labelName] && (
            <Box sx={{ minWidth: '85px' }}>
              {/* 吹き出しアイコン */}
              <IconButton
                disabled={props.disabled}
                onClick={() => props.handleChatBubbleClick(props.columnName, props.labelName)}
                sx={iconHover}
              >
                <Box sx={chatBubbleCircleSx(props.disabled)}>
                  <ChatBubbleIcon sx={chatBubbleIconSx} />
                </Box>
              </IconButton>
              {/* 鉛筆アイコンの表示非表示 */}
              {props.ShowEditIconObj[props.labelName] && (
                <IconButton
                  onClick={() => handleEditIconClick(props.labelName)}
                  sx={iconHover}
                >
                  <Box sx={editIconCircleSx}>
                    <EditIcon />
                  </Box>
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      )}

    </Box>
  );
};

export default LongTextFiledWithIcons;
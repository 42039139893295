import * as React from 'react';
import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Paper,
  styled,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  Toolbar,
  InputLabel,
  Divider,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

import { LOCAL_CONSTANT } from "./Const";

interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
}

//スタイル
const GridContainer = styled(Grid)({
  marginBottom: "20px"
});
const TextBox = styled(Box)({
  marginLeft: "10px",
  backgroundColor: "#e3f5f7b0"
});
const Dividers = styled(Divider)({
  margin: "20px"
});
const TextTypography = styled(Typography)({
  paddingLeft: "20px",
  fontSize: "20px"
});
const OpenButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "#ffffff",
  border: "solid 1px #0000007a"
});

const PortalReport:React.FC<resultDialogProps>=({props})=>{
  return(
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{position:"fixed"}}>
        <Grid item sx={{width: props.width + 56, height: props.height, overflow: "scroll"}}>
          <Container>
            <Paper sx={{margin: "60px 10px", padding: "30px"}}>
              <GridContainer container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <TextBox>
                    <TextTypography>{LOCAL_CONSTANT.LABEL.SALES_FORECASTING_OUTPUT_COUNT}</TextTypography>
                  </TextBox>
                </Grid>
                <Grid item xs={3} sx={{textAlign:"center"}}>
                  <OpenButton>開く</OpenButton>
                </Grid>
              </GridContainer>
              <Dividers/>
              <GridContainer container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <TextBox>
                    <TextTypography>{LOCAL_CONSTANT.LABEL.SURVEY_FORM_CREATING_ORDER_COUNT}</TextTypography>
                  </TextBox>
                </Grid>
                <Grid item xs={3} sx={{textAlign:"center"}}>
                  <OpenButton>開く</OpenButton>
                </Grid>
              </GridContainer>
              <Dividers/>
              <GridContainer container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <TextBox>
                    <TextTypography>{LOCAL_CONSTANT.LABEL.AVERAGE_RECEPTION_RESIDENCE_TIME}</TextTypography>
                  </TextBox>
                </Grid>
                <Grid item xs={3} sx={{textAlign:"center"}}>
                  <OpenButton>開く</OpenButton>
                </Grid>
              </GridContainer>
              <Dividers/>
              <GridContainer container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <TextBox>
                    <TextTypography>レポート4</TextTypography>
                  </TextBox>
                </Grid>
                <Grid item xs={3} sx={{textAlign:"center"}}>
                  <OpenButton>開く</OpenButton>
                </Grid>
              </GridContainer>
              <Dividers/>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <TextBox>
                    <TextTypography>レポート5</TextTypography>
                  </TextBox>
                </Grid>
                <Grid item xs={3} sx={{textAlign:"center"}}>
                  <OpenButton>開く</OpenButton>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PortalReport;
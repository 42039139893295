import { createSlice } from '@reduxjs/toolkit';

export const additionalResearchSlice = createSlice({
    name: 'additionalResearch',
    initialState: {
        coverPdfPath:'',
        pdfPath:'',
    },
    reducers:{
        setAdditionalResearchCoverPdf: (state, action) => {
            state.coverPdfPath = action.payload;
        },
        setAdditionalResearchPdf: (state, action) => {
            state.pdfPath = action.payload;
        }
    }
});

export const {
    setAdditionalResearchCoverPdf,
    setAdditionalResearchPdf
} = additionalResearchSlice.actions;
export default additionalResearchSlice.reducer;
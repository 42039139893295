import { createSlice } from '@reduxjs/toolkit';
import { initCoverObj } from '../data/type';

//Sliceの作成
export const coverSlice = createSlice({
    name: 'cover',
    //初期State(Typeからインポート)
    initialState: {
        obj: initCoverObj,
        planAreaInfo: {},
    },
    //toolKitを利用した値変更処理
    reducers: {
        setObjCover: (state, action) => {
            state.obj = action.payload;
        },
        //候補地情報取り込みボタン押下時の更新項目
        setPlanAreaInfo:(state,action) => { 
            state.obj.address = action.payload.address;
            state.obj.contractDueDate = action.payload.contractDueDate;
            state.obj.openDueDate = action.payload.openDueDate;
            state.obj.newAreaNo = action.payload.newAreaNo;
            state.obj.surveyCreator = action.payload.surveyCreator;
            state.obj.confirmer = action.payload.confirmer;
            state.obj.surveyName = action.payload.surveyName;
            state.obj.prefecturesCode = action.payload.prefecturesCode;
            state.obj.branchNo = action.payload.branchNo;
        }
    }
});

export const {
    setObjCover,
    setPlanAreaInfo
} = coverSlice.actions;
export default coverSlice.reducer;


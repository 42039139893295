export const startDrawingManager = (map: google.maps.Map, strokeColor: string) => {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: false,
      map: map,
      polylineOptions: {
          strokeColor: strokeColor, // ライン色(#RRGGBB形式)
          strokeOpacity: 1, // ライン透明度 0.0～1.0(デフォルト)
          strokeWeight: 2, // ライン太さ(単位ピクセル)
          editable: true,
      },
    });

    return drawingManager;
}
  
export const endDrawingManager = (drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManager.setDrawingMode(null)
}

import * as React from 'react';

import { initAddConflictObj } from '../../../data/type';
import { point2distance } from "../../../utility/actionTurf";
import { putMarker } from "./viewDataFunction";


/**
 * 既存店描画処理
 * @param existingSejStoreList 
 * @param map 
 * @param onClick 
 */
export const drawExistingSejStore = (
    existingSejStoreList: any,
    map: google.maps.Map,
    exSejStoreList: any,
    setExSejStoreList: any,
    targetExSejStore: any,
    planAreaList: any,
) => {
    const tmpMarker:any[] = [];
    existingSejStoreList.forEach((data: any, index: any) => {
        try {
          // marker
          const marker = putMarker(data, 'sej')
          const result = point2distance(
            new google.maps.LatLng(planAreaList[0].centerlat, planAreaList[0].centerlon),
            new google.maps.LatLng(data.centerlat, data.centerlon)
          )
          data.selectedMode = false
          data = { ...data , ...initAddConflictObj };
          data.directDistance = result
          data.roadDistance = 0
          marker.setMap(map);

          tmpMarker.push({
            key: data.store_code,
            data: data,
            marker: marker,
          })

        } catch (error) {
          console.log("------------------------")
          console.log("既存店表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

    // 前回表示分のリスト取得
    const  diffBefore: any[] = []
    exSejStoreList?.forEach((item: any) => {
        diffBefore.push(item.data)
    })
    // 前回表示分のリストとの差分取得
    const diff = diffBefore.filter(x => !existingSejStoreList.includes(x))

    setExSejStoreList(tmpMarker);

    // 前回表示分の差分をMAPから表示削除
    if (diff.length > 0) {
        diffClear(diff, exSejStoreList)
    }

    return tmpMarker
}

/**
 * 前回表示分のmarker、polygonの差分を非表示にする
 * @param diffList 
 * @param list 
 */
const diffClear = (diffList: any, list: any) => {
    list?.forEach(function(item: any){
        diffList?.forEach(function(diffItem: any){
          if (item.key === diffItem.store_code) {
              item.marker.setMap(null);
          }
        })
    });
}

export interface existingSejStoreProps {
    props:{
    }
  }

const ExistingSejStore:React.FC<existingSejStoreProps> = ({props}) => {


    return (
        <></>
    );
        
}


export default ExistingSejStore;

import * as React from 'react';

import {
    TextField,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    InputLabel,
} from '@mui/material';
import { useUtilityContext } from '../../utility-provider';
import { apiRequest } from '../../api/ApiCall';
import { LOCAL_CONSTANT } from '../Const';

export interface commentDialogProps {
    props: {
        open: boolean,
        params: any,
        handleModal:React.Dispatch<React.SetStateAction<boolean>>,
        handleSaveComment: () => void
        handleCanCelComment: () => void
    }
}

export const ChangeHistoryTableComment:React.FC<commentDialogProps> = ({props}) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [disabled, setDisabled] = React.useState<boolean>(true)
    const [comment, setComment] = React.useState("")
    const utilityCtx = useUtilityContext();

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(e.target.value)
        if (e.target.value) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    const registComment = () => {
        // コメント登録
        const params = {
            mode: "regist",
            endPoint: "/location/system/v1/put-rr-log",
            query: {
                plan_area_id: props.params.planAreaId,
                branch: props.params.branch,
                log_type: '99',
                comment: comment,
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setComment("")
                //モーダルを閉じる
                props.handleSaveComment()
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setComment("")
                //モーダルを閉じる
                props.handleSaveComment()
            }
        })();
    }

    const closeComment = () => {
        //モーダルを閉じる
        setComment("")
        props.handleCanCelComment()
    }

    return (
        <>
            <Dialog open={open} onClose={() => props.handleModal(false)} fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputLabel sx={{ padding: '10px' }}>{LOCAL_CONSTANT.CONFIRM_MESSAGE.PLEASE_INPUT_COMMENT}</InputLabel>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={comment}
                        multiline
                        rows={3}
                        maxRows={3}
                        onChange={(e) => handleCommentChange(e)}
                    />
                </DialogContent>
                <Box sx={{ display: 'flex', justifyContent: "space-around", margin: '10px' }}>
                    <DialogActions>
                        <Button
                            variant="outlined" color="error" size="small"
                            onClick={() => closeComment()}>
                            {LOCAL_CONSTANT.LABEL.CANCEL}
                        </Button>
                    </DialogActions>
                    <DialogActions>
                        <Button
                            variant="contained" color="success" size="small"
                            disabled={disabled}
                            onClick={() => registComment()}>
                            {LOCAL_CONSTANT.LABEL.REGIST_LABEL}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ChangeHistoryTableComment;

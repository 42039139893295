import { createSlice } from '@reduxjs/toolkit';

//Sliceの作成
export const locationWalk5minCircleSlice = createSlice({
    name: 'locationWalk5minCircle',
    initialState: [],

    //toolKitを利用した値変更処理
    reducers: {
        setObjLocationWalk5minCircle: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setObjLocationWalk5minCircle
} = locationWalk5minCircleSlice.actions;
export default locationWalk5minCircleSlice.reducer;
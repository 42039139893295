import * as React from 'react';

import {
    zIndexOrder,
} from "../Interface";

export const drawPlanArea = (
    planAreaList: any,
    map: google.maps.Map,
) => {
    planAreaList.forEach((data: any, index: any) => {
    try{
        if (data.step >= 8) {
        return;
        }
        // marker
        var marker = new google.maps.Marker({
        position:
        {
            lat: data.centerlat,
            lng: data.centerlon,
        }
        });
        let icon:string = ""
        let existingFeature:boolean = false
        if (Number(data.step) === 0) {
            // STEP0：黒
            icon = process.env.PUBLIC_URL + '/image/icons/double_circle_black.svg'
        } else if (Number(data.step) === 7) {
            // STEP7：透けてるSEJアイコン
            icon = process.env.PUBLIC_URL + '/image/icons/sej_cleanness.png'
        } else if (data.zone_id) {
            if (!data.zone_is_partial_step) {
                // 同じZONE内にSTEP4以上の候補地が存在しない（is_partial_step=false）
                icon = process.env.PUBLIC_URL + '/image/icons/double_circle_blue.svg'
            } else if (Number(data.step) >= 4) {
                // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
                // STEP4以上：赤
                icon = process.env.PUBLIC_URL + '/image/icons/double_circle.svg'
                existingFeature = true
            } else {
                // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
                // STEP3以下：グレー
                icon = process.env.PUBLIC_URL + '/image/icons/double_circle_gray.svg'
            }
        } else {
            // ZONEに入っていない（zone_idが空）
            // →STEP1～6：青
            icon = process.env.PUBLIC_URL + '/image/icons/double_circle_blue.svg'
        }

        marker.setOptions({
        icon: {
            url: icon,
            anchor: new google.maps.Point(15, 15),
        },
        zIndex: zIndexOrder.kouhochiPolygon,
        })
        marker.setMap(map!);

        const pathOptions:any[] = [];
    } catch (error) {
        console.log("------------------------")
        console.log("表示エラー")
        console.log(error)
        console.log(data)
        console.log("------------------------")
    }
    })
    return planAreaList;
}
export interface planAreaProps {
    props:{
    }
  }

const PlanArea:React.FC<planAreaProps> = ({props}) => {

    return (
        <></>
    );
        
}


export default PlanArea;

import { getFileData, getFileMetadata } from '../../../utility/storage';
import { getAccessToken } from '../../Common';

const picPath = ['pictuer_1_path','pictuer_2_path','pictuer_3_path','pictuer_4_path','pictuer_5_path','pictuer_6_path','pictuer_7_path','pictuer_8_path']

export const initPhoto = (res: any) => {
    const DataList:any = {}
    res.map((row: any) => {
        DataList['plan_area_id'] = row.plan_area_id
        DataList['branch'] = row.branch
        DataList['create_date'] = row.create_date
        DataList['update_date'] = row.update_date
        DataList['delete_flag'] = row.delete_flag
        DataList['pictuer_1_path'] = row.pictuer_1_path
        DataList['pictuer_2_path'] = row.pictuer_2_path
        DataList['pictuer_3_path'] = row.pictuer_3_path
        DataList['pictuer_4_path'] = row.pictuer_4_path
        DataList['pictuer_5_path'] = row.pictuer_5_path
        DataList['pictuer_6_path'] = row.pictuer_6_path
        DataList['pictuer_7_path'] = row.pictuer_7_path
        DataList['pictuer_8_path'] = row.pictuer_8_path
        DataList['viewing_distance'] = row.viewing_distance
        DataList['upload_image'] = ""
    })
    return DataList;
}

export const setPhoto = (objPicture: any, photoArray: any) => {
    const local_path = "/image/picture/defaultPic"
    const bucket_path =  process.env.REACT_APP_GOOGLE_STORAGE + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    const access_token = getAccessToken()
    picPath.map((row: any, index: number) => {
        if (objPicture[row] && objPicture[row] !== '') {
            photoArray[index]['url'] = objPicture[row]
            photoArray[index]['fullPath'] = objPicture[row]
            if(!objPicture[row].includes(local_path)) {
                photoArray[index]['disabled'] = false
                if(!objPicture[row].includes(local_path)){
                    let path = ""
                    if (objPicture[row].includes('/photo/IMG_')) {
                        path = bucket_path + '/' + objPicture.plan_area_id
                    } else if (objPicture[row].includes('/photo/EDITIMG_')) {
                        path = bucket_path + '/' + objPicture.plan_area_id + '/' + objPicture.branch
                    }
                    if (objPicture[row].includes(objPicture.plan_area_id)) {
                        path = bucket_path
                    }

                    path += objPicture[row] + access_token
                    getFileData(path)
                    .then((imageData: any) => {
                        photoArray[index]['url'] = imageData
                    })
                    // メタデータ取得
                    getFileMetadata(path)
                    .then((metaData: any) => {
                        photoArray[index]['meta'] = metaData
                    })  
                }
            }
        }
    })
    return photoArray
}

export const setViewingDistanceParam = (ObjPicture: any) => {
    return { viewing_distance: ObjPicture.viewing_distance}
}

export const upsertPhotoParam = (objPicture: any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Picture/v1/regist",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            pictuer_1_path: objPicture.pictuer_1_path,
            pictuer_2_path: objPicture.pictuer_2_path,
            pictuer_3_path: objPicture.pictuer_3_path,
            pictuer_4_path: objPicture.pictuer_4_path,
            pictuer_5_path: objPicture.pictuer_5_path,
            pictuer_6_path: objPicture.pictuer_6_path,
            pictuer_7_path: objPicture.pictuer_7_path,
            pictuer_8_path: objPicture.pictuer_8_path,
            viewing_distance: objPicture.viewing_distance,
            upload_image: objPicture.upload_image,
        }
    }

    return params
}
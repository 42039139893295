import React from "react";
import dayjs from 'dayjs';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  Button,
} from '@mui/material';
import { useUtilityContext } from '../../utility-provider';
import { LOCAL_CONSTANT } from "../Const";
import MessageDialog from '../components/MessageDialog';
import { uploadFile } from '../../utility/storage';
import { postPython } from '../../api/ApiCall';
import { useUserContext } from "../../user-provider";


export interface messageDialogProps {
  props: {
    open: boolean,
    handleModal: any,
  }
}

const GisImportModal: React.FC<messageDialogProps> = ({ props }) => {
  const [open, setOpen] = React.useState(false);

  const utilityCtx = useUtilityContext();

  const [message, setMessage] = React.useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
  const [hasButton, setHasButton] = React.useState<boolean>(false);
  const [modalMode, setModalMode] = React.useState<string>("menu");
  const [uploadMode, setUploadMode] = React.useState<string>("");

  const [userType, setUserType] = React.useState('');
  const user = useUserContext();

  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open])


  const handleClose = () => {
    setOpen(false)
    setModalMode("menu")
    props.handleModal()
  }

  const handleChangeFile = (newFile: any) => {
    fileUpload(newFile.target.files[0])
    newFile.target.value = '';
  };

  const handleClickFile = (uploadModeKey: string) => {
    setUploadMode(uploadModeKey)
    const fileElem = document.getElementById("gis-upload");
    if (fileElem) {
      fileElem.click()
    }
  }

  const closeMessageDialog = () => {
    setMessage("");
    setHasButton(false);
    setMessageDialogOpen(false);
  };

  const fileUpload = (file: any) => {
    const params = setParams(file.name);

    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        handleClose()
        setMessage(setModeMessage('start'))
        setMessageDialogOpen(true)
        await uploadFile(file, 'temp/'+params['query']['file_name'])
        const response = await postPython(params)
        if (params['endPoint'] === "/n152db") {
          if (response.data.data.status) {
            setMessage(setModeMessage('success'))
          } else {
            setMessage(setModeMessage('error'))
          }
        } else {
          setMessage(setModeMessage('success'))
        }
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(setModeMessage('error'))
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const setParams = (fileName: string) => {
    let params = {
      mode: "regist",
      endPoint: "",
      query: {
        file_name: fileName,
        user_data: {}
      }
    };
    const userData = JSON.parse(user.userData);
    switch (uploadMode) {
      case '5minute':
        params['endPoint'] = "/shp2db"
        break;
      case 'middleProduct1':
        params['query'] = { file_name : dayjs().format("YYYYMMDDHHmmss") + '_middle_product1.xlsm',
                            user_data : {
                              update_top_department: userData.top_department_code_OA,
                              update_department: userData.department_code_OA,
                              update_job: userData.job_category_code_OA,
                              update_mail: userData.email.toString(),
                              update_stuff_number: userData.firebase_id,
                              update_name: userData.user_name
                            }
                          }
        params['endPoint'] = "/excel2db-middle-product"
        break;
      case 'n15AdditionalTradeArea':
        params['endPoint'] = "/n152db"
        break;
    }
    return params
  }

  const setModeMessage = (processKey: string) => {
    let messageVal = ""
    switch (uploadMode) {
      case '5minute':
        if (processKey === "start") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_5MINUTE_WALK_TRADE_AREA
        } else if (processKey === "success") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_5MINUTE_WALK_TRADE_AREA
        } else if (processKey === "error") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_5MINUTE_WALK_TRADE_AREA
        }
        break;
      case 'middleProduct1':
        if (processKey === "start") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD
        } else if (processKey === "success") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD
        } else if (processKey === "error") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROE_UPLOAD
        }
        break;
      case 'n15AdditionalTradeArea':
        if (processKey === "start") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_N15_TRADE_AREA
        } else if (processKey === "success") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_N15_WALK_TRADE_AREA
        } else if (processKey === "error") {
          messageVal = LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_N15_WALK_TRADE_AREA
        }
        break;
    }

    return messageVal
  }

  const handleIntermediateProducts = (key: string) => {
    setModalMode(key)
  }

  //ユーザー取得
  React.useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
}, [user]);

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        onClose={handleClose}
      >
        <DialogContent>
          {
            modalMode === 'menu' &&
            <Box sx={{ margin: '20px 40px' }}>
              <Box sx={{ textAlign: 'center', margin: '20px 10px' }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ padding: '10px 20px'}}
                  onClick={(e) => { handleClickFile("middleProduct1") }}
                >{LOCAL_CONSTANT.LABEL.INTERMEDIATE_PRODUCTS}1</Button>
              </Box>
              <Box sx={{ textAlign: 'center', margin: '20px 10px' }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ padding: '10px 20px'}}
                  onClick={(e) => { handleIntermediateProducts("second") }}
                >{LOCAL_CONSTANT.LABEL.INTERMEDIATE_PRODUCTS}2</Button>
              </Box>
              <Box sx={{ textAlign: 'center', margin: '20px 10px' }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ padding: '10px 20px'}}
                  onClick={(e) => { handleClickFile("5minute") }}
                >{LOCAL_CONSTANT.LABEL.FIVE_MINUTE_WALK_TRADE_AREA}</Button>
              </Box>
            </Box>
          }
          {
            modalMode === 'second' &&
            <Box>
              <Box sx={{ margin: '20px 40px' }}>
                <Box sx={{ textAlign: 'center', margin: '20px 10px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ padding: '10px 20px'}}
                    onClick={(e) => { handleClickFile("n15AdditionalTradeArea") }}
                  >{LOCAL_CONSTANT.LABEL.N15_ADDITIONAL_TRADE_AREA}</Button>
                </Box>
              </Box>
            </Box>
          }
        </DialogContent>
        <input
          id="gis-upload"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
        />
        <MessageDialog props={{
          open:messageDialogOpen,
          handleModal:closeMessageDialog,
          body:message,
          hasButton:hasButton,
          buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
        }}/>
      </Dialog>
    </>
  );
}

export default GisImportModal;



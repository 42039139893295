import { createSlice } from '@reduxjs/toolkit';

//Sliceの作成
export const n15AddMarketSlice = createSlice({
    name: 'n15AddMarket',
    initialState: [],

    //toolKitを利用した値変更処理
    reducers: {
        setN15AddMarket: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setN15AddMarket
} = n15AddMarketSlice.actions;
export default n15AddMarketSlice.reducer;
import * as React from 'react';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Box,
    Button,
} from '@mui/material';

import ReportListData from '../subPage/ReportList/ReportListData';

export interface resultDialogProps {
    props:{
    }
  }

const ReportListIndex:React.FC<resultDialogProps> = ({props}) => {
    // クリックイベント
    const clickSearch = () => {
        console.log('クリックイベント 検索')
    }

    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <Box sx={{ width: "50%", marginLeft: "56px"}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>候補地番号</TableCell>
                            <TableCell>
                                <Input></Input>
                            </TableCell>
                            <TableCell>部</TableCell>
                            <TableCell>
                                <Input></Input>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>候補地名</TableCell>
                            <TableCell>
                                <Input></Input>
                            </TableCell>
                            <TableCell>ステータス</TableCell>
                            <TableCell>
                                <Input></Input>
                            </TableCell>
                            <TableCell>
                            <Button color="success" variant="contained" onClick={(event) => clickSearch()}>検索</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            <ReportListData />
        </div>
    );
        
}


export default ReportListIndex;

import { createSlice } from '@reduxjs/toolkit';
import { initSalesForecastSummaryMainElementListObj } from '../data/type';

//Sliceの作成
export const salesForecastSummaryMainElementListSlice = createSlice({
    name: 'addSalesForecastSummaryMainElementList',
    //初期State(Typeからインポート)
    initialState: initSalesForecastSummaryMainElementListObj,

    //toolKitを利用した値変更処理
    reducers: {
        setSalesForecastSummaryMainElementList: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setSalesForecastSummaryMainElementList
} = salesForecastSummaryMainElementListSlice.actions;
export default salesForecastSummaryMainElementListSlice.reducer;
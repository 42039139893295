import React from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  Button,
} from '@mui/material';

export interface alertMessageDialogProps {
  props: {
    open: boolean,
    handleModal: any,
    label: string,
    body: string,
    hasButton: boolean,
    buttonBody: string,
  }
}


const AlertMessageDialog: React.FC<alertMessageDialogProps> = ({ props }) => {
  const [open, setOpen] = React.useState(false);
  const [hasDisplay, setHasDisplay] = React.useState('none');

  React.useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open])

  React.useEffect(() => {
    if (props.hasButton) {
      setHasDisplay('block');
    } else {
      setHasDisplay('none');
    }
  }, [props.hasButton])

  const handleClose = () => {
    setOpen(false)
    props.handleModal()
  }

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-label-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{props.label}</DialogContentText>
          <DialogContentText id="alert-dialog-body-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', color: '#FF0000' }}>{props.body}</DialogContentText>
          <Box sx={{ display: hasDisplay, textAlign: 'center', margin: '20px 10px' }}>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleClose}
            >{props.buttonBody}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertMessageDialog;



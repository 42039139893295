import { createSlice } from '@reduxjs/toolkit';
import { initDrawingObj } from '../data/type';


export const drawingSlice = createSlice({
    name: 'drawing',
    initialState: {
        obj: initDrawingObj,
        showLongText: false,
        pdfPath:'',
    },
    reducers:{
        setObjDrawing: (state, action) => {
            state.obj = action.payload;
        },
        setDrawingLongTextShow: (state, action) => {
            state.showLongText = action.payload;
        },
        updateAddCell: (state, action) => {
            state.obj.handDrawnLayout = [
                ...state.obj.handDrawnLayout,{
                    id: state.obj.handDrawnLayout.length,
                    handDrawnLayoutFormula: action.payload.handDrawnLayoutFormula,
                    handDrawnLayoutSubTotal: action.payload.handDrawnLayoutSubTotal
                }
            ];
        },
        updateDeleteCell: (state, action) => {
            state.obj.handDrawnLayout = state.obj.handDrawnLayout.filter(row => row.id !== action.payload.id);
        },
        updateSubTotalCell: (state, action) => {
            const index = action.payload.id;
            state.obj.handDrawnLayout[index] = {
                ...state.obj.handDrawnLayout[index],
                handDrawnLayoutSubTotal: action.payload.handDrawnLayoutSubTotal,
            };
        },
        updateFormlaCell: (state, action) => {
            const index = action.payload.id;
            state.obj.handDrawnLayout[index] = {
                ...state.obj.handDrawnLayout[index],
                handDrawnLayoutFormula: action.payload.handDrawnLayoutFormula,
            };
        },
        updateTotalCell: (state, action) => {
            state.obj.handDrawnLayoutTotal = state.obj.handDrawnLayout.reduce((total:any, row:any) => total + Number(row.handDrawnLayoutSubTotal), 0);
        },
        setDrawingPdf:(state, action) => {
            state.pdfPath = action.payload;
        },
        setUploadPdf:(state, action) => {
            state.obj.uploadFilePaths = action.payload;
        }

    }
});

export const {
    setObjDrawing,
    setDrawingLongTextShow,
    updateAddCell,
    updateDeleteCell,
    updateSubTotalCell,
    updateFormlaCell,
    updateTotalCell,
    setDrawingPdf,
    setUploadPdf
} = drawingSlice.actions;
export default drawingSlice.reducer;
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
  IconButton
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { apiRequest } from '../../../api/ApiCall';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  StoreOpeningConditionsTypeList,
  StoreOpeningConditionsBooleanTypeList,
  StoreOpeningConditionsStringTypeList,
  initStoreOpeningConditionsCommentTextFieldObj,
  initStoreOpeningConditionsReadOnlyObj,
  initStoreOpeningConditionsShowEditIconObj,
  initStoreOpeningConditionsShowIconObj,
} from '../../../data/type';
import { setSaveStatus } from '../../../features/longPageSlice';
import {
  setObjStoreOpeningConditions,
  setShowPreviousJob,
  setStoreOpeningConditionsLongTextShow
} from '../../../features/storeOpeningConditionsSlice';
import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import { LOCAL_CONSTANT } from '../../Const';
import InputWithIcons from '../../components/InputWithIcons';
import {
  chatBubbleCircleSx,
  chatBubbleIconSx,
  editIconCircleSx,
  iconHover
} from '../../components/ChatBubbleAndEditIconStyle';
import LongTextFiledWithIcons from '../../components/LongTextFiledWithIcons';
import { mojiLimit } from '../../components/MojiLimit';
import { inactiveTextSx } from '../../components/TextFieldStyle';
import { setPermissionFunc } from '../../components/PermissionFunc';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';

//項目値の横幅調整
const itemValueSx = {
  width: '250px'
};

//24時間営業、酒免許、煙草免許のプルダウン スタイル
const selectMonthStyle = (isUserType: boolean) => {
  return {
    '& .MuiSelect-icon': {
      display: 'none'
    },
    '& .MuiSelect-select': {
      textAlign: 'center',
      paddingRight: '0px !important'
    },
    backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
  };
};

// プルダウン スタイル
const selectStyle = (isUserType: boolean) => {
  return {
    textAlign: 'center',
    backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
  };
};

//ラジオボタン スタイル
const radioStyle = (isUserType: boolean) => {
  return {
    color: isUserType ? 'gray' : 'orange',      //未選択時の色指定
    '&.Mui-checked': {
      color: isUserType ? 'gray' : 'orange',  //選択時の色指定
    }
  }
};

//候補地跡地のプルダウン値
const candidateLocationRuinsItems: { [key: string]: string } = {
  candidateLocationRuinsItem1: "なし",
  candidateLocationRuinsItem2: "自社建替え",
  candidateLocationRuinsItem3: "自社跡地",
  candidateLocationRuinsItem4: "競合看板替え",
  candidateLocationRuinsItem5: "競合跡地",
};

//地域区分のプルダウン値
const areaTypeItems: { [key: string]: string } = {
  areaTypeItem1: "指定都市",
  areaTypeItem2: "市制施行地",
  areaTypeItem3: "町村制施行地",
};

//month
const monthItems: { [key: string]: string } = {
  monthItems1: "0",
  monthItems2: "1",
  monthItems3: "2",
  monthItems4: "3",
  monthItems5: "4",
  monthItems6: "5",
  monthItems7: "6",
  monthItems8: "7",
  monthItems9: "8",
  monthItems10: "9",
  monthItems11: "10",
  monthItems12: "11",
  monthItems13: "12",
};

//アスタリスク
const RedAsterisk = styled('span')({
  color: 'red',
  marginLeft: '4px',
  display: 'flex',
  alignItems: 'center',
});

interface SettingReasonProps {
  props: {
    storeOpeningConditions: StoreOpeningConditionsTypeList,
    setStoreOpeningConditions: React.Dispatch<React.SetStateAction<StoreOpeningConditionsTypeList>>
  }
};



export const SettingReason: React.FC<SettingReasonProps> = ({ props }) => {
  const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
  const showPreviousJob = useAppSelector((state) => state.storeOpeningConditions.showPreviousJob);
  //調査種別プルダウンの値を取得
  const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);
  //ロングテキストを表示非表示のステータスを取得
  const objLongTextShow = useAppSelector((state) => state.storeOpeningConditions.showLongText);
  //ロングページオブジェクト 候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //ステータス管理sequenceとタスクIDを取得するため
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //パラメーターオブジェクト
  const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
  //取得要件 項目権限 非活性のため初期はtrue
  const [isAcquisitionRequirement, setIsAcquisitionRequirement] = useState<boolean>(true);
  //移転 ラジオボタン右のテキストフィールド権限 非活性のため初期はtrue
  const [isStoreMoveDistance, setIsStoreMoveDistance] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  //不備コメントモーダル項目名
  const [columnNameStr, setColumnNameStr] = useState<string>("");
  //不備コメントモーダルラベル名
  const [labelNameStr, setLabelNameStr] = useState<string>("");
  //不備コメントモーダル開閉フラグ
  const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);
  //アイコン全体の表示非表示のステータス
  const [storeOpeningConditionsShowIconsObj, setStoreOpeningConditionsShowIconsObj] = useState<StoreOpeningConditionsBooleanTypeList>(initStoreOpeningConditionsShowIconObj);
  //不備コメントモーダル内、TextFieldのコメント
  const [storeOpeningConditionsCommentTextFieldObj, setStoreOpeningConditionsCommentTextFieldObj] = useState<StoreOpeningConditionsStringTypeList>(initStoreOpeningConditionsCommentTextFieldObj);
  //項目値を編集可能にするステータスの型定義
  const [storeOpeningConditionsReadOnlyObj, setStoreOpeningConditionsReadOnlyObj] = useState<StoreOpeningConditionsBooleanTypeList>(initStoreOpeningConditionsReadOnlyObj);

  //ユーザー種別を格納
  const [userType, setUserType] = useState('');

  //コードマスタ
  const [previousJob, setPreviousJob] = useState<any>();                          //前職
  const [ownershipType, setOwnershipType] = useState<any>();                      //所有形態
  const [storeType, setStoreType] = useState<any>();                              //店舗形態
  const [environmentType, setEnvironmentType] = useState<any>();                  //環境区分
  const [acquisitionRequirement, setAcquisitionRequirement] = useState<any>();    //取得要件
  const [storeMoveType, setStoreMoveType] = useState<any>();                      //既存店敷地移転区分

  const user = useUserContext();
  const utilityCtx = useUtilityContext();

  //インプット
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    const targetValue = e.target.value; //入力値
    const storeOpeningConditionsValue = props.storeOpeningConditions[labelName];
    const regexRange0To20 = /^(|0|20|1\d{1}|[1-9])$/;  //入力規則:整数 範囲:0～20
    const regexRange0To9999 = /^(|0|[1-9][0-9]{0,3})$/;  //入力規則:整数 範囲:0～9999

    switch(labelName){
      case 'settingReason': //ゾーン設定の理由
        props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: mojiLimit(targetValue, 180) }); break;
      case 'candidateLocationRuinsLongText': //候補地跡地の理由
        props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: mojiLimit(targetValue, 120) }); break;
      case 'removalReason': //店名選定の理由
        props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: mojiLimit(targetValue, 120) }); break;
      case 'nearestAlreadystoreDistance': // 最寄り既設店までの距離
        if (regexRange0To9999.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'distanceStandardAlreadystoreCount': //距離基準内の既設店舗数
        if (regexRange0To20.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'cvsLastNippan': //前職 CVS直近日販
        if (regexRange0To9999.test(targetValue)) { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      case 'storeMoveDistance': //前職 移転距離
        if (regexRange0To9999.test(targetValue))  { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break; }
        else { props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: storeOpeningConditionsValue }); break; }
      default: props.setStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: targetValue }); break;
    }

    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //Redux チェックボックス
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
    // 所在地表示以外にて選定にチェックを入れた時　下にロングテキストを表示
    if (labelName === "isStoreNameSelectReason") {
      dispatch(setStoreOpeningConditionsLongTextShow({ ...objLongTextShow, removalReason: e.target.checked }));
    }
    // 入力した値をStoreへ登録する
    dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.checked }));
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //Redux プルダウン
  const handleSelectChange = (e: SelectChangeEvent<string>, labelName: string) => {
    // 候補地跡地の項目値が変更したら、前職のテキストフィールドを更新
    if (labelName === "candidateLocationRuins") {
      // 候補地跡地のプルダウンが「なし」、「自社建替え」、「自社跡地」の場合は前職を非表示
      if (e.target.value === "なし") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value, previousJob: "" }));
        dispatch(setShowPreviousJob(false));
        // 候補地跡地のプルダウンの下にロングテキストを非表示
        dispatch(setStoreOpeningConditionsLongTextShow({ ...objLongTextShow, candidateLocationRuins: false }));
      } else if (e.target.value === "自社建替え" || e.target.value === "自社跡地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value, previousJob: "1" }));
        dispatch(setShowPreviousJob(false));
        // 候補地跡地のプルダウンの下にロングテキストを表示
        dispatch(setStoreOpeningConditionsLongTextShow({ ...objLongTextShow, candidateLocationRuins: true }));
        // 候補地跡地のプルダウンが「競合看板替え」、「競合跡地」の場合は前職の「候補地が競合跡地」のみ表示
      } else if (e.target.value === "競合看板替え" || e.target.value === "競合跡地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value, previousJob: "0" }));
        dispatch(setShowPreviousJob(true));
        // 候補地跡地のプルダウンの下にロングテキストを表示
        dispatch(setStoreOpeningConditionsLongTextShow({ ...objLongTextShow, candidateLocationRuins: true }));
      }
    } else {
      // 入力した値をStoreへ登録する
      dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value }));
    }

    //煙草距離基準 マトリックス値
    if(labelName === 'areaType' || labelName === 'environmentType'){
      //指定都市かつ繁華街（A）の場合、25
      if (objStoreOpeningConditions.areaType === "指定都市" && e.target.value === "0") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "25", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "0" && e.target.value === "指定都市") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "25", [labelName]: e.target.value }));
        //指定都市かつ繁華街（B）の場合、50
      } else if (objStoreOpeningConditions.areaType === "指定都市" && e.target.value === "1") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "50", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "1" && e.target.value === "指定都市") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "50", [labelName]: e.target.value }));
        //指定都市かつ市街地の場合、100
      } else if (objStoreOpeningConditions.areaType === "指定都市" && e.target.value === "2") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "100", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "2" && e.target.value === "指定都市") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "100", [labelName]: e.target.value }));
        //指定都市かつ住宅地（A）の場合、200
      } else if (objStoreOpeningConditions.areaType === "指定都市" && e.target.value === "3") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "3" && e.target.value === "指定都市") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
        //指定都市かつ住宅地（B）の場合、300
      } else if (objStoreOpeningConditions.areaType === "指定都市" && e.target.value === "4") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "4" && e.target.value === "指定都市") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
        //市制施行地かつ繁華街（A）の場合、50
      } else if (objStoreOpeningConditions.areaType === "市制施行地" && e.target.value === "0") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "50", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "0" && e.target.value === "市制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "50", [labelName]: e.target.value }));
        //市制施行地かつ繁華街（B）の場合、100
      } else if (objStoreOpeningConditions.areaType === "市制施行地" && e.target.value === "1") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "100", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "1" && e.target.value === "市制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "100", [labelName]: e.target.value }));
        //市制施行地かつ市街地の場合、150
      } else if (objStoreOpeningConditions.areaType === "市制施行地" && e.target.value === "2") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "150", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "2" && e.target.value === "市制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "150", [labelName]: e.target.value }));
        //市制施行地かつ住宅地（A）の場合、200
      } else if (objStoreOpeningConditions.areaType === "市制施行地" && e.target.value === "3") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "3" && e.target.value === "市制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
        //市制施行地かつ住宅地（B）の場合、300
      } else if (objStoreOpeningConditions.areaType === "市制施行地" && e.target.value === "4") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "4" && e.target.value === "市制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
        //町村制施行地かつ繁華街（A）の場合、空白
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "0") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "0" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
        //町村制施行地かつ繁華街（A）の場合、空白
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "0") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "0" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
        //町村制施行地かつ繁華街（B）の場合、空白
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "1") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "1" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "", [labelName]: e.target.value }));
        //町村制施行地か市街地の場合、150
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "2") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "150", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "2" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "150", [labelName]: e.target.value }));
        //町村制施行地か住宅地（A）の場合、200
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "3") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "3" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "200", [labelName]: e.target.value }));
        //町村制施行地か住宅地（B）の場合、300
      } else if (objStoreOpeningConditions.areaType === "町村制施行地" && e.target.value === "4") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
      } else if (objStoreOpeningConditions.environmentType === "4" && e.target.value === "町村制施行地") {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, tobaccoDistanceStandard: "300", [labelName]: e.target.value }));
      }
    }
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //Redux ラジオボタン
  const handleRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
    // 入力した値をStoreへ登録する
    dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, [labelName]: e.target.value }));
    //更新フラグ
    if (!objLongPageSaveStatus.StoreOpeningConditions) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['StoreOpeningConditions']: true }));
  };

  //入力不可のインプットをクリックしたときアイコンを表示
  const handleInputClick = (labelName: string) => {
    setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: true });
  };

  // 吹き出しをクリックしたとき、モーダル画面を開く
  const handleChatBubbleClick = (columnName: string, labelName: string) => {
    setColumnNameStr(columnName);
    setLabelNameStr(labelName);
    setDeficiencyComentModalFlag(true);
    setStoreOpeningConditionsCommentTextFieldObj({ ...storeOpeningConditionsCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
  };

  //不備コメントモーダル内でのコメント取得
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
    setStoreOpeningConditionsCommentTextFieldObj({
      ...storeOpeningConditionsCommentTextFieldObj,
      [labelName]: e.target.value,
    });
  };

  //不備コメントモーダル内の保存ボタンをクリックしたとき不備コメントを保存
  const handleSaveComment = (columnName: string, labelName: string) => {
    //不備コメントを登録
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/regist",
      query: {
        plan_area_id: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        status_seq: objLocationBase.status_seq,
        table_name: "location_storeopeningconditions",
        column_name: columnName,
        comment: storeOpeningConditionsCommentTextFieldObj[labelName],
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
        //アイコンを非表示
        setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
        //不備コメントモーダルを閉じる
        setDeficiencyComentModalFlag(false);
        //アイコンを非表示
        setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
      }
    })();
  };

  // 鉛筆をクリックしたとき、Input編集可
  const handleEditIconClick = (labelName: string) => {
    if (setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, labelName) === false) {
      //falseにすると、Input編集可
      setStoreOpeningConditionsReadOnlyObj({ ...storeOpeningConditionsReadOnlyObj, [labelName]: false });
      if(labelName === 'acquisitionRequirement'){ setIsAcquisitionRequirement(false); }
    }
  };

  // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
    //Reduxへ保存
    dispatch(setObjStoreOpeningConditions({ ...props.storeOpeningConditions, [labelName]: e.target.value }));

    // インプット編集不可
    setStoreOpeningConditionsReadOnlyObj({ ...storeOpeningConditionsReadOnlyObj, [labelName]: true });

    //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
    if (!(e.relatedTarget)) {
      setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
    }

    //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
    if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
      setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
    }

    //テキストエリアからフォーカスが移動する先がテキストエリアタグの場合アイコンを閉じる
    if (e.relatedTarget && e.relatedTarget.tagName === "TEXTAREA") {
      setStoreOpeningConditionsShowIconsObj({ ...storeOpeningConditionsShowIconsObj, [labelName]: false });
    }
  };

  //ロングテキスト入力制御 
  const handleKeyDown = (e: any, labelName: string) => {
    if (labelName === 'settingReason' && e.key === 'Enter' && e.target.value.split('\n').length >= 3) {
      e.preventDefault();
    } else if (labelName === 'candidateLocationRuinsLongText' && e.key === 'Enter' && e.target.value.split('\n').length >= 2) {
      e.preventDefault();
    } else if (labelName === 'removalReason' && e.key === 'Enter' && e.target.value.split('\n').length >= 2) {
      e.preventDefault();
    }
  };

  //コードマスタ取得
  useEffect(() => {
    const res: any = localStorage.getItem('codeMaster');
    if (res) {
      const d = JSON.parse(res);
      //コードマスター
      setPreviousJob(d['previous_job']);                       //前職
      setOwnershipType(d['ownership_type']);                   //所有形態
      setStoreType(d['store_type']);                           //店舗形態
      setEnvironmentType(d['environment_type']);               //環境区分
      setAcquisitionRequirement(d['acquisition_requirement']); //取得要件
      setStoreMoveType(d['store_move_type']);                  //既存店敷地移転区分
    }
  }, []);

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    if (JSON.parse(user.userData || 'null') !== null) {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  //取得要件 項目の権限
  useEffect(() => {
    const permisson: boolean = setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'acquisitionRequirement');
    if (userType === 'z003') { //RFC
      if (permisson === true) { //非活性の場合は、空になる処理のみ実施
        setIsAcquisitionRequirement(true);  ////取得要件 非活性
        //最寄既設店までの距離が空の場合 取得要件を空に設定
        if(objStoreOpeningConditions.nearestAlreadystoreDistance === ""){
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        //煙草免許(無)が12か月の場合 取得要件を空に設定
        } else if((12 - Number(objStoreOpeningConditions.tobaccoLicence)) === 12){
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        //煙草免許(無)が12か月未満かつ煙草距離基準 < 最寄既設店までの距離の場合 不要項目
        } else if (((12 - Number(objStoreOpeningConditions.tobaccoLicence)) < 12) && Number(objStoreOpeningConditions.tobaccoDistanceStandard) < Number(objStoreOpeningConditions.nearestAlreadystoreDistance)) {
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        }
      }
      if (permisson === false) { //活性の場合
        setIsAcquisitionRequirement(false);  //取得要件 活性
        //最寄既設店までの距離が空の場合 取得要件を非活性化＆空に設定
        if(objStoreOpeningConditions.nearestAlreadystoreDistance === ""){
          setIsAcquisitionRequirement(true);  //非活性
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        //煙草免許(無)が12か月の場合　取得要件を非活性化＆空に設定
        }else if((12 - Number(objStoreOpeningConditions.tobaccoLicence)) === 12){
          setIsAcquisitionRequirement(true);  //非活性
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        //煙草免許(無)が12か月未満かつ煙草距離基準 < 最寄既設店までの距離の場合 不要項目
        } else if (((12 - Number(objStoreOpeningConditions.tobaccoLicence)) < 12) && Number(objStoreOpeningConditions.tobaccoDistanceStandard) < Number(objStoreOpeningConditions.nearestAlreadystoreDistance)) {
          setIsAcquisitionRequirement(true);  //非活性
          dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
        } else {
          setIsAcquisitionRequirement(false);  //活性
        }
      }
    }
    if (userType === 'z001') { //リサーチ
      setIsAcquisitionRequirement(true);  //取得要件 非活性
      //最寄既設店までの距離が空の場合 取得要件を空に設定
      if(objStoreOpeningConditions.nearestAlreadystoreDistance === ""){
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
      //煙草免許(無)が12か月の場合 取得要件を空に設定
      } else if((12 - Number(objStoreOpeningConditions.tobaccoLicence)) === 12){
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
      //煙草免許(無)が12か月未満かつ煙草距離基準 < 最寄既設店までの距離の場合 不要項目
      } else if (((12 - Number(objStoreOpeningConditions.tobaccoLicence)) < 12) && Number(objStoreOpeningConditions.tobaccoDistanceStandard) < Number(objStoreOpeningConditions.nearestAlreadystoreDistance)) {
        dispatch(setObjStoreOpeningConditions({ ...objStoreOpeningConditions, acquisitionRequirement: '' }));  //空
      }
    }
  }, [objStoreOpeningConditions.nearestAlreadystoreDistance,
      objStoreOpeningConditions.tobaccoDistanceStandard,
      objStoreOpeningConditions.tobaccoLicence,
      userType
     ]);

  //移転 ラジオボタン右のテキストフィールド権限
  useEffect(() => {
    if (objStoreOpeningConditions.storeMoveType === '1') {
      const permisson: boolean = setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveDistance');
      if (permisson === true) {  //非活性の場合
        setIsStoreMoveDistance(true);  //非活性
      }
      if (permisson === false) { //活性の場合
        setIsStoreMoveDistance(false);  //活性
      }
    } else {
      setIsStoreMoveDistance(true);
    }
  }, [objStoreOpeningConditions.storeMoveType, userType]);

  useEffect(() => {
    //ReduxをuseStateに保存
    props.setStoreOpeningConditions(objStoreOpeningConditions);
  }, [objStoreOpeningConditions]);

  return (
    <Box>


      <Typography component="h2" mt={2}>１．設定の理由について​</Typography>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>ゾーン設定の理由</Typography>
                {/* 調査書種別が純増、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '04') && <RedAsterisk sx={{ whiteSpace: "nowrap" }}>＊＊</RedAsterisk>}
              </Box>
            </Grid>
            <Grid>
              <LongTextFiledWithIcons props={{
                userType: userType, //ユーザー
                disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'settingReason'), //ユーザー権限
                pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                columnName: 'setting_reason',
                labelName: 'settingReason',
                rows: 3, //ロングテキストの行数 全角180文字
                width: '1000px',//テキストフィールドの幅
                InputValueObj: props.storeOpeningConditions,
                ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                ShowIconsObj: storeOpeningConditionsShowIconsObj,
                handleChatBubbleClick: handleChatBubbleClick,
                handleInputBlur: handleInputBlur,
                handleInputChange: handleInputChange,
                handleInputClick: handleInputClick,
                handleKeyDown: handleKeyDown
              }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>候補地跡地</Typography>
                {/* 調査書種別が純増の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={{ width: '200px' }}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'candidateLocationRuins')}
                    value={objStoreOpeningConditions.candidateLocationRuins}
                    sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'candidateLocationRuins') : storeOpeningConditionsReadOnlyObj.candidateLocationRuins)}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'candidateLocationRuins') : storeOpeningConditionsReadOnlyObj.candidateLocationRuins}
                  >
                    {Object.keys(candidateLocationRuinsItems).map((key) => (
                      <MenuItem key={key} value={candidateLocationRuinsItems[key]}>{candidateLocationRuinsItems[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'candidateLocationRuins')}
                    onClick={() => { handleChatBubbleClick('candidate_location_ruins', 'candidateLocationRuins') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'candidateLocationRuins'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('candidateLocationRuins') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* 候補地跡地のプルダウンで選択した場合ロングテキストを表示 */}
      {objLongTextShow.candidateLocationRuins && (
        <>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                    <Typography sx={{ width: '80px' }}></Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Box>
                    <Typography>①撤退理由 ②撤退・開店予定日 ③活性化策の具体的な内容を記載</Typography>
                    <Typography>※競合看板替えの場合は現行契約が「普通」ｏｒ「定期」を明記</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                    <Typography sx={{ width: '80px' }}></Typography>
                    {/* 調査書種別が純増の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '01' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid>
                  <LongTextFiledWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'candidateLocationRuinsLongText'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'candidate_location_ruins_long_text',
                    labelName: 'candidateLocationRuinsLongText',
                    rows: 2, //ロングテキストの行数 全角120文字
                    width: '1000px',//テキストフィールドの幅
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick,
                    handleKeyDown: handleKeyDown
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Typography component="h2" mt={2}>２．店名選定の理由について</Typography>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '95px', paddingLeft: '50px' }}>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleCheckBoxChange(e, 'isStoreNameSelectReason')}
                      checked={objStoreOpeningConditions.isStoreNameSelectReason}
                      disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'isStoreNameSelectReason') : storeOpeningConditionsReadOnlyObj.isStoreNameSelectReason}
                    ></Checkbox>
                  }
                  label="所在地表示以外にて選定（選定根拠を下記に記載）"
                />
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'isStoreNameSelectReason')}
                    onClick={() => { handleChatBubbleClick('is_store_name_select_reason', 'isStoreNameSelectReason') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'isStoreNameSelectReason'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('isStoreNameSelectReason') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* 所在地表示以外にて選定にチェックを入れた時　下にロングテキストを表示 */}
      {objLongTextShow.removalReason && (
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '95px', paddingLeft: '50px' }}>
                  {/* 調査書種別が純増、S＆B（B店）、セットバック（改造後）、立地評価（タイプ変更後）の場合のときにアスタリスクを表示 */}
                  {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                </Box>
              </Grid>
              <Grid>
                <LongTextFiledWithIcons props={{
                  userType: userType, //ユーザー
                  disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'removalReason'), //ユーザー権限
                  pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                  columnName: 'removal_Reason',
                  labelName: 'removalReason',
                  rows: 2,//ロングテキストの行数 全角120文字
                  width: '1000px',//テキストフィールドの幅
                  InputValueObj: props.storeOpeningConditions,
                  ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                  setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                  ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                  ShowIconsObj: storeOpeningConditionsShowIconsObj,
                  handleChatBubbleClick: handleChatBubbleClick,
                  handleInputBlur: handleInputBlur,
                  handleInputChange: handleInputChange,
                  handleInputClick: handleInputClick,
                  handleKeyDown: handleKeyDown
                }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* 営業条件 */}
      <Typography component="h2" mt={2}>営業条件</Typography>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>24時間営業</Typography>
                <RedAsterisk>＊</RedAsterisk>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'open24Hours')}
                    value={objStoreOpeningConditions.open24Hours}
                    sx={selectMonthStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'open24Hours') : storeOpeningConditionsReadOnlyObj.open24Hours)}
                    startAdornment={<InputAdornment position="start">{LOCAL_CONSTANT.LABEL.YES}</InputAdornment>}
                    endAdornment={<InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'open24Hours') : storeOpeningConditionsReadOnlyObj.open24Hours}
                  >
                    {Object.keys(monthItems).map((key) => (
                      <MenuItem key={key} value={monthItems[key]}>{monthItems[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={itemValueSx}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={String(12 - Number(objStoreOpeningConditions.open24Hours))}
                  sx={inactiveTextSx}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{LOCAL_CONSTANT.LABEL.NOTHING}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>
                  }}
                  variant="filled"
                />
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'open24Hours')}
                    onClick={() => { handleChatBubbleClick('open24_hours', 'open24Hours') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'open24Hours'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('open24Hours') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>酒免許</Typography>
                <RedAsterisk>＊</RedAsterisk>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'alcoholLicense')}
                    value={objStoreOpeningConditions.alcoholLicense}
                    sx={selectMonthStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'alcoholLicense') : storeOpeningConditionsReadOnlyObj.alcoholLicense)}
                    startAdornment={<InputAdornment position="start">{LOCAL_CONSTANT.LABEL.YES}</InputAdornment>}
                    endAdornment={<InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'alcoholLicense') : storeOpeningConditionsReadOnlyObj.alcoholLicense}
                  >
                    {Object.keys(monthItems).map((key) => (
                      <MenuItem key={key} value={monthItems[key]}>{monthItems[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={itemValueSx}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={String(12 - Number(objStoreOpeningConditions.alcoholLicense))}
                  sx={inactiveTextSx}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{LOCAL_CONSTANT.LABEL.NOTHING}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>,
                  }}
                  variant="filled"
                />
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'alcoholLicense')}
                    onClick={() => { handleChatBubbleClick('alcohol_license', 'alcoholLicense') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'alcoholLicense'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('alcoholLicense') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>煙草免許</Typography>
                <RedAsterisk>＊</RedAsterisk>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'tobaccoLicence')}
                    value={objStoreOpeningConditions.tobaccoLicence}
                    sx={selectMonthStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'tobaccoLicence') : storeOpeningConditionsReadOnlyObj.tobaccoLicence)}
                    startAdornment={<InputAdornment position="start">{LOCAL_CONSTANT.LABEL.YES}</InputAdornment>}
                    endAdornment={<InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'tobaccoLicence') : storeOpeningConditionsReadOnlyObj.tobaccoLicence}
                  >
                    {Object.keys(monthItems).map((key) => (
                      <MenuItem key={key} value={monthItems[key]}>{monthItems[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={itemValueSx}>
                <TextField
                  fullWidth
                  disabled={true}
                  value={String(12 - Number(objStoreOpeningConditions.tobaccoLicence))}
                  sx={inactiveTextSx}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{LOCAL_CONSTANT.LABEL.NOTHING}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{LOCAL_CONSTANT.LABEL.MONTHS}</InputAdornment>,
                  }}
                  variant="filled"
                />
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'tobaccoLicence')}
                    onClick={() => { handleChatBubbleClick('tobacco_licence', 'tobaccoLicence') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'tobaccoLicence'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('tobaccoLicence') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* 空行を入れる */}
      <Grid container maxWidth="lg">
        <Grid xs={12} height={30}></Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>地域区分</Typography>
                {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'areaType')}
                    value={objStoreOpeningConditions.areaType}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'areaType') : storeOpeningConditionsReadOnlyObj.areaType}
                    sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'areaType') : storeOpeningConditionsReadOnlyObj.areaType)}
                  >
                    {Object.keys(areaTypeItems).map((key) => (
                      <MenuItem key={key} value={areaTypeItems[key]}>{areaTypeItems[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'areaType')}
                    onClick={() => { handleChatBubbleClick('area_type', 'areaType') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'areaType'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('areaType') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                <Typography>最寄り既設店までの距離</Typography>
                {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
              </Box>
            </Grid>
            <Grid sx={itemValueSx}>
              <InputWithIcons props={{
                userType: userType, //ユーザー
                disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'nearestAlreadystoreDistance'), //ユーザー権限
                pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                columnName: 'nearest_already_store_distance',
                labelName: 'nearestAlreadystoreDistance',
                startAdornment: '',
                endAdornment: 'm',
                InputValueObj: props.storeOpeningConditions,
                ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                ShowIconsObj: storeOpeningConditionsShowIconsObj,
                handleChatBubbleClick: handleChatBubbleClick,
                handleInputBlur: handleInputBlur,
                handleInputChange: handleInputChange,
                handleInputClick: handleInputClick
              }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>環境区分</Typography>
                {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'environmentType')}
                    value={objStoreOpeningConditions.environmentType}
                    disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'environmentType') : storeOpeningConditionsReadOnlyObj.environmentType}
                    sx={selectStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'environmentType') : storeOpeningConditionsReadOnlyObj.environmentType)}
                  >
                    {
                      environmentType?.map((row: any) => (
                        <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'environmentType')}
                    onClick={() => { handleChatBubbleClick('environment_type', 'environmentType') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'environmentType'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('environmentType') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                <Typography>取得要件</Typography>
                {/* 調査書種別が純増、S＆B（B店）、セットバック（改造後）、立地評価（タイプ変更後）の場合のときにアスタリスクを表示 */}
                {((selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && isAcquisitionRequirement === false) && <RedAsterisk>＊＊</RedAsterisk>}
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelectChange(e, 'acquisitionRequirement')}
                    value={objStoreOpeningConditions.acquisitionRequirement}
                    disabled={isAcquisitionRequirement}
                    sx={selectStyle(isAcquisitionRequirement)}
                  >
                    {
                      acquisitionRequirement?.map((row: any) => (
                        <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                      ))
                    }
                    {/* プルダウンに空欄を追加 */}
                    <MenuItem value="">　</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '150px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'acquisitionRequirement')}
                    onClick={() => { handleChatBubbleClick('acquisition_requirement', 'acquisitionRequirement') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'acquisitionRequirement'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('acquisitionRequirement') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                <Typography>煙草距離基準</Typography>
                {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
              </Box>
            </Grid>
            <Grid sx={itemValueSx}>
              <TextField
                fullWidth
                disabled={true}
                value={objStoreOpeningConditions.tobaccoDistanceStandard}
                sx={inactiveTextSx}
                InputProps={{
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  endAdornment: <InputAdornment position="end">m</InputAdornment>,
                }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '140px' }}>

                <Typography width={100}>距離基準内の既設店舗数</Typography>
                {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊</RedAsterisk>}
              </Box>
            </Grid>
            <Grid sx={itemValueSx}>
              <InputWithIcons props={{
                userType: userType, //ユーザー
                disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'distanceStandardAlreadystoreCount'), //ユーザー権限
                pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                columnName: 'distance_standard_alreadystore_count',
                labelName: 'distanceStandardAlreadystoreCount',
                startAdornment: '',
                endAdornment: '',
                InputValueObj: props.storeOpeningConditions,
                ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                ShowIconsObj: storeOpeningConditionsShowIconsObj,
                handleChatBubbleClick: handleChatBubbleClick,
                handleInputBlur: handleInputBlur,
                handleInputChange: handleInputChange,
                handleInputClick: handleInputClick
              }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* 前職 */}
      <Typography component="h2" mt={2}>前職</Typography>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Typography width={120}>前職</Typography>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid sx={itemValueSx}>
                {objStoreOpeningConditions.previousJob === "0" || objStoreOpeningConditions.previousJob === "1" ?
                  (
                    <TextField
                      fullWidth
                      disabled
                      value={objStoreOpeningConditions.previousJob === "0" ? previousJob?.[0].code_display : previousJob?.[1].code_display}
                      sx={inactiveTextSx}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                      }}
                      variant="filled"
                    />
                  ) : (
                    <TextField
                      fullWidth
                      sx={inactiveTextSx}
                      value=""
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                      }}
                      variant="filled"
                    />
                  )
                }
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType')}
                    onClick={() => { handleChatBubbleClick('previous_job', 'storeMoveType') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('storeMoveType') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}></Grid>
      </Grid>

      {showPreviousJob && (
        <Box>
          <Grid container spacing={2} maxWidth="lg">
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                    <Typography>元競合CVS</Typography>
                    {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'conflictCVS'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'conflict_cvs',
                    labelName: 'conflictCVS',
                    startAdornment: '',
                    endAdornment: '',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                    <Typography width={100}>CVS 直近日販</Typography>
                    {/* 調査書種別が純増、セットバック（改造後）、立地評価（タイプ変更後）、S＆B（B店）の場合のときにアスタリスクを表示 */}
                    {(selectedSurveyType === '01' || selectedSurveyType === '02' || selectedSurveyType === '03' || selectedSurveyType === '04') && <RedAsterisk>＊＊</RedAsterisk>}
                  </Box>
                </Grid>
                <Grid sx={itemValueSx}>
                  <InputWithIcons props={{
                    userType: userType, //ユーザー
                    disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'cvsLastNippan'), //ユーザー権限
                    pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                    columnName: 'cvs_last_nippan',
                    labelName: 'cvsLastNippan',
                    startAdornment: '',
                    endAdornment: '万円',
                    InputValueObj: props.storeOpeningConditions,
                    ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                    setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                    ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                    ShowIconsObj: storeOpeningConditionsShowIconsObj,
                    handleChatBubbleClick: handleChatBubbleClick,
                    handleInputBlur: handleInputBlur,
                    handleInputChange: handleInputChange,
                    handleInputClick: handleInputClick
                  }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* 前職が「セブン-イレブン店」（上の候補地跡地が自社建て替え、自社跡地の時）の時のみ、プルダウンと距離を表示 */}
      {objStoreOpeningConditions.previousJob === "1" &&
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid>
                <Typography width={120}></Typography>
              </Grid>
              <Grid>
                <FormControl>
                  <RadioGroup
                    value={objStoreOpeningConditions.storeMoveType}
                    onChange={(e) => handleRadioButtonChange(e, 'storeMoveType')}
                  >
                    <FormControlLabel
                      sx={{ width: '110px' }}
                      value={storeMoveType?.[0].code_value}
                      control={<Radio
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType') : storeOpeningConditionsReadOnlyObj.storeMoveType}
                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType') : storeOpeningConditionsReadOnlyObj.storeMoveType)}
                      />}
                      label={storeMoveType?.[0].code_display} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        sx={{ width: '110px' }}
                        value={storeMoveType?.[1].code_value}
                        control={<Radio
                          disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType') : storeOpeningConditionsReadOnlyObj.storeMoveType}
                          sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeMoveType') : storeOpeningConditionsReadOnlyObj.storeMoveType)}
                        />}
                        label={storeMoveType?.[1].code_display}
                      />
                      <InputWithIcons props={{
                        userType: userType, //ユーザー
                        disabled: isStoreMoveDistance, //ユーザー権限
                        pageName: LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                        columnName: 'previous_job',
                        labelName: 'storeMoveDistance',
                        startAdornment: '',
                        endAdornment: 'm',
                        InputValueObj: props.storeOpeningConditions,
                        ReadOnlyObj: storeOpeningConditionsReadOnlyObj,
                        setReadOnlyObj: setStoreOpeningConditionsReadOnlyObj,
                        ShowEditIconObj: initStoreOpeningConditionsShowEditIconObj,
                        ShowIconsObj: storeOpeningConditionsShowIconsObj,
                        handleChatBubbleClick: handleChatBubbleClick,
                        handleInputBlur: handleInputBlur,
                        handleInputChange: handleInputChange,
                        handleInputClick: handleInputClick
                      }} />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }

      {/* その他 */}
      <Typography component="h2" mt={2}>その他</Typography>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                <Typography>所有形態</Typography>
                <RedAsterisk>＊</RedAsterisk>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid>
                <FormControl>
                  <RadioGroup
                    row
                    value={objStoreOpeningConditions.ownershipType}
                    onChange={(e) => handleRadioButtonChange(e, 'ownershipType')}
                  >
                    <FormControlLabel
                      sx={{ width: '110px' }}
                      value={ownershipType?.[0].code_value}
                      control={<Radio
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType') : storeOpeningConditionsReadOnlyObj.ownershipType}
                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType') : storeOpeningConditionsReadOnlyObj.ownershipType)}
                      />}
                      label={ownershipType?.[0].code_display} />
                    <FormControlLabel
                      value={ownershipType?.[1].code_value}
                      control={<Radio
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType') : storeOpeningConditionsReadOnlyObj.ownershipType}
                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType') : storeOpeningConditionsReadOnlyObj.ownershipType)}
                      />}
                      label={ownershipType?.[1].code_display} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType')}
                    onClick={() => { handleChatBubbleClick('ownership_type', 'ownershipType') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'ownershipType'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('ownershipType') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth="lg">
        <Grid xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                <Typography>店舗形態</Typography>
                <RedAsterisk>＊</RedAsterisk>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <Grid>
                <FormControl>
                  <RadioGroup
                    row
                    value={objStoreOpeningConditions.storeType}
                    onChange={(e) => handleRadioButtonChange(e, 'storeType')}
                  >
                    <FormControlLabel
                      sx={{ width: '110px' }}
                      value={storeType?.[0].code_value}
                      control={<Radio
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType}
                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType)}
                      />}
                      label={storeType?.[0].code_display} />
                    <FormControlLabel
                      value={storeType?.[1].code_value}
                      control={<Radio
                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType}
                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType)}
                      />}
                      label={storeType?.[1].code_display} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleCheckBoxChange(e, 'storeTypeMultipleStore')}
                          checked={objStoreOpeningConditions.storeTypeMultipleStore}
                          disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType}
                          sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType') : storeOpeningConditionsReadOnlyObj.storeType)}
                        ></Checkbox>
                      }
                      label={storeType?.[2].code_display}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
              {userType === 'z001' &&
                <Grid sx={{ width: '200px' }}>
                  <IconButton
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType')}
                    onClick={() => { handleChatBubbleClick('store_type', 'storeType') }}
                    sx={iconHover}
                  >
                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS, 'storeType'))}>
                      <ChatBubbleIcon sx={chatBubbleIconSx} />
                    </Box>
                  </IconButton>
                  <IconButton
                    onClick={() => { handleEditIconClick('storeType') }}
                    sx={iconHover}
                  >
                    <Box sx={editIconCircleSx}>
                      <EditIcon />
                    </Box>
                  </IconButton>
                </Grid>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/*不備コメント モーダル画面 */}
      <DeficiencyCommentRegistModal
        props={{
          modalOpen: deficiencyComentModalFlag,
          setModalOpen: setDeficiencyComentModalFlag,
          CommentTextFieldObj: storeOpeningConditionsCommentTextFieldObj,
          columnName: columnNameStr,
          labelName: labelNameStr,
          handleCommentChange: handleCommentChange,
          handleSaveComment: handleSaveComment,
        }} />

    </Box>
  )
}

export default SettingReason;

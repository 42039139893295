import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    locationTraffic5mcutObj,
    locationTrafficStationObj,
    locationTrafficSchoolObj,
    initCodeMasterObj
 } from '../data/type';

//Sliceの作成
export const locationTraffic5mcutSlice = createSlice({
    name: 'locationTraffic5mcut',
    initialState: {
        obj: locationTraffic5mcutObj,
        stationObj: locationTrafficStationObj,
        schoolObj: locationTrafficSchoolObj,
        initSelectList: initCodeMasterObj,
        selectList: initCodeMasterObj, //通行量 その他 3行目施設プルダウン
        selectList2: initCodeMasterObj //通行量 その他 4行目施設プルダウン
    },
    //toolKitを利用した値変更処理
    reducers: {
        setObjLocationTraffic5mcut: (state, action) => {
            state.obj = action.payload;
        },
        setObjLocationTraffic5mcutOtherTable: (state, action) => {
            state.obj.others_value_1 = action.payload.others_value_1;
            state.obj.others_value_2 = action.payload.others_value_2;
            state.obj.others_facility_3 = action.payload.others_facility_3;
            state.obj.others_name_3 = action.payload.others_name_3;
            state.obj.others_distance_3 = action.payload.others_distance_3;
            state.obj.others_value_3 = action.payload.others_value_3;
            state.obj.others_facility_4 = action.payload.others_facility_4;
            state.obj.others_name_4 = action.payload.others_name_4;
            state.obj.others_distance_4 = action.payload.others_distance_4;
            state.obj.others_value_4 = action.payload.others_value_4;
        },
        setObjLocationTraffic5mcutStation:(state, action) => {
            state.stationObj = action.payload;
        },
        setObjLocationTraffic5mcutSchool:(state, action) => {
            state.schoolObj = action.payload;
        },
        setInitSelectList:(state, action) => {
            state.initSelectList = action.payload;
        },
        setSelectList:(state, action) => {      //通行量 その他 3行目施設プルダウン
            state.selectList = action.payload;
        },
        setSelectList2:(state, action) => {     //通行量 その他 4行目施設プルダウン
            state.selectList2 = action.payload;
        }
    }
});

export const {
    setObjLocationTraffic5mcut,
    setObjLocationTraffic5mcutStation,
    setObjLocationTraffic5mcutSchool,
    setObjLocationTraffic5mcutOtherTable,
    setInitSelectList,
    setSelectList,
    setSelectList2,
} = locationTraffic5mcutSlice.actions;
export default locationTraffic5mcutSlice.reducer;
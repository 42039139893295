import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    Checkbox,
    Select,
    MenuItem,
    FormLabel,
    SelectChangeEvent,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import {
    dateDisplayByType,
    timeDisplay,
    borderStyle,
    borderStyleColor,
    hasDisabled,
    addTrafficParams,
    initCountDate,
    borderStyleDisabled,
    upsertLocationTraffic5mcutParam,
} from "./TrafficVolumeFunction";
import { LOCAL_CONSTANT } from "../../Const";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setObjLocationTraffic5mcut } from '../../../features/locationTraffic5mcutSlice';

export interface addCarProps {
    props:{
        trafficVolumeList:any;
        handleClose: any;
        handleCloseRegist: any;
    }
}
interface typeListInterface {
    [key: string]: any
}

const COUNT_TYPE_USER = '3'

const typeList = {
    'morning': 0,
    'noon': 0,
    'night': 0,
    'Holidays': 0
} as typeListInterface

interface userInputDataInterface {
    [key: string]: any
}

const initUserInputData =  {
    'morning': {'key': 'morning', 'checked': false, 'disabled': false},
    'noon': {'key': 'noon', 'checked': false, 'disabled': false},
    'night': {'key': 'night', 'checked': false, 'disabled': false},
    'Holidays': {'key': 'Holidays', 'checked': false, 'disabled': false}    
} as userInputDataInterface

const checkBoxList = () => {
    return [
        {key: 'morning'},
        {key: 'noon'},
        {key: 'night'},
        {key: 'Holidays'},
    ]
}

const AddCar:React.FC<addCarProps> = ({props}) => {
    const dispatch = useAppDispatch();

    const [list, setList] = React.useState([]);
    const [checkList, setCheckList] = React.useState(JSON.parse(JSON.stringify(typeList)))
    const [redo, setRedo] = React.useState<boolean>(false)
    const [userInputData, setUserInputData] = React.useState(JSON.parse(JSON.stringify(initUserInputData)))
    const utilityCtx = useUtilityContext();
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // 通行量(5m外歩行者TR算入確認)
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    // 通行量 その他 駅出入口の名称と距離 
    const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
    // 通行量 その他 大学短大の名称と距離 
    const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
    const [separateInvestigation, setSeparateInvestigation] = React.useState<any>();
    const [separateValue, setSeparateValue] = React.useState<string>(objLocationTraffic5mcut.car_another_survey)

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            setSeparateInvestigation(d['separate_investigation'])
        }
    }, [])

    // 閉じる
    const handleClose = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleClose()
    };

    const handleCloseRegist = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleCloseRegist()
    };


    React.useEffect(() => {
        const tmpCarList: any = []
        props.trafficVolumeList.map((row: any) => {
            row.morning = 0
            row.noon = 0
            row.night = 0
            row.Holidays = 0
            tmpCarList.push(row)
        })
        setList(tmpCarList)
    }, [props.trafficVolumeList])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>, row: any, target: string) => {
        let hasSelect = true
        if (e.target.checked === true && checkList[target] >= 2) {
            hasSelect = false
        }
        if (hasSelect) {
            let tmp: any = checkList
            if (row[target] === 1) {
                row[target] = 0
                tmp[target] -= 1
                if (tmp[target] === 0) {
                    userInputData[target].disabled = false
                    setUserInputData(userInputData)    
                }
            } else {
                row[target] = row[target] + 1
                tmp[target] += 1
                userInputData[target].disabled = true
                setUserInputData(userInputData)
            }
            setRedo(!redo)
            setCheckList(tmp)    
        }
    }

    const hasChecked = (val: number) => {
        return val > 0 ? true : false
    }

    const checkBoxStyle = () => {
        return {
            width: '25%',
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: '0',
            '&.Mui-checked': {
              color: blue[800],
            },
        }
    }

    const hasBC = (row: any) => {
        let color = '#FFFFFF'
        if (
            row.morning === 1 ||
            row.noon === 1 ||
            row.night === 1 ||
            row.Holidays === 1
        ) {
            color = '#FFFF00'
        }

        return color
    }

    const handleRegist = () => {
        let errorMessage = ""
        if ((checkList.morning < 2 && userInputData['morning'].checked === false) ||
            (checkList.noon < 2 && userInputData['noon'].checked === false)) {
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_MORNING_NOON
        }
        if (separateValue === "1" && (checkList.night < 2 && userInputData['night'].checked === false) ) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_NIGHT
        } else if (separateValue === "2" && (checkList.Holidays < 2 && userInputData['Holidays'].checked === false) ) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_HOLIDAYS
        } else if (separateValue === "3"
            && (checkList.night < 2 && userInputData['night'].checked === false)
            && (checkList.Holidays < 2 && userInputData['Holidays'].checked === false)
        ) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_NIGHT
            errorMessage += "\n" + LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_HOLIDAYS
        }
        if (errorMessage) {
            const message: any = errorMessage.slice(0, -1).split("\n").map((line, key) => <span key={key}>{line}<br /></span>)
            if (utilityCtx.showSnackbar) {
                utilityCtx.showSnackbar("warning", message);
            }
            return
        }
        const requestParams: any = []
        let traffic_type_seq_morning = 0
        let traffic_type_seq_noon = 0
        let traffic_type_seq_night = 0
        let traffic_type_seq_Holidays = 0
        let trafficType = -1
        let type_seq = 0
        let date = new Date();
        let count_date = ''
        if (userInputData['morning'].checked) {
            count_date = initCountDate(date, '9', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['noon'].checked) {
            count_date = initCountDate(date, '13', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['night'].checked) {
            count_date = initCountDate(date, '20', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['Holidays'].checked) {
            count_date = initCountDate(date, '10', '30')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        list.map((row: any) => {
            if (row.morning === 1) {
                trafficType = 0
                traffic_type_seq_morning += 1
                type_seq = traffic_type_seq_morning
            } else if  (row.noon === 1) {
                trafficType = 1
                traffic_type_seq_noon += 1
                type_seq = traffic_type_seq_noon
            } else if (row.night === 1) {
                trafficType = 2
                traffic_type_seq_night += 1
                type_seq = traffic_type_seq_night
            } else if (row.Holidays === 1) {
                trafficType = 4
                traffic_type_seq_Holidays += 1
                type_seq = traffic_type_seq_Holidays
            }
            if (trafficType >= 0) {
                requestParams.push({
                    plan_area_id: objLongPageParam.planAreaId.toString(),
                    branch: objLongPageParam.branch.toString(),
                    traffic_type: trafficType.toString(),
                    traffic_type_seq: type_seq.toString(),
                    counter_uuid: row.uuid.toString(),
                    count_date: row.count_date.toString(),
                    count_time: row.count_time.toString(),
                    front_traffic_jam_time: row.front_traffic_jam_time.toString(),
                    side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
                    count_type: row.count_type.toString(),
                    car_front_standard: row.car_front_standard.toString(),
                    car_front_light: row.car_front_light.toString(),
                    car_front_large: row.car_front_large.toString(),
                    car_side_a_standard: row.car_side_a_standard.toString(),
                    car_side_a_light: row.car_side_a_light.toString(),
                    car_side_a_large: row.car_side_a_large.toString(),
                    car_side_b_standard: row.car_side_b_standard.toString(),
                    car_side_b_light: row.car_side_b_light.toString(),
                    car_side_b_large: row.car_side_b_large.toString(),
                    car_back_standard: row.car_back_standard.toString(),
                    car_back_light: row.car_back_light.toString(),
                    car_back_large: row.car_back_large.toString(),
                    people_5m_in_male_65over: row.people_5m_in_male_65over.toString(),
                    people_5m_in_female_65over: row.people_5m_in_female_65over.toString(),
                    people_5m_in_male_other: row.people_5m_in_male_other.toString(),
                    people_5m_in_female_other: row.people_5m_in_female_other.toString(),
                    people_5m_in_student: row.people_5m_in_student.toString(),
                    people_5m_in_bicycle: row.people_5m_in_bicycle.toString(),
                    people_5m_out_male_65over: row.people_5m_out_male_65over.toString(),
                    people_5m_out_female_65over: row.people_5m_out_female_65over.toString(),
                    people_5m_out_male_other: row.people_5m_out_male_other.toString(),
                    people_5m_out_female_other: row.people_5m_out_female_other.toString(),
                    people_5m_out_student: row.people_5m_out_student.toString(),
                    people_5m_out_bicycle: row.people_5m_out_bicycle.toString(),
                })
            }
            requestParams.sort((a: any, b: any) => {
                return a.traffic_type - b.traffic_type
            })
            trafficType = -1
        })

        if (requestParams.length > 0) {
            const params = {
                mode: "regist",
                endPoint: "/location/Traffic/v1/regist",
                query: {
                  planAreaId: objLongPageParam.planAreaId,
                  branch: objLongPageParam.branch,
                  trafficType: [
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal,
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal,
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal,
                    LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal
                  ].join(','),
                  data: requestParams
                }
            };
            const locationTrafficparams = upsertLocationTraffic5mcutParam(objLocationTraffic5mcut, objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), objStation, objSchool)

            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
            try {
                await apiRequest(params);
                await apiRequest(locationTrafficparams);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                // 閉じる
                handleCloseRegist()
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
            })();
        }
    }

    const handleUserInputDisabled = (key: string) => {
        return userInputData[key].disabled
    }

    const handleUserInputChecked = (key: string) => {
        return userInputData[key].checked
    }

    const handleUserInputCheck=(e:React.ChangeEvent<HTMLInputElement>, key:string)=>{
        userInputData[key].checked = e.target.checked
        setUserInputData(userInputData)
        setRedo(!redo)
    }

    const handleSelectedChange = (e: SelectChangeEvent<string>) => {
        setSeparateValue(e.target.value)
    }

    React.useEffect(() => {
        switch (separateValue) {
            case '0':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = true
                userInputData['Holidays'].checked = false
                break
            case '1':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = true
                userInputData['Holidays'].checked = false
                break
            case '2':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = false
                userInputData['Holidays'].checked = false
                break
            case '3':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = false
                userInputData['Holidays'].checked = false
                break
            default:
                break
        }
        if (list) {
            removeChecked(list, checkList)
        }
        dispatch(
            setObjLocationTraffic5mcut({
            ...objLocationTraffic5mcut,
            ['car_another_survey']: separateValue
        }))
        setUserInputData(userInputData)
        setRedo(!redo)

    }, [separateValue])

    const removeChecked = (list: any, checkList: any) => {
        list.map((row: any) => {
            row.night = 0
            row.Holidays = 0
        })
        checkList.night = 0
        checkList.Holidays = 0
        setList(list)
        setCheckList(checkList)
    }

    return (
        <>
            <Box sx={{padding: '20px', width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%'}}>
                <Box sx={{width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%'}}>
                    <Box sx={{width: '98%', height: '120px', textAlign: 'center', display: 'flex', backgroundColor: '#D9D9D9', color: "#fff"}}>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE_TIME}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.DATE_AND_TIME}</Typography>
                                <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.TIME}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.FRONT}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_A}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_B}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.BACK}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{width: '20%', height: '100%'}}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SELECT_COUNT_DATE}</Typography>
                            <Box sx={{display: 'flex', height: '30%'}}>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.MORNING}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.NOON}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.NIGHT}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.HOLIDAYS}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{textAlign: 'center', width: '100%', overflow: 'scroll', height: '90%', minHeight: '90%', maxHeight: '90%'}}>
                        <Box sx={{width: '100%'}}>
                        <Box sx={{display: 'flex', height: '50px', '& input': {padding: '14px 0', textAlign: 'center'} }}>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                <Typography sx={borderStyleDisabled(50, 50)}>{LOCAL_CONSTANT.LABEL.MANUAL_INPUT}</Typography>
                                <Typography sx={borderStyleDisabled(50, 50)}></Typography>
                            </Box>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>

                            </Box>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                            </Box>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>

                            </Box>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                            </Box>
                            <Box sx={{width: '20%', display: 'flex'}}>
                                {
                                    Object.keys(userInputData).map((key) => (
                                        <Checkbox
                                        key={key}
                                        disabled={handleUserInputDisabled(key)}
                                        sx={checkBoxStyle()}
                                        checked={handleUserInputChecked(key)}
                                        onChange={(e) => {handleUserInputCheck(e, key)}}
                                        />    
                                    ))
                                }
                            </Box>
                        </Box>
                        {list.map((row: any) => (
                            <Box
                                key={row.uuid}
                                sx={{display: 'flex', height: '50px'}}
                            >
                                <Box sx={{width: '20%', display: 'flex'}}>
                                    <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, "", "yearday")}</Typography>
                                    <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, "")}</Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', backgroundColor: hasBC(row)}}>
                                    <Typography sx={borderStyle(34, 50)}>{row.car_front_standard}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_front_light}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_front_large}</Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', backgroundColor: hasBC(row)}}>
                                    <Typography sx={borderStyle(34, 50)}>{row.car_side_a_standard}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_side_a_light}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_side_a_large}</Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', backgroundColor: hasBC(row)}}>
                                    <Typography sx={borderStyle(34, 50)}>{row.car_side_b_standard}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_side_b_light}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_side_b_large}</Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', backgroundColor: hasBC(row)}}>
                                    <Typography sx={borderStyle(34, 50)}>{row.car_back_standard}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_back_light}</Typography>
                                    <Typography sx={borderStyle(33, 50)}>{row.car_back_large}</Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex'}}>
                                    {checkBoxList().map((listRow: any) => (
                                        <Checkbox
                                        key={row.uuid+'_'+listRow.key}
                                        checked={hasChecked(row[listRow.key])}
                                        disabled={hasDisabled(row, listRow.key, userInputData, separateValue)}
                                        sx={checkBoxStyle()}
                                        onChange={(e)=> {handleChange(e, row, listRow.key)}}
                                    />
                                    ))}
                                </Box>
                            </Box>
                        ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{display : 'flex', padding: '10px'}}>
                <FormLabel sx={{ padding: '14px 0' }}>別途調査必要時（該当を選択）</FormLabel>
                <Select
                    onChange={ (e) => handleSelectedChange(e) } 
                    value={ separateValue }
                >
                {
                    separateInvestigation?.map((row: any) => (
                        <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                    ))
                }
                </Select>
            </Box>
            <Box sx={{display : 'flex', justifyContent: 'space-around', bottom: '0', padding: '10px'}}>
                <Button variant="outlined" color="error" size="large" onClick={handleClose}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                <Button variant="contained" color="success" size="large" onClick={handleRegist}>{LOCAL_CONSTANT.LABEL.ADD}</Button>
            </Box>
        </>
    );
}

export default AddCar;
import { createSlice } from '@reduxjs/toolkit';
import {
    initStoreOpeningConditionsObj,
    initStoreOpeningConditionsShowLongTextObj,
} from '../data/type';

//Sliceの作成
export const storeOpeningConditionsSlice = createSlice({
    name: 'storeOpeningConditions',
    //初期State(Typeからインポート)
    initialState: {
        obj: initStoreOpeningConditionsObj,
        initObj: initStoreOpeningConditionsObj,
        showAddItems: false,
        showLongText: initStoreOpeningConditionsShowLongTextObj,
        showPreviousJob: false,
        monthSelect: [],
        sixMonthPassed: '',
        monthHeader: [],
        nippanAverage: 0,
        onYearAverage: 0,
    },
    //toolKitを利用した値変更処理
    reducers: {
        setObjStoreOpeningConditions: (state, action) => {
            state.obj = action.payload;
        },
        setInitObjStoreOpeningConditions: (state, action) => {
            state.initObj = action.payload;
        },
        setClosingDays: (state, action) => {
            state.obj.closingDays = action.payload.closingDays;
        },
        setStoreOpeningConditionsShowAddItems:(state, action) => {
            state.showAddItems = action.payload;
        },
        setStoreOpeningConditionsLongTextShow: (state, action) => {
            state.showLongText = action.payload;
        },
        setShowPreviousJob: (state, action) => {
            state.showPreviousJob = action.payload;
        },
        setMonthSelect:(state,action) => {
            state.monthSelect = action.payload;
        },
        setSixMonthPassed:(state,action) => {
            state.sixMonthPassed = action.payload;
        },
        setMonthHeder:(state, action) => {
            state.monthHeader = action.payload;
        },
        setNippanAverage:(state, action) => {
            state.nippanAverage = action.payload;
        },
        setOnYearAverage:(state, action) => {
            state.onYearAverage = action.payload;
        },
        setAchievementAcquisition: (state, action) => {
            state.obj.achievementAcquisitionStart = action.payload.achievementAcquisitionStart;
            state.obj.achievementAcquisitionEnd = action.payload.achievementAcquisitionEnd;
        }
    }
});

export const {
    setObjStoreOpeningConditions,
    setInitObjStoreOpeningConditions,
    setClosingDays,
    setStoreOpeningConditionsShowAddItems,
    setStoreOpeningConditionsLongTextShow,
    setShowPreviousJob,
    setMonthSelect,
    setSixMonthPassed,
    setMonthHeder,
    setNippanAverage,
    setOnYearAverage,
    setAchievementAcquisition
} = storeOpeningConditionsSlice.actions;
export default storeOpeningConditionsSlice.reducer;
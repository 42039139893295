import dayjs from 'dayjs';

export const initCommentData = (res:any) => {
    const commentList:any[] = [];
    res.map((data:any, index:number) => {
      const commentObj = {
        id: index,
        flg: "",
        createDate: dayjs(data.create_date).format('YYYY-MM-DD'), //日付
        tableName: data.table_name,                               //ページ
        columnName: data.column_name,                             //項目
        comment: data.comment,                                    //コメント
        initComment: data.comment,                                //初期値のコメント キャンセルで戻すため
        deficiencyStatus: data.deficiency_status,                 //不備ステータス
        editStatus: true,                                         //編集の可否 trueが編集不可 falseが編集可
      }
      commentList.push(commentObj);
    })
    return commentList
}

//削除後、IDをふりなおし
export const deleteCommentData = (updateCommentTableData:any) => {
    const updateCommentList:any[] = [];
    updateCommentTableData.map((data:any, index:number) => {
      const commentObj = {
        id: index,
        flg: "",
        createDate: data.createDate,              //日付
        tableName: data.tableName,                //ページ
        columnName: data.columnName,              //項目
        comment: data.comment,                    //コメント
        initComment: data.comment,                //初期値のコメント キャンセルで戻すため
        deficiencyStatus: data.deficiencyStatus,  //不備ステータス
        editStatus: true,                         //編集の可否 trueが編集不可 falseが編集可
      }
      updateCommentList.push(commentObj);
    })
    return updateCommentList
}

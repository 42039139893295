import dayjs from 'dayjs';

//ロングページ基本情報取得API(Redux格納)
export const locationBaseData = (res: any) => {
    const locationBaseData = {
      plan_area_id: res[0].plan_area_id,
      branch: res[0].branch,
      location_type: res[0].location_type,
      location_excel_flag: res[0].location_excel_flag,
      location_excel_sequence: res[0].location_excel_sequence,
      child_location: res[0].child_location,
      parent_location: res[0].parent_location,
      temp_sales_forecast_path: res[0].temp_sales_forecast_path,
      temp_sales_forecast_upload_user: res[0].temp_sales_forecast_upload_user,
      temp_sales_forecast_upload_mail: res[0].temp_sales_forecast_upload_mail,
      sales_forecast_path: res[0].sales_forecast_path,
      sales_forecast_upload_user: res[0].sales_forecast_upload_user,
      sales_forecast_upload_mail: res[0].sales_forecast_upload_mail,
      shp_flag: res[0].shp_flag,
      task_id: res[0].task_id,
      status_id: res[0].status_id,
      status_seq: res[0].status_seq,
      add_survey_cover_excel_path: res[0].add_survey_cover_excel_path,
      add_survey_cover_excel_user: res[0].add_survey_cover_excel_user,
      add_survey_cover_pdf_path: res[0].add_survey_cover_pdf_path,
      add_survey_cover_pdf_user: res[0].add_survey_cover_pdf_user,
      sb_document_excel_path: res[0].sb_document_excel_path,
      sb_document_pdf_path: res[0].sb_document_pdf_path,
      other_document_excel_path: JSON.parse(res[0].other_document_excel_path),
      other_document_pdf_path: JSON.parse(res[0].other_document_pdf_path),
      rr_document_pdf_path:JSON.parse(res[0].rr_document_pdf_path),
      map_excel_path: res[0].map_excel_path,
      map_excel_user: res[0].map_excel_user,
      map_pdf_path: res[0].map_pdf_path,
      map_pdf_user: res[0].map_pdf_user,
      impact_judge_pdf_path: res[0].impact_judge_pdf_path,
      impact_judge_pdf_user: res[0].impact_judge_pdf_user,
      daily_sales_year_1: res[0].daily_sales_year_1,
      daily_sales_year_2: res[0].daily_sales_year_2,
      daily_sales_year_3: res[0].daily_sales_year_3,
      daily_sales_year_4: res[0].daily_sales_year_4,
      daily_sales_year_5: res[0].daily_sales_year_5,
      impact_store_name_1: res[0].impact_store_name_1,
      impact_store_1: res[0].impact_store_1,
      impact_store_name_2: res[0].impact_store_name_2,
      impact_store_2: res[0].impact_store_2,
      impact_store_name_3: res[0].impact_store_name_3,
      impact_store_3: res[0].impact_store_3,
      impact_store_name_4: res[0].impact_store_name_4,
      impact_store_4: res[0].impact_store_4,
      add_survey_doc_pdf_path: JSON.parse(res[0].add_survey_doc_pdf_path),
      add_survey_reference_value_1: res[0].add_survey_reference_value_1,
      add_survey_reference_value_2: res[0].add_survey_reference_value_2,
      add_survey_reference_value_3: res[0].add_survey_reference_value_3,
      add_survey_reference_value_4: res[0].add_survey_reference_value_4,
      add_survey_reference_value_5: res[0].add_survey_reference_value_5
    }
    return locationBaseData;
};

//ロングページ基本情報API登録
export const upsertlocationBaseParam = (objLocationBase: any, plan_area_id:any, branch:any) => {
  const params = {
    mode: "regist",
    endPoint: "/location/Base/v1/regist",
    query: {
      plan_area_id:plan_area_id,
      branch:branch,
      location_type:objLocationBase.location_type,
      location_excel_flag:objLocationBase.location_excel_flag,
      location_excel_sequence:objLocationBase.location_excel_sequence,
      child_location:objLocationBase.child_location,
      parent_location:objLocationBase.parent_location,
      temp_sales_forecast_path:objLocationBase.temp_sales_forecast_path,
      temp_sales_forecast_upload_user:objLocationBase.temp_sales_forecast_upload_user,
      temp_sales_forecast_upload_mail:objLocationBase.temp_sales_forecast_upload_mail,
      sales_forecast_path:objLocationBase.sales_forecast_path,
      sales_forecast_upload_user:objLocationBase.sales_forecast_upload_user,
      sales_forecast_upload_mail:objLocationBase.sales_forecast_upload_mail,
      shp_flag:objLocationBase.shp_flag,
      add_survey_cover_excel_path:objLocationBase.add_survey_cover_excel_path,
      add_survey_cover_excel_user:objLocationBase.add_survey_cover_excel_user,
      add_survey_cover_pdf_path:objLocationBase.add_survey_cover_pdf_path,
      add_survey_cover_pdf_user:objLocationBase.add_survey_cover_pdf_user,
      sb_document_excel_path:objLocationBase.sb_document_excel_path,
      sb_document_pdf_path:objLocationBase.sb_document_pdf_path,
      other_document_excel_path:JSON.stringify(objLocationBase.other_document_excel_path),
      other_document_pdf_path:JSON.stringify(objLocationBase.other_document_pdf_path),
      rr_document_pdf_path:JSON.stringify(objLocationBase.rr_document_pdf_path),
      map_excel_path:objLocationBase.map_excel_path,
      map_excel_user:objLocationBase.map_excel_user,
      map_pdf_path:objLocationBase.map_pdf_path,
      map_pdf_user:objLocationBase.map_pdf_user,
      impact_judge_pdf_path:objLocationBase.impact_judge_pdf_path,
      impact_judge_pdf_user:objLocationBase.impact_judge_pdf_user,
      daily_sales_year_1:objLocationBase.daily_sales_year_1 === null ? "" : objLocationBase.daily_sales_year_1,
      daily_sales_year_2:objLocationBase.daily_sales_year_2 === null ? "" : objLocationBase.daily_sales_year_2,
      daily_sales_year_3:objLocationBase.daily_sales_year_3 === null ? "" : objLocationBase.daily_sales_year_3,
      daily_sales_year_4:objLocationBase.daily_sales_year_4 === null ? "" : objLocationBase.daily_sales_year_4,
      daily_sales_year_5:objLocationBase.daily_sales_year_5 === null ? "" : objLocationBase.daily_sales_year_5,
      impact_store_name_1:objLocationBase.impact_store_name_1 === null ? "" : objLocationBase.impact_store_name_1,
      impact_store_1:objLocationBase.impact_store_1 === null ? "" : objLocationBase.impact_store_1,
      impact_store_name_2:objLocationBase.impact_store_name_2 === null ? "" : objLocationBase.impact_store_name_2,
      impact_store_2:objLocationBase.impact_store_2 === null ? "" : objLocationBase.impact_store_2,
      impact_store_name_3:objLocationBase.impact_store_name_3 === null ? "" : objLocationBase.impact_store_name_3,
      impact_store_3:objLocationBase.impact_store_3 === null ? "" : objLocationBase.impact_store_3,
      impact_store_name_4:objLocationBase.impact_store_name_4 === null ? "" : objLocationBase.impact_store_name_4,
      impact_store_4:objLocationBase.impact_store_4 === null ? "" : objLocationBase.impact_store_4,
      add_survey_doc_pdf_path:JSON.stringify(objLocationBase.add_survey_doc_pdf_path),
      add_survey_reference_value_1:objLocationBase.add_survey_reference_value_1,
      add_survey_reference_value_2:objLocationBase.add_survey_reference_value_2,
      add_survey_reference_value_3:objLocationBase.add_survey_reference_value_3,
      add_survey_reference_value_4:objLocationBase.add_survey_reference_value_4,
      add_survey_reference_value_5:objLocationBase.add_survey_reference_value_5,
      log_type: "0"   //ログ用 項目更新 log_type=0  
    }
  }
  return params
}


//アップロード JSON形式データ加工
export const uploadFileJsonPathFunc = (res:any, userId: string) => {
  let filePath = '';
  
  if (res.fullPath.includes('/excel')) {
    filePath = '/excel/'+res.name;
  } else if (res.fullPath.includes('/pdf')) {
    filePath = '/pdf/'+res.name;
  } else if (res.fullPath.includes('/rr_document')) {
    filePath = '/rr_document/'+res.name;
  } else if (res.fullPath.includes('/drawing')) {
    filePath = '/drawing/'+res.name;
  }

  return {
    file_name:res.name,
    file_path:filePath,
    upload_date:dayjs(res.timeCreated).format('YYYY/MM/DD HH:mm:ss'),
    upload_staff_number:userId
  }
};

//調査書基本テーブル ファイルアップロード
export const upsertLocationBaseFileParam = (objLocationBase:any, plan_area_id:any, branch:any, uploadFile:any, column:string, user_id:string, fileName: string) => {
  const params = {
      mode: "regist",
      endPoint: "/location/Base/v1/regist",
      query: locationBaseFileQuery(objLocationBase, plan_area_id, branch, uploadFile, column, user_id, fileName)
  }
  return params;
}

//調査書基本テーブル ファイルアップロードquery
export const locationBaseFileQuery = (objLocationBase:any, plan_area_id:any, branch:any, uploadFile:any, column:string, user_id:string, fileName:string) => {
  let add_survey_cover_excel_path = objLocationBase.add_survey_cover_excel_path;
  let add_survey_cover_excel_user = objLocationBase.add_survey_cover_excel_user;
  let other_document_excel_path = objLocationBase.other_document_excel_path;
  let other_document_pdf_path = objLocationBase.other_document_pdf_path;
  let rr_document_pdf_path = objLocationBase.rr_document_pdf_path;
  let add_survey_doc_pdf_path = objLocationBase.add_survey_doc_pdf_path;

  if (column === 'add_survey_cover_excel_path') {
    add_survey_cover_excel_path = uploadFile;
    add_survey_cover_excel_user = user_id;
  } else if (column === 'other_document_excel_path') {
    other_document_excel_path = [].concat(objLocationBase.other_document_excel_path, uploadFile);
  } else if (column === 'other_document_pdf_path') {
    other_document_pdf_path = [].concat(objLocationBase.other_document_pdf_path, uploadFile);
  } else if (column === 'rr_document_pdf_path') {
    rr_document_pdf_path = [].concat(objLocationBase.rr_document_pdf_path, uploadFile);
  } else if (column === 'add_survey_doc_pdf_path') {
    add_survey_doc_pdf_path = [].concat(objLocationBase.add_survey_doc_pdf_path, uploadFile);
  }

  const logStatus = setLogStatus(column, true, fileName)

  return {   
    plan_area_id:plan_area_id,
    branch:branch,
    location_type:objLocationBase.location_type,
    location_excel_flag:objLocationBase.location_excel_flag,
    location_excel_sequence:objLocationBase.location_excel_sequence,
    child_location:objLocationBase.child_location,
    parent_location:objLocationBase.parent_location,
    temp_sales_forecast_path:objLocationBase.temp_sales_forecast_path,
    temp_sales_forecast_upload_user:objLocationBase.temp_sales_forecast_upload_user,
    temp_sales_forecast_upload_mail:objLocationBase.temp_sales_forecast_upload_mail,
    sales_forecast_path:objLocationBase.sales_forecast_path,
    sales_forecast_upload_user:objLocationBase.sales_forecast_upload_user,
    sales_forecast_upload_mail:objLocationBase.sales_forecast_upload_mail,
    shp_flag:objLocationBase.shp_flag,
    add_survey_cover_excel_path:add_survey_cover_excel_path,
    add_survey_cover_excel_user:add_survey_cover_excel_user,
    add_survey_cover_pdf_path:objLocationBase.add_survey_cover_pdf_path,
    add_survey_cover_pdf_user:objLocationBase.add_survey_cover_pdf_user,
    sb_document_excel_path:objLocationBase.sb_document_excel_path,
    sb_document_pdf_path:objLocationBase.sb_document_pdf_path,
    other_document_excel_path:JSON.stringify(other_document_excel_path),
    other_document_pdf_path:JSON.stringify(other_document_pdf_path),
    rr_document_pdf_path:JSON.stringify(rr_document_pdf_path),
    map_excel_path:objLocationBase.map_excel_path,
    map_excel_user:objLocationBase.map_excel_user,
    map_pdf_path:objLocationBase.map_pdf_path,
    map_pdf_user:objLocationBase.map_pdf_user,
    impact_judge_pdf_path:objLocationBase.impact_judge_pdf_path,
    impact_judge_pdf_user:objLocationBase.impact_judge_pdf_user,
    daily_sales_year_1:objLocationBase.daily_sales_year_1 === null ? "" : objLocationBase.daily_sales_year_1,
    daily_sales_year_2:objLocationBase.daily_sales_year_2 === null ? "" : objLocationBase.daily_sales_year_2,
    daily_sales_year_3:objLocationBase.daily_sales_year_3 === null ? "" : objLocationBase.daily_sales_year_3,
    daily_sales_year_4:objLocationBase.daily_sales_year_4 === null ? "" : objLocationBase.daily_sales_year_4,
    daily_sales_year_5:objLocationBase.daily_sales_year_5 === null ? "" : objLocationBase.daily_sales_year_5,
    impact_store_name_1:objLocationBase.impact_store_name_1 === null ? "" : objLocationBase.impact_store_name_1,
    impact_store_1:objLocationBase.impact_store_1 === null ? "" : objLocationBase.impact_store_1,
    impact_store_name_2:objLocationBase.impact_store_name_2 === null ? "" : objLocationBase.impact_store_name_2,
    impact_store_2:objLocationBase.impact_store_2 === null ? "" : objLocationBase.impact_store_2,
    impact_store_name_3:objLocationBase.impact_store_name_3 === null ? "" : objLocationBase.impact_store_name_3,
    impact_store_3:objLocationBase.impact_store_3 === null ? "" : objLocationBase.impact_store_3,
    impact_store_name_4:objLocationBase.impact_store_name_4 === null ? "" : objLocationBase.impact_store_name_4,
    impact_store_4:objLocationBase.impact_store_4 === null ? "" : objLocationBase.impact_store_4,
    add_survey_doc_pdf_path:JSON.stringify(add_survey_doc_pdf_path),
    add_survey_reference_value_1:objLocationBase.add_survey_reference_value_1,
    add_survey_reference_value_2:objLocationBase.add_survey_reference_value_2,
    add_survey_reference_value_3:objLocationBase.add_survey_reference_value_3,
    add_survey_reference_value_4:objLocationBase.add_survey_reference_value_4,
    add_survey_reference_value_5:objLocationBase.add_survey_reference_value_5,
    // ログ用
    log_type:logStatus.log_type,
    target_table:logStatus.target_table,
    target_column:logStatus.target_column,
    target_before_value:logStatus.target_before_value,
    target_after_value:logStatus.target_after_value
  }
}


//調査書基本テーブル ファイル削除
export const upsertLocationBaseDeleteFileParam = (objLocationBase:any, plan_area_id:any, branch:any, uploadFileList:any, column:string, fileName: string) => {
  const params = {
      mode: "regist",
      endPoint: "/location/Base/v1/regist",
      query: locationBaseDeleteFileQuery(objLocationBase, plan_area_id, branch, uploadFileList, column, fileName)
  }
  return params;
}

//調査書基本テーブル ファイル削除query
export const locationBaseDeleteFileQuery = (objLocationBase:any, plan_area_id:any, branch:any, uploadFileList:any, column:string, fileName:string) => {
  let add_survey_cover_excel_path = objLocationBase.add_survey_cover_excel_path;
  let add_survey_cover_excel_user = objLocationBase.add_survey_cover_excel_user;
  let add_survey_cover_pdf_path = objLocationBase.add_survey_cover_pdf_path;
  let add_survey_cover_pdf_user = objLocationBase.add_survey_cover_pdf_user;
  let other_document_excel_path = objLocationBase.other_document_excel_path;
  let other_document_pdf_path = objLocationBase.other_document_pdf_path;
  let rr_document_pdf_path = objLocationBase.rr_document_pdf_path;
  let add_survey_doc_pdf_path = objLocationBase.add_survey_doc_pdf_path;

  if (column === 'add_survey_cover_excel_path') {
    add_survey_cover_excel_path = '';
    add_survey_cover_excel_user = '';
    add_survey_cover_pdf_path = '';
    add_survey_cover_pdf_user = '';
  } else if (column === 'other_document_excel_path') {
    other_document_excel_path = uploadFileList;
  } else if (column === 'other_document_pdf_path') {
    other_document_pdf_path = uploadFileList;
  } else if (column === 'rr_document_pdf_path') {
    rr_document_pdf_path = uploadFileList;
  } else if (column === 'add_survey_doc_pdf_path') {
    add_survey_doc_pdf_path = uploadFileList;
  }
  const logStatus = setLogStatus(column, false, fileName)

  return {   
    plan_area_id:plan_area_id,
    branch:branch,
    location_type:objLocationBase.location_type,
    location_excel_flag:objLocationBase.location_excel_flag,
    location_excel_sequence:objLocationBase.location_excel_sequence,
    child_location:objLocationBase.child_location,
    parent_location:objLocationBase.parent_location,
    temp_sales_forecast_path:objLocationBase.temp_sales_forecast_path,
    temp_sales_forecast_upload_user:objLocationBase.temp_sales_forecast_upload_user,
    temp_sales_forecast_upload_mail:objLocationBase.temp_sales_forecast_upload_mail,
    sales_forecast_path:objLocationBase.sales_forecast_path,
    sales_forecast_upload_user:objLocationBase.sales_forecast_upload_user,
    sales_forecast_upload_mail:objLocationBase.sales_forecast_upload_mail,
    shp_flag:objLocationBase.shp_flag,
    add_survey_cover_excel_path:add_survey_cover_excel_path,
    add_survey_cover_excel_user:add_survey_cover_excel_user,
    add_survey_cover_pdf_path:add_survey_cover_pdf_path,
    add_survey_cover_pdf_user:add_survey_cover_pdf_user,
    sb_document_excel_path:objLocationBase.sb_document_excel_path,
    sb_document_pdf_path:objLocationBase.sb_document_pdf_path,
    other_document_excel_path:JSON.stringify(other_document_excel_path),
    other_document_pdf_path:JSON.stringify(other_document_pdf_path),
    rr_document_pdf_path:JSON.stringify(rr_document_pdf_path),
    map_excel_path:objLocationBase.map_excel_path,
    map_excel_user:objLocationBase.map_excel_user,
    map_pdf_path:objLocationBase.map_pdf_path,
    map_pdf_user:objLocationBase.map_pdf_user,
    impact_judge_pdf_path:objLocationBase.impact_judge_pdf_path,
    impact_judge_pdf_user:objLocationBase.impact_judge_pdf_user,
    daily_sales_year_1:objLocationBase.daily_sales_year_1 === null ? "" : objLocationBase.daily_sales_year_1,
    daily_sales_year_2:objLocationBase.daily_sales_year_2 === null ? "" : objLocationBase.daily_sales_year_2,
    daily_sales_year_3:objLocationBase.daily_sales_year_3 === null ? "" : objLocationBase.daily_sales_year_3,
    daily_sales_year_4:objLocationBase.daily_sales_year_4 === null ? "" : objLocationBase.daily_sales_year_4,
    daily_sales_year_5:objLocationBase.daily_sales_year_5 === null ? "" : objLocationBase.daily_sales_year_5,
    impact_store_name_1:objLocationBase.impact_store_name_1 === null ? "" : objLocationBase.impact_store_name_1,
    impact_store_1:objLocationBase.impact_store_1 === null ? "" : objLocationBase.impact_store_1,
    impact_store_name_2:objLocationBase.impact_store_name_2 === null ? "" : objLocationBase.impact_store_name_2,
    impact_store_2:objLocationBase.impact_store_2 === null ? "" : objLocationBase.impact_store_2,
    impact_store_name_3:objLocationBase.impact_store_name_3 === null ? "" : objLocationBase.impact_store_name_3,
    impact_store_3:objLocationBase.impact_store_3 === null ? "" : objLocationBase.impact_store_3,
    impact_store_name_4:objLocationBase.impact_store_name_4 === null ? "" : objLocationBase.impact_store_name_4,
    impact_store_4:objLocationBase.impact_store_4 === null ? "" : objLocationBase.impact_store_4,
    add_survey_doc_pdf_path:JSON.stringify(add_survey_doc_pdf_path),
    add_survey_reference_value_1:objLocationBase.add_survey_reference_value_1,
    add_survey_reference_value_2:objLocationBase.add_survey_reference_value_2,
    add_survey_reference_value_3:objLocationBase.add_survey_reference_value_3,
    add_survey_reference_value_4:objLocationBase.add_survey_reference_value_4,
    add_survey_reference_value_5:objLocationBase.add_survey_reference_value_5,
    // ログ用
    log_type:logStatus.log_type,
    target_table:logStatus.target_table,
    target_column:logStatus.target_column,
    target_before_value:logStatus.target_before_value,
    target_after_value:logStatus.target_after_value
  }
}

// ログステータス
const setLogStatus = (column: string, isUpload: boolean, fileName:string) => {
  let log_type = ""
  let target_table = ""
  let target_column = ""
  let target_before_value = ""
  let target_after_value = ""
  let target_file_name = fileName

  switch (column) {
    case "add_survey_cover_excel_path":
      log_type = "7"
      target_table = "追加調査"
      target_file_name = "表紙"
      break
    case "add_survey_doc_pdf_path":
      log_type = "7"
      target_table = "追加調査"
      break
    case "other_document_excel_path":
    case "other_document_pdf_path":
      log_type = "8"
      target_table = "その他資料"
      break
    case "rr_document_pdf_path":
      log_type = "9"
      target_table = "PR内部用"
      break
  }
  target_column = isUpload ? "ファイルアップロード" : "ファイル削除"
  target_before_value = isUpload ? "" : target_file_name
  target_after_value = isUpload ? target_file_name : ""

  return {
    log_type: log_type,
    target_table: target_table,
    target_column: target_column,
    target_before_value: target_before_value,
    target_after_value: target_after_value,
  }
}

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { lineBreak } from '../../ResearchReport/TradeArea';
import {
    labelHeaderStyle,
    labelStyle,
    table12Column,
    table5Column,
    table6Column,
    tableCellStyle,
} from './TradeAreaStyle';

//車5分商圏 ヘッダー
const Car5MinHeaderFirstColumns = [
    //コロンで改行
    { colName: 'car5MinTradeAreaHouseholdCount', label: '商圏:世帯数:(世帯)' },
    { colName: 'car5MinSingleHouseholdCount', label: '単身:世帯数:(世帯)' },
    { colName: 'car5MinSingleHouseholdRatio', label: '単身比率:(%)' },
    { colName: 'car5MinPopulation', label: '人口:(人)' },
    { colName: 'car5MinAgeGroup20Total', label: '20代:計(人)' },
    { colName: 'car5MinAgeGroup20Ratio', label: '20代:比率(%)' },
    { colName: 'car5MinAgeGroup30Total', label: '30代:計(人)' },
    { colName: 'car5MinAgeGroup30Ratio', label: '30代:比率(%)' },
    { colName: 'car5MinAgeGroup50MoreTotal', label: '50歳以上:計(人)' },
    { colName: 'car5MinAgeGroup50MoreRatio', label: '50歳以上:比率(%)' },
    { colName: 'car5MinAgeGroup65MoreTotal', label: '65歳以上:計(人)' },
    { colName: 'car5MinAgeGroup65MoreRatio', label: '65歳以上:比率(%)' },
];

const Car5MinHeaderSecondColumns = [
    //コロンで改行
    { colName: 'car5MinOfficeCount', label: '事業所:(箇所)' },
    { colName: 'car5MinEmployeesCount', label: '従業員数:(人)' },
    { colName: 'car5MinAllocationOffice', label: '引分:事業所:(箇所)' },
    { colName: 'car5MinAllocationEmployees', label: '引分:従業員:(人)' },
    { colName: 'car5MinDeductionOffice', label: '差引:事業所:(箇所)' },
    { colName: 'car5MinDeductionEmployees', label: '差引:従業員:(人)' },
];

const Car5MinHeaderThirdColumns = [
    //コロンで改行
    { colName: 'car5MinIncomeUpTo300', label: '～300万:(%)' },
    { colName: 'car5MinIncomeUpTo500', label: '～500万:(%)' },
    { colName: 'car5MinIncomeUpTo700', label: '～700万:(%)' },
    { colName: 'car5MinIncomeUpTo1000', label: '～1000万:(%)' },
    { colName: 'car5MinIncomeOver1000', label: '1000万～:(%)' },
];


export interface TradeAreaProps {
    props: {
    }
};

export const TradeAreaCar5MinMp: React.FC<TradeAreaProps> = ({ props }) => {

    const objCar5MinMp = useAppSelector((state) => state.tradeArea.car5MinMpObj);

    return (
        <Box>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Typography component="h2" mt={2}>②車5分商圏</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <TableContainer>
                <Table sx={table12Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {Car5MinHeaderFirstColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/* データ */}
                            <TableCell sx={tableCellStyle}>{objCar5MinMp.car5MinTradeAreaHouseholdCount.toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinSingleHouseholdCount).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinSingleHouseholdRatio * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinPopulation).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAgeGroup20Total).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinAgeGroup20Ratio * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAgeGroup30Total).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinAgeGroup30Ratio * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAgeGroup50MoreTotal).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinAgeGroup50MoreRatio * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAgeGroup65MoreTotal).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinAgeGroup65MoreRatio * 100) / 100).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <TableContainer>
                <Table sx={table6Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {Car5MinHeaderSecondColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* データ */}
                        <TableRow>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinOfficeCount).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinEmployeesCount).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAllocationOffice).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinAllocationEmployees).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinDeductionOffice).toLocaleString()}</TableCell>
                            <TableCell sx={tableCellStyle}>{Math.round(objCar5MinMp.car5MinDeductionEmployees).toLocaleString()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table sx={table5Column}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}>年収階級</TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                        </TableRow>
                        {/* ヘッダー */}
                        <TableRow>
                            {Car5MinHeaderThirdColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* データ */}
                        <TableRow>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinIncomeUpTo300 * 100 * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinIncomeUpTo500 * 100 * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinIncomeUpTo700 * 100 * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinIncomeUpTo1000 * 100 * 100) / 100).toFixed(2)}</TableCell>
                            <TableCell sx={tableCellStyle}>{(Math.round(objCar5MinMp.car5MinIncomeOver1000 * 100 * 100) / 100).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}

export default TradeAreaCar5MinMp;

import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  DialogTitle,
  styled,
  TableBody,
  TableContainer,
  TableCell,
} from '@mui/material';

const ColorButton = styled(Button)({
  backgroundColor: "#00CC5B",
  color: "white",
  '&: hover':{
    backgroundColor: '#29e17a'
  }
});
const ErrorPageDataTablCell = styled(TableCell)({
  color: "red",
  border: "none",
  whiteSpace: "nowrap"
});
const ErrorDataTableCell = styled(TableCell)({
  color: "red",
  border: "none"
});

export interface InvalidInputListProps {
  props:{
    errorMessage: string,
    errorList: any,
    handleModalClose: () => void
  }
};

export const InvalidInputList:React.FC<InvalidInputListProps> = ({props}) => {

  //テーブルマスター
  const [tableNameMaster, setTableNameMaster] = useState<any>([]);

  const setTableName = (table_physics_name: string) => {
    let table_logic_name = ""
    tableNameMaster.map((row: any) => {
      if (row.table_physics_name === table_physics_name) {
        table_logic_name = row.table_logic_name
      }
    })
    return table_logic_name
  }

  const setColumnName = (column_physics_name: string) => {
    let column_logic_name = ""
    tableNameMaster.map((row: any) => {
      if (row.column_physics_name === column_physics_name) {
        column_logic_name = row.column_logic_name
      }
    })
    // 改行置換
    return column_logic_name.replaceAll("<br>", "\n")
  }

  useEffect(() => {
    const tableNameMasterObj = localStorage.getItem("tableNameMaster");
    if (tableNameMasterObj) {
      setTableNameMaster(JSON.parse(tableNameMasterObj))
    }
  },[])

  return (
      <Box>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <DialogTitle>{props.errorMessage}</DialogTitle>
        </Grid>
        <Box sx={{ margin: "30px" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <TableContainer  sx={{ height: "400px"}}>
              {props.errorList?.map((data:any) => (
                <TableBody>
                  <ErrorPageDataTablCell>{setTableName(data.page)}</ErrorPageDataTablCell>
                  {
                    data.column ? (
                      <>
                        {/* テーブル項目名とエラーメッセージを表示 */}
                        <ErrorDataTableCell>{setColumnName(data.column)}</ErrorDataTableCell>
                        <ErrorDataTableCell>
                          {/* エラーメッセージ内に<br>タグがあれば改行して表示 */}
                          {data.error.includes('<br>') ? data.error.split('<br>').map((errorLine:any,index:number) => (<span key={index}>{errorLine}<br/></span>)) : data.error}
                        </ErrorDataTableCell>
                      </>
                    ) : (
                      //通行量のデータ件数をチェックする場合は、テーブルの項目名がないためエラーメッセージを表示
                      <ErrorDataTableCell colSpan={2}>{data.error}</ErrorDataTableCell>
                    )
                  }
                </TableBody>
              ))}
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <ColorButton onClick={props.handleModalClose}>閉じる</ColorButton>
          </Grid>
        </Box>
      </Box>

  );
};


export default InvalidInputList;
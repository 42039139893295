import * as React from 'react';

import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';
import {
	Box,
} from '@mui/material';
const style = {
    width:"100%",
    paddingRight:"40px",
    height:"1190px"
};

export interface pdfViewerProps {
    props:{
        pdfPath: string,
    }
}

/**
 * PDFプレビュー
 */
const PdfViewer:React.FC<pdfViewerProps> = ({props}) => {
    const [storagePath, setStragePath] = React.useState('')
    const [show, setShow] = React.useState('none')

    React.useEffect(() => {
        if (props.pdfPath) {
            setShow('block')
            const gsReference = ref(storage, props.pdfPath);
        
            getDownloadURL(gsReference)
            .then((url) => {
                setStragePath(url)
            })
            .catch((err) => console.log(err));
        } else {
            setShow('none')
        }
    }, [props.pdfPath])

    return (
        <Box sx={{ display: show }}>
            <embed src={storagePath} type="application/pdf" style={style}/>
        </Box>
    );
}

export default PdfViewer;
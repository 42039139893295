//寄付きページAPI取得
export const donationData = (res:any) => {
    const DataObj = {
        drivingLanePositiveFrontage: res[0].driving_lane_positive_frontage,                              //走行車線 正 前面
        drivingLanePositiveSideWayA: res[0].driving_lane_positive_sideway_a,                             //走行車線 正 側道A               
        drivingLanePositiveSideWayB: res[0].driving_lane_positive_sideway_b,                             //走行車線 正 側道B
        drivingLanePositiveBackstreet: res[0].driving_lane_positive_backstreet,                          //走行車線 正 裏道
        drivingLaneOppositeFrontage: res[0].driving_lane_opposite_frontage,                              //走行車線 反 前面
        drivingLaneOppositeSideWayA: res[0].driving_lane_opposite_sideway_a,                             //走行車線 反 側道A
        drivingLaneOppositeSideWayB: res[0].driving_lane_opposite_sideway_b,                             //走行車線 反 側道B
        drivingLaneOppositeBackstreet: res[0].driving_lane_opposite_backstreet,                          //走行車線 反 裏道
        medianStripSelect: res[0].median_strip_select,                                                   //中央分離帯 有無（左から1つめ）
        medianStripSelect2: res[0].median_strip_select2,                                                 //中央分離帯 有無（左から2つめ）
        medianStripSelect3: res[0].median_strip_select3,                                                 //中央分離帯 有無（左から3つめ）
        medianStripSelect4: res[0].median_strip_select4,                                                 //中央分離帯 有無（左から4つめ）
        medianStripHeight: res[0].median_strip_select === '0' ? '' : res[0].median_strip_height,        //中央分離帯 高さ（左から1つめ）
        medianStripHeight2: res[0].median_strip_select2 === '0' ? '' : res[0].median_strip_height2,     //中央分離帯 高さ（左から2つめ）
        medianStripHeight3: res[0].median_strip_select3 === '0' ? '' : res[0].median_strip_height3,     //中央分離帯 高さ（左から3つめ）
        medianStripHeight4: res[0].median_strip_select4 === '0' ? '' : res[0].median_strip_height4,     //中央分離帯 高さ（左から4つめ）
        sidewalkSelect: res[0].sidewalk_select,                                                          //歩道 有無（左から1つめ）
        sidewalkSelect2: res[0].sidewalk_select2,                                                        //歩道 有無（左から2つめ）
        sidewalkSelect3: res[0].sidewalk_select3,                                                        //歩道 有無（左から3つめ）
        sidewalkSelect4: res[0].sidewalk_select4,                                                        //歩道 有無（左から4つめ）
        sidewalkHeight: res[0].sidewalk_select === '0' ? '' : res[0].sidewalk_height,                   //歩道 高さ（左から1つめ）
        sidewalkHeight2: res[0].sidewalk_select2 === '0' ? '' : res[0].sidewalk_height2,                //歩道 高さ（左から2つめ）
        sidewalkHeight3: res[0].sidewalk_select3 === '0' ? '' : res[0].sidewalk_height3,                //歩道 高さ（左から3つめ）
        sidewalkHeight4: res[0].sidewalk_select4 === '0' ? '' : res[0].sidewalk_height4,                //歩道 高さ（左から4つめ）
        roadWidthDoor: res[0].road_width_door,                                                           //車道幅員 ドア（左から1つめ）
        roadWidthDoor2: res[0].road_width_door2,                                                         //車道幅員 ドア（左から2つめ）
        roadWidthDoor3: res[0].road_width_door3,                                                         //車道幅員 ドア（左から3つめ）
        roadWidthDoor4: res[0].road_width_door4,                                                         //車道幅員 ドア（左から4つめ）
        roadWidthForward: res[0].road_width_forward,                                                     //車道幅員 先（左から1つめ）
        roadWidthForward2: res[0].road_width_forward2,                                                   //車道幅員 先（左から2つめ）
        roadWidthForward3: res[0].road_width_forward3,                                                   //車道幅員 先（左から3つめ）
        roadWidthForward4: res[0].road_width_forward4,                                                   //車道幅員 先（左から4つめ）
        roadWidthFrontLocation: res[0].road_width_front_location,                                        //車道幅員 手前（左から1つめ）
        roadWidthFrontLocation2: res[0].road_width_front_location2,                                      //車道幅員 手前（左から2つめ）
        roadWidthFrontLocation3: res[0].road_width_front_location3,                                      //車道幅員 手前（左から3つめ）
        roadWidthFrontLocation4: res[0].road_width_front_location4,                                      //車道幅員 手前（左から4つめ）
        slopeClimb: res[0].slope_climb,                                                                  //坂道（長さ1ｍ当り） 登（左から1つめ）
        slopeClimb2: res[0].slope_climb2,                                                                //坂道（長さ1ｍ当り） 登（左から2つめ）
        slopeClimb3: res[0].slope_climb3,                                                                //坂道（長さ1ｍ当り） 登（左から3つめ）
        slopeClimb4: res[0].slope_climb4,                                                                //坂道（長さ1ｍ当り） 登（左から4つめ）
        slopeDescend: res[0].slope_descend,                                                              //坂道（長さ1ｍ当り） 降（左から1つめ）
        slopeDescend2: res[0].slope_descend2,                                                            //坂道（長さ1ｍ当り） 降（左から2つめ）
        slopeDescend3: res[0].slope_descend3,                                                            //坂道（長さ1ｍ当り） 降（左から3つめ）
        slopeDescend4: res[0].slope_descend4,                                                            //坂道（長さ1ｍ当り） 降（左から4つめ）
        trafficSignalEtcForwardSelect: res[0].traffic_signal_etc_forward_select,                         //信号・踏切 先 有無（左から1つめ）
        trafficSignalEtcForwardSelect2: res[0].traffic_signal_etc_forward_select2,                       //信号・踏切 先 有無（左から2つめ）
        trafficSignalEtcForwardSelect3: res[0].traffic_signal_etc_forward_select3,                       //信号・踏切 先 有無（左から3つめ）
        trafficSignalEtcForwardSelect4: res[0].traffic_signal_etc_forward_select4,                       //信号・踏切 先 有無（左から4つめ）
        trafficSignalEtcForwardDistance: res[0].traffic_signal_etc_forward_select === '0' ? '' : res[0].traffic_signal_etc_forward_distance,     //信号・踏切 先 距離（左から1つめ）
        trafficSignalEtcForwardDistance2: res[0].traffic_signal_etc_forward_select2 === '0' ? '' : res[0].traffic_signal_etc_forward_distance2,  //信号・踏切 先 距離（左から2つめ）
        trafficSignalEtcForwardDistance3: res[0].traffic_signal_etc_forward_select3 === '0' ? '' : res[0].traffic_signal_etc_forward_distance3,  //信号・踏切 先 距離（左から3つめ）
        trafficSignalEtcForwardDistance4: res[0].traffic_signal_etc_forward_select4 === '0' ? '' : res[0].traffic_signal_etc_forward_distance4,  //信号・踏切 先 距離（左から4つめ）
        trafficSignalEtcFrontLocationSelect: res[0].traffic_signal_etc_front_location_select,            //信号・踏切 手前 有無（左から1つめ）
        trafficSignalEtcFrontLocationSelect2: res[0].traffic_signal_etc_front_location_select2,          //信号・踏切 手前 有無（左から2つめ）
        trafficSignalEtcFrontLocationSelect3: res[0].traffic_signal_etc_front_location_select3,          //信号・踏切 手前 有無（左から3つめ）
        trafficSignalEtcFrontLocationSelect4: res[0].traffic_signal_etc_front_location_select4,          //信号・踏切 手前 有無（左から4つめ）
        trafficSignalEtcFrontLocationDistance: res[0].traffic_signal_etc_front_location_select === '0' ? '' : res[0].traffic_signal_etc_front_location_distance,     //信号・踏切 手前 距離（左から1つめ）
        trafficSignalEtcFrontLocationDistance2: res[0].traffic_signal_etc_front_location_select2 === '0' ? '': res[0].traffic_signal_etc_front_location_distance2,   //信号・踏切 手前 距離（左から2つめ）
        trafficSignalEtcFrontLocationDistance3: res[0].traffic_signal_etc_front_location_select3 === '0' ? '' : res[0].traffic_signal_etc_front_location_distance3,  //信号・踏切 手前 距離（左から3つめ）
        trafficSignalEtcFrontLocationDistance4: res[0].traffic_signal_etc_front_location_select4 === '0' ? '' : res[0].traffic_signal_etc_front_location_distance4,  //信号・踏切 手前 距離（左から4つめ）
        visibilityFactorBuilding: res[0].visibility_factor_building,                                     //視界性 建物（左から1つめ）
        visibilityFactorBuilding2: res[0].visibility_factor_building2,                                   //視界性 建物（左から2つめ）
        visibilityFactorBuilding3: res[0].visibility_factor_building3,                                   //視界性 建物（左から3つめ）
        visibilityFactorBuilding4: res[0].visibility_factor_building4,                                   //視界性 建物（左から4つめ）
        visibilityFactorSignPost: res[0].visibility_factor_sign_post,                                    //視界性 サインポール（左から1つめ）
        visibilityFactorSignPost2: res[0].visibility_factor_sign_post2,                                  //視界性 サインポール（左から2つめ）
        visibilityFactorSignPost3: res[0].visibility_factor_sign_post3,                                  //視界性 サインポール（左から3つめ）
        visibilityFactorSignPost4: res[0].visibility_factor_sign_post4,                                  //視界性 サインポール（左から4つめ）
        parkingArea: res[0].parking_area,                                                                //有効駐車場面積 面積（㎡）
        parkingtsuboArea: res[0].parkingtsubo_area,                                                      //有効駐車場面積 面積（坪）
        parkingCapacity: res[0].parking_capacity,                                                        //有効駐車場面積 駐車可能台数
        parkingTangentLineFrontage: res[0].parking_tangent_line_frontage,                                //駐車場接線 前面
        parkingTangentLineSideWayA: res[0].parking_tangent_line_sideway_a,                               //駐車場接線 側道A
        parkingTangentLineSideWayB: res[0].parking_tangent_line_sideway_b,                               //駐車場接線 側道B
        parkingTangentLineBackstreet: res[0].parking_tangent_line_backstreet,                            //駐車場接線 裏道
        no1EntranceFrontage: res[0].no1_entrance_frontage,                                               //出入口 No.1 進入口 前面
        no1EntranceSideWayA: res[0].no1_entrance_sideway_a,                                              //出入口 No.1 進入口 側道A
        no1EntranceSideWayB: res[0].no1_entrance_sideway_b,                                              //出入口 No.1 進入口 側道B
        no1EntranceBackstreet: res[0].no1_entrance_backstreet,                                           //出入口 No.1 進入口 裏道                                       
        no1DepthFrontage: res[0].no1_depth_frontage,                                                     //出入口 No.1 奥行き 前面
        no1DepthSideWayA: res[0].no1_depth_sideway_a,                                                    //出入口 No.1 奥行き 側道A
        no1DepthSideWayB: res[0].no1_depth_sideway_b,                                                    //出入口 No.1 奥行き 側道B
        no1DepthBackstreet: res[0].no1_depth_backstreet,                                                 //出入口 No.1 奥行き 裏道
        no2EntranceFrontage: res[0].no2_entrance_frontage,                                               //出入口 No.2 進入口 前面
        no2EntranceSideWayA: res[0].no2_entrance_sideway_a,                                              //出入口 No.2 進入口 側道A
        no2EntranceSideWayB: res[0].no2_entrance_sideway_b,                                              //出入口 No.2 進入口 側道B
        no2EntranceBackstreet: res[0].no2_entrance_backstreet,                                           //出入口 No.2 進入口 裏道
        no2DepthFrontage: res[0].no2_depth_frontage,                                                     //出入口 No.2 奥行き 前面
        no2DepthSideWayA: res[0].no2_depth_sideway_a,                                                    //出入口 No.2 奥行き 側道A
        no2DepthSideWayB: res[0].no2_depth_sideway_b,                                                    //出入口 No.2 奥行き 側道B
        no2DepthBackstreet: res[0].no2_depth_backstreet,                                                 //出入口 No.2 奥行き 裏道
        completeScheduleYear: res[0].complete_schedule_year,                                             //未開通道路・開発地の場合 完了予定 年
        completeScheduleMonth: res[0].complete_schedule_month,                                           //未開通道路・開発地の場合 完了予定 月
    };
    return DataObj;
};

//寄付きページAPI登録
export const upsertDonationParam = (objDonation:any, plan_area_id:any, branch:any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Donation/v1/regist",
        query: {
            plan_area_id:plan_area_id,
            branch:branch,
            driving_lane_positive_frontage:objDonation.drivingLanePositiveFrontage,                         //走行車線 正 前面
            driving_lane_positive_sideway_a:objDonation.drivingLanePositiveSideWayA,                        //走行車線 正 側道A   
            driving_lane_positive_sideway_b:objDonation.drivingLanePositiveSideWayB,                        //走行車線 正 側道B
            driving_lane_positive_backstreet:objDonation.drivingLanePositiveBackstreet,                     //走行車線 正 裏道
            driving_lane_opposite_frontage:objDonation.drivingLaneOppositeFrontage,                         //走行車線 反 前面
            driving_lane_opposite_sideway_a:objDonation.drivingLaneOppositeSideWayA,                        //走行車線 反 側道A
            driving_lane_opposite_sideway_b:objDonation.drivingLaneOppositeSideWayB,                        //走行車線 反 側道B
            driving_lane_opposite_backstreet:objDonation.drivingLaneOppositeBackstreet,                     //走行車線 反 裏道
            median_strip_select:objDonation.medianStripSelect,                                              //中央分離帯 有無（左から1つめ）
            median_strip_select2:objDonation.medianStripSelect2,                                            //中央分離帯 有無（左から2つめ）
            median_strip_select3:objDonation.medianStripSelect3,                                            //中央分離帯 有無（左から3つめ）
            median_strip_select4:objDonation.medianStripSelect4,                                            //中央分離帯 有無（左から4つめ）
            median_strip_height:objDonation.medianStripSelect === '0' ? '' : objDonation.medianStripHeight,    //中央分離帯 高さ（左から1つめ）
            median_strip_height2:objDonation.medianStripSelect2 === '0' ? '' : objDonation.medianStripHeight2, //中央分離帯 高さ（左から2つめ）
            median_strip_height3:objDonation.medianStripSelect3 === '0' ? '' : objDonation.medianStripHeight3, //中央分離帯 高さ（左から3つめ）
            median_strip_height4:objDonation.medianStripSelect4 === '0' ? '' : objDonation.medianStripHeight4, //中央分離帯 高さ（左から4つめ）  
            sidewalk_select:objDonation.sidewalkSelect,                                                     //歩道 有無（左から1つめ）
            sidewalk_select2:objDonation.sidewalkSelect2,                                                   //歩道 有無（左から2つめ）
            sidewalk_select3:objDonation.sidewalkSelect3,                                                   //歩道 有無（左から3つめ）
            sidewalk_select4:objDonation.sidewalkSelect4,                                                   //歩道 有無（左から4つめ）
            sidewalk_height:objDonation.sidewalkSelect === '0' ? '' : objDonation.sidewalkHeight,          //歩道 高さ（左から1つめ）
            sidewalk_height2:objDonation.sidewalkSelect2 === '0' ? '' : objDonation.sidewalkHeight2,       //歩道 高さ（左から2つめ）
            sidewalk_height3:objDonation.sidewalkSelect3 === '0' ? '' : objDonation.sidewalkHeight3,       //歩道 高さ（左から3つめ）
            sidewalk_height4:objDonation.sidewalkSelect4 === '0' ? '' : objDonation.sidewalkHeight4,       //歩道 高さ（左から4つめ）
            road_width_door:objDonation.roadWidthDoor,                                                      //車道幅員 ドア（左から1つめ）
            road_width_door2:objDonation.roadWidthDoor2,                                                    //車道幅員 ドア（左から2つめ）
            road_width_door3:objDonation.roadWidthDoor3,                                                    //車道幅員 ドア（左から3つめ）
            road_width_door4:objDonation.roadWidthDoor4,                                                    //車道幅員 ドア（左から4つめ）
            road_width_forward:objDonation.roadWidthForward,                                                //車道幅員 先（左から1つめ）
            road_width_forward2:objDonation.roadWidthForward2,                                              //車道幅員 先（左から2つめ）
            road_width_forward3:objDonation.roadWidthForward3,                                              //車道幅員 先（左から3つめ）
            road_width_forward4:objDonation.roadWidthForward4,                                              //車道幅員 先（左から4つめ）
            road_width_front_location:objDonation.roadWidthFrontLocation,                                   //車道幅員 手前（左から1つめ）
            road_width_front_location2:objDonation.roadWidthFrontLocation2,                                 //車道幅員 手前（左から2つめ）
            road_width_front_location3:objDonation.roadWidthFrontLocation3,                                 //車道幅員 手前（左から3つめ）
            road_width_front_location4:objDonation.roadWidthFrontLocation4,                                 //車道幅員 手前（左から4つめ）
            slope_climb:objDonation.slopeClimb,                                                             //坂道（長さ1ｍ当り） 登（左から1つめ）
            slope_climb2:objDonation.slopeClimb2,                                                           //坂道（長さ1ｍ当り） 登（左から2つめ）
            slope_climb3:objDonation.slopeClimb3,                                                           //坂道（長さ1ｍ当り） 登（左から3つめ）
            slope_climb4:objDonation.slopeClimb4,                                                           //坂道（長さ1ｍ当り） 登（左から4つめ）
            slope_descend:objDonation.slopeDescend,                                                         //坂道（長さ1ｍ当り） 降（左から4つめ）
            slope_descend2:objDonation.slopeDescend2,                                                       //坂道（長さ1ｍ当り） 降（左から2つめ）
            slope_descend3:objDonation.slopeDescend3,                                                       //坂道（長さ1ｍ当り） 降（左から3つめ）
            slope_descend4:objDonation.slopeDescend4,                                                       //坂道（長さ1ｍ当り） 降（左から4つめ）
            traffic_signal_etc_forward_select:objDonation.trafficSignalEtcForwardSelect,                    //信号・踏切 先 有無（左から1つめ）
            traffic_signal_etc_forward_select2:objDonation.trafficSignalEtcForwardSelect2,                  //信号・踏切 先 有無（左から2つめ）
            traffic_signal_etc_forward_select3:objDonation.trafficSignalEtcForwardSelect3,                  //信号・踏切 先 有無（左から3つめ）
            traffic_signal_etc_forward_select4:objDonation.trafficSignalEtcForwardSelect4,                  //信号・踏切 先 有無（左から4つめ）
            traffic_signal_etc_forward_distance:objDonation.trafficSignalEtcForwardSelect === '0' ? '' : objDonation.trafficSignalEtcForwardDistance,     //信号・踏切 先 距離（左から1つめ）
            traffic_signal_etc_forward_distance2:objDonation.trafficSignalEtcForwardSelect2 === '0' ? '' : objDonation.trafficSignalEtcForwardDistance2,  //信号・踏切 先 距離（左から2つめ）
            traffic_signal_etc_forward_distance3:objDonation.trafficSignalEtcForwardSelect3 === '0' ? '' : objDonation.trafficSignalEtcForwardDistance3,  //信号・踏切 先 距離（左から3つめ）
            traffic_signal_etc_forward_distance4:objDonation.trafficSignalEtcForwardSelect4 === '0' ? '' : objDonation.trafficSignalEtcForwardDistance4,  //信号・踏切 先 距離（左から4つめ）
            traffic_signal_etc_front_location_select:objDonation.trafficSignalEtcFrontLocationSelect,       //信号・踏切 手前 有無（左から1つめ）
            traffic_signal_etc_front_location_select2:objDonation.trafficSignalEtcFrontLocationSelect2,     //信号・踏切 手前 有無（左から2つめ）
            traffic_signal_etc_front_location_select3:objDonation.trafficSignalEtcFrontLocationSelect3,     //信号・踏切 手前 有無（左から3つめ）
            traffic_signal_etc_front_location_select4:objDonation.trafficSignalEtcFrontLocationSelect4,     //信号・踏切 手前 有無（左から4つめ）
            traffic_signal_etc_front_location_distance:objDonation.trafficSignalEtcFrontLocationSelect === '0' ? '' : objDonation.trafficSignalEtcFrontLocationDistance,     //信号・踏切 手前 距離（左から1つめ）
            traffic_signal_etc_front_location_distance2:objDonation.trafficSignalEtcFrontLocationSelect2 === '0' ? '' : objDonation.trafficSignalEtcFrontLocationDistance2,  //信号・踏切 手前 距離（左から2つめ）
            traffic_signal_etc_front_location_distance3:objDonation.trafficSignalEtcFrontLocationSelect3 === '0' ? '' : objDonation.trafficSignalEtcFrontLocationDistance3,  //信号・踏切 手前 距離（左から3つめ）
            traffic_signal_etc_front_location_distance4:objDonation.trafficSignalEtcFrontLocationSelect4 === '0' ? '' : objDonation.trafficSignalEtcFrontLocationDistance4,  //信号・踏切 手前 距離（左から4つめ）
            visibility_factor_building:objDonation.visibilityFactorBuilding,                                //視界性 建物（左から1つめ）
            visibility_factor_building2:objDonation.visibilityFactorBuilding2,                              //視界性 建物（左から2つめ）
            visibility_factor_building3:objDonation.visibilityFactorBuilding3,                              //視界性 建物（左から3つめ）
            visibility_factor_building4:objDonation.visibilityFactorBuilding4,                              //視界性 建物（左から4つめ）
            visibility_factor_sign_post:objDonation.visibilityFactorSignPost,                               //視界性 サインポール（左から1つめ）
            visibility_factor_sign_post2:objDonation.visibilityFactorSignPost2,                             //視界性 サインポール（左から2つめ）
            visibility_factor_sign_post3:objDonation.visibilityFactorSignPost3,                             //視界性 サインポール（左から3つめ）
            visibility_factor_sign_post4:objDonation.visibilityFactorSignPost4,                             //視界性 サインポール（左から4つめ）
            parking_area:objDonation.parkingArea,                                                           //有効駐車場面積 面積（㎡）
            parkingtsubo_area:tsuboFunc(objDonation.parkingArea),                                           //有効駐車場面積 面積（坪）
            parking_capacity:objDonation.parkingCapacity,                                                   //有効駐車場面積 駐車可能台数
            parking_tangent_line_frontage:objDonation.parkingTangentLineFrontage,                           //駐車場接線 前面
            parking_tangent_line_sideway_a:objDonation.parkingTangentLineSideWayA,                          //駐車場接線 側道A
            parking_tangent_line_sideway_b:objDonation.parkingTangentLineSideWayB,                          //駐車場接線 側道B
            parking_tangent_line_backstreet:objDonation.parkingTangentLineBackstreet,                       //駐車場接線 裏道
            no1_entrance_frontage:objDonation.no1EntranceFrontage,                                          //出入口 No.1 進入口 前面
            no1_entrance_sideway_a:objDonation.no1EntranceSideWayA,                                         //出入口 No.1 進入口 側道A
            no1_entrance_sideway_b:objDonation.no1EntranceSideWayB,                                         //出入口 No.1 進入口 側道B
            no1_entrance_backstreet:objDonation.no1EntranceBackstreet,                                      //出入口 No.1 進入口 裏道 
            no1_depth_frontage:objDonation.no1DepthFrontage,                                                //出入口 No.1 奥行き 前面
            no1_depth_sideway_a:objDonation.no1DepthSideWayA,                                               //出入口 No.1 奥行き 側道A
            no1_depth_sideway_b:objDonation.no1DepthSideWayB,                                               //出入口 No.1 奥行き 側道B
            no1_depth_backstreet:objDonation.no1DepthBackstreet,                                            //出入口 No.1 奥行き 裏道
            no2_entrance_frontage:objDonation.no2EntranceFrontage,                                          //出入口 No.2 進入口 前面
            no2_entrance_sideway_a:objDonation.no2EntranceSideWayA,                                         //出入口 No.2 進入口 側道A
            no2_entrance_sideway_b:objDonation.no2EntranceSideWayB,                                         //出入口 No.2 進入口 側道B
            no2_entrance_backstreet:objDonation.no2EntranceBackstreet,                                      //出入口 No.2 進入口 裏道
            no2_depth_frontage:objDonation.no2DepthFrontage,                                                //出入口 No.2 奥行き 前面
            no2_depth_sideway_a:objDonation.no2DepthSideWayA,                                               //出入口 No.2 奥行き 側道A
            no2_depth_sideway_b:objDonation.no2DepthSideWayB,                                               //出入口 No.2 奥行き 側道B
            no2_depth_backstreet:objDonation.no2DepthBackstreet,                                            //出入口 No.2 奥行き 裏道
            complete_schedule_year:objDonation.completeScheduleYear,                                        //未開通道路・開発地の場合 完了予定 年
            complete_schedule_month:objDonation.completeScheduleMonth,                                      //未開通道路・開発地の場合 完了予定 月
        }
    }
    return params
}

//㎡から坪へ変換
export const tsuboFunc = (total:string) => {
    return total === "" ? "" : String(Number(total)*0.3025)
};
import { createSlice } from '@reduxjs/toolkit';

//Sliceの作成
export const trafficVolumeSlice = createSlice({
    name: 'trafficVolume',
    initialState: [],

    //toolKitを利用した値変更処理
    reducers: {
        setTrafficVolume: (state, action) => {
            return action.payload;
        },
    }
});

export const {
    setTrafficVolume
} = trafficVolumeSlice.actions;
export default trafficVolumeSlice.reducer;
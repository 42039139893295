import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useUserContext } from '../../user-provider';
import PdfViewer from '../../utility/PdfViewer';
import { useUtilityContext } from '../../utility-provider';
import { apiRequest } from '../../api/ApiCall';
import { setRRdocumentPdfPath } from '../../features/longPageSlice';
import { setRRinternalUsePdf } from '../../features/rrInternalUseSlice';
import { getFileData } from '../../utility/storage';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  } from '@mui/material';
import { LOCAL_CONSTANT } from "../Const";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  getFileMetadata,
  uploadFile,
  deleteUploadFile
} from '../../utility/storage';
import MessageDialog from '../components/MessageDialog';
import {
	uploadFileJsonPathFunc,
	upsertLocationBaseFileParam,
	upsertLocationBaseDeleteFileParam,
} from '../components/LocationBaseFunc';
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import { setPermissionFunc, showHideFunc } from '../components/PermissionFunc';
import { staffName } from '../Common';

const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color : 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};

export const RRinternalUse = () => {

	//調査書基本を取得
  	const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

	//ユーザーIDを取得
	const [userId, setUserId] = useState<string>("");
	const [userType, setUserType] = useState("");
	const user = useUserContext();
  
  	const utilityCtx = useUtilityContext();

  	const [message, setMessage] = React.useState<string>("");
  	const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
  	const [hasButton, setHasButton] = React.useState<boolean>(false);
 
	//プレビュー
  	const previewPdfPath = useAppSelector((state) => state.rrInternalUse.pdfPath);

	//候補地NOと枝を取得
	const objLongPageParam = useAppSelector((state) => state.longPage.param);

	const dispatch = useAppDispatch();

	//モーダル開閉フラグ(削除確認)
	const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
	const [path, setPath] = useState<string>("");
	const [fileName, setFileName] = useState<string>("");
	const [date, setDate] = useState<string>("");

	const handleDeleteConfModalOpen = (pathParam:string, nameParam:string, dateParam:string) => {
		setDeleteConfModalOpen(true);
		setPath(pathParam);
		setFileName(nameParam);
		setDate(dateParam);
	}
	
	const handlePreviewChange = (pdfPath: string) => {
		const path = 'gs://'
			+ process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
			+ '/'
			+ objLongPageParam.planAreaId
			+ '/'
			+ objLongPageParam.branch
			+ pdfPath
		// PDFのときのみプレビューを表示
		if (path.toLowerCase().includes('.pdf')) {
			//表示中のPDFレコードを押下時非表示
			if(previewPdfPath === path){
				dispatch(setRRinternalUsePdf(""));
			} else {
				dispatch(setRRinternalUsePdf(path));
			}
		}
	};
	
	const closeMessageDialog = () => {
		setMessage("");
		setHasButton(false);
		setMessageDialogOpen(false);
	};

	const handleClickFile = () => {
		const fileElem = document.getElementById("rrInternalUseFileElem");
		if (fileElem) {
			fileElem.click();
		}
	};

	const handleChangeFile = (newFile: any) => {
		fileUpload(newFile.target.files[0]);
		newFile.target.value = '';
	};

  //ファイル保存
  const fileDownload = (fileName: string) => {
    const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/rr_document/' + fileName;
    (async () => {
      try {
        const url = await getFileData(filePath);
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = fileName;
          link.href = window.URL.createObjectURL(blob);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open('GET', url as string);
        xhr.send();
      }catch(e){
        console.log(e);
      }
    })();
  }

	//ファイルアップロード
	const fileUpload = (file:any) => {
		if (utilityCtx.showSpinner) {
			utilityCtx.showSpinner()
		}
		(async () => {
			try {
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_FILE)
				setMessageDialogOpen(true)     
				await uploadFile(file, objLongPageParam.planAreaId+'/'+objLongPageParam.branch+'/rr_document/'+file.name);
				const uploadResultFile = await getFileMetadata(objLongPageParam.planAreaId+'/'+objLongPageParam.branch+'/rr_document/'+file.name);
				const newUploadFile = uploadFileJsonPathFunc(uploadResultFile, userId);
				//DB保存
				updateRegist(upsertLocationBaseFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newUploadFile, 'rr_document_pdf_path', userId, file.name));
				//Redux保存
				dispatch(setRRdocumentPdfPath([...objLocationBase.rr_document_pdf_path, newUploadFile]));
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_FILE)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			} catch(e) {
				console.log(e);
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_FILE)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			} 
		})();
	};
	
	//ファイル削除
	const handleDeleteChange = () => {
		const newListUploadFile = objLocationBase.rr_document_pdf_path.filter(row => row.file_path !== path && row.upload_date !== date) //選択したpathとupload_date以外を返す
		if (utilityCtx.showSpinner) {
			utilityCtx.showSpinner();
		}
		(async () => {
			try {
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
				setMessageDialogOpen(true)
				//CloudStorageから削除対象のファイル情報を取得
				const deleteFile = await getFileMetadata('/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+path);
				await deleteUploadFile(deleteFile, '/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+path);
				//DB保存
				updateRegist(upsertLocationBaseDeleteFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newListUploadFile, 'rr_document_pdf_path', fileName));
				//Redux保存
				dispatch(setRRdocumentPdfPath([...newListUploadFile]));
				//プレビューを戻す
				dispatch(setRRinternalUsePdf(""));
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			} catch (e) {
				console.log(e);
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			}
		})();
	}
	
	const updateRegist = (params: any) => {
		if (utilityCtx.showSpinner) {
			utilityCtx.showSpinner();
		}
		(async () => {
			try {
				await apiRequest(params);
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			} catch (e) {
				console.log(e);
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			}
		})();
	}


	//ユーザーID取得
	useEffect(() => {
		if (JSON.parse(user.userData || 'null') !== null) {
			setUserId(JSON.parse(user.userData || 'null').staff_number);
			setUserType(JSON.parse(user.userData || 'null').user_type);
		}
	}, [user]);


	return (
		<Box style={{ width: "100%", margin: "30px" }}>

			<Box>
				<MessageDialog props={{
					open:messageDialogOpen,
					handleModal:closeMessageDialog,
					body:message,
					hasButton:hasButton,
					buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
				}}
				/>
				<input
					id="rrInternalUseFileElem"
					type="file"
					style={{display:'none'}}
					onChange={handleChangeFile}
					accept=".pdf,.xls,.xlsx"
				/>
				{
					showHideFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE,'uploadButton') &&
					<>
						<Button
							sx={{margin: '20px 0'}}
							variant="contained"
							color="success"
							size="small"
							onClick={handleClickFile}
							disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE,'uploadButton')}
						>{LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
					</>
				}
			</Box>
			<Grid container spacing={1}>
				<Grid item xs={12} sx={{ paddingRight: "40px" }}>
					<TableContainer sx={{ height: "424px", width: "100%",border: '1px solid black' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
									<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
									<TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
									<TableCell sx={headerCell}></TableCell>
                  <TableCell sx={headerCell}></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{objLocationBase.rr_document_pdf_path.map((data, index) => (
									<TableRow className="tableRow" key={index}>
										<TableCell onClick={()=>handlePreviewChange(data.file_path)}>{data.file_name}</TableCell>
										<TableCell onClick={()=>handlePreviewChange(data.file_path)}>{data.upload_date}</TableCell>
										<TableCell onClick={()=>handlePreviewChange(data.file_path)}>{staffName(data.upload_staff_number)}</TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(data.file_name)}><DownloadIcon /></IconButton>
                    </TableCell>
										<TableCell>
											<IconButton sx={{color:'black'}} onClick={()=>handleDeleteConfModalOpen(data.file_path, data.file_name, data.upload_date)}><DeleteIcon/></IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>

			{/* プレビュー */}
			{previewPdfPath !== "" && (
				<Box sx={{ paddingTop: "30px" }}>
					<PdfViewer props={{pdfPath: previewPdfPath}}/>
				</Box>
    		)}

			{/* 削除確認モーダル */}
			<DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />

		</Box>
	);

}

export default RRinternalUse;

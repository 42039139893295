import { createSlice } from '@reduxjs/toolkit';
import { initPdfData, initEditParams } from '../data/type';

//Sliceの作成
export const impactSlice = createSlice({
    name: 'impact',
    initialState: {
        impactData: [],
        pdfData: initPdfData,
        editImpactData: [], // 影響度編集用
        editParams: initEditParams,
    },

    //toolKitを利用した値変更処理
    reducers: {
        setObjImpact: (state, action) => {
            state.impactData = action.payload;
        },
        setObjPdfData: (state, action) => {
            state.pdfData = action.payload;
        },
        setObjEditImpactData: (state, action) => {
            state.editImpactData = action.payload;
        },
        setObjEditParams: (state, action) => {
            state.editParams = action.payload;
        },
    }
});

export const {
    setObjImpact,
    setObjPdfData,
    setObjEditImpactData,
    setObjEditParams,
} = impactSlice.actions;
export default impactSlice.reducer;
import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
} from '@mui/material';
import { useUtilityContext } from '../../../utility-provider';
import { LOCAL_CONSTANT } from "../../Const";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    height: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    justifyContent: 'center'
};

export interface countDateProps {
    props:{
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        data: any;
        trafficType: any;
        setCountDate: any;
    }
}
const CountDate:React.FC<countDateProps> = ({props}) => {
    const [open, setOpen] = React.useState(false);
    const [countHour, setCountHour] = React.useState<string>("");
    const [countMinute, setCountMinute] = React.useState<string>("");
    const [countTime, setCountTime] = React.useState<string>("");
    const [trafficTypeData, setTrafficTypeData] = React.useState<any>();

    const utilityCtx = useUtilityContext();

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
    };

    const registCountDate = () => {
        let errorMessage = ""
        let countHourMinute = (countHour.toString().padStart(2, '0') + countMinute.toString().padStart(2, '0'))
        if (Number(countHourMinute) < trafficTypeData.min || Number(countHourMinute) > trafficTypeData.max) {
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_COUNT_START_TIME.replace('__RANGE_TIME__', trafficTypeData.range)
        }
        if ((Number(countMinute) + Number(countTime)) >= 60) {
            const tmpHour = (Number(countHour) + 1).toString().padStart(2, '0')
            const tmpMinute = ((Number(countMinute) + Number(countTime)) - 60).toString().padStart(2, '0')
            countHourMinute = tmpHour + tmpMinute
        } else {
            countHourMinute = countHour.toString().padStart(2, '0') + (Number(countMinute) + Number(countTime)).toString().padStart(2, '0')
        }

        if (Number(countHourMinute) < trafficTypeData.min || Number(countHourMinute) > trafficTypeData.max) {
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_COUNT_END_TIME.replace('__RANGE_TIME__', trafficTypeData.range)
        }

        if (errorMessage !== "") {
            const message: any = errorMessage.slice(0, -1).split("\n").map((line, key) => <span key={key}>{line}<br /></span>)
            if (utilityCtx.showSnackbar) {
                utilityCtx.showSnackbar("warning", message);
            }
            return
        } else {
            props.setCountDate(props.data, countHour, countMinute, countTime)
            setOpen(false)
            props.handleModal(false)    
        }
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open)
        }
    }, [props.open])

    React.useEffect(() => {
        if (props.data) {
            setCountHour(Number(props.data.count_date.substring(10, 13)).toString())
            setCountMinute(props.data.count_date.substring(14, 16))
            setCountTime(props.data.count_time)
            switch (props.data.traffic_type) {
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12)
                    break;
                case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal:
                    setTrafficTypeData(LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13)
                    break;
            }
        }
    }, [props.data])

    const timeRange = () => {
        let timeRange = ""
        if (props.data && trafficTypeData) {
            timeRange = trafficTypeData.name
        }

        return timeRange
    }

    const REGEX_NUMBER = /^[0-9]*$/g;

    const changeHandleSurveyTime = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, timeZoon: string) => {
        let input = e.target.value;
        if (input.match(REGEX_NUMBER)) {
            if (timeZoon === 'hour') {
                if (Number(e.target.value) > 23) {
                    return
                }
                setCountHour(e.target.value)    
            } else if (timeZoon === 'minute') {
                if (Number(e.target.value) > 59) {
                    return
                }
                setCountMinute(e.target.value)
            } else if (timeZoon === 'time') {
                if (Number(e.target.value) > 60) {
                    return
                }
                setCountTime(e.target.value)
            }
            return true;
        } else {
            return
        }
    }

    return (
        <>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="set-count-date"
              aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                <Box sx={{ width: '90%' }}>
                    <Box sx={{ display: 'flex', margin: '10px' }}>
                        <Typography sx={{ width: '30%', padding: '14px' }}>{LOCAL_CONSTANT.LABEL.COUNT_TIME_RANGE}</Typography>
                        <Typography sx={{ padding: '14px' }}>{timeRange()}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', margin: '10px' }}>
                        <Typography sx={{ width: '30%', padding: '14px' }}>{LOCAL_CONSTANT.LABEL.COUNT_START_TIME}</Typography>
                        <Box sx={{ width: '70%', display: 'flex' }}>
                            <TextField
                                sx={{ width:'30%' }}
                                value={countHour}
                                onChange={(e) => {changeHandleSurveyTime(e, 'hour')}}
                            ></TextField>
                            <Typography sx={{ padding: '14px' }}>{LOCAL_CONSTANT.LABEL.COLON}</Typography>
                            <TextField
                                sx={{ width:'30%' }}
                                value={countMinute}
                                onChange={(e) => {changeHandleSurveyTime(e, 'minute')}}
                            ></TextField>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', margin: '10px' }}>
                        <Typography sx={{ width: '30%', padding: '14px' }}>{LOCAL_CONSTANT.LABEL.COUNT_TIME}</Typography>
                        <Box sx={{ width: '70%' }}>
                            <TextField
                                sx={{ width:'30%' }}
                                value={countTime}
                                onChange={(e) => {changeHandleSurveyTime(e, 'time')}}
                            ></TextField>
                        </Box>
                    </Box>
                    <Box sx={{ width: '90%', bottom: 0, position: 'absolute'}}>
                        <Box sx={{ width: '100%', margin: '10px 0', display: 'flex', justifyContent: 'space-around' }}>
                            <Button 
                            variant="outlined" color="error" size="small"
                            onClick={handleClose}
                            >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                            <Button
                            variant="contained" color="success" size="small"
                            onClick={registCountDate}
                            >{LOCAL_CONSTANT.LABEL.REGIST_LABEL}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
          </Modal>
        </>
    );
}

export default CountDate;